export default {
  title: 'Reset your password',
  newPassword: 'New password',
  confirmPassword: 'Confirm new password',
  passwordStrength: 'Password strength',
  modifyPassword: 'Change password',
  successNotif: 'Your password has been modified',
  errorNotif: 'There has been an error. Please try again later',
  email: 'E-mail',
  text: 'This link is invalid. Enter your e-mail address below to receive a new link.',
  resendEmail: 'Get a new link',
  emailSent: 'A new link has been sent, please check your inbox.',
  passwordsDontMatch: "Passwords don't match.",
};
