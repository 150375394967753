import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Page, Button, PageMeta } from '@breteuil-website/components/common';
import texts from './texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const ServicesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  "@media (max-width: 960px)": {
    width: '100%',
  },
});

const CommonImage = styled('img')({
  width: '50%',
  "@media (max-width: 960px)": {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    maxWidth: 'unset',
  },
});

const CommonImageSm = styled(('img'))({
  maxWidth: '300px',
  "@media (max-width: 960px)": {
    width: '100%',
    maxWidth: 'unset',
    height: '300px',
    objectFit: 'cover',

  },
});

const CommonImageSmAbs = styled('img')({
  position: 'absolute',
  maxWidth: '300px',
  height: '200px',
  top: '15%',
  right: '-70%',
});

const CommonTextContainer = styled('div')({
  width: '45%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '400px',
  "@media (max-width: 960px)": {
    width: '90%',
    margin: '40px auto',
  },
});

const CommonTextTitle = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '18px',
  color: '#050251',
});

const CommonTextDescription = styled('span')({
  fontFamily: 'Mulish',
  fontWeight: 400,
  fontSize: '14px',
  color: '#050251',
  marginTop: '20px',
  "@media (max-width: 960px)": {
  },
});

const ArchitectTextContainer = styled(CommonTextContainer)({
  maxWidth: '330px',
  margin: '5%',
  justifyContent: 'unset',
  "@media (max-width: 960px)": {
    margin: 'auto',
  },
});

const EntrepreneurTextContainer = styled(CommonTextContainer)({
  margin: 'auto auto 10% auto',
  "@media (max-width: 960px)": {
    margin: '40px auto',
  },
});

const ProjectContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  height: '950px',
  flexDirection: 'column',
  width: '90%',
  maxWidth: '1050px',
  margin: 'auto',
  "@media (max-width: 960px)": {
    height: 'unset',
    width: '100%',
  },
});

const AfterProjectContainer = styled(ProjectContainer)({
  height: '1400px',
  maxWidth: '1200px',

  "@media (max-width: 960px)": {
    height: 'unset',
  },
});


const ProjectTitle = styled('div')({
  width: '100%',
  position: 'sticky',
  top: '90px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '44px',
  textAlign: 'center',
  backgroundColor: 'rgba(5, 2, 81, 0.90)',
  color: 'white',
  padding: '15px 0',
  zIndex: 3,
  "@media (max-width: 960px)": {
    width: '100%',
    margin: 'auto',
    fontSize: '18px',
    top: '50px',
  },
});

const ArchitectBanner = styled('div')({
  position: 'absolute',
  top: '75px',
  left: '-5%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '95%',
  maxWidth: '1200px',
  margin: '30px auto',
  "@media (max-width: 960px)": {
    width: '100%',
    position: 'unset',
    flexDirection: 'column',
  },
});

const EntrepreneurBanner = styled('div')({
  position: 'absolute',
  top: '325px',
  left: '5%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '90%',
  maxWidth: '1050px',
  margin: '30px auto',
  "@media (max-width: 960px)": {
    width: '100%',
    position: 'unset',
    flexDirection: 'column',
    margin: 'auto',
  },
});

const CraftImageContainer = styled('div')({
  position: 'relative',
});

const CraftBanner = styled('div')({
  position: 'absolute',
  top: '800px',
  left: '0',
  display: 'flex',
  flexDirection: 'row',
  width: '90%',
  maxWidth: '750px',
  margin: '30px auto',
  justifyContent: 'space-between',
  "@media (max-width: 960px)": {
    width: '100%',
    position: 'unset',
    flexDirection: 'column',
  },
});

const ServiceTextButton = styled(Button)({
  maxWidth: "215px",
  fontSize: '12px !important',
  fontFamily: 'Poppins',
  textTransform: 'uppercase',
  fontWeight: 700,
  backgroundColor: '#050251 !important',
  margin: 'auto auto 100px auto !important',
  borderRadius: '5px',
  padding: '20px !important',
});

const ProjectWrapper = styled('div')({
  position: 'relative',
  marginBottom: '5em',
});

const Services = observer(() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <ServicesContainer>
        <ProjectWrapper>
          <ProjectTitle style={{ zIndex: 4 }}>{T.title()}</ProjectTitle>
          <AfterProjectContainer>
            <ArchitectBanner>
              <ArchitectTextContainer>
                <CommonTextTitle>{T.architectTitle}</CommonTextTitle>
                <CommonTextDescription>{T.architectDescription}</CommonTextDescription>
              </ArchitectTextContainer>
              {isMobile ? null : <CommonImage src={"/assets/service_architect.jpg"} style={{ width: '60%', maxWidth: '500px', zIndex: 1 }}/>}
            </ArchitectBanner>
            <EntrepreneurBanner>
              <CommonImage src={"/assets/service_entrepreneur.jpg"} style={{ maxWidth: '450px' }}/>
              <EntrepreneurTextContainer>
                <CommonTextTitle>{T.entrepreneurTitle}</CommonTextTitle>
                <CommonTextDescription>{T.entrepreneurDescription}</CommonTextDescription>
              </EntrepreneurTextContainer>
            </EntrepreneurBanner>
            <CraftBanner>
              {isMobile ? <CommonImageSm src={"/assets/service_craft.jpg"}/> : null}
              <CommonTextContainer style={{ maxWidth: '350px' }}>
                <CommonTextTitle>{T.craftTitle}</CommonTextTitle>
                <CommonTextDescription>{T.craftDescription}</CommonTextDescription>
              </CommonTextContainer>
              <CraftImageContainer>
                {isMobile ? null : <CommonImageSm src={"/assets/service_craft.jpg"}/>}
                {isMobile ? null : <CommonImageSmAbs src={"/assets/service_craft_add.jpg"}/>}
              </CraftImageContainer>
            </CraftBanner>
            <ServiceTextButton href={`mailto:services@breteuilhomes.com`} variant="contained">
              {T.contactService}
            </ServiceTextButton>
          </AfterProjectContainer>
        </ProjectWrapper>
      </ServicesContainer>
    </Page>
  );
});

export default Services;
