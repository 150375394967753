import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';

import { LogIn, Register, IsEmailUsedForClientArea, LoginWithCodeArgs, LoginWithCode } from './api';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { action, observable } from 'mobx';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { type QueryStore, wrapStoreQuery } from '@repo-breteuil/front-store-query';

export class LogInStore
{
  constructor(
    public stores: {
      query: QueryStore,
      session: SessionStore,
    },
  )
  {
  }

  @observable private _userEmail: string | null = null;

  @action public setUserEmail(userEmail: string | null)
  {
    this._userEmail = userEmail;
  }

  public get userEmail()
  {
    return this._userEmail;
  }

  public async logIn(
    args: Parameters<typeof LogIn>[1],
  ): Promise<ClientAreaSession>
  {
    const session = await LogIn(this.stores.query, args);
    if (session)
    {
      this.setUserEmail(null);
      this.isEmailUsedForClientArea.reset();
    }
    return this.stores.session.ensureContactSession(session);
  }

  public async register(
    args: Parameters<typeof Register>[1],
  ): Promise<ClientAreaSession>
  {
    const session = await Register(this.stores.query, args);
    if (session)
    {
      this.setUserEmail(null);
      this.isEmailUsedForClientArea.reset();
    }
    return this.stores.session.ensureContactSession(session);
  }

  public async loginWithCode(
    args: LoginWithCodeArgs,
  ): Promise<ClientAreaSession>
  {
    const session = await LoginWithCode(this.stores.query, args);
    if (session)
    {
      this.setUserEmail(null);
      this.isEmailUsedForClientArea.reset();
    }
    return this.stores.session.ensureContactSession(session);
  }

  public isEmailUsedForClientArea = new Fetchable(wrapStoreQuery(this, IsEmailUsedForClientArea), {catchUnhandled: handleNonCriticalError});
}
