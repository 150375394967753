export default {
  buy: 'ACHETER',
  rent: 'LOUER',
  seasonalRental: "LOCATION SAISONNIERE",
  valuation: 'ESTIMATION',
  services: 'SERVICES',
  joinUs: "REJOIGNEZ-NOUS",
  contact: 'NOUS CONTACTER',
  marketData: 'DONNÉES DE MARCHÉ',
  logoAlt: 'Breteuil logo',
  languages: {
    french: 'FR',
    english: 'EN',
  },
  logOut: 'Se déconnecter',
};
