import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Typography, Stack, IconButton } from '@mui/material';
import {
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material';
import { Button } from '@breteuil-website/components/common';
import texts from '../texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const StyledButton = styled(Button)({
  width: 'auto',
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.55)',
  bottom: '15px',
  right: '50%',
  transform: 'translateX(50%)',
  zIndex: 1000,
  color: '#050251',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
  },
});

const StyledIconButton = styled(IconButton)({
  width: 'auto',
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.55)',
  bottom: '15px',
  right: '50%',
  transform: 'translateX(50%)',
  zIndex: 1000,
  color: '#050251',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
  },
});

const InheritFontStack = styled(Stack)({
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
});

const InheritFontTypography = styled(Typography)({
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
});

interface FullscreenButtonProp {
  handleClick: () => void,
  buttonPosition: React.CSSProperties,
  fullscreen: boolean,
}

const FullscreenButton = observer((props: FullscreenButtonProp) => {
  const { handleClick, buttonPosition, fullscreen } = props;
  const isDesktop = useMediaQueryDesktop();
  const CustomButton = isDesktop ? StyledButton : StyledIconButton;
  const T = useTexts(texts);
  return (
    <CustomButton
      onClick={handleClick}
      disableRipple
      style={buttonPosition}
    >
      {isDesktop ? (
        <InheritFontStack direction="row" alignItems="center">
          <InheritFontTypography>{!fullscreen ? T.fullscreen : T.exitFullscreen}</InheritFontTypography>
          {!fullscreen ? <Fullscreen/> : <FullscreenExit/>}
        </InheritFontStack>
      ) : (
        !fullscreen ? <Fullscreen/> : <FullscreenExit/>
      )}
    </CustomButton>
  );
});

export default FullscreenButton;
