import { QueryStore } from '@repo-breteuil/front-store-query';
import type { TransactionFilter } from './types';

export interface NumberStats
{
  avg: number,
}

export interface TransactionsStats
{
  adjustedPricePerSurface: NumberStats | null,
}

export default async function GetTransactionsStatsGov(
  qs: QueryStore,
  args: {
    filter: TransactionFilter,
  },
): Promise<TransactionsStats> {
  return qs.query({
    operationName: 'GetTransactionsStatsGov',
    variables: {
      filter: 'Filters__Transaction',
    },
    fieldsSelection: {
      public: {
        transactionsStatsGov: [{ args: { filter: true } }, {
          adjustedPricePerSurface: {
            avg: true,
          },
        }],
      },
    },
  }, args);
}
