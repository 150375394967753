export default {
  title: 'Alertes emails',
  newSearch: '+ Nouvelle alerte',
  operation: 'Opération',
  operations: {
    buy: 'Achat',
    rent: 'Location',
  },
  sqMeter: 'm²',
  label: 'Libellé',
  city: 'Ville',
  districts: 'Quartiers',
  budget: 'Budget',
  min: 'Min',
  max: 'Max',
  surface: 'Surface',
  bedrooms: 'Chambres',
  frequency: 'Fréquence alerte',
  frequencies: {
    instant: 'Instantanée',
    daily: 'Quotidienne',
  },
  stopNotif: 'Suspendre notifications',
  addAlert: "Enregistrer",
  updateAlert: "Modifier",
  receiveAlertsBy: 'Recevoir mes alertes par',
  email: 'Activer les alertes par e-mail',
  sms: 'SMS',
  whatsApp: 'WhatsApp',
  error: 'Une erreur est survenue, veuillez recharger la page.',
  myAlerts: 'Mes alertes',
  searchCreationSuccessMessage: 'Votre alerte a bien été créée',
  searchCreationErrorMessage: "Nous n'avons pas pu créer votre alerte, veuillez réessayer plus tard",
  searchUpdateSuccessMessage: 'Votre alerte a été modifiée',
  searchUpdateErrorMessage: "Nous n'avons pas pu modifier votre alerte, veuillez réessayer plus tard",
  confirmDeletion: 'Êtes vous sûr de vouloir supprimer cette alerte ?',
  yes: 'Oui',
  no: 'Non',
  deletionSuccessToast: 'La recherche a bien été supprimée',
  deletionErrorToast: "Nous n'avons pas pu supprimer votre alerte, veuillez réessayer plus tard",
  cannotLoad: 'Erreur lors du chargement de vos alertes, veuillez recharger la page.',
  searchLimit: 'Vous ne pouvez créer que 4 alertes.',
  searchLimitReached: 'Vous avez atteint la limite de 4 alertes par compte.',
};
