//TODO: this directory contains hardcoded values that should be returned by the API. Remove it once it's done

export * from './areasFRNamePrepositionDeLieu';
export * from './agencies';
export * from './agents';
export * from './searchLimit';
export * from './parisWithDistricts';
export const pdfRatesURL = "https://cdn.bre.im/BreteuilWebsite/honoraires.pdf";
export const pdfPointMarcheURL = "https://newsletter.mybreteuil.com/v2/assets/files/lettre_taux.pdf";
export * from './months';
export const clientMoneyProtection = "https://cdn.bre.im/BreteuilWebsite/Propertymark_CMP_Security_Certificate.pdf";
export const ukFees = "https://cdn.bre.im/BreteuilWebsite/honoraires-london.pdf";
export const inHouseComplaints = "https://cdn.bre.im/BreteuilWebsite/TPO_In_House_Complaints_Procedure_Template.docx";
export * from './validation';
export * from './contact';
