import type { IdType } from '@repo-breteuil/common-definitions';
import type { FormFieldSelectMultiple } from '@repo-lib/mobx-forms';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@breteuil-website/components/common';
import { styled } from '@mui/material';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  lineHeight: '18px !important',
  fontWeight: 600,
  fontSize: '13px',
  padding: '0px 15px',
  ...(isSelected ? {
    backgroundColor: '#CDBB95 !important',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, .3)',
  } : {
    backgroundColor: '#D8D8D8 !important',
    color: '#636363',
  }),
}));

const CriteriaList = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  rowGap: '4px',
  columnGap: '8px',
  '@media (max-width: 1280px)': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
  },
});

const Criteria = styled('div')({
  display: 'flex',
});

const OptionsGroup = (
  props: {
    options: Array<{ value: IdType, label: string }>,
    selected: Set<IdType>,
    onSelect: (value: IdType) => void,
  },
) => {
  const { options, selected, onSelect } = props;

  return (
    <Criteria>
      {options.map(({ label, value }) => (
        <StyledButton
          isSelected={selected.has(value)}
          key={value}
          onClick={() => onSelect(value)}
        >
          {label}
        </StyledButton>
      ))}
    </Criteria>
  );
};

const CriteriaSelect = observer((
  props: {
    field: FormFieldSelectMultiple<IdType>,
    criteriaGroups: Array<Array<{ id: IdType }>>,
  },
) => {
  const { field, criteriaGroups } = props;

  const handleSelect = React.useCallback((value: IdType) => {
    if (field.value.includes(value))
      field.handleChange(field.value.filter(item => (item !== value)));
    else
      field.handleChange(field.value.concat(value));
  }, [ field ]);

  const optionsGroups = React.useMemo(() => {
    const optionsGroups: Array<Array<{ value: IdType, label: string }>> = [];
    const unassignedOptions = new Map(field.options.options.map((option) => [ option.value, option ]));
    for (const criteriaGroup of criteriaGroups)
    {
      const optionsGroup = criteriaGroup.reduce((group, { id }) => {
        const option = unassignedOptions.get(id);
        if (option !== undefined)
        {
          group.push(option);
          unassignedOptions.delete(id);
        }
        return group;
      }, new Array<{ value: IdType, label: string }>());
      if (optionsGroup.length > 0)
        optionsGroups.push(optionsGroup);
    }
    for (const option of unassignedOptions.values())
      optionsGroups.push([ option ]);
    return optionsGroups;
  }, [ field.options, criteriaGroups ]);

  const selected = React.useMemo(
    () => new Set(field.value),
    [ field.value ],
  );

  return (
    <CriteriaList>
      {optionsGroups.map((group, i) => (
        <OptionsGroup key={i} options={group} selected={selected} onSelect={handleSelect}/>
      ))}
    </CriteriaList>
  );
});

export default CriteriaSelect;
