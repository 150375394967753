import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import type { FavoriteProperty } from '@breteuil-website/store/ui/pages/client-area/favorites/api';

import * as React from 'react';
import { observer } from "mobx-react";
import {
  styled,
  Stack,
  Typography,
} from '@mui/material';

const HeaderContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(3),
  marginBottom: theme.spacing(.5),
  alignItems: "baseline",
  color: "#050251",
  "@media (max-width: 960px)": {
    flexWrap: 'wrap',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(3),
  },
}));

const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "30px",
  fontWeight: 600,
  "@media (max-width: 960px)": {
    fontSize: "19px",
  },
  whiteSpace: 'nowrap',
});

const Area = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "17px",
  fontWeight: 400,
  "@media (max-width: 960px)": {
    fontSize: "15px",
  },
});

export interface PropertyCardHeaderProps {
  property: Property | FavoriteProperty,
  showSubArea?: boolean | undefined,
}

const PropertyCardHeader = observer((props: PropertyCardHeaderProps) => {
  const { property, showSubArea = true } = props;
  return (
    <HeaderContainer>
      <Title>{property.title}</Title>
      {showSubArea ? <Area>{property.subArea.name}</Area> : null}
    </HeaderContainer>
  );
});

export default PropertyCardHeader;
