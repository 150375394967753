import * as React from "react";

export default {
  pageTitle: "Nos services sur mesure - Breteuil Remarkable Homes",
  pageDescription: "Découvrez les services exclusifs de Breteuil Remarkable Homes, l'agence immobilière de prestige spécialisée dans l'immobilier haut de gamme.",
  title()  {
    return(
      <React.Fragment>
        <span>{"Les meilleurs partenaires dans chaque ville"}</span>
      </React.Fragment>
    );
  },
  beforeProject: "Avant projet",
  afterProject: "Après projet",
  brokerTitle: "Financement",
  brokerDescription: "Breteuil vous offre un service d'accès au crédit, par le biais de courtiers aguerris auprès desquels nos agents travaillent depuis de nombreuses années, et un service de conseil en gestion de patrimoine.",
  advisorsTitle: "Fiscalistes",
  advisorsDescription: "Nos avocats partenaires interviennent en conseil et contentieux  pour aider leurs clients à optimiser et maîtriser le poids de la fiscalité dans le cadre de leur patrimoine foncier.",
  architectTitle: "Architectes",
  architectDescription: "Les cabinets d'architecture soigneusement sélectionnés par Breteuil vous garantissent des projets d'exception, conformes à vos exigences et à votre budget.",
  entrepreneurTitle: "Entrepreneurs",
  entrepreneurDescription: "Maitres d'oeuvre et entreprises tous corps d'état : un spectre de professionnels fiables et qualifiés pour vous aider à maitriser vos coûts et vos délais.",
  craftTitle: "Artisans et domotique",
  craftDescription: "Mobilier sur-mesure, menuiserie d'exception, mais aussi maison connectée et contrôle énergétique : les meilleurs prestataires du dernier kilomètre sont dans notre carnet.",
  contactService: 'Nous contacter',
};
