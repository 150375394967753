import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition {
  pageTitle: string,
  metaDescription: string,
  titles: {
    excellence: string,
    transparency: string,
    ethics: string,
  },
  formatTitle(): JSX.Element,
  title: string,
  operationType: {
    transaction: string,
    rental: string,
    seasonalRental: string,
  },
  budget: string,
  search: string,
  everywhere: string,
  cities: {
    paris: string,
    london: string,
    newYork: string,
    deauville: string,
    dinard: string,
    laBaule: string,
    lisbon: string,
    biarritz: string,
    saintJeanDeLuz: string,
  },
  submitBannerForm: string,
  transactionTypes: Array<string>,
  citiesSelect: Array<string>,
  selectLabelCity: string,
  selectLabelTransactionType: string,
  implantations: string,
  implantationsText: string,
  implantationsTitleMobile: string,
  implantationsMobile: string,
  stJean: string,
  wantToSell: string,
  freeValuation: string,
  properties: {
    title: string,
    subtitle: string,
    seeMore: string,
    exclusive: string,
  },
  agencies : {
    HomeAgencyTitle: string,
    HomeAgencyDescription(): JSX.Element,
    HomeAgencyButton: string,
  },
  keyNumbers: {
    agencies(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbAgencies: number,
      },
    ): JSX.Element,
    employees(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbEmployees: number,
      },
    ): JSX.Element,
    cities(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbCities: number,
      },
    ): JSX.Element,
    keyNumbersQuote: string,
    keyNumbersLogoAltText: string,
    keyNumbersMapAnimText: string,

    // warrants(
    //   component: {
    //     Highlight: React.ComponentType<{ children: React.ReactNode }>,
    //   },
    // ): JSX.Element, // TODO
  },
  intro: {
    title: string,
    text1: string,
    text2: string,
    text3: string,
  },
  market: {
    title: string,
    button: string,
  },
  availableCoffee: {
    agentsAvailable: string,
    availability: string,
    agentsAvailableMobile() : JSX.Element,
    availabilityMobile() : JSX.Element,
    agentsAvailableDescription: string,
    contactUsLabel: string,
  },
  clientAreaBanner: {
    mobileTitle() : JSX.Element,
    title: string,
    description: string,
    mobileText() : JSX.Element,
    button: string,
    ipadAlt: string,
  },
  services: {
    title: string,
    description: string,
    button: string,
  },
  people: {
    title: string,
    description: string,
    button: string,
  },
  valuations: {
    title: string,
    description: string,
    button: string,
  },
  myMarket: {
    title() : JSX.Element,
    titleMobile: string,
    text: string,
    buttonLabel: string,
    imageAltTag: string,
  },
  ethics: {
    impactTitle: string,
    impactDesc: string,
    integrityTitle: string,
    integrityDesc: string,
    securityTitle: string,
    securityDesc: string,
    managementTitle: string,
    managementDesc: string,
    parityTitle: string,
    parityDesc: string,
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
