import type {
  PageRouteHandlers,
} from '@lib/routing';

import {
  setTranslatedStaticPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';
import setOptOutHandlers from './optOut';
import setHomeHandlers from './home';
import setPropertyHandlers from './property';
import setPropertiesSearchHandlers from './propertiesSearch';
import setPropertiesSearchRentHandlers from './propertiesSearchRental';
import setPropertiesSearchSeasonalRentHandlers from './propertiesSearchSeasonalRental';
import setAgenciesHandlers from './agencies';
import setValuationHandlers from './valuation';
import setContactHandlers from './contact';

import setClientAreaHandlers from './clientArea';

export default function setHandlers(handlers: PageRouteHandlers<RootPages>)
{
  const Public = handlers.createHandlersScope().extend((location, info) => {
    //Initialized session from storage asynchronously, non-blockingly
    websiteStores.session.initializeSessionFromStorage();
    return null;
  });

  setOptOutHandlers(handlers, { Public });
  setHomeHandlers(handlers, { Public });
  setPropertyHandlers(handlers, { Public });
  setPropertiesSearchHandlers(handlers, { Public });
  setPropertiesSearchRentHandlers(handlers, { Public });
  setPropertiesSearchSeasonalRentHandlers(handlers, { Public });
  setAgenciesHandlers(handlers, { Public });
  setClientAreaHandlers(handlers, { Public });
  setValuationHandlers(handlers, { Public });
  setContactHandlers(handlers, { Public });

  setTranslatedStaticPage(Public, RootPages.Services, Routes.services.routesByLanguage);
  setTranslatedStaticPage(Public, RootPages.About, Routes.about.routesByLanguage);
  setTranslatedStaticPage(Public, RootPages.OurFees, Routes.ourFees.routesByLanguage);
  setTranslatedStaticPage(Public, RootPages.JoinUs, Routes.joinUs.routesByLanguage);
  setTranslatedStaticPage(Public, RootPages.LegalNotice, Routes.legalNotices.routesByLanguage);
}
