import type { PropsWithChildren } from 'react';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { PageTitle } from '@breteuil-website/components/common';
import { Box } from '@mui/material';

const Container = styled(Box)({
  position: 'relative',
  width: '100%',
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(15)}`, // Utilise padding au lieu de margin
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2.5),
  },
}));

const MethodBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  backgroundColor: 'rgb(251, 251, 251)',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2.5),
  },
}));

export interface MethodContainerPorps {
  title: string,
  anchorId?: string | undefined,
}

const MethodContainer = (props: PropsWithChildren<MethodContainerPorps>) => {
  return (
    <React.Fragment>
      <Container {...omitUndefinedValues({ id: props.anchorId })}>
        <PageTitle title={props.title}/>
        <ContentWrapper>
          <MethodBody>
            {props.children}
          </MethodBody>
        </ContentWrapper>
      </Container>
    </React.Fragment>
  );
};

export default MethodContainer;
