import {
  type ThemeOptions,
  createTheme,
  useTheme,
} from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { FontFaces } from './fonts';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
} as const;

/* Imported from @core/components/basetheme.ts */
const baseThemeDefinition = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': FontFaces,
      },
    },
  },
} as const;
/* * */

const BaseTheme: ThemeOptions = {
  breakpoints: {
    values: breakpoints,
  },
};

const palette = {
  primary: {
    dark: '#000A4F',
    main: '#050251',
    light: '#005EF4',
  },
  secondary: {
    main: '#8E8E8E',
  },
} as const;

const themeDefinition = {
  ...baseThemeDefinition,
  ...BaseTheme,
  palette,
} as const;

export default createTheme(themeDefinition);

function makeMediaQueryHook(breakpoint: keyof typeof breakpoints)
{
  return () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(breakpoint));
  };
}

export const useMediaQueryMobile = makeMediaQueryHook('md');
export const useMediaQueryTablet = makeMediaQueryHook('lg');
export const useMediaQueryDesktop = () => {
  const isMobile = useMediaQueryMobile();
  const isTablet = useMediaQueryTablet();
  return !isMobile && !isTablet;
};
