import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  styled,
} from '@mui/material';
import { handleCriticalError } from '@repo-breteuil/front-error';
import { OperationType } from '@repo-breteuil/common-definitions';
import { Carousel } from '@repo-breteuil/react-components';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import PropertyCard from '@breteuil-website/components/common/fragments/property-card/PropertyCard';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  margin: '100px 0',
  textAlign: 'center',
  '@media (max-width: 960px)': {
    marginTop: '80px',
    marginBottom: '40px',
  },
});

const CardsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '75%',
  maxWidth: '1600px',
  margin: 'auto',
  "@media (max-width: 1440px)": {
    width: '90%',
  },
  '@media (max-width: 960px)': {
    width: '100%',
  },
  '& .MuiCardMedia-media': {
    height: 'inherit',
  },
});

const PropertyPreviewCardContainer = styled(Card)({
  width: "100%",
  margin: 'auto',
  boxShadow: 'none !important',
});

const PropertyNameContainer = styled('div')({
  position: 'relative',
  whiteSpace: 'pre',
  textAlign: 'left',
  height: '50px',
  '@media (max-width: 960px)': {
    paddingLeft: '14px',
  },
});

const PropertyAreaName = styled('span')({
  position: 'absolute',
  top: '10px',
  left: '10%',
  zIndex: 3,
  fontFamily: 'Callient',
  fontSize: '50px',
  fontWeight: 700,
  color: '#050251',
  '@media (max-width: 500px)': {
    left: 0,
    display: 'flex',
    width: '100%',
    top: '35px',
    fontSize: '24px',
    justifyContent: 'center',
  },
});

interface PropertyPreviewProps {
  property: Property,
}

const PropertyPreviewCard = observer((props: PropertyPreviewProps) => {
  return (
    <PropertyPreviewCardContainer>
       <PropertyNameContainer>
          <PropertyAreaName>{props.property.area.name}</PropertyAreaName>
        </PropertyNameContainer>
        <PropertyCard
          property={props.property}
          showSubArea={props.property.operationType === OperationType.SeasonalRental && props.property.area.name !== props.property.subArea.name}
        />
    </PropertyPreviewCardContainer>
  );
});

interface ConditionalWrapperProps {
  children: Array<React.ReactNode>,
}

const ConditionalWrapper = observer((props: ConditionalWrapperProps) => {
  const isMobile = useMediaQueryMobile();
  const slidesPerView = isMobile ? 1 : 2;

  return (
    <Carousel
      slidesPerView={slidesPerView}
      loop={true}
      draggable={false}
      slidesToScroll={slidesPerView}
      spaceBetweenSlides={10}
      mobile={isMobile}
    >
      {props.children}
    </Carousel>
  );
});

const Properties = observer(() => {
  const { home } = useWebsiteStores();
  const { recentProperties } = home;
  if (!recentProperties.result) {
    handleCriticalError(new Error('Missing render dependency: HomeStore.recentProperties'));
    return null;
  }
  const properties = recentProperties.result;
  if (properties.length === 0) {
    handleCriticalError(new Error('DB inconsistency: HomeStore.recentProperties has no items, expected at least 1'));
    return null;
  }
  return (
    <Container>
      <CardsContainer>
        <ConditionalWrapper>
          {properties.map((p, index) => (
            <PropertyPreviewCard key={index} property={p}/>
          ))}
        </ConditionalWrapper>
      </CardsContainer>
    </Container>
  );
});

export default Properties;
