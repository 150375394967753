import { observable, action, computed } from 'mobx';

interface BreakpointsT
{
  mobile: number,
  tablet: number,
};

const Breakpoints = {
  mobile: 960,
  tablet: 1280,
};

export class Device
{
  @observable private _width: number;
  @observable private _height: number;

  constructor(private _breakpoints: BreakpointsT = Breakpoints)
  {
  }

  public get width()
  {
    return this._width;
  }

  public get height()
  {
    return this._height;
  }

  @computed public get mobile()
  {
    return this._width <= this._breakpoints.mobile;
  }

  @computed public get tablet()
  {
    return !this.mobile && this._width <= this._breakpoints.tablet;
  }

  @computed public get desktop()
  {
    return !this.mobile && !this.tablet;
  }

  @action public updateDimensions(w: number, h: number)
  {
    this._width = w;
    this._height = h;
  }

  public attachToWindow()
  {
    window.addEventListener("resize", this.handleResizeEvent);
    this.handleResizeEvent();
  }

  private handleResizeEvent = () =>
  {
    this.updateDimensions(window.innerWidth, window.innerHeight);
  };
};

export default new Device();
