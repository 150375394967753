import { OperationType, RentalPeriod } from '@repo-breteuil/common-definitions';

export default function computePropertyPricePerUnit(operationType: OperationType, rentalPeriod: RentalPeriod)
{
  if (operationType === OperationType.SeasonalRental)
    return 'week';
  if (operationType === OperationType.Rental)
  {
    switch (rentalPeriod)
    {
      case RentalPeriod.Monthly: return 'month';
      case RentalPeriod.Weekly: return 'week';
    }
  }
  return null;
}
