import { observable, action } from 'mobx';

export enum CookiesPreferences
{
  All = 'all',
  Essentials = 'essentialsOnly',
}

export class CookiesStore
{
  static StorageKey = 'cookiesPreferences';
  static storage = window.localStorage;

  @observable private _open: boolean;

  public get isOpen(): boolean
  {
    return this._open;
  }

  @action public setOpen(open: boolean): void
  {
    this._open = open;
  }

  @action public setCookiesPreferences(preferences: CookiesPreferences): void
  {
    CookiesStore.storage.setItem(CookiesStore.StorageKey, preferences);
    this.setOpen(false);
  }

  constructor()
  {
    const value = CookiesStore.storage.getItem(CookiesStore.StorageKey);
    if (value)
    {
      if ([
        CookiesPreferences.All,
        CookiesPreferences.Essentials,
      ].includes(value as any))
      {
        this.setOpen(false);
        return;
      }
      CookiesStore.storage.removeItem(CookiesStore.StorageKey);
    }
    this.setOpen(true);
  }
}
