import { QueryStore } from '@repo-breteuil/front-store-query';
import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

export interface Contact {
  newsletterOptIn: boolean,
  optIn: boolean,
}

const ContactFields: ReadonlyNodeFields = {
  newsletterOptIn: true,
  optIn: true,
};

export default function ContactJWTInfo(qs: QueryStore, contactCommunicationJWT: string)
{
  return qs.query<Contact>({
    operationName: 'ContactJWTInfo',
    variables: {
      contactCommunicationJWT: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          contactJWTInfo: [{
            args: {
              contactCommunicationJWT: true,
            },
          }, ContactFields],
        },
      },
    },
  }, { contactCommunicationJWT });
}
