export default {
  adjustBtn: {
    title: "Search Alerts",
    description: "I want to receive properies matching my search/searches.",
  },

  newsletter: {
    title: "Remarkable Homes",
    description: "I want to receive 3 times per month Breteuil's favorite properties.",
  },

  unsubscribeBtn: {
    title: 'Breteuil Communication',
    description: "I want to receive communication from Breteuil (uncheck this box to no longer receive any communication).",
  },

  update: "Update my preferences",

  unsubscribe: "Unsubscribe",

  error: 'An error has occurred. Please try again later.',

  confirm: {
    title: 'Your unsubscription has been registered!',
    subtitle: 'You will not receive any further communication.',
    caption: 'We hope to see you soon!',
  },

  confirmUpdate: {
    title: 'Your preferences have been updated !',
    subtitle: 'Thank you for your trust !',
    caption: '',
  },
};
