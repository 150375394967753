import { type ArrayElementType, pickKeysFromObject } from '@repo-lib/utils-core';
import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Currency } from '@repo-lib/utils-texts';
import type { Language } from '@repo-breteuil/common-texts';
import type {
  OperationType,
  PropertyType,
  PropertyStatus,
  RentalPeriod,
  MyBreteuilPropertySurroundingDistance,
} from '@repo-breteuil/common-definitions';

import { PictureFormat } from '@repo-breteuil/common-definitions';
import { QueryStore, paginationResultAsList } from '@repo-breteuil/front-store-query';


export interface ExploreProperties {
  similar: Array<Property>,
  nearby: Array<Property>,
}

export interface Agency {
  slug: string,
  name: string,
  phone: string,
  email: string,
  id: IdType,
}

export interface Surrounding {
  id: IdType,
  distance: MyBreteuilPropertySurroundingDistance,
  surrounding: {
    id: IdType,
    content: string,
    iconFileURL: string,
  },
}

export interface KeyInfo {
  id: IdType,
  default: boolean,
  content: string,
  iconFileURL: string,
}

export interface Property {
  id: IdType,
  ref: string,
  slug: string,
  title: string,
  mainPictureURL: string,
  picturesURL: Array<string>,
  price: number,
  priceMax: number,
  operationType: OperationType,
  propertyType: PropertyType,
  exclusive: boolean,
  displayedExclusive: boolean | null,
  contact: {
    name: string,
    phone: string,
    email: string,
  },
  surface: number,
  bedrooms: number,
  bathrooms: number,
  currency: Currency,
  status: PropertyStatus,
  confidential: boolean,
  displayedStatus: PropertyStatus | null,
  area: {
    id: IdType,
    slug: string,
    name: string,
    rentalPeriod: RentalPeriod,
  },
  subArea: {
    id: IdType,
    slug: string,
    name: string,
  },
  agency: Agency,
  description: string,
  descriptionFR: string,
  descriptionEN: string,
  video: {
    videoURL: string,
    __typename: 'VideoSource_Raw',
  } | {
    playerEmbedURL: string,
    embedIframe: string,
    __typename: 'VideoSource_Vimeo',
  } | null,
  exploreProperties: ExploreProperties,
  currentUserFavorite: boolean,
  dpe: number,
  dpeCarbonFootprint: number,
  collectiveOwnership: boolean,
  collectiveOwnershipCharges: number,
  collectiveOwnershipLots: number,
  planURL: string | null,
  location: string,
  priceOnRequest: boolean,
  latitude: number,
  longitude: number,
  keyInfos: Array<KeyInfo>,
  surroundings: Array<Surrounding>,
  guestsCount: number,
  quoteDescription: string,
};

const ConfidentialPropertyPublicFieldsKeys = [
  'id',
  'ref',
  'slug',
  'title',
  'price',
  'currency',
  'surface',
  'bedrooms',
  'operationType',
  'contact',
  'agency',
] as const;

export type ConfidentialPropertyPublicFields = Pick<Property, ArrayElementType<typeof ConfidentialPropertyPublicFieldsKeys>>;

export type GetPropertyResultType = (Property & {
  __typename: 'BreteuilWebsiteProperty_Public',
}) | {
  code: string,
  property: ConfidentialPropertyPublicFields,
  __typename: 'BreteuilWebsiteConfidentialPropertyResult',
};

const keyInfoFields: ReadonlyNodeFields = {
  id: true,
  default: true,
  content: [{ args: { language: true }}, true],
  iconFileURL: true,
} as const;

const surroundingFields: ReadonlyNodeFields = {
  id: true,
  distance: true,
  surrounding: {
    id: true,
    content: [{ args: { language: true }}, true],
    iconFileURL: true,
  },
} as const;

const agencyFields: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true }}, true],
  name: true,
  phone: true,
  email: true,
} as const;

const explorePropertyField: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  title: [{ args: { language: true } }, true],
  price: true,
  priceMax: true,
  currency: true,
  propertyType: true,
  operationType: true,
  status: true,
  displayedStatus: true,
  mainPictureURL: [{ args: { format: 'explorePropertyPictureformat' } }, true],
  currentUserFavorite: true,
  area: {
    id: true,
    slug: [{ args: { language: true } }, true],
    rentalPeriod: true,
    name: [{ args: { language: true } }, true],
  },
  subArea: {
    id: true,
    slug: [{ args: { language: true } }, true],
    name: [{ args: { language: true } }, true],
  },
  priceOnRequest: true,
} as const;

const exploreFields: ReadonlyNodeFields = {
  similar: explorePropertyField,
  nearby: explorePropertyField,
} as const;

const propertyFields: ReadonlyNodeFields = {
  id: true,
  ref: true,
  slug: [{ args: { language: true } }, true],
  title: [{ args: { language: true }}, true],
  operationType: true,
  price: true,
  priceMax: true,
  bedrooms: true,
  propertyType: true,
  postalCode: true,
  surface: true,
  country: true,
  bathrooms: true,
  exclusive: true,
  displayedExclusive: true,
  contact: [{ args: { contactUserId: true }}, {
    name: true,
    phone: true,
    email: true,
  }],
  description: [{ args: { language: true } }, true],
  picturesURL: [{ args: { format: true } }, true],
  video: [{
    polymorphicFields: {
      VideoSource_Raw: {
        videoURL: true,
      },
      VideoSource_Vimeo: {
        playerEmbedURL: true,
        embedIframe: true,
      },
    },
  }, {}],
  currency: true,
  status: true,
  confidential: true,
  displayedStatus: true,
  currentUserFavorite: true,
  exploreProperties: exploreFields,
  agency: agencyFields,
  dpe: true,
  dpeCarbonFootprint: true,
  collectiveOwnership: true,
  collectiveOwnershipCharges: true,
  collectiveOwnershipLots: true,
  planURL: true,
  area: {
    id: true,
    slug: [{ args: { language: true } }, true],
    rentalPeriod: true,
    name: [{ args: { language: true } }, true],
  },
  subArea: {
    id: true,
    slug: [{ args: { language: true } }, true],
    name: [{ args: { language: true } }, true],
  },
  priceOnRequest: true,
  latitude: true,
  longitude: true,
  keyInfos: paginationResultAsList(keyInfoFields),
  surroundings: paginationResultAsList(surroundingFields),
  guestsCount: true,
  quoteDescription: [{ args: { language: true }}, true],
} as const;

const confidentialPropertyPublicFields = pickKeysFromObject(propertyFields, ConfidentialPropertyPublicFieldsKeys);

export interface GetPropertyArgs
{
  slug: string,
  language: Language,
  contactUserId?: IdType | null | undefined,
  accessCode?: string | null | undefined,
  accessToken?: string | null | undefined,
}

export async function GetProperty(
  qs: QueryStore,
  args: GetPropertyArgs,
): Promise<GetPropertyResultType | null>
{
  return qs.query<GetPropertyResultType | null>({
    operationName: 'GetProperty',
    variables: {
      slug: 'String!',
      language: 'Language!',
      contactUserId: 'Int',
      format: 'PictureFormat',
      explorePropertyPictureformat: 'PictureFormat',
      accessCode: 'String',
      accessToken: 'String',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          property: [{
            args: {
              slug: true,
              accessCode: true,
              accessToken: true,
            },
            polymorphicFields: {
              BreteuilWebsiteProperty_Public: propertyFields,
              BreteuilWebsiteConfidentialPropertyResult: {
                code: true,
                property: confidentialPropertyPublicFields,
              },
            },
          }, {}],
        },
      },
    },
  }, {
    ...args,
    format: PictureFormat._2160p,
    explorePropertyPictureformat: PictureFormat._720p,
  });
}
