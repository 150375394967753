import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Logout } from '@mui/icons-material';
import { AppBar as MuiAppBar } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import {
  Link,
  Routes,
} from '@breteuil-website/store/routing';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import Drawer from './drawer/Drawer';
import texts from '../texts';
import { isRouteFromClientArea } from '@breteuil-website/components/common/app/appBar/desktop/AppBar';

const Bar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '17px 20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 0 4px #a2a2a2',
  height: '50px',
});

const AccountWrapper = styled('div')({
  height: '30px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const LogoLink = styled(Link)({
  display: "flex",
  alignItems: "center",
});

const AccountIcon = styled(AccountCircleIcon)({
  width: '30px',
  height: '30px',
  color: '#050251',
});

const LogOutButton = styled(Link)({
  display: 'flex',
  justifySelf: 'end',
  alignSelf: 'center',
  color: '#050251',
  columnGap: '5px',
});

const AppBar = observer(() => {
  const stores = useWebsiteStores();
  const lang = useLocale();
  const T = useTexts(texts);

  const [ drawerOpen, setDrawerOpen ] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => setDrawerOpen(false);

  return (
    <MuiAppBar sx={{ bgcolor: "white" }} elevation={0}>
    <Bar>
      <Drawer
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      />
      <LogoLink to={Routes.home.generateStaticPath({ lang })}>
        <img
          src="/assets/LOGO_BRETEUIL.svg"
          alt={T.logoAlt} //TODO: lang
        />
      </LogoLink>
      {stores.session.authenticated && isRouteFromClientArea(router.router.currentRoute, { lang }) ? (
        <LogOutButton
          to={Routes.home.generateStaticPath({ lang })}
          onClick={() => stores.session.logOut()}
        >
          <Logout/>
        </LogOutButton>
      ) : (
        <Link to={Routes.clientAreaSearches.generateStaticPath({ lang })}>
          <AccountWrapper>
            <AccountIcon/>
          </AccountWrapper>
        </Link>
      )}
    </Bar>
    </MuiAppBar>
  );
});

export default AppBar;
