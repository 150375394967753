import { getErrorCode } from "@repo-breteuil/front-error";

export default {
  signUp: 'Créez votre compte',
  alreadyHaveAccount: 'Vous avez déjà un compte ?',
  logIn: 'Connectez-vous',
  firstname: 'Prénom',
  lastname: 'Nom de famille',
  email: 'Email',
  phone: 'Mobile',
  password: 'Mot de passe',
  confirmPassword: 'Confirmer votre mot de passe',
  confirmPasswordError: 'Le mot de passe et le mot de passe de confirmation ne correspondent pas.',
  handleRegisterError: (error: Error): string => {
    switch (getErrorCode(error)) {
      case 'EmailAlreadyUsed':
        return "Cet email est déjà utilisé.";
      case 'MobilePhoneAlreadyUsed':
        return "Ce numéro de téléphone est déjà utilisé.";
      default:
        return "Une erreur est survenue, veuillez réessayer.";
    }
  },
  emailAlreadyUsed: 'Cette adresse est déjà associée à un compte.',
  activationLinkSent: "Un lien d'activation vous a été envoyé, veuillez consulter votre boite mail.",
  emailIsLogin: "Votre email sera votre identifiant de connexion.",
  receive: 'Je souhaite recevoir de la part de Breteuil :',
  favoriteProperties: 'Propriétés coup de coeur',
  create: 'Créer mon compte',
  checkPolicy: 'Consulter notre politique de confidentialité.',
  security: 'Sécurité',
  phoneNumberError: 'Numéro non valide',
  registerError: "Une erreur est survenue lors de l'enregistrement de cette demande.", // TO CHANGE
};
