import * as React from 'react';
import { styled } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Button as GenericButton } from '@breteuil-website/components/common';
import { Routes } from '@breteuil-website/store/routing';
import texts from './texts';

const Container = styled('div')({
  background: '#EFEFEF',
  fontFamily: 'Poppins',
  textAlign: 'center',
  padding: '82px',
});

const Title = styled('div')({
  color: '#050251',
  fontSize: '42px',
  fontWeight: 700,
});

const SubTitle = styled('div')({
  fontFamily: 'Mulish',
  color: '#050251',
  fontSize: '16px',
  fontWeight: 700,
  margin: '30px auto',
});

const Table = styled('div')({
  display: 'grid',
  gridTemplateColumns: '75% 25%',
  maxWidth: '650px',
  backgroundColor: '#FBFBFB',
  paddingLeft: '15px',
  margin: '15px auto',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, .16)',
});

const TableItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#050251',
  fontWeight: 700,
  fontSize: '15px',
  padding: '15px 0px 15px 20px',
  borderBottom: '1px solid #979797',
});

const LastItem = styled(TableItem)({
  borderBottom: 'none',
});

const TickCell = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#050251',
  color: '#FFFFFF',
  borderBottom: '1px solid #979797',
});

const Button = styled(GenericButton)({
  marginTop: '35px',
  fontSize: '12px',
  width: '200px',
});

const Tick = () => <TickCell>✔</TickCell>;

const ClientAreaDescription = () => {
  const lang = useLocale();
  const T = useTexts(texts);

  return (
    <Container>
      <Title>
        {T.clientArea} <br/>
        {T.endlessPossibilities}
      </Title>
      <SubTitle>
        {T.comfort}
      </SubTitle>
      <Table>
        <TableItem>{T.propertyAccess}</TableItem>
        <Tick/>
        <TableItem>{T.adjustments}</TableItem>
        <Tick/>
        <TableItem>{T.reports}</TableItem>
        <Tick/>
        <LastItem>{T.monitoring}</LastItem>
        <Tick/>
      </Table>
      <Button href={Routes.clientAreaSignUp.generateStaticPath({ lang })}>
        {T.createArea}
      </Button>
    </Container>
  );
};

export default ClientAreaDescription;
