import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

// TODO alt in lang file

const EthicsContainer = styled('div')({
    width: '100%',
    minHeight: '750px',
    display: 'flex',
    justifyContent: 'center',
    margin: '150px 0',
    "@media (max-width: 960px)": {
        margin: '50px 0 150px',
    },
});

const EthicsComponent = styled('div')({
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: "repeat(12, 1fr)",
    gridAutoFlow: "row",
    width: "80%",
    maxWidth: '1150px',
    "@media (max-width: 1960px)": {
        maxWidth: '850px',
    },
    "@media (max-width: 960px)": {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "repeat(3 , 290px)",
        gap: "75px 0px",
    },
});

const EthicsImpact = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    gridArea: "5 / 2 / 8 / 6",
    backgroundColor: 'rgba(216, 216, 216, 0.35)',
    padding: '40px',
    "@media (max-width: 960px)": {
        gridArea: "1/1/2/1",
        padding: '30px 20px',
        justifyContent: 'unset',
    },
});

const EthicsIntegrity = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    gridArea: "1 / 1 / 4 / 5",
    backgroundColor: 'rgba(216, 216, 216, 0.35)',
    padding: '40px',
    "@media (max-width: 960px)": {
        gridArea: "2/1/3/1",
        padding: '30px 20px',
    },
});

const EthicsSecurity = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    gridArea: "9 / 1 / 12 / 5",
    backgroundColor: 'rgba(216, 216, 216, 0.35)',
    padding: '40px',
    "@media (max-width: 960px)": {
        gridArea: "3/1/4/1",
        padding: '30px 20px',
    },
});

const PatchBodyContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
    width: '80%',
    "@media (max-width: 960px)": {
        width: '90%',
        padding: '0',
    },
});


const PatchBodyTitle = styled('span')({
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '30px',
    color: "#050251",
    "@media (max-width: 960px)": {
        fontSize: '16px',
    },
});

const PatchBodyDescription = styled('span')({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: "#050251",
    marginTop: '10px',
});


const CompassOverlay = styled('img')({
    position: 'absolute',
    maxWidth: '250px',
    objectFit: 'cover',
    top: '45px',
    right: '-75px',
    "@media (max-width: 960px)": {
        top: 'unset',
        bottom: '-25%',
        right: '-5px',
    },
});

const ShieldOverlay = styled('img')({
    position: 'absolute',
    maxWidth: '250px',
    objectFit: 'cover',
    top: '25%',
    right: '-66px',
    "@media (max-width: 960px)": {
        top: 'unset',
        bottom: '-25%',
        right: '0',
    },
});

const SparkOverlay = styled('img')({
    position: 'absolute',
    maxWidth: '250px',
    objectFit: 'cover',
    top: '25%',
    right: '-66px',
    "@media (max-width: 960px)": {
        top: 'unset',
        left: 'unset',
        bottom: '-25%',
        right: '0',
    },
});



interface PatchBodyProps {
    title: string,
    description: string,
}

const PatchBodyComponent = observer((props: PatchBodyProps) => {
    return (
        <PatchBodyContainer>
            <PatchBodyTitle>{props.title}</PatchBodyTitle>
            <PatchBodyDescription>{props.description}</PatchBodyDescription>
        </PatchBodyContainer>
    );
});


const Ethics = observer(() => {
    const T = useTexts(texts).ethics;

    return (
        <EthicsContainer>
            <EthicsComponent>
                <EthicsIntegrity>
                    <PatchBodyComponent title={T.integrityTitle} description={T.integrityDesc}/>
                    <CompassOverlay src={"/assets/ethics_boussole.png"} alt={"T.altCompass"}/>
                </EthicsIntegrity>
                <EthicsImpact>
                    <PatchBodyComponent title={T.impactTitle} description={T.impactDesc}/>
                    <SparkOverlay src={"/assets/ethics_spark.png"} alt={"T.altSpark"}/>
                </EthicsImpact>
                <EthicsSecurity>
                    <PatchBodyComponent title={T.securityTitle} description={T.securityDesc}/>
                    <ShieldOverlay src={"/assets/ethics_shield.png"} alt={"T.altShield"}/>
                </EthicsSecurity>
            </EthicsComponent>
        </EthicsContainer>
    );
});

export default Ethics;
