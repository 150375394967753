import { styled } from '@mui/material/styles';

import * as React from 'react';
import texts from '../texts';
import { Page } from '@breteuil-website/components/common';
import { observer } from "mobx-react";
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const Container404 = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: '#050251',
  width: '100%',
  position: 'relative',
  height: isMobile ? '39px' : '91px',
}));

const Title = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  fontSize: isMobile ? '17px' : '43px',
}));

const Subtitle = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  fontFamily: 'Poppins',
  alignItems: 'center',
  textAlign: 'center',
  color: '#050251',
  fontWeight: 700,
  ...(isMobile ? {
    margin: '57px 10% 0px 10%',
    fontSize: '21px',
  } : {
    margin: '100px auto 0px auto',
    width: '524px',
    fontSize: '26px',
    whiteSpace: 'pre-line',
  }),
}));

const TextPicture = styled('div')<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  fontFamily: 'Mulish',
  alignItems: 'center',
  textAlign: 'center',
  color: '#757575',
  fontWeight: 400,
  fontSize: '15px',
  ...(isMobile ? {
    margin: '20px 15% 0px 15%',
    padding: theme.spacing(2),
  } : {
    margin: '24px auto 0px auto',
    width: '500px',
    whiteSpace: 'pre-line',
  }),
}));

const Picture = styled('img')<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'block',
  objectFit: isMobile ? 'contain' : 'cover',
  ...(isMobile ? {
    margin: '10px auto 55px auto',
    width: '100%',
    height: '320px',
  } : {
    margin: '0px auto 160px auto',
    width: '608px',
    height: '535px',
  }),
}));

const Error404 = observer (() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();

  return (
    <Page>
      <Container404 isMobile={isMobile}>
        <Title isMobile={isMobile}>
          {T.error404.title}
        </Title>
      </Container404>
      <Subtitle isMobile={isMobile}>
        {T.error404.subtitle}
      </Subtitle>
      <TextPicture isMobile={isMobile}>
        {T.error404.pictureText}
        <br/>
      </TextPicture>
      <Picture src='/assets/error404puppy.jpg' isMobile={isMobile}/>
    </Page>
  );
});

export default Error404;
