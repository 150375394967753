import { action, observable } from 'mobx';
import { IdType } from '@repo-breteuil/common-definitions';
import {
  type Property,
  type ConfidentialPropertyPublicFields,
  type GetPropertyArgs,
  GetProperty,
} from '@breteuil-website/store/ui/pages/property/api';
import { QueryStore } from '@repo-breteuil/front-store-query';

export type VisiblePropertyState = {
  state: 'VisibleProperty',
  property: Property,
};

export type PropertyAccessRequestState = {
  state: 'PropertyAccessRequest',
  property: ConfidentialPropertyPublicFields,
};

export type PropertyAccessCodeInputState = {
  state: 'PropertyAccessCodeInput',
  property: ConfidentialPropertyPublicFields,
  phoneNumberHint: string,
  accessToken: string,
  slug: string,
  invalidAccessCode: boolean,
};

export type PropertyStoreState =
  | VisiblePropertyState
  | PropertyAccessRequestState
  | PropertyAccessCodeInputState;

export class PropertyStore
{
  constructor(public stores: { query: QueryStore })
  {
  }

  @observable private _contactUserId: IdType | null = null;
  @observable private _state: PropertyStoreState;

  @action public setContactUserId(id: IdType | null)
  {
    this._contactUserId = id;
  }

  public get contactUserId()
  {
    return this._contactUserId;
  }

  //Should be static
  public async queryState(args: GetPropertyArgs): Promise<PropertyStoreState | null>
  {
    return GetProperty(this.stores.query, args).then((result) => {
      if (!result)
        return null;
      if (result.__typename === 'BreteuilWebsiteProperty_Public')
        return {
          state: 'VisibleProperty',
          property: result,
        } as const;
      const { code, property } = result;
      if (code === 'AccessDenied')
        return { state: 'PropertyAccessRequest', property } as const;
      if (code === 'AccessCodeRequired' && args.accessCode)
        return { state: 'PropertyAccessRequest', property } as const; //Shouldn't happen
      if (code === 'AccessCodeRequired' || code === 'InvalidAccessCode')
        return {
          state: 'PropertyAccessCodeInput',
          property,
          phoneNumberHint: '', //Will be set later
          accessToken: args.accessToken!, //AccessCodeRequired wouldn't have been returned if accessToken was null
          slug: args.slug,
          invalidAccessCode: (code === 'InvalidAccessCode'),
        } as const;

      //Unknown code; shouldn't happen
      return { state: 'PropertyAccessRequest', property } as const;
    });
  }

  public get state()
  {
    return this._state;
  }

  @action setState(state: PropertyStoreState)
  {
    this._state = state;
  }
}
