const style: Array<google.maps.MapTypeStyle> = [{
  "elementType": "geometry.fill",
  "stylers": [{ "visibility": "on" }],
}, {
  "elementType": "geometry.stroke",
  "stylers": [{
    "lightness": 75,
  }, {
    "visibility": "on",
  }],
}, {
  "elementType": "labels.text",
  "stylers": [{ "visibility": "on" }],
}, {
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#a6a6a6",
  }, {
    "visibility": "on",
  }],
}, {
  "featureType": "administrative.land_parcel",
  "elementType": "labels",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "landscape.man_made",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#dddddd",
  }, {
    "visibility": "on",
  }],
}, {
  "featureType": "landscape.natural",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#dddddd",
  }, {
    "visibility": "on",
  }],
}, {
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "8a8a8a",
    }, {
      "visibility": "off",
    }],
}, {
  "featureType": "poi",
  "elementType": "labels.icon",
  "stylers": [{
    "color": "8a8a8a",
  }, {
    "visibility": "off",
  }],
}, {
  "featureType": "poi",
  "elementType": "labels.text",
  "stylers": [{
    "color": "8a8a8a",
  }, {
    "visibility": "off",
  }],
}, {
  "featureType": "road",
  "elementType": "labels.icon",
  "stylers": [{ "visibility": "off" }],
}, {
  "featureType": "road.arterial",
  "elementType": "labels.icon",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.highway",
  "elementType": "geometry.fill",
  "stylers": [{ "color": "#ffffff" }],
}, {
  "featureType": "road.highway",
  "elementType": "geometry.stroke",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.highway",
  "elementType": "labels",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.highway.controlled_access",
  "elementType": "labels",
  "stylers": [{ "visibility": "off" }],
}, {
  "featureType": "road.highway.controlled_access",
  "elementType": "geometry",
  "stylers": [{ "visibility": "off" }],
}, {
  "featureType": "road.local",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.local",
  "elementType": "geometry.fill",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.local",
  "elementType": "labels.text",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "road.local",
  "elementType": "labels",
  "stylers": [{ "visibility": "on" }],
}, {
  "featureType": "transit",
  "stylers": [
    {
      "visibility": "on",
    }, {
      "color": "8a8a8a",
    }],
}, {
  "featureType": "transit.station",
  "stylers": [
    {
      "visibility": "on",
    }, {
      "color": "8a8a8a",
    }],
}, {
  "featureType": "water",
  "elementType": "geometry.fill",
  "stylers": [{ "color": "#b1c7ff" }],
}];

export default style;
