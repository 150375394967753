import type {
  ResolvedRouteLocation,
  PageRouteHandlers,
  PageRouteHandlersScope,
  RoutingInfo,
} from '@lib/routing';
import type { Locale } from '@repo-breteuil/common-texts';

import { getErrorCode } from '@repo-breteuil/front-error';
import {
  setTranslatedPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';

import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  async function optOutHandler(
    location: ResolvedRouteLocation,
    info: RoutingInfo,
    lang: Locale,
  )
  {
    const { searchParams } = location;
    const jwt = searchParams.get('token');
    if (!jwt)
      return () => ({ page: RootPages.NotFound, route: null });
    try
    {
      await websiteStores.optOut.init(jwt);
    }
    catch (error)
    {
      const code = getErrorCode(error);
      if (code === 'InvalidArguments' || code === 'ResourceNotFound')
        return () => ({ page: RootPages.NotFound, route: null });
      throw error;
    }
    return () => {
      return {
        page: null,
        route: Routes.optOut.generateStaticPath({
          lang,
          searchParams: location.searchParams,
          hash: location.hash,
        }),
      };
    };
  }

  setTranslatedPage(
    Public,
    RootPages.OptOut,
    Routes.optOut.routesByLanguage,
    optOutHandler,
  );
}
