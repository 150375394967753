import * as React from 'react';
import {
  Box,
  styled,
  Typography,
} from '@mui/material';
import texts from './texts';
import { Page } from '@breteuil-website/components/common';
import { useTexts } from '@repo-breteuil/common-texts';

const MainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  margin: 'auto',
  padding: '30vh 0',
  [theme.breakpoints.down('sm')]: {
    padding: '20vh 10px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Mulish !important',
  fontSize: '26px !important',
  color: theme.palette.primary.dark,
  fontWeight: 800,
  lineHeight: '3 !important',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.5 !important',
    fontSize: '16px !important',
    marginBottom: '10px !important',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Mulish !important',
  color: theme.palette.primary.dark,
  fontSize: '16px',
  textTransform: 'none',
  lineHeight: '3 !important',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.5 !important',
    fontSize: '12px !important',
    marginBottom: '10px !important',
  },
}));

const Caption = styled(Typography)(({ theme }) => ({
  fontFamily: 'Mulish !important',
  fontStyle: 'italic',
  color: theme.palette.primary.light,
  fontSize: '13px',
  textTransform: 'none',
  lineHeight: '3 !important',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.5 !important',
    fontSize: '16px !important',
  },
}));
const ConfirmPage = (props: { unsubscribe?: boolean }) => {
  const T = useTexts(texts);
  const { unsubscribe = true } = props;

  return (
    <Page>
      <MainBox>
        <Title>{unsubscribe ? T.confirm.title : T.confirmUpdate.title}</Title>
        <Subtitle>{unsubscribe ? T.confirm.subtitle : T.confirmUpdate.subtitle}</Subtitle>
        <Caption>{unsubscribe ? T.confirm.caption : T.confirmUpdate.caption}</Caption>
      </MainBox>
    </Page>
  );
};

export default ConfirmPage;
