export default {
  text: 'This link is invalid. Enter your e-mail address below to receive a new link.',
  resendEmail: 'Get a new link',
  email: 'E-mail',
  emailSent: 'A new link has been sent, please check your inbox.',
  errorNotif: 'There has been an error, please try again later.',
  goToProfile: 'Go to your client area',
  successText: 'Your account has been activated.',
  thankYou(name: string): string
  {
    return 'Thank you, '+name;
  },
};
