import type { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { computePropertyPricePerUnit } from '@breteuil-website/components/common/functions';
import texts from './texts';
import { PropertyPrice } from '@breteuil-website/components/common';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled('div')({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  lineHeight: "37px",
  fontFamily: 'Mulish',
});

const PriceContainer = styled('div')({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: 'center',
});

const Price = styled('span')({
  fontFamily: 'Raleway',
  fontWeight: 600,
  fontSize: '23px',
  color: "#00195E",
  '@media (max-width: 1250px)': {
    fontSize: '14px',
    fontWeight: 700,
  },
});

const Duration = styled('span')({
  fontFamily: 'Raleway',
  fontWeight: 500,
  fontSize: '15px',
  color: "#00195E",
});

const SeparatorLight = styled('hr')({
  border: "1px solid rgba(117,117,117,0.25)",
  opacity: 1,
  width: "100%",
  margin: "22px 0",
});

const PropertyDatas = styled('div')({
  display: "flex",
  flexDirection: "row",
});


const PropertyDatasLeft = styled('div')({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: '14px',
  textAlign: "left",
  fontStyle: 'italic',
  lineHeight: '22px',
  padding: '24px 0',
});


const PropertySeasonalRentalInfoMobile = observer((props: { property: Property }) => {
  const { property } = props;
  const T = useTexts(texts);
  return (
    <Container>
      <Price>
        <PropertyPrice
          priceOnRequest={property.priceOnRequest}
          formattedPrice={
            T.formatTextRange(
              property.price,
              null,
              property.currency,
              computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
            )
          }
        />
      </Price>
    </Container>
  );
});

const PropertySeasonalRentalInfoDesktop = observer((props: { property: Property }) => {
  const { property } = props;
  const T = useTexts(texts);
  return (
    <Container>
      <PriceContainer>
        <Duration>{T.seasonalRentalDuration}</Duration>
        <Price>
          <PropertyPrice
            priceOnRequest={property.priceOnRequest}
            formattedPrice={
              T.formatTextRange(
                property.price,
                null,
                property.currency,
                computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
              )
            }
          />
        </Price>
      </PriceContainer>
      <SeparatorLight/>
      <PropertyDatas>
        <PropertyDatasLeft>
          {property.quoteDescription || T.defaultQuote}
        </PropertyDatasLeft>
      </PropertyDatas>
      <SeparatorLight/>
    </Container>
  );
});

const PropertySeasonalRentalInfo = (props: { property: Property }) => {
  const isDesktop = useMediaQueryDesktop();
  return isDesktop ? <PropertySeasonalRentalInfoDesktop {...props}/> : <PropertySeasonalRentalInfoMobile {...props}/>;
};

export default PropertySeasonalRentalInfo;
