export default {
  title: 'Mes favoris',
  noProperties: "Vous n'avez aucune propriété favorite pour le moment.",
  error: 'Il y a eu une erreur, veuillez recharger la page.',
  price: 'Prix',
  addDate: "Date d'ajout",
  deleteAll: "Tout supprimer",
  filters: {
    price_desc: 'Prix décroissant',
    price_asc: 'Prix croissant',
    date_desc: 'Du plus récent au plus ancien',
    date_asc: 'Du plus ancien au plus récent',
  },
  confirmations: {
    text: 'Êtes vous sûr de vouloir supprimer tous vos favoris ?',
    yes: 'Oui',
    no: 'Non',
  },
};
