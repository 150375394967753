import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  TransactionFilter,
  TransactionsStats,
  YearTransactionsStats,
} from '@breteuil-website/store/ui/pages/market-data/api';

import { observable, action } from 'mobx';
import * as datefns from 'date-fns';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { AreaIdToCityId } from '@breteuil-website/_hardcoded';
import type { BreteuilWebsiteEnv } from '@breteuil-website/store/ui/env';
import { GetTransactionsStatsPerYearGov, GetTransactionsStatsGov } from './api';
import { GetVariationFromResult } from './computedValues';
import { QueryStore } from '@repo-breteuil/front-store-query';

export class MarketDataStore
{
  @observable private _cityYearsTransactionsStats: Array<Array<YearTransactionsStats>> = [];
  @observable private _cityPastYearTransactionsStats: TransactionsStats;
  @observable private _cityPastYearVariation: number | null = null;
  @observable private _selectedDistrictPastYearVariation: number | null = null;
  private _filters: TransactionFilter | {} = {};

  constructor(
    public stores: {
      query: QueryStore,
      env: BreteuilWebsiteEnv,
    },
  )
  {
  }

  public get cityYearsTransactionsStats() {
    return this._cityYearsTransactionsStats;
  }

  public get cityPastYearTransactionsStats() {
    return this._cityPastYearTransactionsStats;
  }

  public get cityPastYearVariation() {
    return this._cityPastYearVariation;
  }

  public get selectedDistrictPastYearVariation() {
    return this._selectedDistrictPastYearVariation;
  }

  public async setFilters(districtIds: Array<IdType>) {
    if (!districtIds || !AreaIdToCityId.get(this.selectedAreaId)) {
      this._filters = {};
    } else {
      this._filters = {
        "cityId": { equals: AreaIdToCityId.get(this.selectedAreaId) },
        "districtId": {
          "in": districtIds,
        },
      };
    }
    await this.refresh();
  };

  public selectedDistrictsYearsTransactionsStats = new Fetchable(() => (
    Promise.all([
      GetTransactionsStatsPerYearGov(this.stores.query, { filter: { ...this._filters, surface: { gte: 15, lte: 100 } } }),
      GetTransactionsStatsPerYearGov(this.stores.query, { filter: { ...this._filters, surface: { gt: 100 } } }),
    ])
  ), {
    catchUnhandled: handleNonCriticalError,
  });

  public selectedDistrictsPastYearTransactionsStats = new Fetchable(() => (
    GetTransactionsStatsGov(this.stores.query, {
      filter: {
        ...this._filters,
        date: { gte: datefns.subYears(Date.now(), 1).getTime() },
      },
    })
  ), { catchUnhandled: handleNonCriticalError });

  @observable private _selectedAreaId: IdType = 10; // TODO remove harcoded value

  @action async setSelectedAreaId(selectedAreaId: IdType)
  {
    if (this._selectedAreaId !== selectedAreaId) {
      this._selectedAreaId = selectedAreaId;
      const cityId = AreaIdToCityId.get(this.selectedAreaId);
      if (cityId) {
        this._cityYearsTransactionsStats = await Promise.all(([
          { cityId: { equals: cityId }, surface: { gte: 15, lte: 100 } },
          { cityId: { equals: cityId }, surface: { gt: 100 } },
        ] as Array<TransactionFilter>).map(filter => (
          GetTransactionsStatsPerYearGov(this.stores.query, { filter })
        )));
        this._cityPastYearVariation = await GetVariationFromResult(this._cityYearsTransactionsStats);
      }
    }
  }

  public get selectedAreaId()
  {
    return this._selectedAreaId;
  }

  public async refresh(): Promise<void> {
    await this.selectedDistrictsPastYearTransactionsStats.ensureSuccessReload();
    await this.selectedDistrictsYearsTransactionsStats.ensureSuccessReload();
    this._selectedDistrictPastYearVariation = await GetVariationFromResult(this.selectedDistrictsYearsTransactionsStats.lastResult);
  }

  public async init(): Promise<void> {
    googleMapsAPI.init(this.stores.env.googleMapsAPIKey);
    this._cityPastYearTransactionsStats = await GetTransactionsStatsGov(this.stores.query, {
      filter: {
        cityId: { equals: 1 },
        date: { gte: datefns.subYears(Date.now(), 1).getTime() },
      },
    });
    this._cityYearsTransactionsStats = await Promise.all(([
      { cityId: { equals: 1 }, surface: { gte: 15, lte: 100 } },
      { cityId: { equals: 1 }, surface: { gt: 100 } },
    ] as Array<TransactionFilter>).map(filter => (
      GetTransactionsStatsPerYearGov(this.stores.query ,{ filter })
    )));
    this._cityPastYearVariation = await GetVariationFromResult(this._cityYearsTransactionsStats);
  }
}
