import * as React from 'react';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { SelectOptions } from '@repo-lib/mobx-forms';
import {
  // useFormFieldSelect,
  useFormFieldSelectNullable,
} from '@repo-lib/mobx-forms';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from '../texts';
import {
  Select,
} from '@breteuil-website/components/common';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  maxWidth: '1200px',
  width:'100%',
  margin: '0 auto 32px',
});

const StyledSelect = styled(Select)({
  backgroundColor: '#F4F4F4',
  width: '100%',
  boxShadow:'0px 0px 2px 0px #E0E0E0, 0px 2px 2px 0px #E0E0E0;',
});


const SelectCity = observer(() => {
  const T = useTexts(texts);
  const { valuation } = useWebsiteStores();
  const citySelect = useFormFieldSelectNullable({
    options: () => new SelectOptions<string>(new Map(
      valuation.citiesKeys.map((city) => [ city, T.cities[city] ]),
    )),
    defaultValue: () => valuation.selectedCityDefaultValue ?? 'paris',
  }, [ T ]);

  function handleSelectChange() {
    valuation.setIsParisSelected(citySelect.value === 'paris');
  }

  React.useEffect(
    handleSelectChange,
    [citySelect.value],
  );


  return (
    <Container>
        <StyledSelect field={citySelect}/>
    </Container>
  );
});

export default SelectCity;
