import * as Routes from './routes';

export { Link, makeLinkProps } from '@core/store/routing/router';
export { Routes };
export { RootPages } from './pages';
export { default as store } from './RoutingStore';
export * from './translatedRoutes';
export * from './translatedRoutesLang';
export * from './routeUtils';

import routingLogic from './RoutingLogic';

export default routingLogic;
