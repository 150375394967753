import type { IdType } from '@repo-breteuil/common-definitions';

import { QueryStore } from '@repo-breteuil/front-store-query';

export interface AgencyMeetingVerifyPhoneArgs {
  agencyMeetingId: IdType,
  phoneVerifyCode: string,
}

export default async function AgencyMeetingVerifyPhone(
  qs: QueryStore,
  args: AgencyMeetingVerifyPhoneArgs,
): Promise<boolean> {
  return qs.mutation({
    operationName: 'ValuationAgencyMeetingVerifyPhone',
    variables: {
      agencyMeetingId: 'Int!',
      phoneVerifyCode: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            agencyMeeting: {
              verifyPhone: [{
                args: {
                  agencyMeetingId: true,
                  phoneVerifyCode: true,
                },
              }, true],
            },
          },
        },
      },
    },
  }, args);
}
