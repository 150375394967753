import type { IdType, AlertsFrequency } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';

export interface Profile
{
  id: IdType,
  firstname: string,
  lastname: string,
  address: string,
  postalCode: string,
  city: string,
  email: string,
  mobilePhone: string,
  language: Language,
  optIn: boolean,
  newsletterOptIn: boolean,
  alertsFrequency: AlertsFrequency,
}

export const profileFields = {
  id: true,
  firstname: true,
  lastname: true,
  address: true,
  postalCode: true,
  city: true,
  email: true,
  mobilePhone: true,
  language: true,
  optIn: true,
  newsletterOptIn: true,
  alertsFrequency: true,
} as const;
