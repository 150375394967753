import * as React from 'react';
import { observer } from 'mobx-react';
import { formatInTimeZone } from 'date-fns-tz';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTexts } from '@repo-breteuil/common-texts';
import { Button as GenericButton } from '@breteuil-website/components/common';
import lang from '../../texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

interface MeetingSlot {
  date: number,
  locked: boolean,
}

const Container = styled('div')({
  display: 'flex',
  columnGap: '60px',
  width: '45%',
  "@media (max-width: 1280px)": {
    padding: '0',
    paddingTop: '25px',
    flexDirection: 'column',
    columnGap: '0',
    rowGap: '15px',
    width: '100%',
  },
});

const MeetingButtonLayout = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '5px',
  "@media (max-width: 1280px)": {
    margin: '21px 2px',
  },
});

const Button = styled(GenericButton)({
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: 700,
  border: 'none',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.20)',
  color: '#050251',
  borderRadius: '0',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#050251',
    color: '#FFFFFF',
  },
});

const ButtonSelected = styled(GenericButton)({
  color: '#FFFFFF',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: 700,
  backgroundColor: '#424242',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.20)',
  '&:hover': {
    backgroundColor: '#424242',
    color: '#FFFFFF',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.20)',
  },
});

const ErrorSpan = styled('span')({
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '15px',
  fontWeight: 'bold',
  gridColumn: '1 / span 2',
});

interface MeetingButtonGroupProps {
  tabIdx: number,
}

const MeetingButtonGroup = observer((props: MeetingButtonGroupProps) => {
  const { tabIdx } = props;
  const { valuation } = useWebsiteStores();
  const [selectedButton, setSelectedButton] = React.useState<number | null>(null);
  const [availableMeetings, setAvailableMeetings] = React.useState<Array<MeetingSlot>>([]);
  const handleChange = (event) => {
    const selectedValue = Number(event.target.value);
    setSelectedButton(selectedValue);
    valuation.setSelectedMeetingDate(selectedValue);
  };
  const T = useTexts(lang).method3.timeslotSelection;

  React.useEffect(() => {
    setAvailableMeetings(valuation.getParsedAvailableMeeting(tabIdx));
  }, [valuation.selectedDate, tabIdx]); // TODO Why is this needed ?
  return (
    <MeetingButtonLayout>
      {availableMeetings && availableMeetings.length > 0 ? availableMeetings.map((schedule: MeetingSlot, index) => {
        const { date, locked } = schedule;
        return (
          <React.Fragment key={index}>
            {date !== selectedButton ? (
              <Button disabled={locked} value={date} onClick={handleChange}>
                {formatInTimeZone(date, valuation.meetingSlotsTimezone, 'HH:mm')}
              </Button>
            ) : (
              <ButtonSelected disabled={locked} value={date} onClick={handleChange}>
                {formatInTimeZone(date, valuation.meetingSlotsTimezone, 'HH:mm')}
              </ButtonSelected>
            )}
          </React.Fragment>
        );
      }) : <ErrorSpan>{T.errorNoMeetings}</ErrorSpan>}
    </MeetingButtonLayout>
  );
});

const DateText = styled('p')({
  margin: '8px 0',
  fontSize: '14px',
  color: '#050251',
  fontFamily: 'Mulish',
  fontWeight: 600,
  alignSelf: 'center',
});

const TimeslotsTabs = observer(() => {
  const T = useTexts(lang).method3.timeslotSelection;
  const [tabIdx, setTabIdx] = React.useState(0);
  const { valuation } = useWebsiteStores();
  const isDesktop = useMediaQueryDesktop();
  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            p: '2px',
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: isDesktop ? 'center' : 'unset',
            flexDirection: isDesktop ? 'row' : 'column',
            borderTop: isDesktop ? 'none' : '2px solid #050251',
          }}
        >
          <DateText>{T.selectedDate(valuation.selectedDate || new Date())}</DateText>
          <Tabs
            value={tabIdx}
            variant={isDesktop ? "standard" : "fullWidth"}
            onChange={(event, newValue) => { setTabIdx(newValue); }}
          >
            {T.tabsLabels.map((label, idx) => (
              <Tab
                key={idx}
                label={label}
                sx={{
                  flexBasis: 'auto',
                }}
              />
            ))}
          </Tabs>
        </Box>
          <Box sx={{ padding: isDesktop ? '24px 0' : 0 }}>
              <MeetingButtonGroup
                tabIdx={tabIdx}
              />
        </Box>
      </Box>
    </Container>
  );
});

export default TimeslotsTabs;
