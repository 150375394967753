import * as React from "react";
import { Link } from "@mui/material";
import * as hardcoded from '../_hardcoded';
import { Definition } from ".";

export default {
  pageTitle: "Mention Legales - Breteuil Remarkable Homes",
  pageDescription: "Découvrez les mentions légales du site Breteuil.",
  title: "Mentions legales",
  contact: "Contact",
  host: "Hébergement",
  identification: {
    title: "ARTICLE 1. IDENTIFICATION DU SITE",
    editor: "Éditeur du site : ",
    formatDirector(director: string): string
    {
      return `Directeur de la publication : ${director}`;
    },
    contact: "Contact",
  },
  mediator: {
    title: "MÉDIATEUR",
  },
  establishment: {
    title: "ÉTABLISSEMENTS SOUS LICENCE :",
  },
  companyParity: {
    title: "INDEX DE L'ÉGALITÉ PROFESSIONNELLE 2025",
    description: "La loi Avenir de Septembre 2018 visant à supprimer les écarts de rémunération \
    entre les femmes et les hommes dans l'entreprise prévoit la publication du niveau de résultat obtenu \
    sur une note de 100 points à partir de 4 indicateurs, pour les structures de 50 à 250 salariés, qui sont : \
    l'écart de rémunération par tranche d'âge et par catégorie professionnelle pour 40% de la note, \
    l'écart d'augmentations individuelles pour 35% de la note, \
    le pourcentage de salaries augmentées au retour d'un congé maternité pour 15% de la note, \
    le nombre de salaries du sexe sous- représenté parmi les 10 plus hautes rémunérations pour 10% de la note.\n\
    BRETEUIL ILE DE FRANCE a obtenu un index de 95/100.",
  },
  rest: [
    {
      title: "ARTICLE 2. PROPRIÉTÉ INTELLECTUELLE",
      text: [
        (
          <React.Fragment key="intellectualProperty">
            {`Ce site appartient et est exploité par Breteuil.
            Tous les éléments composant le Site, y incluant les marques,
            logos, noms de domaine et autres signes distinctifs apparaissant
            sur le site `}
            <Link href={hardcoded.websiteUrl}>{hardcoded.websiteUrl}</Link>
            {` (ci-après "le Site"), sont protégés par la législation en
            vigueur sur la propriété intellectuelle et le droit d'auteur, et
            appartiennent à Breteuil ou font l'objet d'une
            autorisation d'utilisation.`}
          </React.Fragment>
        ),
      ],
    },
    {
      title: "ARTICLE 3. PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL ",
      text: [
        `Breteuil attache une grande importance à la protection et
          au respect de la vie privée et des données personnelles des 
          utilisateurs de son site internet (ci-après le « Site »).`,
        `Breteuil s'engage à mettre en œuvre les mesures adéquates
          à la protection, la confidentialité et la sécurité des Données
          Personnelles conformément à la réglementation en vigueur en France et
          dans l'Union Européenne, en particulier au Règlement Général sur la
          Protection des Données Personnelles UE 2016/679 du 27 avril 2016 et
          aux règles de droit national prises pour son application.`,
        `Nous informons les utilisateurs des pratiques et des traitements
          inhérents à la collecte et à l'utilisation des Données Personnelles par
          Breteuil en tant que responsable de traitement.`,
        `Il est précisé qu'en naviguant sur le Site, les utilisateurs acceptent
          ces conditions.`,
      ],
    },
    {
      title: "3.1. DONNÉES PERSONNELLES COLLECTÉES",
      text: [
        `Les utilisateurs sont amenés à fournir leurs Données Personnelles
          sous format numérique lors de l'utilisation du Site.`,
      ],
    },
    {
      title: "3.1.1 PRINCIPES APPLICABLES À LA COLLECTE DES DONNÉES PERSONNELLES",
      text: [
        `De manière générale, les utilisateurs peuvent naviguer sur le Site sans
          qu'il soit forcément nécessaire de communiquer à Breteuil des
          Données Personnelles.`,
        `Breteuil collecte uniquement les Données Personnelles
          nécessaires à la réalisation des traitements qu'elle met en place et
          leur assure un haut niveau de protection. A ce titre, les traitements
          nécessitant la collecte de données sont les suivants :`,
        `Répondre à vos demandes et vous proposer des services personnalisés`,
        `Demande de contact / des demandes de visite`,
        `Demande d'informations et/ou de photos supplémentaires d'un bien`,
        `Demande d'estimation de votre bien`,
        `Exécuter un mandat confié par les utilisateurs`,
        `Participer à des enquêtes permettant d'améliorer la relation et de
          l'expérience client`,
        `Envoyer des lettres d'information, des emails et/ou SMS d'alerte
          informatifs ou commerciaux`,
        `Postuler à des offres d'emploi`,
        `Réaliser des statistiques`,
        `Ces Données Personnelles sont ensuite conservées le temps nécessaire à
          l'accomplissement de la requête des utilisateurs. A défaut de réalisation
          concrète, elles sont supprimées dans les délais recommandés par la CNIL,
          soit au bout de trois ans à compter de leur collecte par le Site ou du
          dernier contact émanant de l'utilisateur sous réserve des possibilités
          et obligations légales en matière d'archivage, d'obligations de conservation
          de certaines données et/ou d'anonymisation.`,
      ],
    },
    {
      title: "3.1.2 INFORMATIONS DE NAVIGATION DES UTILISATEURS DU SITE",
      text: [
        `Lors de l'utilisation du Site ou de certains services liés au Site, certaines
          données sont recueillies de manière automatique telles que : adresse IP,
          référence du logiciel de navigation utilisé, données de navigation (date, heure,
          contenu consulté, termes de recherche utilisés, etc.), références du système
          d'exploitation.`,
        `Parmi les technologies utilisées pour recueillir ces informations, Breteuil peut avoir recours à des "sessions" ou à des "cookies" qui stockent les données de chaque utilisateur en mémoire ou sur leur disque dur.`,
        `L'utilisateur peut par ailleurs s'opposer à l'enregistrement de cookies en employant pour cela les fonctionnalités correspondantes sur leur navigateur. Cependant, Breteuil attire l'attention des utilisateurs que, dans un tel cas, l'accès à certains services du site peut se révéler altéré.`,
        `Les données collectées lors de la navigation sont ainsi supprimées au moment où le navigateur de l'utilisateur se ferme, ou, le cas échéant, dans un délai de 26 mois maximum à compter de leur collecte.`,
      ],
    },
    {
      title: "COOKIES GOOGLE ANALYTICS",
      text: [
        `Pour des raisons liées à la technologie employée par Google Analytics, la visite du Site occasionne, lorsque cela est possible, l'emploi d'un cookie installé sur les équipements terminaux des utilisateurs à des fins d'analyse statistique et de mesure d'audience du Site. Dans ce contexte, votre adresse IP est utilisée à des fins de géolocalisation sans qu'il puisse en résulter une information plus précise que la ville, étant précisé que cette adresse IP sera supprimée ou anonymisée une fois la géolocalisation effectuée. L'utilisation de tels cookies est strictement cantonnée à la production de statistiques anonymes par Google. La durée de conservation de ces cookies est limitée à 26 mois.`,
        `Les utilisateurs du site reconnaissent avoir été informés de cette pratique et autorisent Breteuil à l'employer.`,
        (
          <React.Fragment key="gga">
            {`Les modalités pour la désactivation des cookies sont précisées sur le site internet de la CNIL.`}
            <Link href={hardcoded.cnilCookiesUrl}>{hardcoded.cnilCookiesUrl}</Link>
          </React.Fragment>
        ),
      ],
    },
    {
      title: "3.2. BASE LÉGALE DE LA COLLECTE ET DU TRAITEMENT DES DONNÉES PERSONNELLES",
      text: [
        `Les Données Personnelles sont traitées par Breteuil dans les cas autorisés par la réglementation en vigueur et dans les conditions suivantes :`,
        `Obtention d'un consentement libre, spécifique, éclairé et univoque des utilisateurs au traitement de leurs Données Personnelles (il est précisé que pour les mineurs de moins de 18 ans, le consentement doit être donné par le représentant légal`,
        `Collecte de Données Personnelles nécessaires à l'exécution de la demande des utilisateurs`,
        `Respect des obligations légales et/ou réglementaires s'imposant à Breteuil (tel que la lutte contre la fraude et la corruption)`,
        `Protection des intérêts légitimes de Breteuil (tels que la protection de la sécurité de son réseau informatique).`,
      ],
    },
    {
      title: "3.3. DESTINATAIRES DES DONNÉES PERSONNELLES COLLECTÉES",
      text: [
        `Seul le personnel habilité du groupe et des prestataires de Breteuil peut avoir accès aux Données Personnelles collectées et être amené à les traiter, sans préjudice de leur éventuelle transmission aux organes chargés d'une mission de contrôle ou d'inspection conformément à la législation et/ou à la réglementation en vigueur ou aux fins de répondre à une décision judiciaire ou administrative.`,
        `Le personnel habilité est soumis à une obligation de discrétion`,
        `Breteuil s'engage à ne pas commercialiser auprès de tiers les Données Personnelles collectées.`,
      ],
    },
    {
      title: "3.4. TRANSFERT DE DONNÉES PERSONNELLES",
      text: [
        `Les Données Personnelles collectées auprès des utilisateurs du Site sont exclusivement conservées en Europe et ne sont pas transférées en dehors de l'Union Européenne.`,
        `En cas de recours à des affiliés ou à des prestataires situés en dehors de l'Union européenne, Breteuil s'engage à vérifier que des mesures appropriées ont été mises en place afin que les Données Personnelles des utilisateurs bénéficient d'un niveau de protection adéquat.`,
      ],
    },
    {
      title: "3.5. SÉCURITÉ DES DONNÉES",
      text: [
        `Breteuil collecte et traite les Données Personnelles des utilisateurs avec la plus grande confidentialité et dans le respect des lois applicables. Les utilisateurs sont protégés contre les accès, modifications, divulgations ou destructions non autorisés de leurs Données Personnelles.`,
        `Lorsque la divulgation de Données Personnelles à des tiers est nécessaire et autorisée, Breteuil s'assure que ces tiers garantissent aux Données Personnelles concernées le même niveau de protection que celui leur étant offert par Breteuil, et exige des garanties contractuelles afin que les Données Personnelles soient exclusivement traitées pour les finalités acceptées par les utilisateurs, avec la confidentialité et la sécurité requises (notamment grâce à des clauses type de la Commission européenne, des Règles internes d'entreprise (« BCR ») ou le Bouclier de protection des données mis en place entre l'Union européenne et les Etats-Unis d'Amérique (« Privacy Shield »).`,
        `Breteuil met en place des mesures techniques et organisationnelles pour assurer que la conservation des Données Personnelles est sécurisée et ce, pendant la durée nécessaire à l'exercice des finalités poursuivies.`,
        `L'attention des utilisateurs est attirée sur le fait qu'aucune technologie de transmission ou de stockage n'est totalement infaillible.`,
        `Aussi, en cas d'atteinte avérée aux Données Personnelles susceptible d'engendrer un risque élevé pour les droits et libertés des utilisateurs, Breteuil informera l'autorité de contrôle compétente de cette violation selon les modalités prévues par la réglementation applicable.`,
        `Il appartient aux utilisateurs de faire preuve de prudence pour empêcher tout accès non autorisé à leurs Données Personnelles et notamment à leurs terminaux informatiques et numériques (ordinateur, smartphone, tablette en particulier).`,
      ],
    },
    {
      title: "3.6. LIENS VERS D'AUTRES SITES INTERNET",
      text: [
        `Le Site peut occasionnellement contenir des liens vers des sites de partenaires ou de sociétés tierces qui ont leur propre charte de protection des données.`,
        `Breteuil n'exerce aucun contrôle sur le contenu de ces sites et décline toute responsabilité quant à l'utilisation faite des informations collectées lorsque les utilisateurs cliquent sur ces liens.`,
        `Breteuil invite en conséquence les utilisateurs à prendre connaissance des politiques de protection des données mises en œuvre par les éditeurs de ces sites avant de leur transmettre de quelconques informations nominatives les concernant.`,
      ],
    },
    {
      title: "3.7. DROITS DES UTILISATEURS",
      text: [
        `Il est rappelé que les utilisateurs disposent des droits suivants, sous réserve des limitations prévues par la législation en vigueur :`,
      ],
    },
    {
      title: "3.7.1. DROIT D'INFORMATION SUR LE TRAITEMENT DES DONNÉES PERSONNELLES",
      text: [
        `Breteuil s'engage à déployer ses meilleurs efforts pour délivrer une information concise, transparente et accessible sur les conditions de traitement des Données Personnelles des utilisateurs.`,
      ],
    },
    {
      title: "3.7.2. DROIT D'ACCÈS AUX DONNÉES PERSONNELLES",
      text: [
        `Chaque utilisateur peut accéder aux Données Personnelles faisant l'objet d'un traitement par Breteuil et dispose du droit d'en recevoir une copie sous forme électronique (pour toute copie supplémentaire, Breteuil sera en droit d'exiger le règlement de frais basés sur les coûts administratifs occasionnés).`,
      ],
    },
    {
      title: "3.7.3. DROIT D'EFFACEMENT (« DROIT À L'OUBLI ») ET DE RECTIFICATION DES DONNÉES PERSONNELLES",
      text: [
        `Chaque utilisateur dispose du droit de demander la suppression et/ou la rectification des Données Personnelles le concernant lorsque celles-ci sont erronées ou obsolètes.`,
        `Il est précisé que Breteuil pourra conserver certaines Données Personnelles lorsque la loi le lui imposera ou en cas de motif légitime.`,
      ],
    },
    {
      title: "3.7.4. DROIT D'OPPOSITION",
      text: [
        `Les utilisateurs peuvent s'opposer à tout moment pour des motifs légitimes :`,
        `à l'utilisation de leurs Données Personnelles à des fins de marketing direct`,
        `à la réutilisation de leurs Données Personnelles pour des traitements différents que ceux consentis, sauf en cas d'exécution par Breteuil de l'une de ses obligations légales.`,
      ],
    },
    {
      title: "3.7.5. DROIT À LA LIMITATION DES TRAITEMENTS DES DONNÉES PERSONNELLES",
      text: [
        `Les utilisateurs disposent du droit de demander que les traitements effectués sur leurs Données Personnelles soient limités à ce qui est nécessaire. Ce droit est applicable seulement :`,
        `si l'utilisateur conteste l'exactitude de ses Données Personnelles`,
        `si l'utilisateur peut établir que le traitement de ses Données Personnelles est illicite et demande une limitation de leur utilisation plutôt qu'un effacement`,
        `si Breteuil n'a plus besoin des Données Personnelles de l'utilisateur mais que celles-ci sont encore nécessaires à l'utilisateur pour la constatation, l'exercice ou la défense de droits en justice`,
        `si l'utilisateur s'oppose au traitement fondé sur l'intérêt légitime du responsable de traitement, pendant la vérification portant sur le point de savoir si les motifs légitimes poursuivis par le responsable de traitement prévalent sur ceux de l'utilisateur.`,
      ],
    },
    {
      title: "3.7.6. DROIT DE RÉCLAMATION AUPRÈS D'UNE AUTORITÉ DE CONTRÔLE",
      text: [
        `Si les utilisateurs estiment que les efforts mis en œuvre par Breteuil pour préserver la confidentialité des Données Personnelles ne garantissent pas le respect de leurs droits, ils ont la possibilité d'introduire une réclamation auprès de l'autorité de contrôle compétente (CNIL ou toute autre autorité mentionnée sur la liste disponible auprès de la Commission européenne).`,
      ],
    },
    {
      title: "3.7.7. DROIT À LA PORTABILITÉ DES DONNÉES PERSONNELLES",
      text: [
        `Les utilisateurs sont titulaires d'un droit à la portabilité de leurs données, les autorisant à obtenir de Breteuil leurs Données Personnelles dans un format structuré, couramment utilisé et lisible par la machine et à demander que ces Données Personnelles soient transmises à un autre responsable du traitement.`,
      ],
    },
    {
      title: "3.7.8. DROIT DE DÉCIDER DU SORT DES DONNÉES PERSONNELLES APRÈS LA MORT",
      text: [
        `Les utilisateurs ont également le droit d'organiser le sort de leurs Données Personnelles après leur mort par l'adoption de directives générales ou particulières que Breteuil s'engage à respecter.`,
        `En l'absence de telles directives, Breteuil reconnaît aux héritiers la possibilité d'exercer certains droits, en particulier le droit d'accès s'il est nécessaire pour le règlement de la succession du défunt et le droit d'opposition.`,
      ],
    },
    {
      title: "3.7.9. CONDITIONS D'EXERCICE DES DROITS",
      text: [
        `Pour exercer leurs droits, les utilisateurs sont invités à bien vouloir se rapprocher du Délégué à la Protection des Données Personnelles Breteuil selon les modalités décrites à l'article 5.`,
        `Il est précisé que pour les aider dans l'exercice de leurs droits, la CNIL met à leur disposition sur son site internet des modèles de courriers (www.cnil.fr). Avant de traiter la(es) demande(s) des utilisateurs, Breteuil pourra vérifier leur identité en leur demandant un justificatif d'identité.`,
        `Au besoin, ce délai pourra être prolongé de deux (2) mois supplémentaires compte tenu de la complexité et du nombre de demandes, Breteuil s'engageant alors à informer les utilisateurs de la prolongation et des motifs du report.`,
      ],
    },
    {
      title: "3.8. MODIFICATION DE LA CHARTE DE PROTECTION DES DONNÉES PERSONNELLES",
      text: [
        `Breteuil se réserve le droit d'apporter, à tout moment, des modifications à la présente Charte de protection des Données Personnelles afin de respecter les évolutions législatives et réglementaires et/ou d'améliorer sa politique de traitement et de protection des Données Personnelles.`,
        `En cas de modification, une nouvelle version sera actualisée et mise en ligne avec la date de « Dernière mise à jour ».`,
      ],
    },
    {
      title: "ARTICLE 4. DROIT APPLICABLE ET TRIBUNAL COMPETENT",
      text: [
        `La présente Charte est soumise au droit français, en ce compris les dispositions applicables aux règles de Droit international privé. A défaut d'accord amiable, la compétence est donnée aux tribunaux de paris nonobstant pluralité de défendeurs et/ou appel en garantie pour connaitre de tout litige relatif à l'utilisation du site et/ou à la validité, l'exécution et l'interprétation des présentes conditions.`,
      ],
    },
    {
      title: "ARTICLE 5. CONTACT",
      text: [
        (
          <React.Fragment key="contact">
            {"Email : "}
            <Link
              href={`mailto:${hardcoded.email}`}
            >
              {hardcoded.email}
            </Link>
          </React.Fragment>
        ),
      ],
    },
    {
      title: "ARTICLE 6. LIMITATIONS DE RESPONSABILITÉS",
      text: [
        `Breteuil ne peut garantir l'exactitude, la complétude, l'actualité des informations diffusées sur le Site. Breteuil met tout en œuvre pour diffuser des informations exactes et mises à jour. Vous reconnaissez utiliser ces informations sous votre responsabilité. Breteuil ne peut être tenue responsable :`,
        `des erreurs ou omissions des informations diffusées sur le site.`,
        `des dommages directs ou indirects qui pourraient résulter de l'accès ou de l'utilisation du Site, y compris l'accessibilité, les pertes de données, détérioration, destruction ou virus qui pourraient affecter votre équipement informatique et/ou de la présence de virus sur le site.`,
      ],
    },
    {
      title: "ARTICLE 7. DISPONIBILITÉ DU SITE",
      text: [
        `Vous êtes informé que le Site de Breteuil est accessible 24 heures sur 24 et 7 jours sur 7 à l'exception des cas de force majeure, difficultés informatiques, difficultés liées aux réseaux de télécommunications ou difficultés techniques.`,
        `Pour des raisons de maintenance, Breteuil pourra interrompre l'accès au site.`,
      ],
    },
  ],
} satisfies Definition;
