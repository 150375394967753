import * as React from 'react';
import { observer } from 'mobx-react';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

export interface PropertyPriceProps {
  formattedPrice: string,
  priceOnRequest: boolean,
}

const PropertyPrice = observer((props: PropertyPriceProps) => {
  const { formattedPrice, priceOnRequest } = props;
  const T = useTexts(texts);
  return (
    <React.Fragment>
      {priceOnRequest ? T.priceOnRequest : formattedPrice}
    </React.Fragment>
  );
});

export default PropertyPrice;
