import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  title: string,
  newPassword: string,
  confirmPassword: string,
  passwordStrength: string,
  modifyPassword: string,
  successNotif: string,
  errorNotif: string,
  email: string,
  text: string,
  resendEmail: string,
  emailSent: string,
  passwordsDontMatch: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
