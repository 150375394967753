import { Language } from '@repo-breteuil/common-texts';
import type { WeekSchedule } from '@repo-lib/utils-week-schedule';

import { formatHour, makeWeekSchedule } from '@repo-lib/utils-week-schedule';

export interface Agent {
  name: string,
  role: (language: Language) => string,
  phone: string,
  email: string,
  pictureURL: string,
}

export interface Agency {
  id: number,
  slug: string,
  nameTranslated: (language: Language) => string,
  shortName: (language: Language) => string,
  shortNameMobile: (language: Language) => string | null,
  city: (language: Language) => string, //MyBreteuil "city", which can be a region
  district: (language: Language) => string, //MyBreteuil "district", which can be a city
  address: string,
  shortAddress: string,
  zipCode: string,
  cityName: string,
  phone: string,
  email: string,
  description: ((language: Language) => string | null) | null,
  pictureURL: string,
  openingHours: WeekSchedule,
  phoneHours: WeekSchedule,
  team: Array<Agent>,
}

const cities = {
  paris: () => 'Paris',
  littoral: (language: Language) => {
    switch (language) {
      case Language.English: return 'French coast';
      default: return 'Littoral';
    }
  },
  international: () => 'International',
};

const defaultOpeningHours = makeWeekSchedule({
  everyday: { from: formatHour(9, 30), to: formatHour(19, 30) },
  saturday: { from: formatHour(10), to: formatHour(15) },
  sunday: null,
});

const defaultPhoneHours = makeWeekSchedule({
  everyday: { from: formatHour(7), to: formatHour(22) },
});

export const agencies: Array<Agency> = [{
  id: 14,
  slug: 'paris-location',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Lettings Office';
      default: return 'Service Location';
    }
  },
  shortName: () => 'Location',
  shortNameMobile: () => null,
  city: cities.paris,
  district: () => 'Paris',
  address: '35 avenue du Général Leclerc',
  shortAddress: '35 av. du G. Leclerc',
  zipCode: '75014',
  cityName: 'Paris',
  phone: '+33 1 42 24 71 71',
  email: 'location@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/Tour_front_view.jpg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 187, // TODO unsure (there are no "Service Gestion" in DB. 187="Breteuil Location Vacances")
  slug: '187',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Property Management';
      default: return 'Service Gestion';
    }
  },
  shortName: () => 'Gestion',
  shortNameMobile: () => null,
  city: cities.paris,
  district: () => 'Paris',
  address: '35 avenue du Général Leclerc',
  shortAddress: '35 av. du G. Leclerc',
  zipCode: '75014',
  cityName: 'Paris',
  phone: '+33 1 42 24 99 00',
  email: 'gestion@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
},
{
  id: 2,
  slug: 'paris-3eme-marais',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Marais Agency';
      default: return 'Agence Marais';
    }
  },
  shortName: () => 'Marais',
  shortNameMobile: () => 'Paris 3e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '3rd';
      default: return '3ème';
    }
  },
  address: '62 rue de Turenne',
  shortAddress: '62 rue de Turenne',
  zipCode: '75003',
  cityName: 'Paris',
  phone: '+33 1 85 73 69 69',
  email: 'marais@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: "/assets/agencies/agence.jpeg", //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 3,
  slug: 'paris-6eme-saint-placide',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Saint-Placide Agency';
      default: return 'Agence Saint-Placide';
    }
  },
  shortName: () => 'Saint-Placide',
  shortNameMobile: () => 'Paris 6e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '6th';
      default: return '6ème';
    }
  },
  address: '34 rue St Placide',
  shortAddress: '34 rue St Placide',
  zipCode: '75006',
  cityName: 'Paris',
  phone: '+33 1 42 84 85 00',
  email: 'saintplacide@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-1.jpeg',
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 4,
  slug: 'paris-7eme-duquesne',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Invalides Agency';
      default: return 'Agence Invalides';
    }
  },
  shortName: () => 'Invalides',
  shortNameMobile: () => 'Paris 7e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '7th';
      default: return '7ème';
    }
  },
  address: '28 avenue Duquesne',
  shortAddress: '28 av. Duquesne',
  zipCode: '75007',
  cityName: 'Paris',
  phone: '+33 1 53 58 30 90',
  email: 'duquesne@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 251,
  slug: 'paris-7eme-gros-caillou',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Gros Caillou Agency';
      default: return 'Agence Gros Caillou';
    }
  },
  shortName: () => 'Gros Caillou',
  shortNameMobile: () => 'Paris 7e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '7th';
      default: return '7ème';
    }
  },
  address: '117 rue Saint Dominique',
  shortAddress: '117 rue St Dominique',
  zipCode: '75007',
  cityName: 'Paris',
  phone: '+33 1 88 40 52 35',
  email: 'saintdominique@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 5,
  slug: 'paris-8eme-monceau',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Monceau Agency';
      default: return 'Agence Monceau';
    }
  },
  shortName: () => 'Monceau',
  shortNameMobile: () => 'Paris 8e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '8th';
      default: return '8ème';
    }
  },
  address: '17 boulevard de Courcelles',
  shortAddress: '17 bd. de Courcelles',
  zipCode: '75008',
  cityName: 'Paris',
  phone: '+33 1 56 43 60 06',
  email: 'monceau@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agence2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 6,
  slug: 'paris-11eme-mairie-du-11e',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return '11th City hall Agency';
      default: return 'Agence Mairie du 11e';
    }
  },
  shortName: () => 'Mairie du 11e',
  shortNameMobile: () => 'Paris 11e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '11th';
      default: return '11ème';
    }
  },
  address: '13 bis avenue Parmentier',
  shortAddress: '13b av. Parmentier',
  zipCode: '75011',
  cityName: 'Paris',
  phone: '+33 1 45 55 11 11',
  email: 'parmentier@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 210,
  slug: 'paris-14eme-mairie-du-14e',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return '14th City hall Agency';
      default: return 'Agence Mairie du 14e';
    }
  },
  shortName: () => 'Mairie du 14e',
  shortNameMobile: () => 'Paris 14e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '14th';
      default: return '14ème';
    }
  },
  address: '35 avenue du Général Leclerc',
  shortAddress: '35 av. du G. Leclerc',
  zipCode: '75014',
  cityName: 'Paris',
  phone: '+33 1 87 12 80 00',
  email: 'leclerc@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 8,
  slug: 'paris-15eme-zola',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Zola Agency';
      default: return 'Agence Zola';
    }
  },
  shortName: () => 'Zola',
  shortNameMobile: () => 'Paris 15e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '15th';
      default: return '15ème';
    }
  },
  address: '91 rue du Théâtre',
  shortAddress: '91 rue du Théâtre',
  zipCode: '75015',
  cityName: 'Paris',
  phone: '+33 1 58 01 18 18',
  email: 'zola@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
},
{
  id: 349,
  slug: 'paris-15eme-montparnasse',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Montparnasse Agency';
      default: return 'Agence Montparnasse';
    }
  },
  shortName: () => 'Montparnasse',
  shortNameMobile: () => 'Paris 15e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '15th';
      default: return '15ème';
    }
  },
  address: '42 Bd du Montparnasse',
  shortAddress: '42 bd. du Montparnasse',
  zipCode: '75015',
  cityName: 'Paris',
  phone: '+33 1 88 40 44 00',
  email: 'montparnasse@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-zoom-2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 12,
  slug: 'paris-16eme-victor-hugo',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Victor Hugo Agency';
      default: return 'Agence Victor Hugo';
    }
  },
  shortName: () => 'Victor Hugo',
  shortNameMobile: () => 'Paris 16e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '16th';
      default: return '16ème';
    }
  },
  address: '155 avenue Victor Hugo',
  shortAddress: '155 av. Victor Hugo',
  zipCode: '75116',
  cityName: 'Paris',
  phone: '+33 1 40 67 00 00',
  email: 'victorhugo@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 9,
  slug: 'paris-16eme-auteuil',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Auteuil Agency';
      default: return 'Agence Auteuil';
    }
  },
  shortName: () => 'Auteuil',
  shortNameMobile: () => 'Paris 16e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '16th';
      default: return '16ème';
    }
  },
  address: '8 rue Corot',
  shortAddress: '8 rue Corot',
  zipCode: '75016',
  cityName: 'Paris',
  phone: '+33 1 42 24 80 00',
  email: 'corot@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/Tour_front_view.jpg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 312,
  slug: 'paris-16eme-mairie-du-16eme',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return '16th City hall Agency';
      default: return 'Agence Mairie Du 16e';
    }
  },
  shortName: () => 'Mairie Du 16e',
  shortNameMobile: () => 'Paris 16e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '16th';
      default: return '16ème';
    }
  },
  address: '67 rue de la Tour',
  shortAddress: '67 rue de la Tour',
  zipCode: '75016',
  cityName: 'Paris',
  phone: '+33 1 88 40 52 70',
  email: 'pompe@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-zoom-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 10,
  slug: 'paris-16eme-passy-muette',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Passy-Muette Agency';
      default: return 'Agence Passy-Muette';
    }
  },
  shortName: () => 'Passy-Muette',
  shortNameMobile: () => 'Paris 16e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '16th';
      default: return '16ème';
    }
  },
  address: '1 rue de la Pompe',
  shortAddress: '1 rue de la Pompe',
  zipCode: '75016',
  cityName: 'Paris',
  phone: '+33 (0)1 88 40 52 70',
  email: 'muette@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-zoom-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 11,
  slug: 'paris-17eme-niel',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Niel Agency';
      default: return 'Agence Niel';
    }
  },
  shortName: () => 'Niel',
  shortNameMobile: () => 'Paris 17e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '17th';
      default: return '17ème';
    }
  },
  address: '34 avenue Niel',
  shortAddress: '34 av. Niel',
  zipCode: '75017',
  cityName: 'Paris',
  phone: '+33 1 40 54 78 78',
  email: 'niel@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agence.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 231,
  slug: 'paris-18eme-montmartre',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Montmartre Agency';
      default: return 'Agence Montmartre';
    }
  },
  shortName: () => 'Montmartre',
  shortNameMobile: () => 'Paris 18e',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '18th';
      default: return '18ème';
    }
  },
  address: '59 rue Caulaincourt',
  shortAddress: '59 rue Caulaincourt',
  zipCode: '75018',
  cityName: 'Paris',
  phone: '+33 1 87 12 33 33',
  email: 'caulaincourt@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-2.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
},
{
  id: 350,
  slug: 'paris-neuilly',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Neuilly Agency';
      default: return 'Agence Neuilly';
    }
  },
  shortName: () => 'Neuilly',
  shortNameMobile: () => 'Neuilly',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '';
      default: return '';
    }
  },
  address: '110 av. Achille Peretti',
  shortAddress: '110 av. Achille Peretti',
  zipCode: '92200',
  cityName: 'Neuilly sur Seine',
  phone: '+33 1 88 40 44 20',
  email: 'neuilly@breteuilhomes.com',
  description: () => '',
  pictureURL: '/assets/agencies/agence2.jpeg',
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 351,
  slug: 'paris-16eme-hauts-de-seine',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Agency Paris West';
      default: return 'Agence Ouest Parisien';
    }
  },
  shortName: () => 'Ouest Parisien',
  shortNameMobile: () => 'Ouest Parisien',
  city: cities.paris,
  district(language: Language) {
    switch (language) {
      case Language.English: return '16th';
      default: return '16ème';
    }
  },
  address: '91 avenue Paul Doumer',
  shortAddress: '91 av Paul Doumer',
  zipCode: '75016',
  cityName: 'Paris',
  phone: '+33 1 87 44 85 17',
  email: 'hautsdeseine@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-1.jpeg', //TODO
  openingHours: defaultOpeningHours,
  phoneHours: defaultPhoneHours,
  team: [],
}, {
  id: 16,
  slug: 'littoral-pays-basque-biarritz',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Biarritz Agency';
      default: return 'Agence Biarritz';
    }
  },
  shortName: () => 'Biarritz',
  shortNameMobile: () => 'Biarritz',
  city: cities.littoral,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'Pays Basque';
      default: return 'Pays Basque';
    }
  },
  address: '25 avenue Edouard VII',
  shortAddress: '25 av. Edouard VII',
  zipCode: '64200',
  cityName: 'Biarritz',
  phone: '+33 5 59 54 57 05',
  email: ' biarritz@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-zoom-1.jpeg', //TODO
  openingHours: defaultOpeningHours, //TODO
  phoneHours: defaultPhoneHours, //TODO
  team: [],
}, {
  id: 18,
  slug: 'littoral-pays-basque-st-jean-de-luz',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'St Jean de Luz Agency';
      default: return 'Agence St Jean de Luz';
    }
  },  shortName: () => 'St Jean de Luz',
  shortNameMobile: () => 'St Jean de Luz',
  city: cities.littoral,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'Pays Basque';
      default: return 'Pays Basque';
    }
  },
  address: '31 boulevard Thiers',
  shortAddress: '31 bd. Thiers',
  zipCode: '64500',
  cityName: 'Saint Jean de Luz',
  phone: '+33 5 59 54 15 46',
  email: 'saintjeandeluz@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-1.jpeg', //TODO
  openingHours: defaultOpeningHours, //TODO
  phoneHours: defaultPhoneHours, //TODO
  team: [],
}, {
  id: 233,
  slug: 'littoral-loire-atlantique-la-baule',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'La Baule Agency';
      default: return 'Agence La Baule';
    }
  },
  shortName: () => 'La Baule',
  shortNameMobile: () => 'La Baule',
  city: cities.littoral,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'Loire-Atlantic';
      default: return 'Loire Atlantique';
    }
  },
  address: '21 avenue Pierre Loti 44500', //TODO
  shortAddress: '21 av. Pierre Loti', //TODO
  zipCode: '44500',
  cityName: 'La Baule Escoublac',
  phone: '+33 2 40 60 06 14',
  email: 'labaule@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/labaule-agency-front.jpg', //TODO
  openingHours: defaultOpeningHours, //TODO
  phoneHours: defaultPhoneHours, //TODO
  team: [],
}, {
  id: 17,
  slug: 'littoral-bretagne-dinard',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Dinard Agency';
      default: return 'Agence Dinard';
    }
  },
  shortName: () => 'Dinard',
  shortNameMobile: () => 'Dinard',
  city: cities.littoral,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'Brittany';
      default: return 'Bretagne';
    }
  },
  address: '15 boulevard du président Wilson',
  shortAddress: '15 bd. Wilson',
  zipCode: '35800',
  cityName: 'Dinard',
  phone: '+33 2 57 67 15 33',
  email: 'dinard@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-1.jpeg', //TODO
  openingHours: defaultOpeningHours, //TODO
  phoneHours: defaultPhoneHours, //TODO
  team: [
  ],
}, {
  id: 296,
  slug: 'littoral-normandie-deauville',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Deauville Agency';
      default: return 'Agence Deauville';
    }
  },
  shortName: () => 'Deauville',
  shortNameMobile: () => 'Deauville',
  city: cities.littoral,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'Normandie';
      default: return 'Normandie';
    }
  },
  address: '27 rue Breney',
  shortAddress: '27 rue Breney',
  zipCode: '14800',
  cityName: 'Deauville',
  phone: '+33 2 58 06 00 60',
  email: 'deauville@breteuilhomes.com',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-zoom-1.jpeg', //TODO
  openingHours: defaultOpeningHours, //TODO
  phoneHours: defaultPhoneHours, //TODO
  team: [
  ],
}, {
  id: 186,
  slug: 'international-londres-chelsea',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Chelsea Agency';
      default: return 'Agence Chelsea ';
    }
  },
  shortName: () => 'London, Chelsea',
  shortNameMobile: (languages: Language) => {
    switch (languages)
    {
      case Language.English:
        return 'London, Chelsea';
      default:
        return 'Londres, Chelsea';
    }
  },
  city: cities.international,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'London';
      default: return 'Londres';
    }
  },
  address: '348 Fulham Road',
  shortAddress: '348 Fulham Road',
  zipCode: 'SW10 9UH',
  cityName: 'London',
  phone: '+44 20 7351 6308',
  email: 'chelsea@breteuil.co.uk',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-int-2.jpeg', //TODO
  openingHours: makeWeekSchedule({
    everyday: { from: formatHour(9, 30), to: formatHour(19, 30) },
    sunday: null,
  }),
  phoneHours: defaultPhoneHours,
  team: [
  ],
}, {
  id: 44,
  slug: 'international-londres-fulham',
  nameTranslated(language: Language) {
    switch (language) {
      case Language.English: return 'Fulham Agency';
      default: return 'Agence Fulham';
    }
  },
  shortName: () => 'Fulham',
  shortNameMobile: (languages: Language) => {
    switch (languages)
    {
      case Language.English:
        return 'London, Fulham';
      default:
        return 'Londres, Fulham';
    }
  },
  city: cities.international,
  district(language: Language) {
    switch (language) {
      case Language.English: return 'London';
      default: return 'Londres';
    }
  },
  address: '632 Fulham Road',
  shortAddress: '632 Fulham Road',
  zipCode: 'SW6 5RT',
  cityName: 'London',
  phone: '+44 20 3931 0885',
  email: 'fulham@breteuil.co.uk',
  description: () => '', //TODO
  pictureURL: '/assets/agencies/agency-ext-zoom-2.jpeg', //TODO
  openingHours: makeWeekSchedule({
    everyday: { from: formatHour(9, 30), to: formatHour(19, 30) },
    sunday: null,
  }),
  phoneHours: defaultPhoneHours,
  team: [
  ],
}];

export const hardCodedAgencyIds = agencies.map((agency) => agency.id);

export const AgencyIdToAgencyDetails = new Map<number, Agency>(agencies.map(agency => [agency.id, agency]));
