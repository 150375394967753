import { Definition } from ".";

export default {
  pageTitle: "Location immobilière de standing et de prestige - Breteuil Remarkable Homes",
  pageDescription: "Découvrez notre sélection exclusive de biens immobiliers haut de gamme disponibles à la location avec Breteuil Remarkable Homes.",
  form: {
    budget: "Budget",
    priceMin: "Minimum",
    priceMax: "Maximum",
    formatBedrooms(value: number): string
    {
      return value > 1 ? "Chambres" : "Chambre";
    },
    area: "Region",
    guests: "Invités",
  },
  everywhere: "Partout",
  resetButton: "Effacer",
  submitButton: "Valider",
  errorLoadMore: 'Un problème est survenu lors du chargement. Veuillez réessayer plus tard',
} satisfies Definition;
