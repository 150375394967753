import { styled } from '@mui/material/styles';
import {
  TextFieldBase as GenericValueTextField,
  TextField as GenericTextField,
} from '@repo-lib/mobx-forms-mui-components';

export const TextFieldStyle = {
  borderRadius: '0px',
  backgroundColor: '#FAFAFA',
  width: '100%',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.12)',
  '& label.Mui-focused': {
    color: '#0C17E1',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#0C17E1',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
      borderRadius: '0px',
    },
    '&:hover fieldset': {
      borderColor: '#0C17E1',
      borderRadius: '0px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0C17E1',
      borderRadius: '0px',
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%',
  },
} as const;

export const ValueTextField = styled(GenericValueTextField)(TextFieldStyle) as typeof GenericValueTextField;

export const TextField = styled(GenericTextField)(TextFieldStyle) as typeof GenericTextField;
