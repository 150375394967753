import type { IdType } from '@repo-breteuil/common-definitions';

import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {ExpandMore, LocationOn} from '@mui/icons-material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import {
  createFormFieldSlider,
  useFormFieldSelect,
} from '@repo-lib/mobx-forms';
import { SuspendableReaction } from '@repo-lib/utils-mobx-store';
import { useReaction } from '@repo-lib/utils-mobx-react';
import { ensureFetchableResource } from '@repo-breteuil/front-error';
import { Currency } from '@repo-lib/utils-texts';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import { makeBudgetSliderFormatInputValueFn } from '@breteuil-website/components/common';
import BreteuilWebsitePricePicker from '@breteuil-website/components/common/form/BreteuilWebsitePricePicker';
import { getOperationTypePriceFilterValues } from '@breteuil-website/store/ui/pages/properties-search';
import { Select, SelectNullable, Button } from '@breteuil-website/components/common';
import { scale } from '@breteuil-website/components/common';
import { getPropertiesSearchRoute } from '@breteuil-website/store/routing';
import texts from '../texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '90%',
  margin: "55px auto",
  maxWidth: '700px',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
    margin: "35px auto",
  },
});

const SelectCityWrapper = styled('div')({
  width: "25%",
  '@media (max-width: 960px)': {
    width: "100%",
    margin: '15px auto',
    height: '56px',
  },
});

const SelectOperationTypeWrapper = styled('div')({
  width: "20%",
  '@media (max-width: 960px)': {
    width: "100%",
    margin: '15px auto',
    height: '56px',
  },
});

const ButtonWrapper = styled('div')({
  width: "15%",
  '@media (max-width: 960px)': {
    width: "100%",
    margin: '15px auto',
    height: '56px',
  },
});

const BudgetPickerWrapper = styled('div')({
  width: "40%",
  '@media (max-width: 960px)': {
    width: "100%",
    margin: '15px auto',
    height: '56px',
  },
  backgroundColor: '#FAFAFA !important',
  border: '1px solid #FAFAFA',
  color: '#050251 !important',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  '& fieldset': {
    borderRadius: 0,
  },
  '& .MuiButton-textPrimary': {
    backgroundColor: 'inherit !important',
  },
  '&:hover': {
    border: "1px solid #0C17E1",
  },
});

const BWExpandMoreIcon = styled(ExpandMore)({
  color: '#050251 !important',
});
const BWLocationOnIcon = styled(LocationOn)({
  color: '#050251 !important',
});

const SubmitButton = styled(Button)({
  height: "100% !important",
  fontSize: "12px !important",
  '@media (max-width: 960px)': {
    padding:'17.5px 0 !important',
    fontSize: "14px !important",
  },
});

const SelectCity = styled(SelectNullable)({
  '& .MuiSelect-outlined': {
    fontSize: '14px',
    fontWeight: "700 !important",
    fontFamily: 'Mulish !important',
    color: '#050251 !important',
    paddingRight: '0 !important',
 },
});

const SelectOperationType = styled(Select)({
  width: "100%",
  '& label.Mui-focused': {
      color: '#0C17E1',
  },
  '& .MuiSelect-outlined': {
    fontSize: '14px',
    fontWeight: "700 !important",
    fontFamily: 'Mulish !important',
    color: '#050251 !important',
  },
  '& .MuiMenuItem-root': {
      display: "flex !important",
  },
  '& .MuiInput-underline:after': {
      borderBottomColor: '#0C17E1',
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: '#FFFFFF',
          borderRadius: '0px',
      },
      '&:hover fieldset': {
          borderColor: '#0C17E1',
          borderRadius: '0px',
      },
      '&.Mui-focused fieldset': {
          borderColor: '#0C17E1',
          borderRadius: '0px',
      },
  },
  '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%',
  },
});

const EverywhereValue = -1;

const BannerForm = observer(() => {
  const { areas } = useWebsiteStores();
  const { areasPerId, filtersAreas } = ensureFetchableResource(areas.areas);

  const lang = useLocale();
  const T = useTexts(texts);

  const operationType = useFormFieldSelect({
    options: () => new SelectOptions<OperationType>(new Map([
      [ OperationType.ResidencyTransaction, T.operationType.transaction ],
      [ OperationType.Rental, T.operationType.rental ],
      [ OperationType.SeasonalRental, T.operationType.seasonalRental],
    ])),
    defaultValue: () => OperationType.ResidencyTransaction,
  }, [ T ]);

  const area = useFormFieldSelect({
    options: () => new SelectOptions<IdType>(new Map([
      [ EverywhereValue, T.everywhere ],
      ...(filtersAreas.get(operationType.value) || []).map(({ area }) => [ area.id, area.name ]),
    ] as Array<[ IdType, string ]>)),
    defaultValue: () => EverywhereValue,
  }, [ filtersAreas, operationType.value, T ]);

  const [ price ] = React.useState(() => createFormFieldSlider<number>(
    getOperationTypePriceFilterValues(operationType.value).default,
    {
      formatInputValue: makeBudgetSliderFormatInputValueFn({
        getOperationType: () => operationType.value,
      }),
    },
  ));

  useReaction(() => new SuspendableReaction(
    () => operationType.value,
    (operationType) => {
      price.setValue(getOperationTypePriceFilterValues(operationType).default);
    },
  ));

  function getCurrentArea()
  {
    const areaId = area.value;
    if (areaId === EverywhereValue)
      return null;
    const areaWithSubAreas = areasPerId.get(areaId);
    if (!areaWithSubAreas)
      throw new Error(`Assertion failed: couldn't find Area#${areaId}`);
    return areaWithSubAreas.area;
  }

  const currentArea = React.useMemo(
    getCurrentArea,
    [ area.value, filtersAreas ],
  );

  const handleSubmit = React.useCallback((values) => {
    const route = getPropertiesSearchRoute({
      lang,
      operationType: operationType.value,
      priceMax: operationType.value === OperationType.ResidencyTransaction ? scale(price.value)  : price.value,
      areaSlug: getCurrentArea()?.slug,
    });
    router.changeRoute(route);
  }, []);

  const { maxAmount } = getOperationTypePriceFilterValues(operationType.value);
  const filterRef = React.useRef<HTMLDivElement | null>(null);
  return (
    <React.Fragment>
      <Row ref={filterRef}>
        <SelectCityWrapper>
          <SelectCity
            field={area}
            placeholder={T.selectLabelCity}
            dropDownArrowComponent={BWExpandMoreIcon}
            startAdornmentComponent={BWLocationOnIcon}
          />
        </SelectCityWrapper>
        <SelectOperationTypeWrapper>
          <SelectOperationType
            field={operationType}
            dropDownArrowComponent={BWExpandMoreIcon}
          />
        </SelectOperationTypeWrapper>
        <BudgetPickerWrapper>
          <BreteuilWebsitePricePicker
            field={price}
            maxAmount={maxAmount}
            operationType={operationType.value}
            currency={currentArea?.currency ?? Currency.EUR}
            ref={filterRef}
          />
        </BudgetPickerWrapper>
        <ButtonWrapper>
          <SubmitButton onClick={handleSubmit}>
            {T.submitBannerForm}
          </SubmitButton>
        </ButtonWrapper>
      </Row>
    </React.Fragment>
  );
});

export default BannerForm;
