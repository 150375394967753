import * as React from 'react';
import { observer } from "mobx-react";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts, error as errorTexts } from '@repo-breteuil/common-texts';
import router, { Routes } from '@breteuil-website/store/routing';
import { SendLogInCode } from '@breteuil-website/store/ui/pages/client-area/log-in/api';
import {
  CodeInput,
  Button as GenericButton,
} from '@breteuil-website/components/common';
import { getErrorCode } from '@repo-breteuil/front-error';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    maxWidth: '700px',
    backgroundColor: '#F0F5FF',
    borderRadius: 0,
    padding: '3em',
    '@media (max-width: 960px)': {
      padding: '.5em',
    },
  },
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '24px',
  height: '24px',
  padding: "24px",
});

const DialogTitle = styled(MuiDialogTitle)({
  color: '#050251',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  fontFamily: 'Poppins',
  textAlign: 'center',
  '@media (max-width: 960px)': {
    fontSize: '18px',
    position: "relative",
    margin: "15px auto 0",
  },
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '15px 0 40px 0',
});

const Button = styled(GenericButton)({
  position: 'relative',
  width: '50% !important',
  margin: '0 auto 25px auto !important',
  '@media (max-width: 960px)': {
    width: '70% !important',
  },
  '&:hover': {
    color: "white",
  },
});

const CodeInputSize = 6;

export interface LogInWithCodeProps {
  open: boolean,
  handleClose: () => void,
}

const LogInWithCode = observer((props: LogInWithCodeProps) => {
  const { open, handleClose } = props;
  const lang = useLocale();
  const T = useTexts(texts);
  const CT = useTexts(errorTexts.texts);
  const { logIn, query, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    code: createValueFormField('', { validation: (value) => value.length === 6 }),
  }), {
    onSubmit: (values) => {
      const promise = logIn.loginWithCode({ email: logIn.userEmail! /* Safe assertion */, code: values.code });
      globalMessage.handlePromise(promise, {
        onSuccess(res) {
          if (res) {
            router.changeRoute(Routes.clientAreaSearches.generateStaticPath({ lang }));
          }
        },
        successMessage: null,
        onError(error) {
          if (getErrorCode(error) === 'InvalidCredentials')
            globalMessage.display(T.invalidCodeCredentials, { severity: 'error' });
          else
            globalMessage.display(CT.genericError, { severity: 'error' });
        },
        errorMessage: null,
      });
    },
  });

  const resetCodeWhenClosing = () => {
    form.reset();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={resetCodeWhenClosing}
      fullWidth
      slotProps={{
        backdrop: {
          sx: { backgroundColor: '#FFFFFF', opacity: '0.8 !important' },
        },
      }}
    >
      <CloseButton onClick={resetCodeWhenClosing}>
        <CloseIcon/>
      </CloseButton>
      <DialogTitle>
        {T.loginWithCode}
      </DialogTitle>
      <DialogContent>
        <Typography component="h5" mb={2}>
          {T.codeDescription(logIn.userEmail! /* Safe assertion */)}
        </Typography>
        <form onSubmit={form.handleSubmit}>
          <CodeInput
            size={CodeInputSize}
            value={form.fields.code.value}
            onChange={(newValue) => {
              form.fields.code.handleChange(newValue);
              if (newValue.length === CodeInputSize) {
                form.handleSubmit(newValue);
                resetCodeWhenClosing();
              }
            }}
          />
        </form>
      </DialogContent>
      <Button
        onClick={() => {
          form.reset();
          const promise = SendLogInCode(query, { email: logIn.userEmail! /* Safe assertion */});
          globalMessage.handlePromise(promise, {
            successMessage: T.successfullCodeResent,
            errorMessage: CT.genericError,
          });
        }}
      >
        {T.sendNewCode}
      </Button>
    </Dialog>
  );
});

export default LogInWithCode;
