import * as React from 'react';

export default {
  title: 'Bienvenue sur le site de BRETEUIL',
  text(): JSX.Element
  {
    return (
      <React.Fragment>
        Nous utilisons des cookies et des technologies similaires pour vous offrir une meilleure expérience de navigation et analyser notre traffic.
        Certains cookies sont indispensables au bon fonctionnement de nos services.
      </React.Fragment>
    );
  },
  onlyEssentials: 'REFUSER LES COOKIES NON ESSENTIELS',
  acceptAll: 'ACCEPTER TOUS LES COOKIES',
};
