import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { OperationType } from '@repo-breteuil/common-definitions';
import type { Criteria } from './shared';

import { criteriaFields } from './shared';
import { QueryStore } from '@repo-breteuil/front-store-query';

export async function GetCriteria(
  qs: QueryStore,
  args: {
    language: Language,
    areaId: IdType,
    operationType: OperationType,
  },
): Promise<Array<Array<Criteria>>>
{
  return qs.query({
    operationName: 'GetCriteria',
    variables: {
      language: 'Language!',

      areaId: 'Int!',
      operationType: 'MyBreteuilOperationType!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          searchCriteria: [{
            args: {
              operationType: true,
              areaId: true,
            },
          }, criteriaFields ],
        },
      },
    },
  }, args);
}
