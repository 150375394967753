import type { createFormFieldSlider } from '@repo-lib/mobx-forms';
import type { Currency } from '@repo-lib/utils-texts';
import { OperationType } from '@repo-breteuil/common-definitions';

import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Popover,
  styled,
  Button,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { BudgetSlider, scale } from '@breteuil-website/components/common';
import FilterItem from '@breteuil-website/components/common/form/FilterItem';
import BreteuilWebsiteButton from '@breteuil-website/components/common/form/BreteuilWebsiteButton';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Modal = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  fontFamily: 'Mulish',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: isMobile ? '20px 30px' : '30px 130px',
  backgroundColor: '#FFFFFF',
  borderTop: '2px solid #050251',
  boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)',
  '@media (max-width: 960px)': {
    width: '300px',
  },
}));

const ValuesContainer = styled('div')({
  display: 'flex',
  margin: '15px 0px 20px',
});

const Value = styled('div')({
  margin: '0px 6px',
  '@media (max-width: 550px)': {
    fontSize: "14px",
  },
});

const SliderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '20px',
});

const BudgetPickerIcon = styled('span')({
  display: 'flex',
  color: '#050251',
  padding: '12px',
  fontSize: '14px !important',
  alignItems: 'center',
});

const BWExpandMoreIcon = styled(ExpandMore)({
  color: '#050251 !important',
  marginRight: "10px",
});

// !important because otherwise mui default style takes priority
// For some reason styled doesnt include textTransform hence the error ignored. CSS is applied properly tho..
const PricePickerButton = styled(Button)({
  // @ts-ignore
  width: '100%',
  height: '100%',
  fontFamily: 'Mulish !important',
  fontSize: "14px !important",
  color: "rgba(0, 0, 0, 0.6) !important",
  display: "flex !important",
  justifyContent: 'space-between !important',
  padding: "10px 8px !important",
  textTransform: 'none !important',
});

interface priceModalProps {
  field: ReturnType<typeof createFormFieldSlider<number>>,
  maxAmount: number,
  currency: Currency,
  operationType: OperationType,
  onClose: () => void,
}

const PriceModal = observer((props: priceModalProps) => {
  const T = useTexts(texts).filter.price;
  const { onClose, ...sliderProps } = props;
  const isMobile = useMediaQueryMobile();
  const trueValue = props.operationType === OperationType.ResidencyTransaction ?
    scale(props.field.props.value) : props.field.props.value;

  return (
    <Modal isMobile={isMobile}>
      <b>{T.description}</b>
      <ValuesContainer>
        <Value>
          {T.formatMax(trueValue, props)}
        </Value>
      </ValuesContainer>
      <SliderWrapper>
        <BudgetSlider {...sliderProps}/>
      </SliderWrapper>
      <BreteuilWebsiteButton
        variant="contained"
        onClick={onClose}
      >
        {T.apply}
      </BreteuilWebsiteButton>
    </Modal>
  );
});

interface pricePickerProps {
  field: ReturnType<typeof createFormFieldSlider<number>>,
  maxAmount: number,
  currency: Currency,
  operationType: OperationType,
}

const PricePicker = observer(React.forwardRef(
  (props: pricePickerProps, ref: React.MutableRefObject<HTMLDivElement | null>) => {
    const T = useTexts(texts).filter.price;
    const [anchorEl, setAnchorEl] = React.useState<Element | (() => Element) | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = () => {
      if (ref.current) {
        setAnchorEl(ref.current);
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const trueValue = props.operationType === OperationType.ResidencyTransaction ?
      scale(props.field.props.value) : props.field.props.value;

    return (
      <React.Fragment>
        <FilterItem isSelected={open}>
          <BudgetPickerIcon>
            <img src={"/assets/coin-stack.svg"} alt="Budget icon"/>
          </BudgetPickerIcon>
          <PricePickerButton
            disableRipple
            {...omitUndefinedValues({ "aria-describedby": id })}
            onClick={handleClick}
          >
            {T.formatPrice(trueValue, props)}
            <BWExpandMoreIcon/>
          </PricePickerButton>
        </FilterItem>
        <Popover
          {...omitUndefinedValues({ id })}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { width: '100%', maxWidth: '700px' },
          }}
        >
          <PriceModal
            {...props}
            onClose={handleClose}
          />
        </Popover>
      </React.Fragment>
    );
  }));

export default PricePicker;
