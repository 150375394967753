import type { Agency } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';

import * as React from 'react';
import * as datefns from 'date-fns';
import { fr as DateFnsFrLocale } from 'date-fns/locale';
import { Currency } from '@repo-lib/utils-texts';
import {
  number as numberTexts,
  error as errorTexts,
} from '@repo-breteuil/common-texts';
import { formatNumberWithSeparators } from '@repo-lib/utils-texts';

// TODO trads

export default {
  pageTitle: "Real estate appraisal: get a free property valuation - Breteuil Remarkable Homes",
  pageDescription: "Need to appraise your property? Trust Breteuil Remarkable Homes, the prestigious real estate agency specializing in high-end real estate.",
  method1: {
    title: 'Quick Valuation',
    form: {
      labels: {
        address: 'Adresse du bien',
        transactionPrice: "Prix d'achat de votre bien",
        transactionMonth: "Mois d'achat",
        transactionYear: "Année d'achat",
        renovationCost: 'Montant des travaux',
        renovationYear: "Année de début des travaux",
        renovationYearInfo: "(si différent de l'année d'achat)",
        surface: 'Surface (m2)',
        phone: 'Mon numéro de mobile',
      },
      submit: "Get an activation code",
      phoneAlreadyConfirmed: 'Numéro de téléphone déjà confirmé.',
      addressWithoutNumberError: "The address should start with the street number.",
      transactionYearError: "The year must be 4 digits long.",
      errorToast: 'Une erreur est survenue',
      handleFormSubmissionErrors(error): string {
        switch (error.code)  {
          case 'InvalidPhoneNumber' :
            return "Numéro de téléphone incorrect. Veuillez le saisir à nouveau.";
          case 'UnsupportedAddress':
            return "Nous ne disposons pas de données suffisantes à cette adresse.";
          case 'InvalidAddress':
            return "Adresse non localisable.";
          case 'InvalidTransactionYear':
            return "Année d'achat invalide.";
          case 'InvalidRenovationYear':
            return "Année de début des travaux invalide.";
          case 'InvalidToken':
            return "Le code fourni est invalide.";
          default :
            return "Une erreur est survenue.";
        }
      },
      phoneConfirmed: "New valuation",
      surfacePlaceholder: 'm2',
    },
    confirmPhoneDialog: {
      title: (
        <React.Fragment>
          Enter the activation code
          <br/>
          you recieved via SMS
        </React.Fragment>
      ),
      actions: {
        sendNewCode: 'Send a new code',
        confirm: 'Continue',
      },
      successfullCodeResent: 'Un nouveau code a été envoyé.',
      phoneAlreadyConfirmed: 'Numéro de téléphone déjà confirmé.',
      handleFormSubmissionErrors(error): string {
        switch (error.code)  {
          case 'InvalidToken' :
            return("Le numéro entré est invalide.");
          default :
            return("Une erreur est survenue.");
        }
      },
    },
    valuationResult: {
      valuationText: "Estimation de votre bien",
      valuationPrice: (price: number | null | undefined, currency: Currency) => (
        <span>
          {price != null
            ? numberTexts.EN.formatNumber(price, { currency, truncate: 'integer' })
            : numberTexts.EN.decorateNumberValue('-', { currency })
          }
        </span>
      ),
      valuationError: (
        <React.Fragment>
          Nous n&apos;avons pas la donnée suffisante pour évaluer votre bien de manière précise.<br/>
          Veuillez prendre rendez vous avec un conseiller (méthode 3)
        </React.Fragment>
      ),
    },
  },
  method2: {
    title: 'Smart Valuation',
    form: {
      select: {
        extArea: {
          label: 'Outside surface',
          balcony: 'Balcony',
          terrace: 'Terrace',
          terraceUnoverlooked: 'Rooftop',
          backyard: 'Backyard',
        },
        binary: {
          yes: 'Yes',
          no: 'No',
        },
        direction: {
          label: 'Orientation',
          north: 'North',
          east: 'East',
          west: 'West',
          south: 'South',
        },
        remarkableView: {
          label: 'View',
          normal: 'Normal',
          good: 'Good',
          oustanding: 'Outstanding',
        },
        buildingType: {
          label: 'Building',
          haussmannian: 'Haussmannian',
          modern: 'Recent',
          other: '1950 / Paris stones / etc...',
        },
        renovation: {
          label: 'Finishing Touch',
          new: 'Fully renovated',
          normal: 'Good state',
          needRefresh: 'Need refresh',
          workNeeded: 'Need a makeover',
        },
        rooms: {
          one: '1',
          two: '2',
          three: '3',
          four: '4',
          fiveAndMore: '5+',
        },
        floors: {
          rdc: 'G',
          one: '1',
          two: '2',
          three: '3',
          four: '4',
          fiveAndMore: '5+',
        },
        popularAdress: 'Popular adress',
        elevator: 'Elevator',
        lastFloor: 'Last floor',
      },
      input: {
        exteriorSurface: 'Outside surface',
        address: 'Adress',
        surface: 'Surface',
        surfaceMetric: 'm²',
        transactionMonth: 'Transaction month',
        transactionPrice: 'Transaction price',
        transactionYear: 'Transaction year',
        renovationCost: 'Renovation cost',
        renovationYear: (
          <React.Fragment>
            Renovation Year
          </React.Fragment>
        ),
        balcony: 'Balcony surface',
        terrace: 'Terrace surface',
        terraceUnoverlooked: 'Rooftop surface',
        backyard: 'Baryard Surface',
        rooms: 'Rooms',
        floor: 'Floor',
        renovation: 'Finishing touch',
        contactMe: 'I would like to be contacted',
      },
      submitButton: 'Calculate',
      submitPhone: 'Receive my code',
      phoneActivationTitle: (
        <React.Fragment>
          Enter your phone number
          <br/>
          to receive your activation code
        </React.Fragment>
      ),
      phoneActivationTitleMobile: "Enter your phone number to receive your activation code",
    },
    results: {
      loading: 'Calcul in progress',
      labelPlaceholder: 'Estimated price',
      labelResult: 'Price between',
      placeholder: '- €', // TRAD
      formatResult: (result: number) => {
        const roundedResult = Math.round(result / 10000) * 10000;
        return `${formatNumberWithSeparators(roundedResult - Math.round((result * 0.05) / 10000) * 10000, ' ')} € and ${formatNumberWithSeparators(Math.round((result * 1.15) / 10000) * 10000, ' ')} €`;
      },
    },
    unsupportedCityDisclaimer: "Nous n'avons pas suffisament de données pour estimer votre bien dans cette ville pour le moment. Veuillez prendre rendez-vous.",
  },
  method3: {
    title: 'Make an appointment',
    prev: 'Return',
    anchorId: 'valutation-appointment',
    anchorText: 'Make an appointment online',
    agencySelection: {
      title: "This valuation would not be complete without the expertise of an advisor!",
      subTitle: (
        <React.Fragment>
          Your advisor will confirm their visit within 24 hours to finalize your estimate accurately.
          <span> Free of charges with no obligations.</span>
        </React.Fragment>
      ),
      selectedAgency: (agency: Agency | null) => (
        <React.Fragment>
          <span>{agency?.address ? `Your Breteuil agency at ${agency?.address}` : null}</span>
          <span>{agency?.phone} &#8226; {agency?.email}</span>
        </React.Fragment>
      ),
      mobileSelectedAgency: (agency: Agency | null) => (
        <React.Fragment>
          <span>{agency?.address ? `Your Breteuil agency at ` : null}</span>
          <span>{agency?.address}</span>
        </React.Fragment>
      ),
      label: 'Property address',
    },
    timeslotSelection: {
      selectedDate: (date: Date) => datefns.format(date, 'eeee dd MMMM', { locale: DateFnsFrLocale }),
      tabsLabels: ['MORNING', 'AFTERNOON', 'EVENING'],
      confirm: 'Confirm',
      errorNoAddress: "Please enter the address of your property.",
      errorNoAgencyNearAddress: "No agency near your address, however, we still have registered your request.",
      errorNoMeetings: 'No appointment available at the chosen date.',
    },
    details: {
      summary: {
        title: 'Summary of your request',
        appointmentDate: (date: Date) =>  datefns.format(date, 'eeee dd MMMM HH:mm', { locale: DateFnsFrLocale }),
        appointmentDuration: '30 mn',
        appointmentInfo: "An agent will contact you to prepare the meeting.",
      },
      confirmationForm: {
        title: 'Details to confirm your appointment',
        labels: {
          name: 'Your name',
          surname: 'Your surname',
          email: 'Your email',
          phone: 'Your mobile phone',
          comment: 'Comments regarding your appointment',
        },
        cancel: 'Change the date',
        confirm: 'Confirm your request',
        successTitle: "Thank you for your trust ! ",
        successSubTitle: (
          <React.Fragment>
            Votre conseiller vous contactera
            <br/>
            sous 24h pour confirmer sa venue.
          </React.Fragment>
        ),
        errorMeetingAlreadyConfirmed: 'Your appointment is already confirmed.',
        errorMeetingDateAlreadyLocked: "The selected slot is no longer available",
        errorInvalidPhoneNumber: "Invalid phone number",
        errorInvalidMeetingDate: "Le créneau sélectionné n'est pas valide",
        errorNoMeetingDate: "Please select a meeting date.",
        successMessage: "Votre rendez-vous est confirmé avec succès",
      },
    },
  },
  cities: {
    paris: 'Paris',
    london: 'London',
    newYork: 'New-York',
    deauville: 'Deauville',
    dinard: 'Dinard',
    laBaule: 'La Baule',
    lisbon: 'Lisbon',
    biarritz: 'Biarritz',
    saintJeanDeLuz: 'Saint-Jean-De-Luz',
  },
  citySelectLabel: "Select the localisation of your property: ",
  errorToast: (e: Error) => errorTexts.EN.formatError(e),
};
