import type { Agency } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';

import { Language } from '@repo-breteuil/common-texts';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface closestAgencyArgs {
  address: string,
  language: Language,
}

export default async function closestAgency(
  qs: QueryStore,
  args: closestAgencyArgs,
): Promise<Agency>
{
  return qs.query({
    operationName: 'closestAgency',
    variables: {
      address: 'String!',
      language: 'Language',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            closestAgency: [{ args: { address: true } }, {
              id: true,
              name: true,
              slug: [{ args: { language: true } }, true],
              timezone: true,
              phone: true,
              email: true,
              latitude: true,
              longitude: true,
              address: true,
            }],
          },
        },
      },
    },
  }, args);
}
