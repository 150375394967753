import * as React from 'react';
import type { Currency } from '@repo-lib/utils-texts';
import {
  OperationType,
  PropertyType,
  MyBreteuilPropertySurroundingDistance,
  getDPERating,
} from '@repo-breteuil/common-definitions';
import { number as numberTexts } from '@repo-breteuil/common-texts';

export default {
  exclusive: 'Exclusivity agreement',
  buy: 'Buy',
  apartment: 'apartment',

  formatBedrooms(amount: number): string
  {
    return `${amount} room${amount > 1 ? 's' : ''}`;
  },
  formatBathrooms(amount: number): string
  {
    return `${amount} bathroom${amount > 1 ? 's' : ''} `;
  },
  formatSurface(amount: number): string
  {
    return `${amount} m2`;
  },
  formatDPE(
    args: {
      kwh: number | null,
      co2: number | null,
    },
  ): string
  {
    const text = "EPC rating: ";
    const { kwh, co2 } = args;
    if (!kwh || !co2)
      return text + "ongoing";
    const rating = getDPERating({ kwh, co2 });
    const details = [
      `${args.kwh}kWh`,
      `${args.co2}kg CO2`,
    ].join(' & ');
    return text + `${rating} (${details})`;
  },
  formatPrice(amount: number, currency: Currency): string
  {
    return `${numberTexts.EN.formatNumber(amount, { truncate: 'integer', currency })}`;
  },
  formatPriceSurface(amount: number, surface: number, currency: Currency): string
  {
    return `${numberTexts.EN.formatNumber(amount / surface, { truncate: 'integer', currency })} / m2`;
  },
  formatAgency(agencyName: string): string
  {
    return `Agency ${agencyName}`.toUpperCase();
  },
  formatAgencyPhone(phone: string): string
  {
    return `Tel. : ${phone}`;
  },
  formatAgencyEmail(email: string): string
  {
    return `E-mail : ${email}`;
  },
  formatCopro(lots: number): string
  {
    return `Co-ownership property: ${lots} apartments`;
  },
  formatCharges(charges: number, currency: Currency): string
  {
    return `Yearly Service charges: ${this.formatPrice(charges, currency)}`;
  },
  formatPropertyType(propertyType: PropertyType) {
    switch (propertyType) {
      case 'Apartment':
        return 'Appartement';
      case 'House':
        return 'Maison';
      case 'Land':
        return 'Terrain';
      case 'Loft':
        return 'Loft';
      default:
        return null;
    }
  },
  formatOperationType(operationType: OperationType) {
    switch (operationType) {
      case 'ResidencyTransaction':
        return 'à vendre';
      case 'Rental':
      case 'SeasonalRental':
        return 'à louer';
      default:
        return null;
    }
  },
  formatExclu(isExclusive: Boolean): string
  {
    return isExclusive ? 'EXCLUSIVE' : '';
  },
  linkMarketData: 'Notre outil pour suivre le marché dans ce quartier', // TRAD
  textMarketData: 'pour suivre le marché dans ce quartier', // TRAD
  textGeoRisk: 'Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : www.georisques.gouv.fr', // TRAD
  perMonth: ' / month',
  perWeek: ' / week',
  pdfMap: 'Floor plan (PDF)',
  recommendations: {
    title: 'You will also like...',
    around: 'In the same area',
    farAway: 'To evade yourself',
  },
  market: {
    title: 'Focus on the market',
  },
  propertiesNearby: 'In the same area',
  propertiesExplore: 'To evade yourself',
  ctaShare: 'Share',
  ctaMail: 'Email',
  ctaPhone: 'Phone',
  similarProperties: 'Similar Properties',
  ctaValuation : 'Make a valuation',
  formatMailSubject(ref: string): string
  {
    return `Request for property information : ${ref}`;
  },
  formatMailText(url: string): string
  {
    return `Greetings,%0A%0AI would like to schedule a visit : ${url}%0A%0APlease call me back at : `;
  },
  fullscreen: "Fullscreen",
  exitFullscreen: "Exit Fullscreen",
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string
  {
    const start = "From";
    const middle = "to";
    const middleEndOnly = "Up to";
    const fromWithUnit = min ? numberTexts.EN.formatNumber(min, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    const from = min ? numberTexts.EN.formatNumber(min, { separators: true, currency: currency, truncate: 'integer' }) : null;
    const toWithUnit = max ? numberTexts.EN.formatNumber(max, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    if (!min && !max)
      return '';
    else if (!max && min) {
      return `${start} ${fromWithUnit}`;
    }
    else if (!min && max) {
      return `${middleEndOnly} ${toWithUnit}`;
    }
    else {
      return `${from} ${middle} ${toWithUnit}`;
    }
  },
  location: "Location:",
  keyInfos: "Key information and surroundings:",
  keyInfosTitle: "Key information:",
  surroundingsTitle: "Surrounding:",
  formatDistance(distance: MyBreteuilPropertySurroundingDistance): string
  {
    const parsedDistance = distance.split('_')[1] || '';
    return `${parsedDistance}`;
  },
  formatGuests(guestsCount: number): string
  {
    return `${guestsCount} guest${guestsCount > 0 ? 's' : ''}`;
  },
  seasonalRentalDuration: "Rates for 7 nights*",
  defaultQuote: (
    <React.Fragment>
      This quote includes the occupancy of the villa and housekeeping service 6 times a week, we also offer the option of our Chef service, providing breakfast for the guests.
      <br/>
      <br/>
      To further refine this quote, our team of advisors is at your disposal.
    </React.Fragment>
  ),
  propertyAccessRequest: {
    title: "Request access",
    description: "This property is confidential and requires special authorization to access, please contact your referent to obtain access information.",
    button: "Access",
  },
  propertyAccessCodeInput: {
    title: "Exclusive property - confidential link",
    description: "This property is confidential and requires an SMS access code.",
    formatPhoneNumber: (phoneNumber: string) => `An access code has been sent to ${phoneNumber}`,
    invalidAccessCode: "Incorrect code. Please try again.",
  },
};
