import { QueryStore, errorWrapper } from '@repo-breteuil/front-store-query';

export default function OptIn(qs: QueryStore, contactCommunicationJWT: string): Promise<boolean>
{
  return qs.mutation({
    operationName: 'optInMybContact',
    variables: {
      contactCommunicationJWT: 'String!',
    },
    fieldsSelection: {
      public: {
        mybContact: {
          optIn: errorWrapper([{
            args: {
              contactCommunicationJWT: true,
            },
          }, true]),
        },
      },
    },
  }, { contactCommunicationJWT });
}
