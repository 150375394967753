export const editor = "M4A";
export const address = "26 rue Pasteur 78440 Fontenay Saint Père";
export const sarl = "SARL au capital de 2 309 000€";
export const rcs = "RCS  Versailles 520 768 482";
export const publicationDirector = "Arthur COLAROSSI";

export const mediator = {
  name: "Médiation - Vivons Mieux Ensemble",
  address: "465 avenue de la Libération 5400 Nancy",
  mail: "mediation@vivons-mieux-ensemble.fr",
};

export const websiteUrl = "www.breteuilhomes.com";
export const cnilCookiesUrl = "https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser";
export const email = "contact@breteuilhomes.com";

export const host = "OVH SAS";
export const hostAddress = "2 rue Kellermann - 59100 Roubaix - France";

const ALB = {
  name: 'ALB Immobilier',
  businessName: 'ALB IMMOBILIER',
  tradingName: 'Breteuil Immobilier',
  rcs: 'RCS Saint Malo 838 236 065',
  cardNumber: 'CPI 3503 2018 000 027 619',
  account: 'Banque SARL ALB immobilier',
  iban: 'FR7640978000221491412100194',
  code: 'BSPFFRPPXXX',
  siren: '838 236 065',
  tva: 'FR85838236065',
  sas: 'SARL ALB Immobilier au capital de 1000 €',
  pc: 'Carte professionnelle CPI 3503 2018 000 027 619',
  warranty: 'Garantie SO.CAF 26 avenue de Suffren 75015 Paris',
  salaryExcelDisplayedName: 'ALB Breteuil Immobilier',
};

const BBS = {
  name: 'Breteuil Bretagne Sud',
  businessName: 'BRETEUIL BRETAGNE SUD',
  tradingName: 'Breteuil Immobilier',
  rcs: 'RCS St Nazaire 889 452 280',
  cardNumber: 'CPI 4402 2021 000 000 011',
  account: 'Crédit Agricole Ille et Vilaine',
  iban: 'FR7613606001074632897052024',
  code: 'AGRIFRPP836',
  siren: '889 452 280',
  tva: 'FR51889452280',
  sas: 'Breteuil Bretagne Sud (SAS) au capital de 6 000 €',
  pc: 'Carte professionnelle CPI 4402 2021 000 000 011',
  warranty: 'Garantie Galian 89, rue de la Boétie 75008 Paris',
  salaryExcelDisplayedName: 'Breteuil Bretagne Sud',
};

const BreteuilIDF = {
  name: 'Breteuil Île-de-France',
  businessName: 'BRETEUIL ÎLE-DE-FRANCE',
  tradingName: 'Breteuil Immobilier',
  rcs: 'RCS Paris B 419 692 389',
  cardNumber: 'CPI 7501 2016 000 013 289',
  account: 'Banque Palatine',
  iban: 'FR7640978000221222522300176',
  code: 'BSPFFRPPXXX',
  siren: '419 692 389',
  tva: 'FR10419692389',
  sas: 'SAS Breteuil Ile de France au capital de 231 569,99€',
  pc: 'Carte professionnelle CPI 7501 2016 000 013 289',
  warranty: 'Garantie Galian 89, rue de la Boétie 75008 Paris',
  salaryExcelDisplayedName: 'SAS Breteuil Ile de France',
};

const BreteuilSudOuest = {
  name: 'Breteuil Sud Ouest',
  businessName: 'BRETEUIL SUD OUEST',
  tradingName: 'Breteuil Immobilier',
  rcs: 'RCS Bayonne B 904 789 856',
  cardNumber: 'CPI 7501 2016 000 013 289',
  account: 'Crédit Agricole Ille et Vilaine',
  iban: 'FR7613606001074633244091834',
  code: 'AGRIFRPP836',
  siren: '904 789 856',
  tva: 'FR53904789856',
  sas: 'SAS Breteuil Sud Ouest au capital de 6 000 €',
  pc: 'Carte professionnelle CPI 6401 2022 000 000 006',
  warranty: 'Garantie Galian 89, rue de la Boétie 75008 Paris',
  salaryExcelDisplayedName: 'Breteuil Sud Ouest',
};

const BreteuilCF = {
  name: 'Breteuil Cote Fleurie',
  businessName: 'BRETEUIL COTE FLEURIE',
  tradingName: 'Breteuil Cote Fleurie',
  rcs: '499 967 149 R.C.S. Lisieux',
  cardNumber: 'CPI 7501 2017 00 021 720',
  account: 'Banque SARL breteuil Côte Fleurie',
  iban: 'FR76409780002212225000800126',
  code: '40978',
  siren: '499 967 149',
  tva: 'FR62499967149',
  sas: 'BRETEUIL COTE FLEURIE, SARL au capital de 8 000€',
  pc: 'Carte professionnelle CPI 7501 2017 00 021 720',
  warranty: 'Liberty Mutual Insurance Europe, 42 rue de Washington 75008 Paris',
  salaryExcelDisplayedName: 'Breteuil Cote Fleurie',
};

export const companies = [BreteuilIDF, BBS, BreteuilCF, BreteuilSudOuest, ALB];
