import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Stack } from '@mui/material';
import {
  KingBed as KingBedIcon,
  Bathtub as BathtubIcon,
  Groups as GroupsIcon,
  Straighten as StraightenIcon,
} from '@mui/icons-material';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import texts from '../texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const FlexContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '40px 0',
  '@media (max-width: 1250px)': {
    margin: 0,
  },
});

const DetailWithLogo = observer((props: { label: string, icon: React.ReactNode }) => {
  const { label, icon } = props;
  return (
    <Stack direction='row' alignItems='center' gap={2}>
      {icon}
      {label}
    </Stack>
  );
});

const PropertyDetailsWithLogo = observer((props: { property: Property }) => {
  const { property } = props;
  const isDesktop = useMediaQueryDesktop();
  const T = useTexts(texts);
  return (
    <FlexContainer>
      {property.bedrooms ? (
        <DetailWithLogo
          label={isDesktop ? T.formatBedrooms(property.bedrooms) : `${property.bedrooms}`}
          icon={<KingBedIcon/>}
        />
      ) : null}
      {property.bathrooms ? (
        <DetailWithLogo
          label={isDesktop ? T.formatBathrooms(property.bathrooms) : `${property.bathrooms}`}
          icon={<BathtubIcon/>}
        />
      ) : null}
      {property.guestsCount ? (
        <DetailWithLogo
          label={isDesktop ? T.formatGuests(property.guestsCount) : `${property.guestsCount}`}
          icon={<GroupsIcon/>}
        />
      ) : null}
      {property.surface ? (
        <DetailWithLogo
          label={T.formatSurface(property.surface)}
          icon={<StraightenIcon/>}
        />
      ) : null}
    </FlexContainer>
  );
});

export default PropertyDetailsWithLogo;
