import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';

import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';
import { QueryStore } from '@repo-breteuil/front-store-query';

export async function Register(
  qs: QueryStore,
  args: {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    mobilePhone?: string,
  },
): Promise<ClientAreaSession>
{
  return qs.mutation({
    operationName: 'Register',
    variables: {
      firstname: 'String!',
      lastname: 'String!',
      mobilePhone: 'String',
      email: 'String!',
      password: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            register: [{
              args: {
                firstname: true,
                lastname: true,
                mobilePhone: true,
                email: true,
                password: true,
              },
            }, (
              clientAreaSessionResult()
            )],
          },
        },
      },
    },
  }, args);
}
