import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
} from '@lib/routing';

import { setTranslatedPage } from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

  // TODO make this a query
  const citiesMap = new Map<string/*area name*/, string/*préposition*/>([
    ['Paris', 'paris'],
    ['Londres', 'london'],
    ['London', 'london'],
    ['New York', 'newYork'],
    ['Deauville', 'deauville'],
    ['Dinard', 'dinard'],
    ['La Baule', 'laBaule'],
    ['Lisbonne', 'lisbon'],
    ['Lisbon', 'lisbon'],
    ['Biarritz', 'biarritz'],
    ['St Jean', 'saintJeanDeLuz'],
  ]);

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  setTranslatedPage(
    Public,
    RootPages.Valuation,
    Routes.valuation.routesByLanguage,
    async (location, info) => {
      const { searchParams } = location;
      const city = searchParams.get('city') ?? null;
      const address = searchParams.get('address') ?? null;
      const comId = searchParams.get('comId') || undefined;

      await websiteStores.agencies.agencies.ensureSuccess();
      await websiteStores.valuation.init();
      city === null ? null : websiteStores.valuation.setSelectedCityDefaultValue(citiesMap.get(city) ?? 'paris');
      websiteStores.valuation.setSelectedAddress(address || null);
      websiteStores.valuation.setComId(comId);
      return null;
    });
}

