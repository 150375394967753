import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { Button } from '@breteuil-website/components/common';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from '../texts';
import BannerForm from './BannerForm';

const Container = styled('div')(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  height: isMobile ? 'calc(100vh - 45px)' : 'calc(100vh - 90px)',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  margin: 'auto',
}));

const ContentContainer = styled('div')(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: 'auto',
  ...(isMobile ? {
    marginTop: '32vh',
  } : {
    width: '60%',
    '@media (max-width: 1700px)': {
      width: '70%',
    },
    '@media (max-width: 1200px)': {
      width: '85%',
    },
  }),
}));

const Title = styled('div')(({ isMobile }: { isMobile?: boolean }) => ({
  display: 'flex',
  fontFamily: 'Poppins',
  fontWeight: 700,
  color: '#050251',
  flexDirection: 'column',
  textAlign: 'center',
  ...(isMobile ? {
    padding: '0px 41px',
    marginBottom: '20px',
    fontSize: '28px',
    lineHeight: '40px',
  } : {
    fontSize: '34px',
    lineHeight: '60px',
  }),
}));

const VideoWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: -100,
});

const Video = styled('video')({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const ButtonMobileContainer = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledLink = styled(Link)({
  width: '48% !important',
  height: '50px !important',
  fontSize: '12px !important',
  margin: '20px auto',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
});

const CTAButton = styled(Button)({
  width: '100% !important',
  height: '50px !important',
  fontSize: '12px !important',
  lineHeight: 'normal !important',
  backgroundColor: '#050251 !important',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
});

const Banner = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();

  return(
    <Container isMobile={isMobile}>
      <ContentContainer isMobile={isMobile}>
        <Title isMobile={isMobile}>
          {T.title}
        </Title>
        {isMobile ? (
          <ButtonMobileContainer>
            <StyledLink to={Routes.buy.generateStaticPath({ lang })}>
              <CTAButton>{T.operationType.transaction}</CTAButton>
            </StyledLink>
            <StyledLink to={Routes.rent.generateStaticPath({ lang })}>
              <CTAButton>{T.operationType.rental}</CTAButton>
            </StyledLink>
            <StyledLink to={Routes.seasonalRent.generateStaticPath({ lang })}>
              <CTAButton>{T.operationType.seasonalRental}</CTAButton>
            </StyledLink>
          </ButtonMobileContainer>
        ) : (
          <BannerForm/>
        )}
      </ContentContainer>
      <VideoWrapper>
        <Video muted loop autoPlay playsInline>
          <source src={isMobile ? '/assets/scuffed_video.mp4' : '/assets/scuffed_video.mp4'}/>
        </Video>
      </VideoWrapper>
    </Container>
  );
});

export default Banner;
