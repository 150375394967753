import { QueryStore } from '@repo-breteuil/front-store-query';

export interface MeetingSlot {
    date: number/*timestamp*/,
    locked: boolean,
}

export default async function PrepareSchedule(qs: QueryStore): Promise<Array<MeetingSlot>> {
    return qs.query({
        operationName: 'PrepareSchedule',
        fieldsSelection: {
            public: {
                breteuilWebsite: {
                    valuation: {
                        agencyMeeting: {
                            defaultSchedule: {
                                    date: true,
                                    locked: true,
                                },
                        },
                    },
                },
            },
        },
    });
};
