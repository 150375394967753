import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  text: string,
  resendEmail: string,
  email: string,
  emailSent: string,
  errorNotif: string,
  goToProfile: string,
  successText: string,
  thankYou(name: string): string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
