import * as React from 'react';
import { Helmet } from "react-helmet";

interface PageMeta {
  title: string,
  description: string,
}

const PageMeta = (props: PageMeta) => {
  const { title, description } = props;
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={description}/>
        <title>{title}</title>
      </Helmet>
    </React.Fragment>
  );
};

export default PageMeta;
