import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
} from '@lib/routing';

import { setTranslatedStaticPage } from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  setTranslatedStaticPage(
    Public,
    RootPages.Contact,
    Routes.contact.routesByLanguage,
    async (location, info) => {
      await websiteStores.agencies.agencies.ensureSuccess();
      return null;
    });
}

