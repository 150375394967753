import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  pageTitle: string,
  pageDescription: string,
  titles: {
    france: string,
    uk: string,
  },
  links: {
    inHouseComplaints: string,
    clientMoneyProtection: string,
    fees: string,
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
