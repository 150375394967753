export const contactRh = {
  phone: "+33 (0)1 45 55 10 09",
  mail: "rh@breteuilhomes.com",
};

export const contactManagment = {
  phone: "+33 (0)6 70 57 44 52",
  mail: "management@breteuilhomes.com",
};

export const defaultContactEmail = 'contact@breteuilhomes.com';
