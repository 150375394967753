import * as React from 'react';

export default {
  tabs: {
    profile: 'Mon profil',
    searches(): JSX.Element{
      return(
        <React.Fragment>Recherches<br/>et alertes</React.Fragment>
      );
    },
    favorites: 'Favoris',
    logout: 'Déconnexion',
    overview: "Vue d'ensemble",
    walletAndMarket: 'Portefeuille',
  },
  returnToSite: 'Retour site',
  clientArea: 'Espace client',
  logOut: 'Se déconnecter',
};
