import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
  RoutingInfo,
  RouteHandler,
  RouteHandlerReturnType,
  PageRedirect,
  StaticRouteBinding,
  StaticRouteBindingArgs,
  DynamicRouteBinding,
  DynamicRouteBindingArgs,
} from '@lib/routing';

import type {
  RouteInterface,
  ResolvedRouteLocation,
  StaticRoute,
} from '@repo-lib/routing-routes';
import { isPromise, mapRecord } from '@repo-lib/utils-core';

export type LanguageRouteHandlerBase<ResultType, Language extends keyof any> = (
  location: ResolvedRouteLocation,
  info: RoutingInfo,
  language: Language,
) => ResultType;
export type LanguageRouteHandler<PageType, Language extends keyof any> = LanguageRouteHandlerBase<RouteHandlerReturnType<PageRedirect<PageType>>, Language>;

export function makeTranslatedPageSetters<Language extends keyof any>(
  args: {
    setLanguage: (language: Language) => void,
  },
)
{
  const { setLanguage } = args;

  function genTranslatedPageHandler<PageType>(
    language: Language,
    handler?: LanguageRouteHandler<PageType, Language>,
  ): RouteHandler<PageRedirect<PageType>>
  {
    return (location, info) => {
      if (!handler)
      {
        setLanguage(language);
        return null;
      }
      const res = handler(location, info, language);
      if (isPromise(res))
        return res.then(handler => {
          setLanguage(language);
          if (!handler)
            return null;
          return handler;
        });
      setLanguage(language);
      return res;
    };
  }

  function setTranslatedPage<PageType>(
    scope: PageRouteHandlersScope<PageType>,
    page: PageType,
    routes: Record<Language, RouteInterface>,
    handler?: LanguageRouteHandler<PageType, Language>,
  ): Record<Language, DynamicRouteBinding<PageType>>
  {
    return mapRecord(routes, (route, language) => {
      const binding = scope.setPage(page, route, genTranslatedPageHandler(language, handler));
      return binding;
    });
  }

  function setTranslatedStaticPage<PageType>(
    scope: PageRouteHandlersScope<PageType>,
    page: PageType,
    routes: Record<Language, StaticRoute>,
    handler?: LanguageRouteHandler<PageType, Language>,
  ): Record<Language, StaticRouteBinding<PageType>>
  {
    return mapRecord(routes, (route, language) => {
      const binding = scope.setStaticPage(page, route, genTranslatedPageHandler(language, handler));
      return binding;
    });
  }

  return {
    setTranslatedPage,
    setTranslatedStaticPage,
  };
}

//Note: because of a typescript bug (4.7), DynamicRouteBinding is assignable to StaticRouteBinding
//Because of this, the 1st signature of this function will be valid for DynamicRouteBindings.
//This removes `routeParams` arg's requirement, which can lead to invalid usages of this function.
export function redirectToTranslatedPage<PageType, Language extends keyof any>(
  handlers: PageRouteHandlers<PageType>,
  args: {
    to: Record<Language, StaticRouteBinding<PageType>>,
    language: Language,
    location: ResolvedRouteLocation,
    info: RoutingInfo,
  } & StaticRouteBindingArgs,
): ReturnType<RouteHandler<PageRedirect<PageType>>>
export function redirectToTranslatedPage<PageType, Language extends keyof any>(
  handlers: PageRouteHandlers<PageType>,
  args: {
    to: Record<Language, DynamicRouteBinding<PageType>>,
    language: Language,
    location: ResolvedRouteLocation,
    info: RoutingInfo,
  } & DynamicRouteBindingArgs,
): ReturnType<RouteHandler<PageRedirect<PageType>>>
export function redirectToTranslatedPage<PageType, Language extends keyof any>(
  handlers: PageRouteHandlers<PageType>,
  args: {
    to: Record<Language, DynamicRouteBinding<PageType>>,
    language: Language,
    location: ResolvedRouteLocation,
    info: RoutingInfo,
  } & DynamicRouteBindingArgs,
): ReturnType<RouteHandler<PageRedirect<PageType>>>
{
  const { to, language, location, info, ...redirectArgs } = args;
  const redirect = to[language];
  return handlers.redirect(redirect(redirectArgs), location, info);
}
