import type { IdType } from '@repo-breteuil/common-definitions';

import { QueryStore } from '@repo-breteuil/front-store-query';

export async function DeleteSearch(qs: QueryStore, id: IdType): Promise<boolean>
{
  return qs.mutation({
    operationName: 'DeleteSearch',
    variables: {
     id: 'Int!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            search: {
              delete: [{
                args: { id: true },
              }, true ],
            },
          },
        },
      },
    },
  }, { id });
}

