import { styled } from '@mui/material';
import { TextField as GenericTextField } from '@breteuil-website/components/common/elements';

export const TextField = styled(GenericTextField)({
  '& .MuiInputBase-root': {
    color: '#050251',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Mulish',
    fontSize: '15px',
    fontWeight: 700,
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
});
