import type { createFormFieldSlider } from '@repo-lib/mobx-forms';
import type { Currency } from '@repo-lib/utils-texts';

import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useTexts, number as numberTexts } from '@repo-breteuil/common-texts';
import { Slider } from '@breteuil-website/components/common';
import {
  getOperationTypePriceFilterValues,
} from '@breteuil-website/store/ui/pages/properties-search';
import texts from './texts';

const StyledSlider = styled(Slider)({
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'transparent !important',
  },
});

function getStep(
  value: number,
  step: number,
  breakpoints?: Array<[ number, number ]>,
)
{
  if (breakpoints)
    for (const [ max, step ] of breakpoints)
      if (value < max)
        return step;
  return step;
}

function formatInputFn(
  value: number,
  step: number,
  breakpoints?: Array<[ number, number ]>,
)
{
  if (step === 1)
    return value;
  step = getStep(value, step, breakpoints);
  return value - value % step;
}

/*
 * Handles the custom steps
 */
export function makeBudgetSliderFormatInputValueFn(
  args: {
    getOperationType: () => OperationType,
  },
)
{
  const { getOperationType } = args;
  return (value: number) => {
    const operationType = getOperationType();
    switch (operationType)
    {
      case OperationType.Rental:
        return formatInputFn(value, 100);
      case OperationType.SeasonalRental:
        return formatInputFn(value, 1000);
      case OperationType.ResidencyTransaction:
        return formatInputFn(value, 1);
      default:
        return formatInputFn(value, 50_000);
    }
  };
}
const scalingBreakpoints = [
  0,          // 0%
  500_000,    // 20%
  1_000_000,  // 40%
  3_000_000,  // 60%
  5_000_000,  // 80%
  15_000_000, // 100%
];

function getPreviousMarkIndex(value) {
  for (let i = 0; i < scalingBreakpoints.length; i++)
  {
    if (value < scalingBreakpoints[i])
      return i - 1;
  }
  return 4; // Highest possible value that need scaling
}

export const scaleDown = (value) => {
  if (value >= 15_000_000)
    return 100;
  const previousMarkIndex = getPreviousMarkIndex(value);
  const percentile = previousMarkIndex * 20;
  const previousMark = scalingBreakpoints[previousMarkIndex];
  const nextMark = scalingBreakpoints[previousMarkIndex + 1];
  const remainder = value - previousMark;
  if (remainder === 0)
    return percentile;
  const increment = (nextMark - previousMark) / 20;
  return percentile + (remainder / increment);
};

export const scale = (value) => {
  const previousMarkIndex = Math.floor(value / 20);
  const previousMark = scalingBreakpoints[previousMarkIndex];
  const remainder = value % 20;
  if (remainder === 0)
    return previousMark;
  const nextMark = scalingBreakpoints[previousMarkIndex + 1];
  const increment = (nextMark - previousMark) / 20;
  return remainder * increment + previousMark;
};
export const BudgetSlider = observer((
  props: {
    field: ReturnType<typeof createFormFieldSlider<number>>,
    currency: Currency,
    operationType: OperationType,
    showLabels?: boolean,
    className?: string,
  },
) => {
  const T = useTexts(texts);
  const currentTextsNumber = useTexts(numberTexts.texts);
  const {
    field,
    currency,
    operationType,
    showLabels,
    className,
  } = props;

  const priceSliderValueProps = React.useMemo(() => {
    const { min, max } = getOperationTypePriceFilterValues(operationType);
    const {
      step,
      formatNumberOpts,
    } = (operationType === OperationType.Rental) ? {
      step: 100,
      formatNumberOpts: {
        truncate: 2, //Hundreds
        period: 'month' as const,
      },
    } :(operationType === OperationType.SeasonalRental) ? {
      step: 100,
      formatNumberOpts: {
        truncate: 2, //Hundreds
        period: 'week' as const,
      },
    } : (operationType === OperationType.ResidencyTransaction) ? {
      step: 1,
      formatNumberOpts: {
        truncate: 3, //Thousands
      },
    } : {
      step: 50000,
      formatNumberOpts: {
        truncate: 2, //Hundreds
      },
    };
    return {
      min,
      max,
      step,
      scale : operationType === OperationType.ResidencyTransaction ? scale : (value) => value,
      ...(showLabels ? {
        valueLabelDisplay: 'on' as const,
        valueLabelFormat: (value) => {
          const trueMax = operationType === OperationType.ResidencyTransaction ? scale(max):  max;
          if (value >= trueMax)
            return T.noLimit;
          return (
            currentTextsNumber.formatNumber(value, {
              ...formatNumberOpts,
              separators: true,
              currency,
            })
          );
        },
      } : {
        valueLabelDisplay: 'off' as const,
      }),
    };
  }, [ currency, operationType, showLabels, T ]);

  return (
    <StyledSlider
      track="normal"
      size="small"
      {...priceSliderValueProps}
      field={field}
      {...omitUndefinedValues({ className })}
    />
  );
});
