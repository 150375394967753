import { OperationType } from '@repo-breteuil/common-definitions';


export default {
  formatPageTitle(operationType: OperationType): string
  {
    const title = (operationType === 'Rental' || operationType === 'SeasonalRental') ?
     "High-end and prestigious property rentals - Breteuil Remarkable Homes" :
     "High-end and prestigious real estate purchase - Breteuil Remarkable Homes" ;
    return title;
  },
  formatPageDescription(operationType: OperationType): string
  {
    const title = (operationType === 'Rental' || operationType === 'SeasonalRental') ?
     "Discover our exclusive selection of high-end properties available for rent with Breteuil Remarkable Homes" :
     "Breteuil Remarkable Homes assists you in your high-end real estate purchase project. Discover our exclusive selection of exceptional properties for sale." ;
    return title;
  },
  labelTransaction: 'Transaction',
  labelBedrooms: 'Number of bedrooms',
  priceLabel: 'Price',
  area: 'Area',
  subArea: 'Subarea',
  everywhere: 'Everywhere',
  district : 'District',
  city: 'City',
  labelZone: 'Area',
  labelOperationType: 'Transaction',
  filterLabel: 'Filter',
  submit: 'Search',
  operationType: {
    transaction: 'Buy',
    rental: 'Rent',
    seasonalRental: 'Seasons',
  },
  errorLoadMore: 'An unexpected event happened during the loading. Try again later.',
  results: 'Results',
  formatMinBedroomsText(n: number) {
    return `${n}+`;
  },
  formatPageTitleLabel(
    operationType: OperationType,
    area: {
      name: string,
    } | null,
  ): string
  {
    const operation = new Map([
      [OperationType.Rental, "Rental"],
      [OperationType.SeasonalRental, "Seasonal Rental"],
      [OperationType.ResidencyTransaction, "Buy"],
    ]);
    const title = operation.get(operationType) || "";
    if (area === null)
      return title;
    return `${title} in ${area.name}`;
  },
  printResults(nbResults: number | null)
  {
    let text = '';
    if (nbResults !== null)
      text += ` (${nbResults})`;
    return text;
  },
  alertFilterArea: 'An area is mandatory to create an alert',
  formatBudgetFilterPeriod(operationType: OperationType): string
  {
    const period = new Map([
      [OperationType.Rental, "/ month"],
      [OperationType.SeasonalRental, "/ weeks"],
    ]);
    return period.get(operationType) || "";
  },
};
