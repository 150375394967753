import type { OperationType } from '@repo-breteuil/common-definitions';

import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  formatPageTitle(operationType: OperationType): string,
  formatPageDescription(operationType: OperationType): string,
  labelZone: string,
  labelOperationType: string,
  labelBedrooms: string,
  priceLabel: string,
  area: string,
  subArea: string,
  everywhere: string,
  filterLabel: string,
  submit: string,
  operationType: {
    transaction: string,
    rental: string,
    seasonalRental: string,
  },
  errorLoadMore: string,
  results: string,
  formatMinBedroomsText(n: number) : string,
  formatPageTitleLabel(
    operationType: OperationType,
    area: {
      name: string,
      FRNamePrepositionDeLieu: string | null,
    } | null,
  ): string,
  printResults(nbResults: number | null) : string,
  alertFilterArea: string,
  formatBudgetFilterPeriod(operationType: OperationType): string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
