import { autorun } from 'mobx';
import {
  PageRouter,
} from '@lib/routing';
import { handleCriticalError, handleNonCriticalError } from '@repo-breteuil/front-error';
import router from '@core/store/routing/router';
import { RootPages } from '@breteuil-website/store/routing/pages';
import setHandlers from '@breteuil-website/store/routing/handlers';
import routingStore from '@breteuil-website/store/routing/RoutingStore';
import { websiteStores } from '../ui/BreteuilWebsiteStores';

class RoutingLogic extends PageRouter<RootPages>
{
  constructor()
  {
    super(router, routingStore, {
      onRouteNotFound: () => ({ page: RootPages.NotFound, route: null }),
      onRouteChangeError: (error) => {
        handleCriticalError(error);
        return null;
      },
      onRouteChangeDroppedError: handleNonCriticalError,
    });
    setHandlers(this.handlers);
  }

  init()
  {
    this.updateFromCurrentLocation();
    autorun(() => {
      if (this.loadError)
        websiteStores.globalMessage.displayError(this.loadError as any);
    });
  }
}

export default new RoutingLogic();
