import type { Currency, FormatNumberOpts } from '@repo-lib/utils-texts';
import { OperationType } from '@repo-breteuil/common-definitions';

import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export function formatPrice(
  value: number,
  args: {
    currency: Currency,
    operationType: OperationType,
    formatNumber: (n: number, opts?: FormatNumberOpts) => string,
  },
): string
{
  const { currency, operationType } = args;
  return args.formatNumber(value, {
    currency,
    separators: true,
    ...(operationType === OperationType.Rental || operationType === OperationType.SeasonalRental) ? {
      truncate: 2, //Hundreds
      period: 'month' as const,
    } : (operationType === OperationType.ResidencyTransaction) ? {
      truncate: 3, //Thousands
    } : {
      truncate: 2, //Hundreds
    },
  });
}

export interface Definition
{
  filter: {
    price: {
      formatPrice(
        amount: number,
        args: {
          maxAmount?: number,
          currency: Currency,
          operationType: OperationType,
        },
      ): string,
      formatRange(
        amount: [ number, number ],
        args: {
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element,
      formatMin(
        amount: number,
        args: {
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element,
      formatMax(
        amount: number,
        args: {
          maxAmount?: number,
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element,
      description: string,
      apply: string,
    },
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
