import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  adjustBtn: {
    title: string,
    description: string,
  },

  newsletter: {
    title: string,
    description: string,
  },

  unsubscribeBtn: {
    title: string,
    description: string,
  },

  update: string,

  unsubscribe: string,

  error: string,

  confirm: {
    title: string,
    subtitle: string,
    caption: string,
  },

  confirmUpdate: {
    title: string,
    subtitle: string,
    caption: string,
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
