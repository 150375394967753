import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import textsSignup from '@breteuil-website/components/pages/client-area/sign-up/texts';
import { SendLogInCode } from '@breteuil-website/store/ui/pages/client-area/log-in/api';
import Page from '@breteuil-website/components/common/app/Page';
import LogInForm from '@breteuil-website//components/pages/client-area/login/LogInForm';
import ClientAreaDescription from '@breteuil-website//components/pages/client-area/login/ClientAreaDescription';
import PasswordResetForm from '@breteuil-website//components/pages/client-area/login/PasswordResetForm';
import LogInWithEmail from '@breteuil-website/components/pages/client-area/login/LogInWithEmail';
import LogInWithCode from './LogInWithCode';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  fontFamily: 'Mulish',
  color: '#757575',
  fontSize: '15px',
});

const Title = styled('div')({
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '50px',
  fontWeight: 700,
  margin: '47px auto 15px auto',
});

const SubTitle = styled('div')({
  color: '#757575',
  fontSize: '15px',
  marginBottom: '15px',
});

const LoginSection = styled('div')({
  maxWidth: '500px',
  width: '90%',
  margin: '0px auto 50px auto',
});

const StyledButton = styled('button')({
  fontSize: '15px',
  color: '#0C17E1',
  fontWeight: 600,
  fontFamily: 'Mulish',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
});

const Separator = styled('div')({
  background: 'linear-gradient(to left, #FFF, #989898 20%, #989898 80%, #FFF)',
  margin: '30px auto',
  height: '1px',
});

const Login = styled('span')({
  marginRight: '5px',
});

const LogIn = observer(() => {
  const T = useTexts(texts);
  const T_SignUp = useTexts(textsSignup);
  const [showResetPasswordForm, setShowResetPasswordForm] = React.useState(false);
  const [openLoginWithCode, setOpenLoginWithCode] = React.useState(false);
  const { logIn, query, globalMessage } = useWebsiteStores();

  const handleSendLoginCode = () => {
    setOpenLoginWithCode(true);
    const promise = SendLogInCode(query, { email: logIn.userEmail! });
    globalMessage.handlePromise(promise, {
      successMessage: T.codeSent,
      errorMessage: null,
    });
  };

  const handleResetPassword = () => {
    setShowResetPasswordForm(true);
  };

  const handleLogIn = () => {
    setShowResetPasswordForm(false);
  };

  return(
    <Page>
      <Container>
        <Title>
          {T.title}
        </Title>
        {!showResetPasswordForm && (
          <SubTitle>
            {T.logIn}
          </SubTitle>
        )}
        <LoginSection>
          {showResetPasswordForm ? (
            <PasswordResetForm email={logIn.userEmail}/>
          ) : !logIn.isEmailUsedForClientArea.lastResult ? (
            <LogInWithEmail/>
          ) : (
            <LogInForm/>
          )}
          <Separator/>
          {logIn.isEmailUsedForClientArea.lastResult && (
            <div>
              <StyledButton onClick={handleSendLoginCode}>
                {T.loginWithCode}
              </StyledButton>
              <LogInWithCode
                open={openLoginWithCode}
                handleClose={() => setOpenLoginWithCode(false)}
              />
            </div>
          )}
          {!showResetPasswordForm ? (
            <div>
              <StyledButton onClick={handleResetPassword}>
                {T.forgotPassword}
              </StyledButton>
            </div>
          ) : (
            <div>
              <Login>{T_SignUp.alreadyHaveAccount}</Login>
              <StyledButton onClick={handleLogIn}>
                {T.logInWithoutColon}
              </StyledButton>
            </div>
          )}
        </LoginSection>
        <ClientAreaDescription/>
      </Container>
    </Page>
  );
});

export default LogIn;
