export default {
  title: 'Votre espace client',
  logIn: 'Connectez-vous : ',
  logInWithoutColon: 'Connectez-vous !',
  email: 'E-mail',
  password: 'Mot de passe',
  forgotPassword: 'Mot de passe oublié ?',
  resetPassword: 'Réinitialiser votre mot de passe',
  noAccount: 'Pas encore de compte ?',
  createAccount: 'Créez le maintenant !',
  passwordResetEmailSent: 'Un email de réinitialisation de mot de passe a été envoyé, veuillez consulter votre boite mail.',
  accountNotVerified: "Votre compte n'est pas activé. Activez le via le mail que vous avez reçu lors de votre inscription, ou entrez votre adresse mail ci-dessous pour recevoir un nouveau lien d'activation.",
  sendActivationLink: 'Recevoir un lien',
  invalidCredentials: 'Identifiants invalides, veuillez réessayer.',
  send: 'Connexion',
  clientArea: 'Un espace client.',
  endlessPossibilities: 'Des possibilités infinies.',
  comfort: 'Toujours plus de confort pour nos clients privilégiés.',
  propertyAccess: 'Accès aux propriétés en avant-première',
  adjustments: 'Ajustements de vos recherches en temps réel',
  reports: 'Rapports de marché détaillés',
  monitoring: 'Monitoring de votre portefeuille foncier',
  createArea: 'Créer mon espace',
  loginWithCode: 'Connectez vous avec un code',
  successfullCodeResent: 'Un nouveau code a été envoyé.',
  sendNewCode: 'Renvoyer un nouveau code',
  codeSent: 'Un code de connexion vous a été envoyé',
  invalidCodeCredentials: "Code de connexion invalide ou expiré, veuillez réessayer.",
  codeDescription(email: string): string
  {
    return `Un code de connexion vous à été envoyé par email (${email})`;
  },
};
