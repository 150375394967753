import type { EmptyInterface } from '@repo-lib/utils-core';

import * as React from 'react';
import { observer } from 'mobx-react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { memoize } from '@repo-lib/utils-core';
import {
  type Locale,
  Language,
  isValidLocale,
  useTexts,
  getBrowserLanguage,
} from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import {
  GlobalMessageSnackbar,
  GlobalLoadingProgress,
} from '@repo-breteuil/react-components';
import CookiePopup from '@breteuil-website/components/common/cookies/CookiePopup';
import { PageMeta } from '@breteuil-website/components/common';
import texts from './texts';
import { StoresProvider } from './providers';
import theme from './theme';
import { HrefLangTags } from './HrefLangTags';
import { Router } from './Router';

const AppContent = observer(() => {
  const T = useTexts(texts);
  const stores = useWebsiteStores();
  return (
    <>
      <GlobalLoadingProgress store={stores.globalLoading}/>
      <GlobalMessageSnackbar store={stores.globalMessage}/>
      <CookiePopup/>
      <CssBaseline/>
      <PageMeta
        title={T.defaultPageTitle}
        description={T.defaultPageDescription}
      />
      <HrefLangTags/>
      <Router/>
    </>
  );
});

const getDefaultLocale = memoize((): Locale => {
  const locale = getBrowserLanguage();
  if (locale === null || !isValidLocale(locale))
    return Language.French;
  return locale;
});

@observer
export default
class App extends React.Component<EmptyInterface, EmptyInterface>
{
  render()
  {
    const defaultLocale = getDefaultLocale();
    const env = {
      gitVersion: __GIT_VERSION__,
      gitCommitHash: __GIT_COMMITHASH__,
      gitBranch: __GIT_BRANCH__,
      devBuild: __DEV_BUILD__,
      googleMapsAPIKey: __GOOGLE_MAPS_API_KEY__,
    };
    return (
      <ThemeProvider theme={theme}>
        <StoresProvider lang={defaultLocale} env={env}>
          <AppContent/>
        </StoresProvider>
      </ThemeProvider>
    );
  }
};
