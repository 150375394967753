import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import router, { Routes } from '@breteuil-website/store/routing';
import { useTexts } from '@repo-breteuil/common-texts';
import { CodeInput, PageMeta } from '@breteuil-website/components/common';
import { PropertyAccessCodeInputState } from '@breteuil-website/store/ui/pages/property';
import { useLocale } from '@repo-breteuil/common-texts';
import BreteuilWebsitePage from '@breteuil-website/components/common/app/Page';
import texts from './texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: "Mulish",
  maxWidth: theme.breakpoints.values.lg,
  margin: 'auto',
  padding: '4vh 2vw',
  height: 'calc(100vh - 90px)',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '2vh 4vw',
    height: 'calc(100vh - 50px)',
    width: '100%',
    margin: "auto",
  },
}));

const InfoBox = styled('div')({
  backgroundColor: '#F5F8FF',
  padding: '30px',
  borderRadius: '8px',
  marginBottom: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
});

const Description = styled('div')(({ theme }) => ({
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "30px",
  color: theme.palette.secondary.main,
  marginBottom: '30px',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginBottom: '20px',
}));

const PhoneNumber = styled('div')(({ theme }) => ({
  fontSize: "17px",
  fontWeight: 600,
  color: theme.palette.primary.dark,
  marginBottom: '30px',
}));

interface PropertyAccessCodeInputProps
{
  state: PropertyAccessCodeInputState,
}

const PropertyAccessCodeInput = observer((props: PropertyAccessCodeInputProps) => {
  const { state } = props;
  const { slug, accessToken, phoneNumberHint, invalidAccessCode } = state;
  const language = useLocale();
  const T = useTexts(texts);
  const [code, setCode] = React.useState('');
  const { property } = useWebsiteStores();

  const onCodeSubmit = (accessCode: string) => {
    const { contactUserId } = property;
    property.queryState({ slug, language, contactUserId, accessToken, accessCode }).then((state) => {
      if (!state) //TODO
        throw new Error("Property isn't visible anymore");
      property.setState(state);
      const route = ('property' in state) ? (
        Routes.propertyShort.generatePath({ propertySlug: state.property.slug }, { lang: language })
      ) : null;
      router.changeRouteBypassHandler({ route, scrollTop: 0 }, { replace: true });
    });
  };

  React.useEffect(() => {
    if (code.length === 4)
      onCodeSubmit(code);
  }, [code]);

  return (
    <BreteuilWebsitePage>
      <PageMeta
        title={T.propertyAccessCodeInput.title}
        description={T.propertyAccessCodeInput.description}
      />
      <Container>
        <InfoBox>
          <IconWrapper>
            <LockIcon sx={{ fontSize: 40 }}/>
          </IconWrapper>
          <Description>
            {T.propertyAccessCodeInput.description}
          </Description>
          <PhoneNumber>
            {T.propertyAccessCodeInput.formatPhoneNumber(phoneNumberHint)}
          </PhoneNumber>
          <CodeInput
            size={4}
            value={code}
            onChange={setCode}
          />
          {invalidAccessCode && (
            <Description style={{ color: '#ff0000', marginTop: '10px' }}>
              {T.propertyAccessCodeInput.invalidAccessCode}
            </Description>
          )}
        </InfoBox>
      </Container>
    </BreteuilWebsitePage>
  );
});

export default PropertyAccessCodeInput;
