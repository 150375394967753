import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Link } from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import { SocialMedias } from '@breteuil-website/components/common/';
import texts from './texts';
import { agencies } from '@breteuil-website/_hardcoded';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';

const FooterContainer = styled('footer')({
  position: 'relative',
  width: '100%',
  minHeight: '545px',
  padding: '50px 5vw',
  backgroundColor: '#000A4F',
});

const AgenciesContainer = styled('div')({
  display: 'grid',
  rowGap: '0',
  columnGap: '5vw',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(10, 1fr)',
  margin: 'auto',
  width: '100%',
  maxWidth: '1300px',
  '@media (max-width: 1150px)': {
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    width: '80%',
  },
});

const AgencyLink = styled(Link)({
  width: '100%',
  height: '100%',
  padding: '20px 0',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'white',
  borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
  fontSize: '14px',
  "@media (max-width: 960px)": {
    fontSize: '12px',
    padding: '10px 0',
  },
});

const AgencyName = styled('span')({
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Mulish',
  whiteSpace: 'nowrap',
});

const AgencyAdress = styled('span')({
  textAlign: 'right',
  fontFamily: 'Mulish',
});

const SocialMediaContainer = styled('div')({
  gridRowEnd: '11',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 1150px)': {
    gridRowEnd: "unset",
    marginTop: '28px',
  },
});

const LinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  color: "white",
});

const RawLink = styled(Link)({
  height: "30px",
  textAlign: "left",
  fontFamily: "Mulish",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  "@media (max-width: 960px)": {
    fontSize: '12px',
  },
});

const Footer = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);
  return (
    <FooterContainer>
      <AgenciesContainer>
        {agencies.map((agency) => (agency.shortNameMobile(lang) !== null ?
          <AgencyLink key={agency.slug} to={Routes.agency.generatePath({ agencySlug: agency.slug }, { lang })}>
            <AgencyName>{agency.shortNameMobile(lang)}</AgencyName>
            <AgencyAdress>{agency.shortAddress}</AgencyAdress>
          </AgencyLink>
          : null))}
        <SocialMediaContainer>
          <LinkContainer>
            <RawLink to={Routes.ourFees.generateStaticPath({ lang })} rel={"noreferrer"}>
              {T.fees}
            </RawLink>
            <RawLink to={Routes.legalNotices.generateStaticPath({ lang })} rel={"noreferrer"}>
              {T.legalNotices}
            </RawLink>
          </LinkContainer>
          <SocialMedias iconColor={'white'}/>
        </SocialMediaContainer>
      </AgenciesContainer>
    </FooterContainer>
  );
});

export default Footer;
