import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import textSignup from '@breteuil-website/components/pages/client-area/sign-up/texts';
import {
  Button,
  TextField as GenericTextField,
} from '@breteuil-website/components/common';
import { EmailValidation } from '@breteuil-website/_hardcoded';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Form = styled('div')({
  display: 'grid',
  rowGap: '15px',
  width: '100%',
});

const StyledTextField = styled(GenericTextField)({
  '&:first-of-type': {
    marginBottom: '2px',
  },
  '& .MuiInputBase-root': {
    color: '#989898',
    fontFamily: 'Mulish',
    fontSize: '13px',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
});

const PasswordResetForm = observer((props: { email?: string | null }) => {
  const { email } = props;
  const T = useTexts(texts);
  const T_SignUp = useTexts(textSignup);
  const { passwordReset, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    email: createValueFormField(email || '', { validation: EmailValidation }),
  }), {
    onSubmit: (values) => {
      const promise = passwordReset.requestPasswordReset(values);
      return globalMessage.handlePromise(promise, {
        successMessage: () => T.passwordResetEmailSent,
        errorMessage: () => T_SignUp.registerError,
      });
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Form>
        <StyledTextField
          field={form.fields.email}
          label={T.email}
        />
        <Button
          disabled={form.submitting}
          type='submit'
        >
          {T.resetPassword}
        </Button>
      </Form>
    </form>
  );
});

export default PasswordResetForm;
