import { styled } from '@mui/material/styles';
import {
  Select as GenericSelect,
  SelectNullable as GenericSelectNullable,
  SelectMultiple as GenericSelectMultiple,
} from '@repo-lib/mobx-forms-mui-components';
import { TextFieldStyle } from './TextField';

export const Select = styled(GenericSelect)(TextFieldStyle) as typeof GenericSelect;
export const SelectNullable = styled(GenericSelectNullable)(TextFieldStyle) as typeof GenericSelectNullable;
export const SelectMultiple = styled(GenericSelectMultiple)(TextFieldStyle) as typeof GenericSelectMultiple;
