import * as React from 'react';

export default {
  pageTitle: "Luxury real estate in France and abroad - Breteuil Remarkable Homes",
  metaDescription: "Find your next cutting-edge residency with Breteuil Remarkable Homes, the leader agency for luxury real estate",
  titles: {
    excellence: "Excellence",
    transparency: "Transparency",
    ethics: "Ethics",
  },
  formatTitle(): JSX.Element
  {
    return (
      <React.Fragment>
        <span>{"17 agencies in Paris"}</span>
        <span>{"5 on the french coast"}</span>
        <span>{"4 worldwide"}</span>
      </React.Fragment>
    );
  },
  title: 'Remarkable homes.',
  operationType: {
    transaction: 'Buy',
    rental: 'Rent',
    seasonalRental: 'Seasonal Rental',
  },
  budget: 'Budget',
  search: 'Search',
  everywhere: 'Everywhere',
  cities: {
    paris: 'Paris',
    london: 'London',
    newYork: 'New York',
    deauville: 'Deauville',
    dinard: 'Dinard',
    laBaule: 'La Baule',
    lisbon: 'Lisbon',
    biarritz: 'Biarritz',
    saintJeanDeLuz: 'Saint-Jean-De-Luz',
  },
  citiesSelect: [
    'Paris',
    'Londres',
    'New York',
    'Deauville',
    'Dinard',
    'La Baule',
    'Lisbonne',
    'Biarritz',
    'Saint-Jean-De-Luz',
  ],
  transactionTypes: [
    'Buy',
    'Rent',
  ],
  submitBannerForm: 'Search',
  selectLabelTransactionType: "Action",
  implantations: 'Breteuil locations',
  implantationsTitleMobile: 'Our locations',
  implantationsText: `Established without franchise in 4 countries, our agencies work together around a mutual file for one single interest: yours.`,
  implantationsMobile: `Established without franchise in 4 countries, our agencies work together around a mutual file for one single interest: yours.`,
  stJean: 'St Jean',
  wantToSell: 'You want to sell or simply know the value of your property :',
  freeValuation: 'FREE VALUATION',
  properties: {
    title: 'Newly listed properties',
    subtitle: 'Let us inspire you.',
    seeMore: 'SEE MORE',
    exclusive: 'Exclusive',
  },
  selectLabelCity: 'City',
  agencies : {
    HomeAgencyTitle: "Signature agencies designed for the future.",
    HomeAgencyDescription(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Signed by Studio MHNA, these prestigious enclosures made of durable materials offer complete transparency and provide a unique comfort. Our exceptional locations ensure optimal exposure."}</span>
        </React.Fragment>
      );
    },
    HomeAgencyButton : 'All our agencies',
  },
  keyNumbers: {
    agencies(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbAgencies: Number,
      },
    ): JSX.Element {
      const { Highlight, nbAgencies } = component;
      return (
        <React.Fragment>
          <Highlight>
            {`${nbAgencies} `}agencies
          </Highlight>
        </React.Fragment>
      );
    },
    employees(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbEmployees: Number,
      },
    ): JSX.Element {
      const { Highlight, nbEmployees } = component;
      return (
        <React.Fragment>
          <Highlight>
            {`${nbEmployees} `}employees
          </Highlight>
        </React.Fragment>
      );
    },
    cities(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbCities: Number,
      },
    ): JSX.Element {
      const { Highlight, nbCities } = component;
      return (
        <React.Fragment>
          <Highlight>
            in{` ${nbCities} `}cities
          </Highlight>
        </React.Fragment>
      );
    },
    keyNumbersQuote: "to support all your real estate projects",
    keyNumbersLogoAltText: "Breteuil logo",
    keyNumbersMapAnimText: "Map",
  },

  clientAreaBanner: {
    mobileTitle(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Get a preview of"}</span>
        <span>{"our off market assets"}</span>
        <span>{"before anyone else"}</span>
        </React.Fragment>
      );
    },
    title: "Get a preview of out off market assets before anyone else", // DEV TRANSLATION
    description: `Some properties are only shown to our subscribed users :`, // DEV TRANSLATION
    mobileText(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Some properties are only shown"}</span>
        <span>{"to our subscribed users."}</span>
        <span>{"You can find them in your client area !"}</span>
        </React.Fragment>
      );
    },
    button: `CREATE MY ACCOUNT`,
    ipadAlt: `IPAD`,
  },


  intro: {
    title: "International prestige real estate",
    text1: "Breteuil is established in 4 countries to offer you an exceptional service in Paris, London, Lisbon, New York and on the French coast. We offer a portfolio of prestigious properties for sale, rent or holiday rental to a demanding and loyal clientele.",
    text2: "All our offices, franchise-free, work together around a unique database of global buyers, to give your property optimum exposure internationally and guarantee unrivalled efficiency in your transactions.",
    text3: "Our agencies are open from Monday to Saturday and our agents are available on the phone 7 days a week to assist you in your project: contact us!",
  },

  market: {
    title: 'Market reports',
    button: 'ALL MARKET REPORTS',
  },
  services: {
    title: "Breteuil helps you before and after your project",
    description: "The best partners at your service, in each of our locations.",
    button: "Our Services",
  },
  people: {
    title: "Experienced advisors, involved managers.",
    description: "Employee advisors, participatory and supportive management, great operational agility: key components for ambitious performance.",
    button: "Join us",
  },
  valuations: {
    title: "Smart and fast valuations.",
    description: "A reliable online estimate before your appointment with a advisor.",
    button: "Make a valuation",
  },
  availableCoffee: {
    agentsAvailable: 'Available everyday,',
    availability: 'from 7am to 10pm',
    agentsAvailableMobile(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Available everyday,"}</span>
        </React.Fragment>
      );
    },
    availabilityMobile(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"from 7am to 10pm"}</span>
        </React.Fragment>
      );
    },
    agentsAvailableDescription: "Our agents are available when you need them. Because it's often early in the morning or later in the evening when you're willing to talk about your project, you can call us at your convenience and find an advisor ready to listen.",
    contactUsLabel: "CONTACT US",
  },

  myMarket: {
    title(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"All the real estate data"}</span>
        </React.Fragment>
      );
    },
    titleMobile: 'MyMarket, all the real estate data.',
    text: "The most reliable and thorough real estate database that indentify all the properties sold in Paris to track the real estate market and value you asset accuratly, with transparency.", // DEV Translation
    buttonLabel: 'MY MARKET',
    imageAltTag: 'MY MARKET',
  },
  ethics: {
    impactTitle: "Reduced impact",
    impactDesc: "Less paper, an all-electric fleet, agencies renovated with durable materials, tangible energy savings.",
    integrityTitle: "Integrity",
    integrityDesc: "Our valuations, advices and sales methods are strict and tolerate no deviation. We never sacrifice integrity for complacency.",
    securityTitle: "Discretion and security.",
    securityDesc: "We are deeply committed to preserving the privacy of our clients, from the signing of the mandate to the management of their real estate portfolio. Our processes are highly secure and our internal policy is particularly strict.",
    managementTitle: "Managerial goodwill",
    managementDesc: "Our agency directors are true captains, whose involvement goes as far as participating in the Group's capital. They are experienced in a supportive and participatory management style, and lead their teams by example, through listening and exchange.",
    parityTitle: "Parity",
    parityDesc: "Our company has a workforce that is over 50% women and we are proud to promote cultural diversity and equal pay.",
  },
};
