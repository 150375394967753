import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
  ResolvedRouteLocation,
  RoutingInfo,
} from '@lib/routing';
import { ensureFetchableResource } from '@repo-breteuil/front-error';
import type { Locale } from '@repo-breteuil/common-texts';

import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import {
  setTranslatedPage,
  setTranslatedStaticPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  async function contactHandler(
    location: ResolvedRouteLocation,
    info: RoutingInfo,
    lang: Locale,
  )
  {
    await websiteStores.agencies.agencies.ensureSuccess();
    return () => ({
      page: null,
      route: Routes.contact.generateStaticPath({
        lang,
        searchParams: location.searchParams,
        hash: location.hash,
      }),
    });
  }

  setTranslatedStaticPage(Public, RootPages.Contact, Routes.contact.routesByLanguage);
  setTranslatedStaticPage(
    Public,
    RootPages.Contact,
    Routes.agencies.routesByLanguage,
    contactHandler,
  );
  setTranslatedPage(
    Public,
    RootPages.Agency,
    Routes.agency.routesByLanguage,
    async (location, info, language) => {
      const { agencySlug } = location.routeParams;
      await websiteStores.agencies.agencies.ensureSuccess();
      const agencies = ensureFetchableResource(websiteStores.agencies.agencies);
      const agency = agencies.find(a => a.slugs.includes(agencySlug));
      if (agency)
      {
        googleMapsAPI.init(websiteStores.env.googleMapsAPIKey);
        websiteStores.agency.refresh(agency.id);
        websiteStores.agency.setCurrentAgencyHardcoded(agency);
        return () => ({
          page: null,
          route: Routes.agency.generatePath({ agencySlug: agency.slug }, { lang: language }),
        });
      }
      return () => ({ page: RootPages.NotFound, route: null });
    },
  );
}
