export default {
  title: 'Personal data',
  firstname: 'First name',
  lastname: 'Last name',
  mail: 'E-mail',
  password: 'Password',
  phone: 'Phone',
  address: 'Address',
  zipCode: 'Zip code',
  city: 'City',
  languages: {
    french: 'Français',
    english: 'English',
    frenchEn: 'French',
  },
  language: 'Language',
  alerts: 'Receive alerts by',
  coms: 'Breteuil communications',
  alertFrequency: "Frequency of search alerts",
  newsletterOptOut: 'Newsletter',
  saveChanges: 'Save',
  sms: 'SMS',
  whatsapp: 'WhatsApp',
  yes: 'Yes',
  no: 'No',
  successToast: 'Your profile has been modified',
  errorToast: 'There has been an error, please try again later',
  frequencies: {
    instant: 'Instantly',
    daily: 'Daily',
  },
  comsDisabled: 'All communications from Breteuil are disabled.',
};
