import type { SelectOptions } from '@repo-lib/mobx-forms';

import * as React from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Button } from '@breteuil-website/components/common/elements/Button';

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: '100%',
  margin: 'auto',
  columnGap: '4px',
  rowGap: '4px',
});

const SelectButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !['isSelected', 'isOverrideSelected', 'overrideTileWidth', 'labelLength'].includes(prop as string),
})<{
  isSelected?: boolean,
  isOverrideSelected?: boolean,
  overrideTileWidth?: boolean,
  labelLength?: number,
}>(({ isSelected, isOverrideSelected, overrideTileWidth, labelLength, theme }) => ({
  height: '50px',
  fontWeight: 'bold',
  fontSize: '13px',
  fontFamily: 'Mulish',
  color: '#595959',
  textTransform: 'none',
  flexGrow: 1,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  border: "1px solid #DADADA",
  maxWidth: '100% !important',
  width: overrideTileWidth ? 'auto' : `${Math.ceil(labelLength! / 8) * 100}px`,

  // Base non-selected state
  backgroundColor: '#F4F4F4 !important',
  '&:hover': {
    backgroundColor: '#CBCBCB !important',
    boxShadow: 'none !important',
  },

  // Selected state
  ...(isSelected && {
    backgroundColor: '#050251 !important',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#050251 !important',
    },
  }),

  // Override selected state
  ...(isOverrideSelected && {
    color: '#FFFFFF !important',
    backgroundColor: '#050251 !important',
  }),
}));


export const SelectTiles = <T extends any>(
  props: {
    options: SelectOptions<T>,
    selected: Array<T>,
    onSelect?: ((value: T) => void) | undefined,
    overrideTileWidth: boolean,
    overrideSelectedColor: boolean,
    customStyle: SxProps<Theme>,
  },
) => {
  const {
    options,
    selected,
    onSelect,
    overrideTileWidth,
    overrideSelectedColor,
    customStyle,
  } = props;

  return (
    <Container>
      {options.options.map(({ label, value }, i) => (
        <SelectButton
          key={i}
          isSelected={selected.includes(value)}
          isOverrideSelected={selected.includes(value) && overrideSelectedColor}
          overrideTileWidth={overrideTileWidth}
          labelLength={label.length}
          {...(onSelect ? {
            onClick: () => onSelect(value),
          } : null)}
          sx={customStyle}
        >
          {label}
        </SelectButton>
      ))}
    </Container>
  );
};

export default SelectTiles;
