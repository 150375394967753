import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface CreateMeetingArgs {
  address: string,
  meetingDate: number,
  phone: string,
  email: string,
  name: string,
  remark?: string | undefined,
  language: Language,
  communicationIdentifier?: string | undefined,
}


export interface Agency {
  id: IdType,
  name: string,
  slug: string,
  timezone: string,
  phone: string,
  email: string,
  longitude: number,
  latitude: number,
  address: string,
}

export interface AgencyMeeting {
  id: number,
  latitude: number,
  longitude: number,
}

export interface BreteuilWebsiteAgencyWithValuationMeetingInstance {
  agency: Agency,
  agencyMeeting: AgencyMeeting,
  verifyPhone: boolean,
}

export default async function CreateMeeting(
  qs: QueryStore,
  args: CreateMeetingArgs,
): Promise<BreteuilWebsiteAgencyWithValuationMeetingInstance> {
  return qs.mutation({
    operationName: 'CreateMeeting',
    variables: {
      address: 'String!',
      meetingDate: 'Date!',
      phone: 'String!',
      email: 'String!',
      name: 'String!',
      remark: 'String',
      language: 'Language',
      communicationIdentifier: 'String',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            agencyMeeting: {
              createMeeting: [{
                args: {
                  address: true,
                  meetingDate: true,
                  phone: true,
                  email: true,
                  name: true,
                  remark: true,
                  communicationIdentifier: true,
                },
              }, {
                agency: {
                  id: true,
                  name: true,
                  slug: [{ args: { language: true } }, true],
                  timezone: true,
                  phone: true,
                  email: true,
                  latitude: true,
                  longitude: true,
                  address: true,
                },
                agencyMeeting: {
                  id: true,
                  latitude: true,
                  longitude: true,
                },
                verifyPhone: true,
              }],
            },
          },
        },
      },
    },
  }, args);
}
