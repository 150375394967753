import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
 Box,
 Stack,
 Typography,
} from '@mui/material';
import { Mail, Phone } from '@mui/icons-material/';
import {
 Page,
 PageMeta,
 PageTitle,
} from '@breteuil-website/components/common';
import { contactRh, contactManagment } from '@breteuil-website/_hardcoded/contact';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled(Box)({
 position: 'relative',
});

const ContentWrapper = styled(Box)(({ theme }) => ({
 padding: theme.spacing(5, 0),
 [theme.breakpoints.down('md')]: {
   padding: theme.spacing(4, 0),
 },
}));

const BodyContainer = styled(Stack)({
 flexDirection: 'row',
 alignItems: 'center',
 justifyContent: 'space-between',
 maxWidth: 1300,
 margin: '0 auto',
 "@media (max-width: 960px)": {
   flexDirection: 'column',
   rowGap: '32px',
 },
});

const CustomImage = styled('img')({
 height: '575px',
 objectFit: 'cover',
 "@media (max-width: 960px)": {
   width: '100%',
   height: 'unset',
 },
});

const ContactContainer = styled(Stack)({
 width: '100%',
 color: 'rgba(5, 2, 81, 0.90)',
 fontFamily: "Poppins",
 "@media (max-width: 960px)": {
   paddingInline: '24px',
 },
});

const ContactWrapper = styled(Stack)({
 rowGap: '64px',
 "@media (max-width: 960px)": {
   rowGap: '32px',
 },
});

const Contact = (props: { phone: string, mail: string, title: string }) => {
 const { phone, mail, title } = props;
 return (
   <ContactContainer direction='column' rowGap={1}>
     <Typography fontFamily='inherit' fontWeight='bold'>
       {title}
     </Typography>
     <Stack direction='row' columnGap={2}>
       <Mail/>
       <a href={`mailto:${mail}`}>{mail}</a>
     </Stack>
     <Stack direction='row' columnGap={2}>
       <Phone/>
       <a href={`tel:${phone}`}>{phone}</a>
     </Stack>
   </ContactContainer>
 );
};

const JoinUs = () => {
 const T = useTexts(texts);

 return (
   <Page>
     <PageMeta
       title={T.pageTitle}
       description={T.pageDescription}
     />
     <Container>
       <PageTitle title={T.title}/>
       <ContentWrapper>
         <BodyContainer>
           <ContactWrapper direction='column'>
             <Contact
               phone={contactRh.phone}
               mail={contactRh.mail}
               title={T.join}
             />
             <Contact
               phone={contactManagment.phone}
               mail={contactManagment.mail}
               title={T.joisAsLicensee}
             />
           </ContactWrapper>
           <CustomImage src='/assets/agencies/agence2.jpeg'/>
         </BodyContainer>
       </ContentWrapper>
     </Container>
   </Page>
 );
};

export default JoinUs;
