import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedRoute, TranslatedStaticRoute } from '@breteuil-website/store/routing/translatedRoutes';

//TODO: in paths, replace `properties` with propertyType (house, apt, ...)

export const buy = new TranslatedStaticRoute({
  [Locale.French]: '/achat',
  [Locale.English]: '/buy',
});
export const buyArea = new TranslatedRoute({
  [Locale.French]: '/achat/[areaSlug]',
  [Locale.English]: '/buy/[areaSlug]',
});

export const rent = new TranslatedStaticRoute({
  [Locale.French]: '/louer',
  [Locale.English]: '/rent',
});
export const rentArea = new TranslatedRoute({
  [Locale.French]: '/louer/[areaSlug]',
  [Locale.English]: '/rent/[areaSlug]',
});

export const seasonalRent = new TranslatedStaticRoute({
  [Locale.French]: '/location-saisonniere',
  [Locale.English]: '/seasonal-rental',
});
export const seasonalRentArea = new TranslatedRoute({
  [Locale.French]: '/location-saisonniere/[areaSlug]',
  [Locale.English]: '/seasonal-rental/[areaSlug]',
});

export const properties = new TranslatedStaticRoute({
  [Locale.French]: '/proprietes',
  [Locale.English]: '/properties',
});

export const propertyByRefId = new TranslatedRoute({
  [Locale.French]: '/[propertySlug]',
  [Locale.English]: '/[propertySlug]',
}, {
  match: ({ propertySlug }) => (
    //A route composed only of numbers represents a Property refId, which is a valid Property slug
    /[0-9]+/.test(propertySlug)
  ),
});

export const propertyShort = new TranslatedRoute({
  [Locale.French]: '/proprietes/[propertySlug]',
  [Locale.English]: '/properties/[propertySlug]',
});
export const buyProperty = new TranslatedRoute({
  [Locale.French]: '/achat/[areaSlug]/proprietes/[propertySlug]',
  [Locale.English]: '/buy/[areaSlug]/properties/[propertySlug]',
});
export const rentProperty = new TranslatedRoute({
  [Locale.French]: '/louer/[areaSlug]/proprietes/[propertySlug]',
  [Locale.English]: '/rent/[areaSlug]/properties/[propertySlug]',
});
