import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  title: string,
  logIn: string,
  logInWithoutColon: string,
  email: string,
  password: string,
  forgotPassword: string,
  resetPassword: string,
  noAccount: string,
  createAccount: string,
  passwordResetEmailSent: string,
  accountNotVerified: string,
  sendActivationLink: string,
  invalidCredentials: string,
  send: string,
  clientArea: string,
  endlessPossibilities: string,
  comfort: string,
  propertyAccess: string,
  adjustments: string,
  reports: string,
  monitoring: string,
  createArea: string,
  loginWithCode: string,
  successfullCodeResent: string,
  sendNewCode: string,
  codeSent: string,
  invalidCodeCredentials: string,
  codeDescription(email: string): string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
