import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import {
  createValueFormField,
  FormControl,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import Page from '@breteuil-website/components/common/app/Page';
import {
  Button as GenericButton,
  TextField as GenericTextField,
  PasswordStrengthBar,
} from '@breteuil-website/components/common/elements';
import InvalidPasswordToken from '@breteuil-website/components/pages/client-area/password-reset/InvalidPasswordToken';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  fontFamily: 'Poppins',
  width: 'min(90%, 600px)',
  margin: 'auto auto 100px auto',
});

const Title = styled('h1')({
  marginBlock: '1.5em',
  textAlign: 'center',
  fontSize: '2.25rem',
  fontWeight: 700,
  color: '#050251',
  '@media (max-width: 960px)': {
    fontSize: '1.5rem',
  },
});

const Form = styled('form')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '1rem',
});

const LabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Label = styled('label')({
  fontSize: '1rem',
  color: '#050251',
  '@media (max-width: 960px)': {
    fontSize: '.8rem',
  },
});

const TextField = styled(GenericTextField)({});

const SubmitButton = styled(GenericButton)({
  gridColumn: '1 / 3',
});

const PasswordReset = observer(() => {
  const T = useTexts(texts);
  const lang = useLocale();
  const { passwordReset, globalMessage } = useWebsiteStores();
  const [ form ] = React.useState(() => (
    new FormControl({
      password: createValueFormField('', {validation: (value) => value.length >= 6 }),
      confirmPassword: createValueFormField('', {validation: (value) => (value === form.fields.password.value)}),
    }, {
      onSubmit: (values: {password: string} ) => {
        const promise = passwordReset.resetPassword({
           password: values.password,
           passwordResetToken: passwordReset.token!,
         });
         globalMessage.handlePromise(promise, {
          successMessage: T.successNotif,
          onSuccess: () => router.changeRoute(Routes.clientAreaSearches.generateStaticPath({ lang })),
          errorMessage: T.errorNotif,
          onError: (error) => {
            if((error as any).code === 'InvalidToken')
              setInvalidToken(true);
          },
         });
       },
    })
  ));

  const [invalidToken, setInvalidToken] = React.useState(false);

  return(
    <Page>
      <Container>
        <Title>{T.title}</Title>
        <Form onSubmit={form.handleSubmit}>
          <LabelContainer>
            <Label htmlFor="password">{T.newPassword}</Label>
          </LabelContainer>
          <TextField id="password" field={form.fields.password} type="password" fullWidth/>
          <div/> {/*empty div for grid layout*/}
          <PasswordStrengthBar password={form.fields.password.value}/>
          <LabelContainer>
            <Label htmlFor="confirmPassword">{T.confirmPassword}</Label>
          </LabelContainer>
          <TextField id="confirmPassword" field={form.fields.confirmPassword} type="password" fullWidth/>
          <SubmitButton type="submit" disabled={form.submitting}>{T.modifyPassword}</SubmitButton>
        </Form>
        {invalidToken && <InvalidPasswordToken/>}
      </Container>
    </Page>
  );
});

export default PasswordReset;
