export default {
  title: 'Favorites',
  noProperties: "You don't have any favorite property.",
  error: "There has been an error, please reload the page.",
  price: 'Price',
  addDate: 'Date added',
  deleteAll: "Delete All",
  filters: {
    price_desc: 'Price: High-Low',
    price_asc: 'Price: Low-High',
    date_desc: 'Date: Newest to Oldest',
    date_asc: 'Date: Oldest to Newest',
  },
  confirmations: {
    text: 'Are you sure you want to delete all your favorites?',
    yes: 'Yes',
    no: 'No',
  },
};
