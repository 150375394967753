import { User } from '@breteuil-website/store/ui/pages/agency/api';
import * as React from 'react';
import { observer } from "mobx-react";
import { Card, CardMedia, Button, styled } from '@mui/material';
import { TransactionsMap } from '@repo-breteuil/react-components';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { Page, PageMeta } from '@breteuil-website/components/common';
import Infos from '@breteuil-website/components/pages/agency/Infos';
import mapStyle from './mapStyle';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';

const Container = styled('div')({
  position: 'relative',
});

const TopPart = styled('div')({
  marginLeft: 'auto',
  width: '95%',
  display: 'flex',
  justifyContent: 'space-between',
});

const DesktopCarousel = styled('div')({
  width: '75%',
});

const Picture = styled('img')({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const AgencyDescription = styled('div')({
  color: '#989898',
  fontFamily: 'Poppins',
  margin: '0px auto',
  fontSize: '14px',
  lineHeight: '23px',
  textAlign: 'center',
  width: '90%',
  maxWidth: '1000px',
  marginBottom: '80px',
});

const Map = styled('div')({
  width: '100%',
  height: '600px',
  marginBottom: '110px',
  marginTop: '75px',
});

const MobileMap = styled('div')({
  width: '100%',
  height: '500px',
  margin: '40px 0',
});

const PictureContainer = styled('div')({
  width: '100%',
  height: '56.25vw', // 16/9
});

const AgentListTitleContainer = styled('div')({
  width: '90%',
  margin: 'auto',
});

const AgentListContainer = styled('div')({
  display: 'grid',
  justifyContent: 'space-between',
  width: '95%',
  margin: '50px auto',
  columnGap: '50px',
  rowGap: '50px',
  '@media (max-width: 1200px)': {
    gridTemplateColumns: 'repeat(2, 1fr) !important',
    margin: '25px auto',
    columnGap: '25px',
    rowGap: '25px',
  },
  '@media (max-width: 960px)': {
    gridTemplateColumns: 'repeat(2, 1fr) !important',
    margin: '25px auto',
    columnGap: '25px',
    rowGap: '25px',
  },
  '@media (max-width: 600px)': {
    gridTemplateColumns: 'repeat(1, 1fr) !important',
    margin: '25px auto',
    columnGap: '25px',
    rowGap: '25px',
  },
});

const TeamTitle = styled('h2')({
  fontFamily: 'Poppins',
  fontSize: '25px',
  fontWeight: 700,
  color: '#050251',
  textTransform: 'uppercase',
  marginBottom: '15px',
  '@media (max-width: 960px)': {
    fontSize: '28px',
    textAlign: 'center',
  },
});

const AgentPicture = styled(CardMedia)({
  width: '100%',
});

const AgentCardComponent = styled(Card)({
  width: '100%',
  maxWidth: '300px',
  margin: 'auto',
  boxShadow: 'none',
  '@media (max-width: 960px)': {
    maxWidth: 'unset',
  },
});

export const CTAButton = styled(Button)({
  width: '88px !important',
  height: '45px !important',
  backgroundColor: '#050251 !important',
  borderRadius: 'unset !important',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
  '& .MuiButton-endIcon': {
    margin: '0 !important',
  },
  '& img': {
    width: '24px',
    height: '24px',
  },
});

interface AgentProps {
  agent: User,
}

interface AgentListProps {
  agents: Array<User>,
  leftover?: Boolean,
}

const AgentCard = observer((props: AgentProps) => (
  <AgentCardComponent>
    <AgentPicture
      image={props.agent.photoURL || "/assets/agencies/collabs/PHOTO_PLACEHOLDER.jpg"}
    />
  </AgentCardComponent>
));

const AgentListComponent = observer((props: AgentListProps) => {
  const nbCol = props.leftover ? 4 : props.agents.length;
  return (
    <AgentListContainer style={{gridTemplateColumns: `repeat(${nbCol}, 1fr)`}}>
      {props.agents.map((agent, index) =>
        <AgentCard agent={agent} key={index}/>,
      )}
    </AgentListContainer>
  );
});

const Agency = observer(() => {
  const { agency: store, locale } = useWebsiteStores();
  const agency = store.agency.result;
  const agencyHardcoded = store.currentAgencyHardcoded!;
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();

  const markers = React.useMemo(() => [{
    lat: agencyHardcoded.latitude,
    lng: agencyHardcoded.longitude,
    title: agencyHardcoded.nameTranslated(locale.locale),
    markerType: 'CustomMarker' as const,
    icon: {
      url: "/assets/agencies/map/pin.svg",
      size: { x: 72, y: 104 },
    },
  }], [agency]);

  return (
    <Page>
      <PageMeta
        title={T.formatPageTitle(agency?.name || agencyHardcoded.name)}
        description={T.formatPageDescription(agency?.name || agencyHardcoded.name)}
      />
      <Container>
        {isMobile ? (
          <div>
            <PictureContainer>
              <Picture src={agencyHardcoded.pictureURL}/>
            </PictureContainer>
            <Infos
              location={agencyHardcoded.district(locale.locale)}
              name={agencyHardcoded.nameTranslated(locale.locale)}
              address={`${agencyHardcoded.address}`}
              phone={agencyHardcoded.phone}
              email={agencyHardcoded.email}
              openingHours={agencyHardcoded.openingHours}
              phoneHours={agencyHardcoded.phoneHours}
            />
          </div>
        ) : (
          <TopPart>
            <Infos
              location={agencyHardcoded.district(locale.locale)}
              name={agencyHardcoded.nameTranslated(locale.locale)}
              address={`${agencyHardcoded.address}`}
              phone={agencyHardcoded.phone}
              email={agencyHardcoded.email}
              openingHours={agencyHardcoded.openingHours}
              phoneHours={agencyHardcoded.phoneHours}
            />
            <DesktopCarousel>
              <Picture src={agencyHardcoded.pictureURL}/>
            </DesktopCarousel>
          </TopPart>
        )}
        {agency?.team?.length && agency?.team?.length > 3 ? (
          <>
            <AgentListTitleContainer>
              <TeamTitle>{T.teamTitle}</TeamTitle>
            </AgentListTitleContainer>

            <AgentListComponent agents={agency.team.slice(0, 4)}/>
            {agency.team.length > 4 ? <AgentListComponent leftover={true} agents={agency.team.slice(4)}/> : null}
          </>
        ) : null}
      </Container>
      {isMobile ? (
        <MobileMap>
          <TransactionsMap
            defaultZoomLevel={16}
            defaultLatitude={agencyHardcoded.latitude}
            defaultLongitude={agencyHardcoded.longitude}
            style={mapStyle}
            markers={markers}
          />
        </MobileMap>
      ) : (
        <Map>
          <TransactionsMap
            defaultZoomLevel={16}
            defaultLatitude={agencyHardcoded.latitude}
            defaultLongitude={agencyHardcoded.longitude}
            style={mapStyle}
            markers={markers}
          />
        </Map>
      )}
      {agencyHardcoded?.description ? (
        <AgencyDescription>
          {agencyHardcoded.description(locale.locale)}
        </AgencyDescription>
      ) : null}
    </Page>
  );
});

export default Agency;
