import type { Locale } from '@repo-breteuil/common-texts';
import { GlobalLoading } from '@repo-breteuil/front-store-global-loading';
import { GlobalMessage } from '@repo-breteuil/front-store-global-message';
import { QueryStore } from '@repo-breteuil/front-store-query';
import { LocaleStore } from './common/Locale';
import { SessionStore } from './common/Session';
import { AgenciesStore } from './common/agencies';
import { AgencyStore } from './pages/agency';
import { ContactStore } from './pages/contact';
import { AreasStore } from './common/areas';
import { PropertiesSearchStore } from './pages/properties-search';
import { PropertiesSearchRentStore } from './pages/properties-search/rent';
import { PropertiesSearchSeasonalRentStore } from './pages/properties-search/seasonalRent';
import { ClientAreaTabsStore } from './pages/client-area/common/tabs';
import { EmailVerifyStore } from './pages/client-area/email-verify';
import { FavoritesStore } from './pages/client-area/favorites';
import { LogInStore } from './pages/client-area/log-in';
import { PasswordResetStore } from './pages/client-area/password-reset';
import { ProfileStore } from './pages/client-area/profile';
import { SearchesStore } from './pages/client-area/searches';
import { HomeStore } from './pages/home';
import { MarketDataStore } from './pages/market-data';
import { OptOutStore } from './pages/opt-out';
import { ValuationStore } from './pages/valuation';
import { PropertyStore } from './pages/property';
import { CookiesStore } from './common/CookiesStore';
import { BreteuilWebsiteEnv } from './env';

export interface BreteuilWebsiteStoresProps
{
  lang: Locale,
  env: BreteuilWebsiteEnv,
}

export class BreteuilWebsiteStores
{
  public env: BreteuilWebsiteEnv;
  public locale: LocaleStore;
  public globalLoading: GlobalLoading;
  public globalMessage: GlobalMessage;
  public query: QueryStore;

  //Common:
  public session: SessionStore;
  public agencies: AgenciesStore;
  public areas: AreasStore;
  public cookies: CookiesStore;

  //Pages:
  public agency: AgencyStore;
  public contact: ContactStore;
  public propertiesSearch: PropertiesSearchStore;
  public propertiesSearchRent: PropertiesSearchRentStore;
  public propertiesSearchSeasonalRent: PropertiesSearchSeasonalRentStore;
  public clientAreaTabs: ClientAreaTabsStore;
  public emailVerify: EmailVerifyStore;
  public favorites: FavoritesStore;
  public logIn: LogInStore;
  public passwordReset: PasswordResetStore;
  public profile: ProfileStore;
  public searches: SearchesStore;
  public home: HomeStore;
  public marketData: MarketDataStore;
  public optOut: OptOutStore;
  public valuation: ValuationStore;
  public property: PropertyStore;

  constructor(args: BreteuilWebsiteStoresProps)
  {
    this.env = args.env;
    this.locale = new LocaleStore(args.lang);
    this.globalLoading = new GlobalLoading();
    this.globalMessage = new GlobalMessage(this);
    this.query = new QueryStore(this);

    this.session = new SessionStore(this);
    this.agencies = new AgenciesStore(this);
    this.areas = new AreasStore(this);
    this.cookies = new CookiesStore();

    this.agency = new AgencyStore(this);
    this.contact = new ContactStore(this);
    this.propertiesSearch = new PropertiesSearchStore(this);
    this.propertiesSearchRent = new PropertiesSearchRentStore(this);
    this.propertiesSearchSeasonalRent = new PropertiesSearchSeasonalRentStore(this);
    this.clientAreaTabs = new ClientAreaTabsStore();
    this.emailVerify = new EmailVerifyStore(this);
    this.favorites = new FavoritesStore(this);
    this.logIn = new LogInStore(this);
    this.passwordReset = new PasswordResetStore(this);
    this.profile = new ProfileStore(this);
    this.searches = new SearchesStore(this);
    this.home = new HomeStore(this);
    this.marketData = new MarketDataStore(this);
    this.optOut = new OptOutStore(this);
    this.valuation = new ValuationStore(this);
    this.property = new PropertyStore(this);
  }
}

//TODO: remove this once stores migration is complete
import Locales from '@core/store/Locales';
export const websiteStores = new BreteuilWebsiteStores({
  lang: Locales.currentLocale,
  env: {
    gitVersion: __GIT_VERSION__,
    gitCommitHash: __GIT_COMMITHASH__,
    gitBranch: __GIT_BRANCH__,
    devBuild: __DEV_BUILD__,
    googleMapsAPIKey: __GOOGLE_MAPS_API_KEY__,
  },
});
