import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './fr'; // TODO Trad

export interface Definition
{
  pageTitle: string,
  pageDescription: string,
  title: string,
  contact: string,
  host: string,
  identification: {
    title: string,
    editor: string,
    formatDirector(director: string): string,
    contact: string,
  },
  mediator: {
    title: string,
  },
  establishment: {
    title: string,
  },
  companyParity: {
    title: string,
    description: string,
  },
  rest: Array<{
    title: string,
    text: Array<React.ReactNode | string>,
  }>,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
