import type { IdType } from '@repo-breteuil/common-definitions';
import type { Search } from '@breteuil-website/store/ui/pages/client-area/searches/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Collapse } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ClientAreaTabs } from '@breteuil-website/store/ui/pages/client-area/common/tabs';
import { Button as GenericButton } from '@breteuil-website/components/common/elements';
import texts from './texts';
import ClientArea from '@breteuil-website/components/pages/client-area/ClientArea';
import Form from '@breteuil-website/components/pages/client-area/searches/Form';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  fontFamily: 'Poppins',
  margin: 'auto',
  maxWidth: '1300px',
  padding: '0px 40px',
  '@media (max-width: 1280px)': {
    padding: '0px 20px',
  },
});

const Title = styled('div')({
  color: '#050251',
  fontSize: '40px',
  fontWeight: 700,
  margin: '20px auto 18px auto',
  '@media (max-width: 1280px)': {
    fontSize: '22px',
    margin: '14px auto 18px auto',
  },
});

const NewSearchTitle = styled('div')({
  backgroundColor: '#050251',
  color: '#FFFFFF',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '17px',
  fontWeight: 700,
  height: '55px',
  cursor: 'pointer',
  padding: '20px 60px 0 60px',
  '@media (max-width: 1280px)': {
    padding: '20px 30 0 30',
  },
});

const CreationFormContainer = styled('div')({
  borderRadius: '15px',
  backgroundColor: '#FFFFFF',
  marginBottom: '30px',
  overflow: 'hidden',
  '@media (max-width: 1280px)': {
    outline: 'unset',
    boxShadow: 'unset',
  },
});

const SearchHeader = styled('div')({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  padding: '15px 60px 0 60px',
  backgroundColor: '#5A5887',
  '@media (max-width: 1280px)': {
    padding: '15px 30px 0 30px',
  },
});

const SearchContainer = styled('div')({
  height: '55px',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  color: '#FFFFFF',
  cursor: 'pointer',
});

const NewSearchBottomBar = styled('div')({
  height: '20px',
  backgroundColor: '#050251',
});


const SearchBottomBar = styled('div')({
  height: '15px',
  backgroundColor: '#050251',
});


const SearchItemContainer = styled('div')({
  borderRadius: '15px',
  overflow: 'hidden',
  marginBottom: '8px',
});

const StyledButton = styled(GenericButton)({
  width: 'auto',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});


const SearchesList_Mobile = observer(() => {
  const { searches: searchesStore} = useWebsiteStores();
  const searches = searchesStore.searches.lastResult ?? [];
  const [openSearch, setOpenSearch] = React.useState<IdType | null>(searchesStore.search?.id || null);
  const CollapseSearchStyle = {
    backgroundColor: '#050251',
    display: 'flex',
    height: '55px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    padding: '15px 60px 0 60px',
    marginBottom: '0 !important',
    '@media (max-width: 1280px)': {
      padding: '15px 30px 0 30px',
    },
  };
  const ActiveSearchContainer = {
    // border: '2px solid #050251',
    // marginBottom: '5px',
  };
  const handleSearchCollapse = (id: IdType | null) => {
    setOpenSearch(id === openSearch ? null : id);
  };
  return (
    <>
      {searches.map((search: Search) => (
        <SearchItemContainer
          key={search.id}
          style={search.id === openSearch ? ActiveSearchContainer : {}}>
          <SearchContainer>
            <SearchHeader
              style={search.id === openSearch ? CollapseSearchStyle : {}}
              onClick={() => handleSearchCollapse(search.id)}
            >
              {search.label}
              <StyledButton disableRipple>
                {search.id === openSearch ? <ArrowDropUp/> : <ArrowDropDown/>}
              </StyledButton>
            </SearchHeader>
          </SearchContainer>
          <Collapse
            orientation='vertical'
            in={openSearch === search.id}
          >
            <Form search={search}/>
          </Collapse>
          <SearchBottomBar style={{ backgroundColor: search.id === openSearch ? '#050251' : '#5A5887' }}/>
        </SearchItemContainer>
      ))}
    </>
  );
});

const Searches = observer(() => {
  const { searches: searchesStore, clientAreaTabs } = useWebsiteStores();
  const searches = searchesStore.searches.lastResult ?? [];
  clientAreaTabs.setActiveTab(ClientAreaTabs.Searches);
  const [open, setOpen] = React.useState(searchesStore.search === null);
  const CollapseShadow = !open ? {boxShadow: '0px 2px 7px #CACACA, inset 0px 1px #0c17e1, inset 0px -1px #0c17e1'} : undefined;
  const handleCollapse = () => {
    if (searches.length > 0) {
      setOpen(!open);
    }
  };

  React.useEffect(() => {
    if (searches.length > 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [searches]);

  const T = useTexts(texts);
  return(
    <ClientArea>
      <Container>
        <Title>
          {T.title}
        </Title>
        <CreationFormContainer style={CollapseShadow || {}}>
          <NewSearchTitle
            onClick={handleCollapse}
          >
            {T.newSearch}
            <StyledButton disableRipple>
              {open ? <ArrowDropUp/> : <ArrowDropDown/>}
            </StyledButton>
          </NewSearchTitle>
          <Collapse
            orientation='vertical'
            in={open}
          >
            <Form search={null}/>
          </Collapse>
          <NewSearchBottomBar/>
        </CreationFormContainer>
        {searches.length > 0 ? (
          <SearchesList_Mobile/>
        ) : null}
      </Container>
    </ClientArea>
  );
});

export default Searches;
