import type { PropertySearchType } from '@breteuil-website/store/ui/pages/properties-search/api';

import * as React from 'react';
import { observer } from 'mobx-react';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { styled } from '@mui/material/styles';
import { PropertyCard, Button } from '@breteuil-website/components/common';
import { PropertyLoadingSpinner as LoadingSpinner } from '@breteuil-website/components/common/elements';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '70px',
  width: '90%',
  maxWidth: '1300px',
  margin: '70px auto',
  '@media (min-width: 2000px)': {
    maxWidth: '1800px',
  },
  '@media (max-width: 960px)': {
    width: '80%',
    '& .MuiCardMedia-media' : {
      height: '60vw !important',
    },
  },
  '@media (max-width: 500px)': {
    width: '100%',
    '& .MuiCardMedia-media' : {
      height: '60vw !important',
    },
  },
});

const LoadMoreButton = styled(Button)({
  width: '200px',
  margin: 'auto',
  "@media (max-width: 960px)": {
    width: '150px',
  },
});

const NoResultSpan = styled('span')({
  width: '100%',
  fontFamily: 'Poppins',
  fontSize: '43px',
  lineHeight: '44px',
  fontWeight: 700,
  color: '#050251',
  padding: '30px',
  margin: 'auto !important',
  textAlign: 'center',
});

const LoadingContainer = styled('div')({
  height: "100px !important",
  display: 'flex',
  margin: '50px auto',
  justifyContent: 'center',
  "@media (max-width: 960px)": {
    height: '50px !important',
    margin: '25px auto',
  },
});

const PropertyCardContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  width: '90%',
  maxWidth: '1300px',
  margin: 'auto',
  '@media (min-width: 2000px)': {
    maxWidth: '1800px',
    width: '100%',
    '& .MuiCardMedia-media' : {
      height: '600px !important',
    },
  },
  '@media (max-width: 960px)': {
    width: '100%',
    '& .MuiCardMedia-media' : {
      height: '70vw !important',
    },
  },
});

export interface PropertiesResultsProps {
  properties: UncachedPagination<PropertySearchType>,
  errorLoadMore: string,
  seasonnalRent?: boolean,
}

const PropertiesResults = observer((props: PropertiesResultsProps) => {
  const { properties, errorLoadMore, seasonnalRent = false } = props;
  const { globalMessage } = useWebsiteStores();
  const T = useTexts(texts);
  if (!properties.data || properties.data.empty) {
    if (properties.loading)
      return (
        <Container>
          <LoadingContainer>
            <LoadingSpinner/>
          </LoadingContainer>
        </Container>
      );
    return (
      <Container>
        <NoResultSpan>{T.noResult}</NoResultSpan>
      </Container>
    );
  }
  return (
    <Container
      sx={{ gap: seasonnalRent ? '45px' : '70px' }}
    >
      {properties.data.items.map((property) => (
        <PropertyCardContainer key={property.id}>
          <PropertyCard key={property.id} property={property} large/>
        </PropertyCardContainer>
      ))}
      {properties.loading ? (
        <LoadingContainer>
          <LoadingSpinner/>
        </LoadingContainer>
      ) : (
        properties.data.hasNextPage ? (
          <LoadMoreButton
            onClick={() => {
              properties.loadMoreAfter().catch(() => {
                globalMessage.display(errorLoadMore, { severity: 'error' });
              });
            }}
          >
            {T.loadMoreLabel}
          </LoadMoreButton>
        ) : null
      )}
    </Container>
  );
});

export default PropertiesResults;
