import type { Currency } from '@repo-lib/utils-texts';
import { OperationType, PropertyType } from '@repo-breteuil/common-definitions';
import { number as numberTexts } from '@repo-breteuil/common-texts';

const OperationTypeToText = new Map<OperationType, string>([
  [OperationType.Rental, ' à louer'],
  [OperationType.SeasonalRental, ' à louer'],
  [OperationType.ResidencyTransaction, ' à vendre'],
]);

const PropertyTypeToText = new Map<PropertyType, string>([
  [PropertyType.Apartment, 'Appartement'],
  [PropertyType.House, 'Maison'],
  [PropertyType.Loft, 'Loft'],
]);

export default {
  exclusive: 'Exclusivité',
  sold(operationType: OperationType): string
  {
    return operationType === OperationType.ResidencyTransaction ? "Vendu" : "Loué";
  },
  offer: 'Sous-offre',
  favoriteDeletionConfirmation: 'Supprimer cette propriété de vos favoris ?',
  yes: 'Oui',
  no: 'Non',
  favoriteDeletionToast: 'Propriété retirée de vos favoris',
  favoriteAddToast: 'Propriété ajoutée à vos favoris',
  favoriteError: 'Il y a eu une erreur, veuillez réessayer plus tard.',
  clientAreaPropertyModalTitle: 'Créer votre compte pour ajouter cette propriété en favoris',
  loginText: 'Vous avez déjà un compte ?',
  connect: 'Connectez-vous',
  formatBedrooms(bedrooms: number): string
  {
    return `${bedrooms} ${bedrooms > 1 ? "Chambres" : "Chambre"}`;
  },
  formatGuests(guests: number): string
  {
    return `${guests} ${guests > 1 ? "Invités" : "Invité"}`;
  },
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string
  {
    const startOnly = "À partir";
    const middleEndOnly = "Jusqu'à";
    const fromWithUnit = min ? numberTexts.FR.formatNumber(min, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    const toWithUnit = max ? numberTexts.FR.formatNumber(max, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    if (min) {
      return `${startOnly} ${fromWithUnit}`;
    }
    else if (!min && max) {
      return `${middleEndOnly} ${toWithUnit}`;
    }
    else {
      return '';
    }
  },
  formatTransactionInfos: (operationType?: OperationType, propertyType?: PropertyType, district?: string): string | null => {
    const formattedOpertionType = operationType ? OperationTypeToText.get(operationType) : null;
    const formattedPropertyType = propertyType && PropertyTypeToText.get(propertyType) ? PropertyTypeToText.get(propertyType) : "Propriété";
    const formattedDistrict = district ? `, ${district}` : '';
    return [formattedPropertyType, formattedOpertionType, formattedDistrict].filter(val => Boolean(val)).join('');
  },
};
