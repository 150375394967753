import type { IdType } from '@repo-breteuil/common-definitions';
import type { PropertiesRentalFilter } from '@breteuil-website/store/ui/pages/properties-search';

import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { styled, Box } from '@mui/material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import { SuspendableReaction } from '@repo-lib/utils-mobx-store';
import { useReaction } from '@repo-lib/utils-mobx-react';
import { useDebounced } from '@repo-lib/utils-react';
import { useEffectAfterMount } from '@repo-lib/utils-react';
import { useFormFieldSelectNullable } from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import {
  ensureFetchableResource,
} from '@repo-breteuil/front-error';
import router, { getPropertiesSearchRoute } from '@breteuil-website/store/routing';
import { Page, SelectNullable, PropertiesResults, NumberPicker, CreateAlertButton } from '@breteuil-website/components/common';
import { PageMeta } from '@breteuil-website/components/common';
import { useFormFieldTextInputIntNullable } from '@repo-breteuil/react-components';
import texts from './texts';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: '0 auto',
  width: '33%',
  columnGap: theme.spacing(1),
  minWidth: '400px',
  "& > *": {
    flex: 1,
  },
  "@media (max-width: 960px)": {
    minWidth: 'unset',
    width: '100%',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));

const EverywhereValue = -1;

const PropertiesSearch = observer(() => {
  const { areas, propertiesSearchSeasonalRent, globalMessage } = useWebsiteStores();
  const { filtersAreas, areasPerId } = ensureFetchableResource(areas.areas);

  const T = useTexts(texts);
  const locale = useLocale();
  const lang = () => locale;

  const areaMap = React.useMemo(
    () => new Map([
        [ EverywhereValue, T.everywhere ],
        ...(filtersAreas.get(propertiesSearchSeasonalRent.filter.operationType) || []).map(({ area }) => [ area.id, area.name ]),
      ] as Array<[ IdType, string ]>),
    [ filtersAreas ],
  );

  const area = useFormFieldSelectNullable({
    options: () => new SelectOptions<IdType>(areaMap),
    defaultValue: () => propertiesSearchSeasonalRent.filter.areaId ?? EverywhereValue,
  }, [ filtersAreas, T ]);

  function getCurrentArea()
  {
    const areaId = area.value;
    if (!areaId || areaId === EverywhereValue)
      return null;
    const areaWithSubAreas = areasPerId.get(areaId);
    if (!areaWithSubAreas)
      throw new Error(`Assertion failed: couldn't find Area#${areaId}`);
    return areaWithSubAreas;
  }

  const currentArea = React.useMemo(
    getCurrentArea,
    [ area.value, filtersAreas ],
  );

  const bedrooms = useFormFieldTextInputIntNullable(() => propertiesSearchSeasonalRent.filter.bedroomsMin ?? null, { lang });
  // const guests = useFormFieldTextInputIntNullable(() => store.filter.guests ?? null);
  // const priceMin = useFormFieldTextInputIntNullable(() => store.filter.price ?? null);
  // const priceMax = useFormFieldTextInputIntNullable(() => store.filter.priceMax ?? null);

  useReaction(() => new SuspendableReaction(
    () => propertiesSearchSeasonalRent.filter,
    action((filter) => {
      // filter.price && priceMin.setValue(filter.price);
      // filter.priceMax && priceMax.setValue(filter.priceMax);
      filter.bedroomsMin && bedrooms.setValue(filter.bedroomsMin);
      // filter.guests && guests.setValue(filter.guests);
      area.setValue(filter.areaId ?? EverywhereValue);
    }),
  ));

  function getFilters(): PropertiesRentalFilter {
    return {
      operationType: propertiesSearchSeasonalRent.filter.operationType,
      bedroomsMin: bedrooms.value ?? undefined,
      // price: priceMin.value ?? undefined,
      // priceMax: priceMax.value ?? undefined,
      // guests: guests.value ?? undefined,
      areaId: !area.value || area.value === EverywhereValue ? undefined : area.value,
    };
  }

  function handleFiltersUpdate() {
    area.value ? propertiesSearchSeasonalRent.setIsAreaSelected(true) : propertiesSearchSeasonalRent.setIsAreaSelected(false);
    const filter = getFilters();
    propertiesSearchSeasonalRent.setFilter(filter);
    propertiesSearchSeasonalRent.properties.first().catch(() => {
      globalMessage.display(T.errorLoadMore, { severity: 'error' });
    });

    //Refresh the URL when any of the filters change,
    //to update all the parts of the URL (both the route and the searchParams)
    const route = getPropertiesSearchRoute({
      ...filter,
      lang: locale,
      areaSlug: currentArea?.area.slug,
    });
    router.changeRouteBypassHandler(route, { replace: true });
  }

  useDebounced(
    handleFiltersUpdate,
    { delay: 1000 },
    [bedrooms.value],
  );

  useEffectAfterMount(
    handleFiltersUpdate,
    [area.value],
  );

  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <Box
        width="100%"
        sx={{
          boxShadow: '0 0 4px #a2a2a2',
        }}
      >
        <FlexContainer padding={2}>
          <SelectNullable label={T.form.area} field={area}/>
          <NumberPicker formatLabel={T.form.formatBedrooms} field={bedrooms}/>
          <Box>
            <CreateAlertButton
              CreateAlertMutation={() => propertiesSearchSeasonalRent.CreateAlert()}
              hasAreaSelected={area.value !== EverywhereValue}
              showText
            />
          </Box>
        </FlexContainer>
      </Box>
      <PropertiesResults
        properties={propertiesSearchSeasonalRent.properties}
        errorLoadMore={T.errorLoadMore}
        seasonnalRent={propertiesSearchSeasonalRent.filter.operationType === OperationType.SeasonalRental}
      />
    </Page>
  );
});

export default PropertiesSearch;
