import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { Box, Tooltip } from '@mui/material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import {
  createFormFieldToggle,
  useFormFieldSelect,
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import {
  Switch,
} from '@repo-lib/mobx-forms-mui-components';
import { Language, useTexts } from '@repo-breteuil/common-texts';
import { AlertsFrequency } from '@repo-breteuil/common-definitions';
import { ClientAreaTabs } from '@breteuil-website/store/ui/pages/client-area/common/tabs';
import texts from './texts';
import {
  ValueTextField,
} from '@breteuil-website/components/common';
import {
  Button,
  TextField,
  Select,
} from '@breteuil-website/components/common/elements/client-area';
import ClientArea from '@breteuil-website/components/pages/client-area/ClientArea';
import { PhoneValidation } from '@breteuil-website/_hardcoded';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  fontFamily: 'Poppins',
  margin: 'auto',
  maxWidth: '1300px',
  padding: '0px 40px',
  '@media (max-width: 1280px)': {
    padding: '0px 16px',
    marginBottom: '60px',
  },
});

const Title = styled('div')({
  color: '#050251',
  fontSize: '40px',
  fontWeight: 700,
  margin: '20px auto 18px auto',
  '@media (max-width: 1280px)': {
    margin: '14px auto 18px auto',
    fontSize: '22px',
  },
});

const Row = styled('div')({
  display: 'flex',
  columnGap: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const MobileRow = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Columns = styled('div')({
  display: 'flex',
  columnGap: '12px',
  justifyContent: 'center',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    columnGap: '18px',
    rowGap: '18px',
  },
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '18px',
  width: '100%',
});

const FieldWrapper = styled('div')({
  display: 'flex',
  padding: '9px 14px',
  color: '#050251',
  fontFamily: 'Mulish',
  fontSize: '15px',
  fontWeight: 700,
  '@media (max-width: 1280px)': {
    padding: '9px 5px 9px 14px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, .3)',
  },
});

const FormContainer = styled('div')({
  borderRadius: '15px',
  backgroundColor: '#FFFFFF',
  padding: '60px',
  boxShadow: '0px 2px 7px #CACACA',
  '@media (max-width: 1280px)': {
    padding: '0',
    boxShadow: 'unset',
  },
});

const BottomContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  marginTop: '18px',
  '@media (max-width: 1280px)': {
    rowGap: '18px',
    flexDirection: 'column',
  },
});

const BottomContainerFieldWrapper = styled('div')({
  display: 'flex',
  alignContent: 'center',
  '@media (max-width: 1280px)': {
    rowGap: '18px',
    flexDirection: 'column',
  },
});

const AlertFrequencySelect = styled(Select)({
  width: '290px',
  height: '56px',
  '@media (max-width: 1280px)': {
    width: '100%',
  },
});

const ButtonStyled = styled(Button)({
  width: 'auto',
  paddingInline: '100px',
});

const Profile = observer(() => {
  const { clientAreaTabs, profile, globalMessage } = useWebsiteStores();
  clientAreaTabs.setActiveTab(ClientAreaTabs.Profile);
  const T = useTexts(texts);
  const { value } = profile.profileData;

  if (value.status !== 'success')
    throw new Error('assertion failed: ClientAreaProfileStore.profileData is not loaded');

  const profileData = value.result;

  const alertsFrequency = useFormFieldSelect<AlertsFrequency>({
    options: () => new SelectOptions<AlertsFrequency>(new Map([
      [AlertsFrequency.RealTime, T.frequencies.instant],
      [AlertsFrequency.Daily, T.frequencies.daily],
    ])),
    defaultValue: () => profileData.alertsFrequency,
  });

  const language = useFormFieldSelect<Language>({
    options: () => new SelectOptions<Language>(new Map([
      [Language.French, T.languages.french],
      [Language.English, T.languages.english],
    ])),
    defaultValue: () => profileData.language,
  });

  const form = useForm(() => ({
    lastname: createValueFormField<string>(profileData.lastname, { validation: (value) => (value !== null) }),
    firstname: createValueFormField<string>(profileData.firstname, { validation: (value) => (value !== null) }),
    mobilePhone: createValueFormField(profileData.mobilePhone ? profileData.mobilePhone : '', { validation: ((value) => PhoneValidation(value) || value === '') }),
    address: createValueFormField(profileData.address),
    postalCode: createValueFormField(profileData.postalCode),
    city: createValueFormField(profileData.city),
    language,
    optIn: createFormFieldToggle(profileData.optIn),
    newsletterOptIn: createFormFieldToggle(profileData.newsletterOptIn),
    alertsFrequency,
  }), {
    onSubmit: () => {
      if (!form.hasNonDefaultValues())
        return;
      const promise = profile.updateAccount(form.nonDefaultValues);
      return globalMessage.handlePromise(promise, {
        successMessage: () => T.successToast,
        errorMessage: () => T.errorToast,
      });
    },
    defaultValuesAutoResetDeps: [ profileData ],
  });
  const isMobile = useMediaQueryMobile();

  return (
    <ClientArea>
      <Container>
        <Title>
          {T.title}
        </Title>
        <FormContainer>
          <form onSubmit={form.handleSubmit}>
            <Columns>
              <Column>
                <TextField
                  field={form.fields.lastname}
                  label={T.lastname}
                />
                <TextField
                  field={form.fields.firstname}
                  label={T.firstname}
                />
                <ValueTextField
                  value={profileData.email}
                  onChange={() => {}}
                  label={T.mail}
                  disabled
                />
                <ValueTextField
                  value="********"
                  onChange={() => {}}
                  label={T.password}
                  disabled
                />
              </Column>
              <Column>
                <TextField
                  field={form.fields.mobilePhone}
                  label={T.phone}
                />
                <TextField
                  field={form.fields.address}
                  label={T.address}
                />
                <Row>
                  <TextField
                    field={form.fields.postalCode}
                    label={T.zipCode}
                  />
                  <TextField
                    field={form.fields.city}
                    label={T.city}
                  />
                </Row>
                <Select
                  field={language}
                  label={T.language}
                />
              </Column>
            </Columns>
            <BottomContainer>
              <BottomContainerFieldWrapper>
                <FieldWrapper>
                  {isMobile ? (
                    <MobileRow>
                      {T.coms}
                      <Switch field={form.fields.optIn}/>
                    </MobileRow>
                  ) : (
                    <Row>
                      {T.coms}
                      <Switch field={form.fields.optIn}/>
                    </Row>
                  )}
                </FieldWrapper>
                <FieldWrapper>
                  {isMobile ? (
                    <MobileRow>
                      {T.newsletterOptOut}
                      <Switch field={form.fields.newsletterOptIn}/>
                    </MobileRow>
                  ) : (
                    <Row>
                      {T.newsletterOptOut}
                      <Switch field={form.fields.newsletterOptIn}/>
                    </Row>
                  )}
                </FieldWrapper>
                <Tooltip title={!form.fields.optIn.value ? T.comsDisabled : undefined}>
                  <Box>
                    <AlertFrequencySelect
                      field={alertsFrequency}
                      label={T.alertFrequency}
                      InputProps={{
                        disabled: !form.fields.optIn.value,
                      }}
                    />
                  </Box>
                </Tooltip>
              </BottomContainerFieldWrapper>
              <ButtonStyled
                type='submit'
                disabled={form.submitting || !form.hasNonDefaultValues()}
              >
                {T.saveChanges}
              </ButtonStyled>
            </BottomContainer>
          </form>
        </FormContainer>
      </Container>
    </ClientArea>
  );
});

export default Profile;
