import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import type { FavoriteProperty } from '@breteuil-website/store/ui/pages/client-area/favorites/api';
import type { PropertyCardProps } from './PropertyCard';

import * as React from 'react';
import { observer } from 'mobx-react';
import { CardMedia, styled } from '@mui/material';
import { useLocale } from '@repo-breteuil/common-texts';
import { Carousel } from '@repo-breteuil/react-components';
import { useMediaQueryMobile, useMediaQueryTablet, useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { Link, Routes } from '@breteuil-website/store/routing';

const PicturesContainer = styled('div')({
  display: 'flex',
  columnGap: '2px',
});

function getDisplayPicture(property: Property | FavoriteProperty) {
  const isDesktop = useMediaQueryDesktop();
  const pictures = property.picturesURL;
  if (!pictures || !pictures.length)
    return [property.mainPictureURL];
  else if (property.picturesURL.length < 2)
    return isDesktop ? [property.mainPictureURL, property.mainPictureURL] : [property.mainPictureURL];
  else
    return pictures;
}

const Pictures = observer((props: PropertyCardProps) => {
  const { property } = props;
  const lang = useLocale();
  const isMobile = useMediaQueryMobile();
  const isTablet = useMediaQueryTablet();
  const displayPictures = getDisplayPicture(property);

  if (props.large && !isTablet && !isMobile) {
    return (
      <PicturesContainer>
        <Carousel
          slidesPerView={2}
          loop={true}
          draggable={false}
          arrows={true}
          mobile={isMobile}
          spaceBetweenSlides={10}
          slidesToScroll={1}
        >
          {displayPictures.map((pic, key) => (
            <Link key={key} to={Routes.propertyShort.generatePath({ propertySlug: property.slug }, { lang })}>
              <CardMedia
                image={pic}
                component="img"
                sx={{ height: '370px' }}
              />
            </Link>
          ))}
        </Carousel>
      </PicturesContainer>
    );
  }

  return (
    <Carousel
      slidesPerView={1}
      loop={true}
      draggable={true}
      arrows={false}
      mobile={isMobile}
      spaceBetweenSlides={10}
      slidesToScroll={1}
    >
      {displayPictures.map((pic, key) => (
        <Link key={key} to={Routes.propertyShort.generatePath({ propertySlug: property.slug }, { lang })}>
          <CardMedia
            image={pic}
            component="img"
            sx={{ height: '370px' }}
          />
        </Link>
      ))}
    </Carousel>
  );
});

export default Pictures;
