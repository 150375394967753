import * as React from 'react';
import nl2br from 'react-nl2br';
import {
  Box,
  Stack,
  styled,
} from '@mui/material';
import {
  Page,
  PageMeta,
} from '@breteuil-website/components/common';
import { Info, Title } from './styledComponents';
import * as hardcoded from './_hardcoded';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Bullet = styled('span')(({ theme }) => ({
  color: '#050251',
  marginRight: theme.spacing(1),
  fontSize: '0.9rem',
  position: 'relative',
  bottom: '3px',
}));

const Container = styled(Box)({
  width: '90%',
  maxWidth: '1300px',
  margin: "90px auto",
  "@media (max-width: 960px)": {
    margin: "45px auto",
  },
});

// TODO Create component
const StickyTitle = styled(Box)({
  width: '100%',
  position: 'sticky',
  top: '90px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '44px',
  textAlign: 'center',
  backgroundColor: 'rgba(5, 2, 81, 0.90)',
  color: 'white',
  padding: '15px 0',
  zIndex: 3,
  "@media (max-width: 960px)": {
    width: '100%',
    margin: 'auto',
    fontSize: '18px',
    top: '50px',
  },
});

const Establishments = () => {
  const T = useTexts(texts).establishment;
  return (
    <Stack direction="column">
      <Title>{T.title}</Title>
      {hardcoded.companies.map((company, index) => (
        <Stack key={index} direction="column" marginY={1}>
          <Info sx={{ color: '#050251' }}>{company.name}</Info>
          <Info>{company.sas}</Info>
          <Info>{company.rcs}</Info>
          <Info>{company.pc}</Info>
          <Info>{company.warranty}</Info>
        </Stack>
      ))}
    </Stack>
  );
};

const Identification = () => {
  const T = useTexts(texts).identification;
  return (
    <Stack direction="column">
      <Title>{T.title}</Title>
      <Info sx={{ color: '#050251' }}>{hardcoded.editor}</Info>
      <Info>{hardcoded.address}</Info>
      <Info>{hardcoded.sarl}</Info>
      <Info>{hardcoded.rcs}</Info>
    </Stack>
  );
};

const Mediator = () => {
  const T = useTexts(texts).mediator;
  return (
    <Stack direction="column">
      <Title>{T.title}</Title>
      <Info sx={{ color: '#050251' }}>{hardcoded.mediator.name}</Info>
      <Info>{hardcoded.mediator.address}</Info>
      <Info>{hardcoded.mediator.mail}</Info>
    </Stack>
  );
};

const CompanyParity = () => {
  const T = useTexts(texts).companyParity;
  return (
    <Stack mt={8}>
      <Title>{T.title}</Title>
      <Info>{nl2br(T.description)}</Info>
    </Stack>
  );
};

const LegalNotices = () => {
  const T = useTexts(texts);

  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <StickyTitle>{T.title}</StickyTitle>
      <Container>
        <Stack direction="column" spacing={4}>
          <Identification/>
          <Mediator/>
          <Establishments/>
          {T.rest.map(({ title, text}, index) => (
            <Stack key={index} direction="column" spacing={1}>
              <Title>{title}</Title>
              {text.map((info, index) => (
                <Info key={`info-${index}`}>
                  <Bullet>&#8226;</Bullet>
                  {info}
                </Info>
              ))}
            </Stack>
          ))}
        </Stack>
        <CompanyParity/>
      </Container>
    </Page>
  );
};

export default LegalNotices;
