import type {
  KeyInfo,
  Surrounding,
} from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Stack, Typography } from '@mui/material';
import { PropertyKeyInfo, PropertySurronding } from '@breteuil-website/components/pages/property/common';
import texts from '../texts';
import { ShowMore } from '@breteuil-website/components/common';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    justifyContent: 'unset',
  },
});

const SurroundingsContainer = styled(Stack)({
  borderLeft: "2px solid #979797",
  paddingLeft: '40px',
  '@media (max-width: 1280px)': {
    marginTop: '16px',
    borderLeft: 'none',
    paddingLeft: 0,
  },
});

const Title = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '22px',
  fontWeight: 700,
  color: '#050251',
  '@media (max-width: 1280px)': {
    fontSize: '19px',
    fontWeight: 700,
  },
});

export interface PropertyAdditionalInfosProps {
  keyInfos: Array<KeyInfo>,
  surroundings: Array<Surrounding>,
}

const PropertyAdditionalInfosMobile = observer((props: PropertyAdditionalInfosProps) => {
  const { keyInfos, surroundings } = props;
  const firstElements = keyInfos.slice(0, 2).map((keyInfo) => <PropertyKeyInfo key={keyInfo.id} keyInfo={keyInfo}/>);
  return (
    <Container>
      <ShowMore
        firstElements={firstElements}
      >
        {keyInfos.length && keyInfos.length > 0 ? keyInfos.slice(2).map((keyInfo) =>
          <PropertyKeyInfo key={keyInfo.id} keyInfo={keyInfo}/>,
        ) : null}
        {surroundings.length && surroundings.length > 0 ? surroundings.map((surrounding) =>
          <PropertySurronding key={surrounding.id} surrounding={surrounding}/>,
        ) : null}
      </ShowMore>
    </Container>
  );
});

const PropertyAdditionalInfosDesktop = observer((props: PropertyAdditionalInfosProps) => {
  const { keyInfos, surroundings } = props;
  const T = useTexts(texts);
  return (
    <Container>
      {keyInfos.length && keyInfos.length > 0 ? (
        <Stack direction="column" gap={1} flex={1}>
          <Title>{T.keyInfosTitle}</Title>
          {keyInfos.map((keyInfo) =>
            <PropertyKeyInfo key={keyInfo.id} keyInfo={keyInfo}/>,
          )}
        </Stack>
      ) : null}
      {surroundings.length && surroundings.length > 0 ? (
        <SurroundingsContainer direction="column" gap={1} flex={1}>
          <Title>{T.surroundingsTitle}</Title>
          {surroundings.map((surrounding) =>
            <PropertySurronding key={surrounding.id} surrounding={surrounding}/>,
          )}
        </SurroundingsContainer>
      ): null}
    </Container>
  );
});

const PropertyAdditionalInfos = (props: PropertyAdditionalInfosProps) => {
  const isDesktop = useMediaQueryDesktop();
  return isDesktop ? <PropertyAdditionalInfosDesktop {...props}/> : <PropertyAdditionalInfosMobile {...props}/>;
};

export default PropertyAdditionalInfos;
