import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  title: string,
  firstname: string,
  lastname: string,
  mail: string,
  password: string,
  phone: string,
  address: string,
  zipCode: string,
  city: string,
  languages: {
    french: string,
    english: string,
    frenchEn: string,
  },
  language: string,
  alerts: string,
  coms: string,
  alertFrequency: string,
  newsletterOptOut: string,
  saveChanges: string,
  sms: string,
  whatsapp: string,
  yes: string,
  no: string,
  successToast: string,
  errorToast: string,
  frequencies: {
    instant: string,
    daily: string,
  },
  comsDisabled: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));

