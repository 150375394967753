import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Mail, Phone } from '@mui/icons-material';
import { Button } from '@breteuil-website/components/common';
import type { WeekSchedule } from '@repo-lib/utils-week-schedule';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';

const Container = styled('div')({
  width: "35%",
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    textAlign: 'center',
    margin: '20px 0',
  },
});

const InfoContainer = styled('div')({
  width: 'fit-content',
  maxWidth: '100%',
  height: 'fit-content',
  maxHeight: '100%',
  '@media (max-width: 960px)': {
    margin: 'auto',
    width: '95%',
    height: '95%',
  },
});

const Name = styled('div')({
  fontFamily: 'Poppins',
  fontSize: '25px',
  fontWeight: 700,
  color: '#050251',
  textTransform: 'uppercase',
  marginBottom: '15px',
  '@media (max-width: 960px)': {
    fontSize: '28px',
    whiteSpace: 'pre',
  },
});

const Text = styled('div')<{ isMobile?: boolean }>(({ isMobile }) => ({
  fontFamily: 'Mulish',
  fontSize: '15px',
  color: '#050251',
  justifyContent: isMobile ? 'center' : 'start',
}));

const Subtitle = styled('div')({
  fontFamily: 'Mulish',
  fontSize: '15px',
  color: '#050251',
  fontWeight: 700,
  marginTop: '30px',
});

const IconsContainer = styled('div')({
  position: 'relative',
  justifyContent: 'space-around',
  alignItems: 'center',
  display: 'flex',
  width: '50%',
  margin: '0 auto 40px auto',
});

const StyledCTAButton = styled(Button)({
  width: '45% !important',
  height: '45px !important',
  backgroundColor: '#050251 !important',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
  '& .MuiButton-endIcon': {
    margin: '0 !important',
    fontSize: '32px',
  },
});

const Hours = styled('div')({
  color: '#050251',
  marginLeft: '20px',
});

const ClosedHours = styled('div')({
  color: 'red',
  marginLeft: '20px',
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ScheduleRow = styled('div')({
  display: 'flex',
  justifyContent: 'inherit',
});

interface InfoProps {
  location: string,
  name: string,
  address: string,
  phone: string,
  email: string,
  openingHours: WeekSchedule,
  phoneHours: WeekSchedule,
}

const Infos = observer((props: InfoProps) => {
  const T = useTexts(texts);
  const { name, address, phone, email, openingHours, phoneHours } = props;
  const isMobile = useMediaQueryMobile();

  return (
    <Container>
      <InfoContainer>
        <Name>{name}</Name>
        <Text isMobile={isMobile}>
          {isMobile && (
            <IconsContainer>
              <StyledCTAButton variant='contained' href={`mailto:${email}`} endIcon={<Mail/>}/>
              <StyledCTAButton variant='contained' href={`tel:${phone}`} endIcon={<Phone/>}/>
            </IconsContainer>
          )}
          <span>
            {isMobile ? T.formatAddress(address) : T.formatAddress(address, phone, email)}
          </span>
        </Text>
        <Subtitle>{T.openingHours}</Subtitle>
        <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'start' }}>
          <Text isMobile={isMobile}>
            {T.formatOpeningSchedule(openingHours, {
              Day: ({ children }) => <div>{children}</div>,
              Hours: ({ children }) => <Hours>{children}</Hours>,
              ClosedHours: ({ children }) => <ClosedHours>{children}</ClosedHours>,
              Row: ({ children }) => <Row>{children}</Row>,
            })}
          </Text>
        </div>
        <Subtitle>
          {T.formatPhoneSchedule(phoneHours, {
            Day: ({ children }) => <div>{children}</div>,
            Hours: ({ children }) => <div style={{ color: '#050251', marginLeft: '5px' }}>{children}</div>,
            ClosedHours: ({ children }) => <div style={{ color: 'red', marginLeft: '5px' }}>{children}</div>,
            Row: ({ children }) => <ScheduleRow>{children}</ScheduleRow>,
          })}
        </Subtitle>
      </InfoContainer>
    </Container>
  );
});

export default Infos;
