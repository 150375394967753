import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { SendEmailVerify, VerifyEmail } from './api';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { QueryStore } from '@repo-breteuil/front-store-query';

export class EmailVerifyStore
{
  constructor(
    public stores: {
      query: QueryStore,
      session: SessionStore,
    },
  )
  {
  }

  public verify = new Fetchable(
    (emailVerifyToken: string) => (
      VerifyEmail(this.stores.query, { emailVerifyToken })
    ).then((session) => (
      this.stores.session.ensureVerifiedSession(session)
    )),
    { catchUnhandled: handleNonCriticalError },
  );

  public async sendEmailVerify(email: string): Promise<void>
  {
    await SendEmailVerify(this.stores.query ,{ email });
  }

  public async verifyEmail(emailVerifyToken: string)
  {
    return this.verify.ensureSuccessReload(emailVerifyToken);
  }
}
