import type { IdType } from '@repo-breteuil/common-definitions';
import type { PropertyCardProps } from './PropertyCard';

import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Favorite } from '@mui/icons-material';
import {
  styled,
  CircularProgress,
  ClickAwayListener,
  Popper,
} from '@mui/material';
import * as API from '@breteuil-website/store/ui/pages/client-area/favorites/api';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { Button } from '@breteuil-website/components/common';
import ClientAreaModal from './ClientAreaModal';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';

const StyledFavoriteIcon = styled(Favorite)({
  position: 'absolute',
  right: '20px',
  top: '20px',
  fontSize: '40px !important',
  zIndex: 2,
  '&:hover': {
    cursor: 'pointer',
  },
  '& > path': {
    filter: 'drop-shadow(0px 1px 2px rgb(0 0 0 / 0.2))',
  },
});

const StyledSpinner = styled(CircularProgress)({
  position: 'absolute',
  right: '20px',
  top: '20px',
  fontSize: '40px !important',
});

const ConfirmationPopper = styled('div')({
  fontFamily: 'Poppins',
  fontSize: '14px',
  color: '#050251',
  backgroundColor: '#FFFFFF',
  height: '120px',
  width: '300px',
  padding: '10px 20px',
});

const PopperButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '10px',
});

const PopperButton = styled(Button)({
  width: '80px',
});

const FavoriteOverlayButton = observer((
  props: {
    Icon: typeof Favorite,
    property: { id: IdType, currentUserFavorite: boolean },
    operation: 'add' | 'remove',
    color: string,
    requiresConfirmation?: boolean,
  },
) => {
  const { session, query, globalMessage } = useWebsiteStores();
  const T = useTexts(texts);
  const { property, operation, color } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const setPopperAnchor = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClick = () => {
    if (!session.authenticated) {
      handleOpenModal();
    } else {
      handleFavorite();
    }
  };

  const [loading, handleFavorite] = (operation === 'add') ? (
    globalMessage.useAsyncHandler(
      () => API.SetFavoriteProperty(query, { id: property.id, favorite: true }),
      {
        successMessage: () => T.favoriteAddToast,
        onSuccess: action(() => {
          property.currentUserFavorite = true;
        }),
      },
      [property],
    )
  ) : (
    globalMessage.useAsyncHandler(
      () => API.SetFavoriteProperty(query, { id: property.id, favorite: false }),
      {
        successMessage: () => T.favoriteDeletionToast,
        onSuccess: action(() => {
          property.currentUserFavorite = false;
        }),
      },
      [property],
    )
  );

  if (loading)
    return <StyledSpinner sx={{ color }}/>;

  return (
    <React.Fragment>
      <StyledFavoriteIcon
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.requiresConfirmation ? setPopperAnchor(e) : handleClick();
        }}
        sx={{ color }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        /*MUI typings makes these props mandatory*/
        {...(null as unknown as {
          nonce: any,
          onResize: any,
          onResizeCapture: any,
          placeholder: any,
          onPointerEnterCapture: any,
          onPointerLeaveCapture: any,
        })}
        disablePortal={true}
        modifiers={[{
          name: 'preventOverflow',
          enabled: false,
        }, {
          name: 'flip',
          enabled: false,
        }]}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <ConfirmationPopper>
            {T.favoriteDeletionConfirmation}
            <PopperButtonsContainer>
              <PopperButton onClick={handleClick}>
                {T.yes}
              </PopperButton>
              <PopperButton onClick={() => setAnchorEl(null)}>
                {T.no}
              </PopperButton>
            </PopperButtonsContainer>
          </ConfirmationPopper>
        </ClickAwayListener>
      </Popper>
      <ClientAreaModal show={openModal} handleClose={handleCloseModal}/>
    </React.Fragment>
  );
});

const FavoriteOverlay = observer((props: PropertyCardProps) => {
  const { property } = props;

  return (
    <FavoriteOverlayButton
      Icon={Favorite}
      property={property}
      operation={property.currentUserFavorite ? 'remove' : 'add'}
      color={property.currentUserFavorite ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)'}
      requiresConfirmation={props.variant === 'clientAreaFavorites'}
    />
  );
});

export default FavoriteOverlay;
