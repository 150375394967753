import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedRoute, TranslatedStaticRoute } from '@breteuil-website/store/routing/translatedRoutes';

export const news = new TranslatedStaticRoute({
  [Locale.French]: '/actualites',
  [Locale.English]: '/news',
});

export const newsByType = new TranslatedRoute({
  [Locale.French]: '/actualites/[newsType]',
  [Locale.English]: '/news/[newsType]',
});

export const newsArticleShort = new TranslatedRoute({
  [Locale.French]: '/actualites/[articleSlug]',
  [Locale.English]: '/news/[articleSlug]',
});

export const newsArticle = new TranslatedRoute({
  [Locale.French]: '/actualites/[newsType]/[articleSlug]',
  [Locale.English]: '/news/[newsType]/[articleSlug]',
});
