import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { QueryStore } from '@repo-breteuil/front-store-query';

export interface ClientAreaContact
{
  id: IdType,
  firstname: string,
  lastname: string,
  verified: boolean,
}

export interface ClientAreaSession<AdditionalContactFields = {}>
{
  token: string,
  expire: number/*Timestamp*/,
  language: Language,
  contact: (ClientAreaContact & AdditionalContactFields) | null,
}

export function clientAreaSessionResult(
  additionalContactFields?: ReadonlyNodeFields,
): ReadonlyNodeFields
{
  return {
    token: true,
    expire: true,
    language: true,
    contact: {
      ...additionalContactFields,
      id: true,
      firstname: true,
      lastname: true,
      verified: true,
    },
  };
}

export async function GetSession(qs: QueryStore): Promise<ClientAreaSession | null>
{
  return qs.query({
    operationName: 'GetSession',
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          session: clientAreaSessionResult(),
        },
      },
    },
  });
}
