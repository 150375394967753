import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import ShareModal from '@breteuil-website/components/common/app/share/ShareModal';
import { Button } from '@breteuil-website/components/common/elements/Button';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

export interface ShareDetails {
  url: string,
  title: string,
  text: string,
}

interface ShareProps {
  url: string,
}

export const CTAButton = styled(Button)(({
  width: '32% !important',
  backgroundColor: '#050251 !important',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
  '& .MuiButton-endIcon': {
    margin: "0 !important",
  },
  '& img': {
    width: '28px',
    height: '28px',
  },
}));

const ShareComponent = observer((props: ShareProps) => {
  const T = useTexts(texts);
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [shareDetails, setShareDetails] = React.useState<ShareDetails>({
    url: props.url,
    title: T.title,
    text: T.text,
  });

  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails);
      } catch (error) {
        console.error(error);
      }
    } else {
      setShareModalOpen(true);
      console.warn('Web share is currently not supported on this browser.');
    }
  };

  const triggerSharing = async () => {
    const url = props.url;
    setShareDetails(prevState => ({
      ...prevState,
      url,
    }));

    return handleSharing();
  };

  return (
    <>
      <CTAButton variant="contained" endIcon={<img src="/assets/icons/export.svg" alt="export icon"></img>} onClick={() => triggerSharing()}/>
      <ShareModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        onOpen={() => setShareModalOpen(true)}
        shareDetails={shareDetails}
      />
    </>
  );
});

export default ShareComponent;
