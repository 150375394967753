import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Button } from '@breteuil-website/components/common';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from './texts';

const Container = styled('div')({
  backgroundColor: '#F2F2F2',
  minHeight: '300px',
  padding: '50px 0',
  '@media (max-width: 960px)': {
    minHeight: '40vh',
    flexDirection: 'column',
    padding: '30px 0',
  },
});

const BannerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  width: '90%',
  maxWidth: '1500px',
  margin: 'auto',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
  },
});

const TextAgent = styled('div')({
  width: '50%',
  margin: 'auto',
  '@media (max-width: 960px)': {
    width: '80%',
    margin: '125px auto 25px auto',
  },
});

const TextPadder = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: '70%',
  margin: 'auto',
  '@media (max-width: 1440px)': {
    width: '90%',
  },
  '@media (max-width: 960px)': {
    width: '100%',
  },
});

const Text = styled('div')({
  fontFamily: 'Poppins',
  fontSize: '30px',
  fontWeight: 700,
  width: '90%',
  maxWidth: '600px',
  '@media (max-width: 1200px)': {
    fontSize: '23px',
  },
  '@media (max-width: 960px)': {
    fontSize: '24px',
    display: 'inline',
  },
  '& span': {
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },
});

const AgentsAvailableText = styled(Text)({
  color: '#050251',
});

const AvailabilityText = styled(Text)({
  color: '#050251',
});

const AgentDescription = styled('div')({
  width: '90%',
  fontFamily: 'Poppins',
  fontSize: '14px',
  margin: '25px 0',
  color: '#050251',
  '@media (max-width: 960px)': {
    margin: '25px 0',
    width: '100%',
    maxWidth: '500px',
  },
  '@media (max-width: 425px)': {
    fontSize: '12px',
    display: 'inline',
  },
});

const ImageWrapper = styled('div')({
  width: '50%',
});

const ImageCoffee = styled('img')({
  height: '430px',
  position: 'absolute',
  bottom: '-90px',
  zIndex: 1,
  right: '50px',
  '@media (max-width: 960px)': {
    height: '225px',
    top: '-130px',
    left: '50%',
    transform: 'translateX(-60%)',
  },
});

const AgentAvailableButton = styled(Button)({
  maxWidth: '200px',
  fontSize: '12px !important',
  fontFamily: 'Poppins',
  textTransform: 'uppercase',
  fontWeight: 700,
  backgroundColor: '#050251 !important',
});

const AvailableCoffeeComponent = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts).availableCoffee;
  const isMobile = useMediaQueryMobile();

  return (
    <Container>
      <BannerWrapper>
        <TextAgent>
          <TextPadder>
            <AgentsAvailableText>
              <span>{isMobile ? T.agentsAvailableMobile() : T.agentsAvailable}</span>
            </AgentsAvailableText>
            <AvailabilityText>
              <span>{isMobile ? T.availabilityMobile() : T.availability}</span>
            </AvailabilityText>
            <AgentDescription>{T.agentsAvailableDescription}</AgentDescription>
            <Link to={Routes.agencies.generateStaticPath({ lang })}>
              <AgentAvailableButton>
                {T.contactUsLabel}
              </AgentAvailableButton>
            </Link>
          </TextPadder>
        </TextAgent>
        <ImageWrapper>
          <ImageCoffee src='/assets/footerCoffee.png'/>
        </ImageWrapper>
      </BannerWrapper>
    </Container>
  );
});

export default AvailableCoffeeComponent;
