import type { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { PropertyCard } from '@breteuil-website/components/common';
import { useMediaQueryTablet } from '@breteuil-website/components/theme';

const Container = styled('div')({
  width: "75%",
  maxWidth: '1600px',
  margin: "0px auto 50px",
  '@media (max-width: 960px)': {
    width: "100%",
    margin: "50px auto",
  },
});

const CardContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '30px',
  gridAutoRows: 'minmax(100px, auto)',
  '@media (min-width: 1600px)': {
    '& .MuiCardMedia-media': {
      height: '530px !important',
    },
  },
  '@media (max-width: 1280px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '50px',
  },
});

const PropertyNameContainer = styled('div')({
  position: 'relative',
  marginBottom: '20px',
  whiteSpace: 'pre',
  textAlign: 'left',
  '@media (max-width: 960px)': {
    paddingLeft: '14px',
  },
});

const PropertyAreaName = styled('span')({
  position: 'absolute',
  top: '-35px',
  left: '10%',
  zIndex: 3,
  fontFamily: 'Callient',
  fontSize: '50px',
  fontWeight: 700,
  color: '#050251',
  '@media (max-width: 500px)': {
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    fontSize: '24px',
    justifyContent: 'center',
  },
});

const PropertyRows = observer((
  props: {
    title?: string,
    properties: Array<Property>,
  },
) => {
  const isTablet = useMediaQueryTablet();
  const propertyLimit = isTablet ? 1 : 2;
  const properties = props.properties.slice(0, propertyLimit);
  if (properties.length < propertyLimit)
    return null;
    return (
    <Container>
      <CardContainer>
        {properties.map((property, index) => (
            <div key={index}>
            <PropertyNameContainer>
              <PropertyAreaName>{property.area.name}</PropertyAreaName>
            </PropertyNameContainer>
            <PropertyCard property={property}/>
            </div>
        ))}
      </CardContainer>
    </Container>
  );
});

export default PropertyRows;
