import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import lang from '@breteuil-website/components/pages/valuation/texts';
import { useWebsiteStores } from "@breteuil-website/components/providers";
import {
  Button as GenericButton,
  CodeInput,
} from '@breteuil-website/components/common';
import { useTexts } from "@repo-breteuil/common-texts";

const CodeInputSize = 4;

const Dialog = styled(MuiDialog)({
  '& .MuiPaper-root': {
    maxWidth: '700px',
    backgroundColor: '#F0F5FF',
    borderRadius: 0,
    padding: '3em',
    '@media (max-width: 960px)': {
      padding: '.5em',
    },
  },
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '24px',
  height: '24px',
  padding: "24px",
});

const DialogTitle = styled(MuiDialogTitle)({
  color: '#050251',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  fontFamily: 'Poppins',
  textAlign: 'center',
  '@media (max-width: 960px)': {
    fontSize: '18px',
    position: "relative",
    margin: "15px auto 0",
  },
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '15px 0 40px 0',
});

const Button = styled(GenericButton)({
  position: 'relative',
  width: '50% !important',
  margin: '0 auto 25px auto !important',
  '@media (max-width: 960px)': {
    width: '70% !important',
  },
  '&:hover': {
    color: "white",
  },
});

export interface CodeDialogProps {
  open: boolean,
  handleClose: () => void,
  handleSubmit: (submittedCode: string) => void,
  handleResendPromise: () => Promise<boolean>,
}

const ConfirmPhoneDialog = (props: CodeDialogProps) => {
  const { open, handleClose, handleSubmit, handleResendPromise } = props;
  const { globalMessage } = useWebsiteStores();
  const T = useTexts(lang).method1.confirmPhoneDialog;
  const [code, setCode] = React.useState('');
  const resetCodeWhenClosing = () => {
    setCode('');
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={resetCodeWhenClosing}
      fullWidth
      slotProps={{
        backdrop: {
          sx: { backgroundColor: '#FFFFFF', opacity: '0.8 !important' },
        },
      }}
    >
      <CloseButton onClick={resetCodeWhenClosing}>
        <CloseIcon/>
      </CloseButton>
      <DialogTitle>
        {T.title}
      </DialogTitle>
      <DialogContent>
        <CodeInput
          size={CodeInputSize}
          value={code}
          onChange={(newValue) => {
            setCode(newValue);
            if (newValue.length === CodeInputSize) {
              handleSubmit(newValue);
              setCode('');
            }
          }}
        />
      </DialogContent>
      <Button
        onClick={() => {
          setCode('');
          const promise = handleResendPromise();
          globalMessage.handlePromise(promise, {
            onSuccess: (res) => {
              if (res)
                globalMessage.display(T.successfullCodeResent, { severity: 'success' });
              else
                globalMessage.display(T.phoneAlreadyConfirmed, { severity: 'error' });
            },
            successMessage: null,
            errorMessage: (error) =>  {
              return T.handleFormSubmissionErrors(error);
            },
          });
        }}
      >
        {T.actions.sendNewCode}
      </Button>
    </Dialog>
  );
};

export default ConfirmPhoneDialog;
