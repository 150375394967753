import * as React from 'react';
import { styled } from '@mui/material';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';

const StyledFilterItem = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  ...(isMobile ? {
    height: '100%',
    padding: '6px 0',
  } : {
    height: '100%',
    boxShadow: '6.5px 0px 0px -6px #C6C6C6, -6.5px 0px 0px -6px #C6C6C6',
  }),
}));

const FilterItem = React.memo((props: { children: React.ReactNode, isSelected?: boolean }) => {
  const isMobile = useMediaQueryMobile();
  return (
    <StyledFilterItem isMobile={isMobile}>
      {props.children}
    </StyledFilterItem>
  );
});

export default FilterItem;
