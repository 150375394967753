import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { Agency, AgencyMeeting } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';
import { getDateParts } from '@repo-lib/utils-date-tz';
import type { VerifyPhoneArgs } from './api/VerifyPhone';
import type { ConfirmMeetingArgs } from './api/ConfirmMeeting';
import type { CreateMeetingArgs } from './api/CreateMeeting';

import { observable, action } from 'mobx';
import { indexMultipleArrayItems } from '@repo-lib/utils-core';
import { Fetchable } from "@repo-lib/utils-mobx-store";
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import type { BreteuilWebsiteEnv } from '@breteuil-website/store/ui/env';
import {
  VerifyPhone,
  resendPhoneVerifyCode,
  PrepareSchedule,
  ConfirmMeeting,
  CreateMeeting,
  AgencyMeetingVerifyPhone,
  AgencyMeetingResendVerifyCode,
  CreateDetailedSimplePropertyValuation,
  ClosestAgency,
} from './api';
import { QueryStore } from '@repo-breteuil/front-store-query';

interface MeetingSlot {
  date: number,
  locked: boolean,
}

type VerifyValuationArgs = Omit<VerifyPhoneArgs, 'valuationId'>;

function getParsedDateDayKey(
  date: {
    year: number,
    month: number,
    day: number,
  },
)
{
  const { year, month, day } = date;
  return `${year}-${month}-${day}`;
}

function getDateDayKey(
  date: Date | number/*Timestamp*/,
  opts: {
    timezone?: string | undefined, //Default: UTC
  } = {},
)
{
  return getParsedDateDayKey(getDateParts(date, opts));
}

export class ValuationStore {
  constructor(
    public stores: {
      query: QueryStore,
      env: BreteuilWebsiteEnv,
    },
  )
  {
  }

  // TODO make this a query
  public citiesKeys = [
    'paris',
    'london',
    'newYork',
    'deauville',
    'dinard',
    'laBaule',
    'lisbon',
    'biarritz',
    'saintJeanDeLuz',
  ];

  public resendVerifyCode = new Fetchable(
    (args: { id: IdType | undefined }) => {
      if (!args.id)
        throw new Error('Cannot re-send code for undefined valuation');
      return resendPhoneVerifyCode(this.stores.query, { valuationId: args.id });
    }, {
    catchUnhandled: handleNonCriticalError,
  });

  @observable private _selectedCityDefaultValue: string | null = null;

  public get selectedCityDefaultValue()
  {
    return this._selectedCityDefaultValue;
  }

  @action public setSelectedCityDefaultValue(selectedCityDefaultValue: string | null)
  {
    this._selectedCityDefaultValue = selectedCityDefaultValue;
  }

  @observable private _comId: string | undefined = undefined;

  @action public setComId(comId: string | undefined)
  {
    this._comId = comId;
  }

  // Method3
  @observable private _selectedMeetingDate: number | null = null;

  public get selectedMeetingDate()
  {
    return this._selectedMeetingDate;
  }

  @action public setSelectedMeetingDate(selectedMeetingDate: number | null)
  {
    this._selectedMeetingDate = selectedMeetingDate;
  }

  @observable private _selectedDate: Date | null = null;

  public get selectedDate()
  {
    return this._selectedDate;
  }

  @action public setSelectedDate(selectedDate: Date | null)
  {
    this._selectedDate = selectedDate;
  }

  @observable private _agencyMeeting: AgencyMeeting | null = null;

  @action public setAgencyMeeting(agencyMeeting: AgencyMeeting | null)
  {
    this._agencyMeeting = agencyMeeting;
  }

  public get agencyMeeting()
  {
    return this._agencyMeeting;
  }

  @observable private _agency: Agency | null = null;

  @action public setAgency(agency: Agency | null)
  {
    this._agency = agency;
  }

  public get agency()
  {
    return this._agency;
  }

  //This map's key is the a string representing the day of the date ("2023-03-27")
  @observable private _availableMeetings: {
    slots: Map<string, Array<MeetingSlot & {
      parsedDate: ReturnType<typeof getDateParts>,
    }>>,
    timezone?: string,
  } = {
    slots: new Map(),
  };

  @observable private _address: string | null = null;

  @action public async setAddress(address: string | null)
  {
    this._address = address;
    if (address === null)
      return this.resetValuationStore();
    this.setSelectedAddress(address);
  }

  public get address()
  {
    return this._address;
  }

  @observable private _isMeetingConfirmed: boolean = false;

  @action public async setIsMeetingConfirmed(isConfirmed: boolean)
  {
    this._isMeetingConfirmed = isConfirmed;
  }

  public get isMeetingConfirmed()
  {
    return this._isMeetingConfirmed;
  }

  @observable private _verifyPhone: boolean = false;

  @action public async setVerifyPhone(verifyPhone: boolean)
  {
    this._verifyPhone = verifyPhone;
  }

  public get verifyPhone()
  {
    return this._verifyPhone;
  }

  //The timeslots returned by that endpoint are based on this timezone
  public static DefaultAvailableMeetingsTimezone = 'Europe/Paris';

  @action private async setDefaultAvailableMeetings() {
    const meetings = await PrepareSchedule(this.stores.query);
    const timezone = ValuationStore.DefaultAvailableMeetingsTimezone;
    this._availableMeetings = meetings ? {
      slots: indexMultipleArrayItems(
        meetings.map(meeting => ({
          ...meeting,
          parsedDate: getDateParts(meeting.date, { timezone }),
        })),
        ({ parsedDate }) => getParsedDateDayKey(parsedDate),
      ),
      timezone,
    }: {
      slots: new Map(),
    };
  }

  public async init() {
    googleMapsAPI.init(this.stores.env.googleMapsAPIKey);
    this.setAddress(null);
    this.resetValuationStore();
    await this.setDefaultAvailableMeetings();
    this.setSelectedDate(this.getMinDate());
  }

  @action public getMinDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Date + 1 days
    return currentDate;
  }

  public get meetingSlotsTimezone()
  {
    return this._availableMeetings.timezone || 'UTC';
  }

  public getParsedAvailableMeeting(timePeriod: number)
  {
    /**
     * timePeriod
     * 0: Morning 8h-12h
     * 1: Afternoon 13h-17h
     * 2: Evening 18h-21h
     */
    if (!this._selectedDate) {
      return [];
    }

    const selectedDay = this._selectedDate;
    const { slots, timezone } = this._availableMeetings;
    const day = getDateDayKey(selectedDay, { timezone });
    const dayMeetings = slots.get(day);
    if (!dayMeetings)
      return [];
    // get all the meeting for the period
    const availableMeetings = dayMeetings.filter((schedule) => {
      const { hour } = schedule.parsedDate;
      switch (timePeriod) {
        case 0: return hour >= 8 && hour <= 12;
        case 1: return hour >= 13 && hour <= 17;
        case 2: return hour >= 17 && hour <= 21;
        default: return false;
      }
    });

    return availableMeetings;
  }

  @action public lockUnavailableMeeting()
  {
    const selectedMeetingDate = this._selectedMeetingDate!;
    const { slots, timezone } = this._availableMeetings;
    const day = getDateDayKey(selectedMeetingDate, { timezone });
    const dayMeetings = slots.get(day);
    if (!dayMeetings)
      return;
    for (const meeting of dayMeetings)
    {
      if (meeting.date === selectedMeetingDate)
      {
        meeting.locked = true;
        break;
      }
    }
  }

  public confirmMeeting = new Fetchable((args: ConfirmMeetingArgs) => ConfirmMeeting(this.stores.query, args), {
    catchUnhandled: handleNonCriticalError,
  });

  public createMeeting = new Fetchable(async (args: CreateMeetingArgs) => {
    const meeting = await CreateMeeting(this.stores.query, { ...args, communicationIdentifier: this._comId });
    this.setAgency(meeting.agency);
    this.setAgencyMeeting(meeting.agencyMeeting);
    this.setVerifyPhone(meeting.verifyPhone);
  }, {
    catchUnhandled: handleNonCriticalError,
  });


  async verifyConfirmMeeting()
  {
    if (!this._agencyMeeting || !this._verificationCode)
      throw new Error('Tried to verify undefined valuation');
    const res = await AgencyMeetingVerifyPhone(this.stores.query, {
      agencyMeetingId: this._agencyMeeting.id,
      phoneVerifyCode: this._verificationCode,
    });
    if (res === null)
      throw new Error('The phone code verification has failed');
    this.confirmMeeting.setResult(res);
  }

  public agencyMeetingResendVerifyCode = new Fetchable(
    () => {
      if (!this._agencyMeeting)
        throw new Error('There is no agencyMeetingId'); // This should never happen
      return AgencyMeetingResendVerifyCode(this.stores.query, { agencyMeetingId: this._agencyMeeting.id });
    }, {
    catchUnhandled: handleNonCriticalError,
  });

  @observable private _verificationCode: string = '';

  @action public async setVerificationCode(verificationCode: string)
  {
    this._verificationCode = verificationCode;
  }

  @action resetValuationStore()
  {
    this.setAgency(null);
    this.setSelectedDate(null);
    this.setAgencyMeeting(null);
    this.setIsMeetingConfirmed(false);
    this.setSelectedMeetingDate(null);
    this.setSelectedAddress(null);
  }

  // Method 2
  public detailedValuation = new Fetchable(
    (args) => CreateDetailedSimplePropertyValuation(this.stores.query, {...args, communicationIdentifier: this._comId }),
    { catchUnhandled: handleNonCriticalError },
  );

  async verifyDetailedValuation(args: VerifyValuationArgs)
  {
    if (!this.detailedValuation.result)
      throw new Error('Tried to verify undefined valuation');
    const res = await VerifyPhone(this.stores.query, {
      valuationId: this.detailedValuation.result.id,
      phoneVerifyCode: args.phoneVerifyCode,
    });
    if (res === null)
      throw new Error('The phone code verification has failed');
    this.detailedValuation.setResult(res);
  }

  public startsWithNumber(value: string) {
    return /^\d+/.test(value);
  }

  public closestAgency = new Fetchable(
    (args: { address: string, language: Language }) => (
      !args.address ?
        Promise.resolve(null)
        : ClosestAgency(this.stores.query, args)
    ), {
    catchUnhandled: handleNonCriticalError,
  });

  @observable private _selectedAddress: string | null = null;

  public get selectedAddress()
  {
    return this._selectedAddress;
  }

  @action public setSelectedAddress(selectedAddress: string | null)
  {
    this._selectedAddress = selectedAddress;
  }

  // Select city

  @observable private _isParisSelected: boolean = true;

  @action public setIsParisSelected(isParisSelected: boolean)
  {
    this._isParisSelected = isParisSelected;
  }

  public get isParisSelected()
  {
    return this._isParisSelected;
  }

}
