import * as React from 'react';
import { observer } from "mobx-react";
import { Locale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import texts from './texts';
import { MenuItem, styled } from '@mui/material';
import { getCurrentLocationInOtherLanguage } from '@breteuil-website/store/routing';
import { useWebsiteStores } from '@breteuil-website/components/providers';

interface StyledMenuItemProps {
  active?: boolean,
}

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => (prop !== 'active'),
})<StyledMenuItemProps>(({ active }) => ({
  minHeight: "25px !important",
  height: '25px !important',
  width: '25px !important',
  justifyContent: 'center !important',
  fontFamily: 'Poppins',
  fontSize: '10px !important',
  padding: "13px !important",
  margin: '0px 3px',
  color: '#050251 !important',
  cursor: 'pointer',
  borderRadius: '50% !important',
  border: active ? "2px solid #050251 !important" : "2px solid transparent !important",
  fontWeight: active ? '700 !important' : 400,
  '&:hover': {
    fontWeight: 700,
  },
  '& .MuiInput-root': {
    maxWidth: '50%',
  },
}));

const LanguagePicker = observer(() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  const { locale } = useWebsiteStores();

  const languages: Array<[Locale, string]> = [
    [Locale.French, T.languages.french],
    [Locale.English, T.languages.english],
  ];

  const [activeLanguage, setActiveLanguage] = React.useState<Locale>(locale.locale);

  return (
    <>
      {languages.map(([language, text]) => {
        const isActive = language === activeLanguage;
        return (
          <StyledMenuItem
            key={language}
            value={language}
            active={isActive}
            onClick={() => {
              setActiveLanguage(language);
              const href = getCurrentLocationInOtherLanguage(language);
              if (href) {
                window.location.href = href; // TODO: use ChangeRoute when reloading will be supported.
                if (!isMobile)
                  locale.setLocale(language);
              }
            }}
          >
            {text}
          </StyledMenuItem>
        );
      })}
    </>
  );
});

export default LanguagePicker;
