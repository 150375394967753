import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { Carousel } from '@repo-breteuil/react-components';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import FullscreenButton from './FullscreenButton';
import { useMediaQueryDesktop, useMediaQueryMobile } from '@breteuil-website/components/theme';

const Img = styled('img')({
  width: '100%',
  objectFit: 'cover',
  height: '80vh',
  maxHeight: '100vh',
  '@media (max-width: 960px)': {
    height: `calc(60vh - 50px)`,
  },
  '@media (min-width: 1960px)': {
    height: '85vh',
  },

});

const Container = styled('div')({
  position: 'relative',
});

const CarouselContentContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
});

const FullscreenImg = styled(Img)({
 objectFit: 'contain',
});

const CarouselContent = (props: {
  handleClick: () => void,
  buttonPosition: React.CSSProperties,
  imageHeight?: React.CSSProperties | undefined,
  fullscreen: boolean,
  pictures: Array<string>,
  id: number,
}) => {
  const {
    handleClick,
    buttonPosition,
    imageHeight,
    fullscreen,
    pictures,
    id,
  } = props;
  const ImgComponent = !fullscreen ? Img : FullscreenImg;
  const isMobile = useMediaQueryMobile();
  return (
    <CarouselContentContainer>
      <FullscreenButton
          handleClick={handleClick}
          buttonPosition={buttonPosition}
          fullscreen={fullscreen}
      />
      <Carousel
        key={id}
        slidesPerView={1}
        loop={true}
        draggable={false}
        arrows={true}
        mobile={isMobile}
      >
        {pictures.map((picture, index) => (
          <ImgComponent style={imageHeight || {}} key={index} src={picture}/>
        ))}
      </Carousel>
    </CarouselContentContainer>
  );
};

const PropertyCarousel = observer((props: { property: Property }) => {
  const { id, picturesURL: pictures } = props.property;
  const [ fullscreen, setFullscreen ] = React.useState<boolean>(false);
  const uniqueId: string = Date.now().toString();
  const handleClick = () => setFullscreen(!fullscreen);
  const imageHeight = fullscreen ? { height: '100vh' as const } : undefined;
  const isDesktop = useMediaQueryDesktop();
  const buttonPosition = { bottom: isDesktop ? '30px' : '15px' };
  return (
    <Container id={uniqueId}>
      {fullscreen ? (
        <Dialog
          open={fullscreen}
          onClose={handleClick}
          fullScreen
          sx={{ zIndex: 99999 }}
          transitionDuration={0}
        >
          <CarouselContent
            handleClick={handleClick}
            buttonPosition={buttonPosition}
            imageHeight={imageHeight}
            fullscreen={fullscreen}
            pictures={pictures}
            id={id}
          />
        </Dialog>
      ) : (
        <CarouselContent
          handleClick={handleClick}
          buttonPosition={buttonPosition}
          imageHeight={imageHeight}
          fullscreen={fullscreen}
          pictures={pictures}
          id={id}
        />
      )}
    </Container>
  );
});

export default PropertyCarousel;
