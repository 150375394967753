import type { Currency } from '@repo-lib/utils-texts';
import { OperationType, PropertyType } from '@repo-breteuil/common-definitions';
import { number as numberTexts } from '@repo-breteuil/common-texts';

const OperationTypeToText = new Map<OperationType, string>([
  [OperationType.Rental, ' for rent'],
  [OperationType.SeasonalRental, ' for rent'],
  [OperationType.ResidencyTransaction, ' for sale'],
]);

const PropertyTypeToText = new Map<PropertyType, string>([
  [PropertyType.Apartment, 'Apartment'],
  [PropertyType.House, 'House'],
  [PropertyType.Loft, 'Loft'],
]);

export default {
  exclusive: 'Exclusive',
  sold(operationType: OperationType): string
  {
    return operationType === OperationType.ResidencyTransaction ? "Sold" : "Rented";
  },
  offer: 'Offer',
  favoriteDeletionConfirmation: 'Remove this property from your favorites ?',
  yes: 'Yes',
  no: 'No',
  favoriteDeletionToast: 'Property removed from your favorites',
  favoriteAddToast: 'Property added to your favorites',
  favoriteError: 'There has been an error, please try again later.',
  clientAreaPropertyModalTitle: 'Create an account to add this property to your favorites',
  loginText: 'Already registered ?',
  connect: 'Log-in',
  formatBedrooms(bedrooms: number): string
  {
    return `${bedrooms} ${bedrooms > 1 ? "Bedrooms" : "Bedroom"}`;
  },
  formatGuests(guests: number): string
  {
    return `${guests} ${guests > 1 ? "Guests" : "Guest"}`;
  },
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string
  {
    const start = "From";
    const middleEndOnly = "Up to";
    const fromWithUnit = min ? numberTexts.EN.formatNumber(min, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    const toWithUnit = max ? numberTexts.EN.formatNumber(max, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    if (min) {
      return `${start} ${fromWithUnit}`;
    }
    else if (!min && max) {
      return `${middleEndOnly} ${toWithUnit}`;
    }
    else {
      return '';
    }
  },
  formatTransactionInfos: (operationType?: OperationType, propertyType?: PropertyType, district?: string): string | null => {
    const formattedOpertionType = operationType ? OperationTypeToText.get(operationType) : null;
    const formattedPropertyType = propertyType && PropertyTypeToText.get(propertyType) ? PropertyTypeToText.get(propertyType) : "Property";
    const formattedDistrict = district ? `, ${district}` : '';
    return [formattedPropertyType, formattedOpertionType, formattedDistrict].filter(val => Boolean(val)).join('');
  },
};
