import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';

const AccordionSummaryWithBorder = styled(AccordionSummary)({
  borderBottom: '1px solid #050251',
  "@media (max-width: 1280px)": {
    padding: '0 !important',
  },
});

const AccordionDetailsWithPadding = styled(AccordionDetails)({
  paddingTop: '30px !important',
  "@media (max-width: 1280px)": {
    padding: '20px 0 0 0 !important',
  },
});

export interface DropdownWithTitleProps  {
  title: string,
  icon?: JSX.Element,
  defaultExpended?: boolean,
  titleStyle?: {
    fontSize: string,
    fontWeight: string,
    fontFamily: string,
    color: string,
  },
  titleMobileStyle?: {
    fontSize: string,
    fontWeight: string,
    fontFamily: string,
    color: string,
  },
}

const DropdownWithTitle = (props: React.PropsWithChildren<DropdownWithTitleProps>) => {
  const {
    title,
    icon = <ExpandMore/>,
    defaultExpended = true,
    titleStyle = {
      fontFamily: 'Cormorant',
      fontSize: '40px',
      fontWeight: 700,
      color: '#050251',
    },
    titleMobileStyle = {
      fontFamily: 'Cormorant',
      fontSize: '26px',
      fontWeight: 700,
      color: '#050251',
    },
    children,
  } = props;
  const isDesktop = useMediaQueryDesktop();

  return (
    <Accordion
      elevation={0}
      defaultExpanded={defaultExpended}
    >
      <AccordionSummaryWithBorder
        expandIcon={icon}
        aria-controls="key-info-content"
        id="key-info-header"
      >
        <Typography component='span' sx={isDesktop ? {...titleStyle} : {...titleMobileStyle}}>
          {title}
        </Typography>
      </AccordionSummaryWithBorder>
      <AccordionDetailsWithPadding>
        {children}
      </AccordionDetailsWithPadding>
    </Accordion>
  );
};

export default DropdownWithTitle;
