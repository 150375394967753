import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {
  MailOutline as MailOutlineIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { useTexts } from '@repo-breteuil/common-texts';
import BreteuilWebsitePage from '@breteuil-website/components/common/app/Page';
import { PageMeta } from '@breteuil-website/components/common';
import texts from './texts';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,
  margin: 'auto',
  padding: '4vh 2vw',
  height: 'calc(100vh - 90px)',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: '2vh 4vw',
    height: 'calc(100vh - 50px)',
  },
}));

const Header = styled('div')(({ theme }) => ({
  width: "41%",
  fontFamily: "Mulish",
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    margin: "auto",
  },
}));

const InfoBox = styled('div')({
  backgroundColor: '#F5F8FF',
  padding: '30px',
  borderRadius: '8px',
  marginBottom: '30px',
});

const Description = styled('div')(({ theme }) => ({
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "30px",
  color: theme.palette.secondary.main,
  marginBottom: '30px',
}));

const ContactInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

const ContactItem = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  color: theme.palette.primary.dark,
  fontSize: '16px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface PropertyAccessRequestProps
{
  contact: {
    email: string,
    phone: string | null,
  },
}

const PropertyAccessRequest = observer((props: PropertyAccessRequestProps) => {
  const { contact } = props;
  const T = useTexts(texts);

  return (
    <BreteuilWebsitePage>
      <PageMeta
        title={T.propertyAccessRequest.title}
        description={T.propertyAccessRequest.description || ""}
      />
      <Container>
        <Header>
          <InfoBox>
            <Description>
              {T.propertyAccessRequest.description}
            </Description>

            <ContactInfo>
              <ContactItem href={`mailto:${contact.email}`}>
                <MailOutlineIcon sx={{ width: 20, height: 20 }}/>
                {contact.email}
              </ContactItem>

              {contact.phone && (
                <ContactItem href={`tel:${contact.phone}`}>
                  <PhoneIcon sx={{ width: 20, height: 20 }}/>
                  {contact.phone}
                </ContactItem>
              )}
            </ContactInfo>
          </InfoBox>
        </Header>
      </Container>
    </BreteuilWebsitePage>
  );
});

export default PropertyAccessRequest;
