import { styled } from '@mui/material/styles';
import {
  Button as GenericButton,
} from '@mui/material';

export const Button = styled(GenericButton)({
  minHeight: '42px',
  width: '100%',
  padding: '10px 28px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '15px',
  border: 0,
  borderRadius: '0px',
  boxShadow: 'none',
  backgroundColor: '#050251',
  '&:hover': {
    boxShadow: 'none !important',
  },
});

Button.defaultProps = { variant: 'contained', disableFocusRipple: true };
