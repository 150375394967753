import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Button } from '@breteuil-website/components/common';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from './texts';


const BannerContainer = styled('div')({
    width: '90%',
    margin: 'auto',
    minHeight: '750px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1500px',
   "@media (max-width: 960px)": {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
      },
});

const ServiceImageWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
      },
});

const ServiceImage = styled('img')({
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    "@media (max-width: 960px)": {
        maxWidth: '350px',
    },
});


const ServiceTextWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
      },
});

const ServiceText = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    margin: 'auto 0 auto auto',
    "@media (max-width: 960px)": {
        width: '80%',
        margin: '25px auto',
      },
});

const ServiceTextTitle = styled('span')({
    fontSize: '30px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '45px',
    color: '#050251',
    '@media (max-width: 960px)': {
        fontSize: '24px',
        lineHeight: '30px',
    },
});

const ServiceTextDescription = styled('span')({
    fontSize: '14px !important',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#050251',
    margin: '30px 0',
});

const ServiceTextButton = styled(Button)({
    maxWidth: "200px",
    fontSize: '12px !important',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    fontWeight: 700,
    backgroundColor: '#050251 !important',
});

const Services = observer(() => {
    const T = useTexts(texts).services;
    const lang = useLocale();
    return (
        <BannerContainer>
            <ServiceImageWrapper>
                <ServiceImage src={"/assets/asset_services.png"}/>
            </ServiceImageWrapper>
            <ServiceTextWrapper>
                <ServiceText>
                    <ServiceTextTitle>{T.title}</ServiceTextTitle>
                    <ServiceTextDescription>{T.description}</ServiceTextDescription>
                    <Link to={Routes.services.generateStaticPath({ lang })}>
                    <ServiceTextButton>
                            {T.button}
                    </ServiceTextButton>
                    </Link>
                </ServiceText>
            </ServiceTextWrapper>
        </BannerContainer>
    );
});

export default Services;
