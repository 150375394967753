export default {
  title: 'Réinitialiser votre mot de passe',
  newPassword: 'Nouveau mot de passe',
  confirmPassword: 'Confirmer le nouveau mot de passe',
  passwordStrength: 'Sécurité du mot de passe',
  modifyPassword: 'Modifier le mot de passe',
  successNotif: 'Votre mot de passe a bien été modifié',
  errorNotif: 'Une erreur est survenue. Réessayez plus tard',
  email: 'E-mail',
  text: 'Ce lien semble erroné. Remplissez le champ ci-dessous pour recevoir un nouveau lien de réinitialisation de mot de passe.',
  resendEmail: 'Recevoir un nouveau lien',
  emailSent: 'Un nouveau lien vous a été envoyé, veuillez consulter votre boite mail.',
  passwordsDontMatch: 'Les mots de passe ne sont pas identiques.',
};
