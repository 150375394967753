import type { AlertsFrequency } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';
import type { Profile } from './shared';

import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';
import { profileFields } from './shared';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface UpdateAccountArgs
{
  firstname?: string,
  lastname?: string,
  email?: string,
  mobilePhone?: string,
  password?: string,
  address?: string,
  postalCode?: string,
  city?: string,
  language?: Language,
  optIn?: boolean,
  newsletterOptIn?: boolean,
  alertsFrequency?: AlertsFrequency,
}

export async function UpdateAccount(
  qs: QueryStore,
  args: UpdateAccountArgs,
): Promise<ClientAreaSession<Profile>>
{
  return qs.mutation({
    operationName: 'UpdateAccount',
    variables: {
      firstname: 'String',
      lastname: 'String',
      mobilePhone: 'String',
      address: 'String',
      postalCode: 'String',
      city: 'String',
      language: 'Language',
      optIn: 'Boolean',
      newsletterOptIn: 'Boolean',
      alertsFrequency: 'MyBreteuilAlertsFrequency',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            updateAccount: [{
              args: {
                firstname: true,
                lastname: true,
                mobilePhone: true,
                address: true,
                postalCode: true,
                city: true,
                language: true,
                optIn: true,
                newsletterOptIn: true,
                alertsFrequency: true,
              },
            }, (
              clientAreaSessionResult(profileFields)
            )],
          },
        },
      },
    },
  }, args);
}
