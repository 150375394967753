import { QueryStore } from '@repo-breteuil/front-store-query';

export async function SendLogInCode(
  qs: QueryStore,
  args: {
    email: string,
  },
): Promise<boolean>
{
  return qs.mutation({
    operationName: 'SendLogInCode',
    variables: {
      email: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            sendLogInCode: [{
              args: {
                email: true,
              },
            }, true],
          },
        },
      },
    },
  }, args);
}
