
import * as React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({
  display: "flex",
  width: '100%',
  '& > iframe': {
    width: '100vw',
    height: 'calc(100vw / 1.78)', // 1.78:1 is the 4K (3840/2160) aspect ratio
  },
}));

export interface VimeoPlayerProps {
  html: string,
};

export const VimeoPlayer = (props: VimeoPlayerProps) => {
  const { html } = props;
  return (
    <Container dangerouslySetInnerHTML={{ __html: html }}/>
  );
};
