import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router, { Routes } from '@breteuil-website/store/routing';
import {
  Button as GenericButton,
  TextField as GenericTextField,
} from '@breteuil-website/components/common';
import { EmailValidation } from '@my-breteuil/store/utils/form-field';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const TextField = styled(GenericTextField)({
  '&:first-of-type': {
    marginBottom: '1rem',
  },
  '& .MuiInputBase-root': {
    color: '#989898',
    fontFamily: 'Mulish',
    fontSize: '13px',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
});

const Button = styled(GenericButton)({
  marginTop: '1.25rem',
});

const LogInWithEmail = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);
  const { logIn, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    email: createValueFormField('', { validation: EmailValidation }),
  }), {
    onSubmit: (values) => {
      logIn.setUserEmail(values.email);
      const promise = logIn.isEmailUsedForClientArea.ensureSuccessReload(values);
      globalMessage.handlePromise(promise, {
        onSuccess(res) {
          if (!res)
            router.changeRoute(Routes.clientAreaSignUp.generateStaticPath({ lang }));
        },
        successMessage: null,
        errorMessage: null,
      });
    },
  });
  return(
    <React.Fragment>
      <form onSubmit={form.handleSubmit}>
        <TextField
          field={form.fields.email}
          label={T.email}
        />
        <Button
          type='submit'
          disabled={form.submitting}
        >
          {T.send}
        </Button>
      </form>
    </React.Fragment>
  );
});

export default LogInWithEmail;
