export default {
  error404: {
    title: 'Page not found',
    subtitle: `Nous sommes désolés,
    ce lien ne fonctionne plus.`,// TODO
    pictureText: `Nous n’avons pas trouvé la page que vous recherchiez.
    Parfois, un petit chien mignon peut consoler.`,// TODO
    pictureAlt: 'Dog', //TODO
  },
  fatal: {
    title: 'Error', //TODO
    subtitle: 'Ouch.', //TODO
    pictureText: `Nous avons rencontré un problème grave.
    Nous vous recommandons de relancer votre navigateur.`, //TODO
    pictureAlt: 'Hedgehog', //TODO
  },
};
