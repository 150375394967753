import type { IdType } from '@repo-breteuil/common-definitions';
import type { Search } from '@breteuil-website/store/ui/pages/client-area/searches/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import {
  useForm,
  createValueFormField,
  createFormFieldToggle,
  useFormFieldSelectMultiple,
  useFormFieldSelectMultipleAsync,
  useFormFieldSelect,
} from '@repo-lib/mobx-forms';
import {
  ensureFetchableResource,
  assertAPIDataConsistency,
} from '@repo-breteuil/front-error';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useFormFieldTextInputIntNullable } from '@repo-breteuil/react-components';
import { OperationType } from '@repo-breteuil/common-definitions';
import router from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import { searchLimit } from '@breteuil-website/_hardcoded';
import texts from './texts';
import { SelectMultiple as GenericSelectMultiple } from '@breteuil-website/components/common';
import {
  Button as GenericButton,
  TextField,
  Select,
} from '@breteuil-website/components/common/elements/client-area';
import CriteriaSelect from './CriteriaSelect';
import DeleteButton from './DeleteButton';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  border: '2px solid #050251',
  display: 'grid',
  rowGap: '17px',
  padding: '40px',
  '@media (max-width: 1280px)': {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 14px',
    rowGap: '21px',
    border: 0,
    backgroundColor: '#F9F9F9',
  },
});

const SearchLimitWarning = styled('div')({
  color: '#FF0000',
  fontSize: '14px',
  margin: '0px auto 10px auto',
});

const SelectMultiple = styled(GenericSelectMultiple)({
  '& .MuiInputBase-root': {
    color: '#050251',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Mulish',
    fontSize: '15px',
    fontWeight: 700,
    height: '56px',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
  '& .MuiIconButton-root': {
    color: '#0C17E1',
  },
});

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
  justifyContent: 'center',
  '@media (max-width: 1280px)': {
    columnGap: '3px',
    rowGap: '21px',
    flexDirection: 'column',
  },
});

const Button = styled(GenericButton)({
  width: 'unset',
  '@media (max-width: 1280px)': {
    width: '100%',
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '5px',
});

const TopFilters = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '3px',
  justifyContent: 'center',
  '@media (max-width: 1280px)': {
    width: '100%',
    flexDirection: 'column',
    rowGap: '21px',
  },
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '3px',
  justifyContent: 'center',
  '@media (max-width: 1280px)': {
    width: '100%',
    rowGap: '21px',
  },
});

const Form = observer((props: { search: Search | null }) => {
  const { areas, searches, globalMessage } = useWebsiteStores();
  const { filtersAreas: filtersAreas_, geoAreaIdsPerSubAreaId } = ensureFetchableResource(areas.areas);
  const filtersAreas = filtersAreas_.get(null) || [];
  assertAPIDataConsistency(filtersAreas.length > 0, "AreasStore.filtersAreas shouldn't be empty");

  const { search } = props;
  const locale = useLocale();
  const lang = () => locale;
  const T = useTexts(texts);
  const searchesNumber = searches.searches.lastResult === undefined ? 0 : searches.searches.lastResult.length;
  const searchesLimitReached = (searchesNumber >= searchLimit);
  const maxSearchesDisable = (search === null) && searchesLimitReached;
  const operationType = useFormFieldSelect<OperationType>({
    options: () => new SelectOptions(new Map([
      [ OperationType.ResidencyTransaction, T.operations.buy ],
      [ OperationType.Rental, T.operations.rent ],
    ])),
    defaultValue: () => search ? search.operationType : OperationType.ResidencyTransaction,
  });

  const area = useFormFieldSelect<IdType>({
    options: () => new SelectOptions(new Map(filtersAreas.map(({ area }) => (
      [ area.id, area.name ]
    )))),
    defaultValue: () => search ? (
      search.area.id
    ) : (
      filtersAreas[0].area.id
    ),
  }, [ filtersAreas ]);

  function getCurrentArea()
  {
    const areaId = area.value;
    const areaWithSubAreas = filtersAreas.find(({ area }) => (area.id === areaId));
    if (!areaWithSubAreas)
      throw new Error(`Assertion failed: couldn't find Area#${areaId}`);
    return areaWithSubAreas;
  }

  const currentArea = React.useMemo(
    getCurrentArea,
    [ area.value, filtersAreas ],
  );

  const subAreas = useFormFieldSelectMultiple({
    options: () => new SelectOptions<IdType>(
      new Map(currentArea.subAreas.map(subArea => (
        [ subArea.id, subArea.name ]
      ))),
    ),
    defaultValue: () => search ? (
      search.subAreas.data.edges.map(({ node: subArea }) => subArea.id)
    ) : (
      []
    ),
    validation: ((value) => value.length > 0),
  }, [ currentArea ]);

  const criteria = useFormFieldSelectMultipleAsync({
    fetchable: searches.criteria.value,
    formatOptions: ({ criteria }) => (
      new SelectOptions<IdType>(
        new Map(criteria.map(item => (
          [ item.id, item.name ]
        ))),
      )
    ),
    default: {
      options: () => (
        new SelectOptions<IdType>(new Map((search?.criteria || []).map((criteria) => (
          [ criteria.id, criteria.name ]
        ))))
      ),
      value: () => (search?.criteria || []).map(({ id }) => id),
    },
    preserveValue: true,
    mutuallyExclusiveOptions: ({ criteriaGroups }) => (
      criteriaGroups.map(group => (
        group.map(item => item.id)
      ))
    ),
  }, [ currentArea ]);

  React.useEffect(
    () => {
      searches.criteria.reload({
        areaId: area.value,
        operationType: operationType.value,
      });
    },
    [ operationType.value, area.value ],
  );

  const budgetMin = useFormFieldTextInputIntNullable(() => search === null ? null : search.budgetMin,
    { lang, validation: (value) => value === null || value >= 0 },
  );
  const budgetMax = useFormFieldTextInputIntNullable(() => search === null ? null : search.budgetMax,
    { lang, validation:
      (value) => (
        ( value !== null ?
          ( budgetMin.value !== null ?
            ( value >= budgetMin.value ) :
            ( value >= 0 )
          ) : value === null
        )
      ),
    },
  );
  const surfaceMin = useFormFieldTextInputIntNullable(() => search === null ? null : search.surfaceMin,
    { lang, validation: (value) => value === null || value >= 0 },
  );
  const surfaceMax = useFormFieldTextInputIntNullable(() => search === null ? null : search.surfaceMax,
    { lang, validation:
      (value) => (
        ( value !== null ?
          ( surfaceMin.value !== null ?
            ( value >= surfaceMin.value ) :
            ( value >= 0 )
          ) : value === null
        )
      ),
    },
  );
  const bedroomsMin = useFormFieldTextInputIntNullable(() => search === null ? null : search.bedroomsMin,
    { lang, validation: (value) => value === null || value >= 0 },
  );
  const bedroomsMax = useFormFieldTextInputIntNullable(() => search === null ? null : search.bedroomsMax,
    { lang, validation:
      (value) => (
        ( value !== null ?
          ( bedroomsMin.value !== null ?
            ( value >= bedroomsMin.value ) :
            ( value >= 0 )
          ) : value === null
        )
      ),
    },
  );

  const form = useForm(() => ({
    label: createValueFormField<string>(search === null ? '' : search.label),
    budgetMin,
    budgetMax,
    surfaceMin,
    surfaceMax,
    bedroomsMin,
    bedroomsMax,
    areaId: area,
    criteriaIds: criteria,
    subAreasIds: subAreas,
    operationType,
    notificationEmail: createFormFieldToggle(search === null ? false : search.notificationEmail),
  }), {
    onSubmit: ({ subAreasIds, ...values }) => {
      const geoAreasIds = subAreasIds.map(subAreaId => geoAreaIdsPerSubAreaId.get(subAreaId)!);
      if(search === null)
      {
        const createArgs = { ...values, geoAreasIds };
        const promise = searches.createSearch(createArgs);
        return globalMessage.handlePromise(promise, {
          successMessage: () => T.searchCreationSuccessMessage,
          errorMessage: () => T.searchCreationErrorMessage,
          onSuccess: (search) => router.changeRoute(Routes.clientAreaSearch.generatePath({searchId: search.id}, { lang: locale })),
          onError: (error) => console.log(error),
        });
      }
      const updateArgs = {id: search.id, ...values, geoAreasIds};
      const promise = searches.updateSearch(updateArgs);
      return globalMessage.handlePromise(promise, {
        successMessage: () => T.searchUpdateSuccessMessage,
        errorMessage: () => T.searchUpdateErrorMessage,
      });
    },
    resetOnSubmitSuccess: search === null,
    defaultValuesAutoResetDeps: [ search ],
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        {maxSearchesDisable ? (
          <SearchLimitWarning>
            {T.searchLimitReached}
          </SearchLimitWarning>
        ) : null}
        <TopFilters>
          <Select
            field={operationType}
            label={T.operation}
            disabled={maxSearchesDisable}
          />
          <Select
            field={area}
            label={T.city}
            disabled={maxSearchesDisable}
          />
        </TopFilters>
        <SelectMultiple
          field={subAreas}
          label={T.districts}
          disabled={maxSearchesDisable}
        />
        <RowContainer>
          <Row>
            <TextField
              field={form.fields.budgetMin}
              label={T.budget}
              placeholder={T.min}
              InputLabelProps={{ shrink: true }}
              disabled={maxSearchesDisable}
            />
            <TextField
              field={form.fields.budgetMax}
              placeholder={T.max}
              disabled={maxSearchesDisable}
            />
          </Row>
          <Row>
            <TextField
              field={form.fields.surfaceMin}
              label={T.surface}
              placeholder={T.min}
              InputLabelProps={{ shrink: true }}
              disabled={maxSearchesDisable}
            />
            <TextField
              field={form.fields.surfaceMax}
              placeholder={T.max}
              disabled={maxSearchesDisable}
            />
          </Row>
          <Row>
            <TextField
              field={form.fields.bedroomsMin}
              label={T.bedrooms}
              placeholder={T.min}
              InputLabelProps={{ shrink: true }}
              disabled={maxSearchesDisable}
            />
            <TextField
              field={form.fields.bedroomsMax}
              placeholder={T.max}
              disabled={maxSearchesDisable}
            />
          </Row>
        </RowContainer>
        {(searches.criteria.value.status === 'success') && !maxSearchesDisable ? (
          <CriteriaSelect field={criteria} criteriaGroups={searches.criteria.value.result.criteriaGroups}/>
        ) : null}
        <ButtonContainer>
          {search !== null ? <DeleteButton searchId={search.id}/> : null}
          <Button
            type='submit'
            disabled={ form.submitting || maxSearchesDisable }
          >
            {search === null ? T.addAlert : T.updateAlert}
          </Button>
        </ButtonContainer>
      </Container>
    </form>
  );
});

export default Form;
