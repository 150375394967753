import type { IdType } from '@repo-breteuil/common-definitions';
import type { Agent } from '@breteuil-website/_hardcoded';
import type { Agency } from '@breteuil-website/store/ui/common/agencies';

import { observable, action } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { ensureFetchableResource, handleNonCriticalError } from '@repo-breteuil/front-error';
import { type QueryStore, wrapStoreQuery } from '@repo-breteuil/front-store-query';
import type { BreteuilWebsiteEnv } from '@breteuil-website/store/ui/env';
import type { AgenciesStore } from '@breteuil-website/store/ui/common/agencies';
import { GetAgency } from './api';

export { Agency, Agent };

export class AgencyStore
{
  constructor(
    public stores: {
      query: QueryStore,
      agencies: AgenciesStore,
      env: BreteuilWebsiteEnv,
    },
  )
  {
  }

  @observable private _currentAgencyHardcoded: Agency | null = null;

  @action public setCurrentAgencyHardcoded(currentAgencyHardcoded: Agency | null)
  {
    this._currentAgencyHardcoded = currentAgencyHardcoded;
  }

  public get currentAgencyHardcoded()
  {
    return this._currentAgencyHardcoded;
  }

  public get agencies(): Array<Agency>
    {
      return ensureFetchableResource(this.stores.agencies.agencies);
    }

  public agency = new Fetchable(wrapStoreQuery(this, GetAgency), { catchUnhandled: handleNonCriticalError });

  @action public refresh(agencyId: IdType)
  {
    this.agency.ensureSuccessReload({ id: agencyId });
  }

}
