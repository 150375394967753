import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';
import type { Profile } from './shared';

import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';
import { profileFields } from './shared';
import { QueryStore } from '@repo-breteuil/front-store-query';

export async function GetProfileData(qs: QueryStore): Promise<ClientAreaSession<Profile> | null>
{
  return qs.query({
    operationName: 'GetProfileData',
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          session: clientAreaSessionResult(profileFields),
        },
      },
    },
  });
}
