import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedRoute } from '@breteuil-website/store/routing/translatedRoutes';

const Areas = [
  'paris',
  'londres',
  'london',
  'new-york',
  'st-jean',
  'sao-joao',
  'san-juan',
  'biarritz',
  'lisbonne',
  'dinard',
  'la-baule',
  'deauville',
];

export const compatBuyArea = new TranslatedRoute({
  [Locale.French]: '/[areaSlug]',
  [Locale.English]: '/[areaSlug]',
}, {
  match: ({ areaSlug }) => (
    Areas.includes(areaSlug)
  ),
});
