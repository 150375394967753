import { styled } from '@mui/material';
import { Select as GenericSelect } from '@breteuil-website/components/common/elements';

export const Select = styled(GenericSelect)({
  '& .MuiInputBase-root': {
    color: '#050251',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Mulish',
    fontSize: '15px',
    fontWeight: 700,
    height: '56px',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
  '& .MuiIconButton-root': {
    color: '#0C17E1',
  },
});
