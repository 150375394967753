import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

export const propertyField: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true }}, true ],
  title: [{ args: { language: true }}, true ],
  mainPictureURL: [{ args: { format: true }}, true ],
  picturesURL: [{ args: { format: true }}, true ],
  price: true,
  priceMax: true,
  bedrooms: true,
  currency: true,
  operationType: true,
  propertyType: true,
  exclusive: true,
  displayedExclusive: true,
  currentUserFavorite: true,
  status: true,
  displayedStatus: true,
  area: {
    id: true,
    slug: [{ args: { language: true } }, true],
    rentalPeriod: true,
  },
  subArea: {
    id: true,
    slug: [{ args: { language: true }}, true ],
    name: [{ args: { language: true }}, true ],
  },
  priceOnRequest: true,
};
