import * as React from 'react';
import LinkList from '@breteuil-website/components/common/app/LinkList';
import { Routes } from '@breteuil-website/store/routing';
import { Box, styled } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import LanguagePicker from '../../LanguagePicker';
import texts from '../../texts';
import { SocialMedias } from '@breteuil-website/components/common';
import { observer } from 'mobx-react';

const StyledLinkList = styled(LinkList)({
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '35px',
  color: '#050251',
  paddingBottom: '30px',
});

const LanguagePickerWrapper = styled('div')({
  width: '70px',
  display: 'flex',
  flexDirection: 'row',
  margin: '60px 0 30px -10px',
  justifyContent: 'space-between',
});

interface DrawerContentProps {
  handleClose: () => void,
}

const DrawerContent = observer((props: DrawerContentProps) => {
  const lang = useLocale();
  const T = useTexts(texts);

  const drawerLinks = [
    {
      text: T.buy,
      to: Routes.buy.generateStaticPath({ lang }),
    },
    {
      text: T.rent,
      to: Routes.rent.generateStaticPath({ lang }),
    },
    {
      text: T.seasonalRental,
      to: Routes.seasonalRent.generateStaticPath({ lang }),
    },
    {
      text: T.services,
      to: Routes.services.generateStaticPath({ lang }),
    },
    {
      text: T.valuation,
      to: Routes.valuation.generateStaticPath({ lang }),
    },
    {
      text: T.contact,
      to: Routes.contact.generateStaticPath({ lang }),
    },
    {
      text: T.joinUs,
      to: Routes.joinUs.generateStaticPath({ lang }),
    },
  ];

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      role="presentation"
      onKeyDown={props.handleClose}
    >
      <Box sx={{ margin: '0 auto' }}>
        <LanguagePickerWrapper>
          <LanguagePicker/>
        </LanguagePickerWrapper>
        <StyledLinkList elements={drawerLinks}/>
        <SocialMedias alignStart={true} iconColor="#050251"/>
      </Box>
    </Box>
  );
});

export default DrawerContent;
