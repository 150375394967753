import type { Contact } from '@breteuil-website/store/ui/pages/opt-out/api/ContactJWTInfo';

import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Button,
  Stack,
  styled,
} from '@mui/material';
import { useForm, createFormFieldToggle } from '@repo-lib/mobx-forms';
import texts from './texts';
import OptOutButton from '@breteuil-website/components/pages/opt-out/Button';
import { Page } from '@breteuil-website/components/common';
import ConfirmPage from './ConfirmPage';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  padding: "20vh 0",
  textAlign: 'center',
  maxWidth: '1200px',
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    padding: "5vh 0",
  },
  [theme.breakpoints.up('sm')]: {
    width: '80vw',
  },
}));

export type BooleanFields = ReturnType<typeof createFormFieldToggle>;

function creatOptOutFormFields(contact?: Contact)
{
  return {
    autoSearchAlerts: createFormFieldToggle(contact?.optIn ?? true),
    newsletters: createFormFieldToggle(contact?.newsletterOptIn ?? true),
  };
}

const OptOut = observer(() => {
  const T = useTexts(texts);
  const { optOut, globalMessage } = useWebsiteStores();

  const form = useForm(() => creatOptOutFormFields(optOut.contactJWTInfo.lastResult), {
    onSubmit(values)
    {
      const promise = Promise.all([
        optOut.changeCommunicationPreferences(values),
        values.autoSearchAlerts ? optOut.optIn() : optOut.optOut(),
      ]);
      globalMessage.handlePromise(promise, {
        successMessage: null,
        errorMessage: T.error,
      });
      return promise;
    },
  });

  if (form.success)
    return (
      <ConfirmPage unsubscribe={false}/>
    );
  return (
    <Page>
      <Container>
        <form onSubmit={form.handleSubmit}>
          <Stack direction="column" justifyContent="left">
            <OptOutButton
              title={T.adjustBtn.title}
              description={T.adjustBtn.description}
              onClick={(checked) => form.fields.autoSearchAlerts.setValue(checked)}
              checked={form.fields.autoSearchAlerts.value}
            />
            <OptOutButton
              title={T.newsletter.title}
              description={T.newsletter.description}
              onClick={(checked) => form.fields.newsletters.setValue(checked)}
              checked={form.fields.newsletters.value}
            />
          </Stack>
          <Stack direction="row" marginTop={3}>
            <Box>
              <Button variant='contained' type="submit">
                {T.update}
              </Button>
            </Box>
          </Stack>
        </form>
      </Container>
    </Page>
  );
});

export default OptOut;
