import type {
  Area,
} from '@breteuil-website/store/ui/common/areas';
import type { Search } from '@breteuil-website/store/ui/pages/client-area/searches/api/shared';
import type {
  PropertiesFilter,
  PropertySearchType,
} from './api';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';

import { observable, action, computed } from 'mobx';
import { arraysContainSameValues } from '@repo-lib/utils-core';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { ensureFetchableResource, handleNonCriticalError } from '@repo-breteuil/front-error';
import { OperationType } from '@repo-breteuil/common-definitions';
import { AreasStore } from '@breteuil-website/store/ui/common/areas';
import router, { RootPages } from '@breteuil-website/store/routing';
import {
  GetPropertiesCount,
  GetProperties,
  CreateSearchFromPropertyFilters,
} from './api';
import { QueryStore } from '@repo-breteuil/front-store-query';

export * from './api';

export interface PriceFilterValues
{
  min: number,
  max: number,
  maxAmount: number,
  default: number,
}

//TODO: double check these values
const OperationTypePriceFilterValues: Partial<Record<OperationType, PriceFilterValues>> = {
  [OperationType.ResidencyTransaction]: {
    min: 4,
    max: 100,
    maxAmount: 15000000,
    default: 100,
  },
  [OperationType.Rental]: {
    min: 1000,
    max: 10000,
    maxAmount: 10000,
    default: 10000,
  },
  [OperationType.SeasonalRental]: {
    min: 5000,
    max: 151000,
    maxAmount: 151000,
    default: 151000,
  },
};
const FallbackPriceFilterValues: PriceFilterValues = {
  min: 100000,
  max: 10000000,
  maxAmount: 1000000,
  default: 10000000,
};

export const BedroomsMinFilterValues = {
  min: 1,
  max: 4,
  maxAmount: 4,
};

export function getOperationTypePriceFilterValues(operationType?: OperationType): PriceFilterValues
{
  if (operationType === undefined)
    return FallbackPriceFilterValues;
  return OperationTypePriceFilterValues[operationType] || FallbackPriceFilterValues;
}

export function clampNumberFilter(
  value: number | undefined,
  limits: { min: number, maxAmount: number },
)
{
  if (value === undefined)
    return undefined;
  return Math.max(limits.min, Math.min(value, limits.maxAmount));
}

export function clampPriceFilterValue(
  operationType: OperationType | undefined,
  value: number,
): number
{
  const priceMaxFilterValues = getOperationTypePriceFilterValues(operationType);
  return clampNumberFilter(value, priceMaxFilterValues)!;
}

export function clampBedroomsMinFilterValue(value: number | undefined)
{
  return clampNumberFilter(value, BedroomsMinFilterValues);
}

export const DefaultPropertiesFilter: PropertiesFilter = {
  operationType: OperationType.ResidencyTransaction,
  priceMax: getOperationTypePriceFilterValues(OperationType.ResidencyTransaction).default,
};

function sanitizeFilter(filter: PropertiesFilter, areas: AreasStore)
{
  const { operationType, priceMax, bedroomsMin, areaId, subAreaId, ...rest } = filter;
  const { geoAreaIdsPerAreaId, geoAreaIdsPerSubAreaId } = ensureFetchableResource(areas.areas);
  const geoAreaId = subAreaId ? (
    subAreaId.map(id => geoAreaIdsPerSubAreaId.get(id)!) // Safe assertion
  ) : (
    areaId ? [ geoAreaIdsPerAreaId.get(areaId)! ] : undefined // Safe assertion
  );
  return {
    ...rest,
    operationType: [
      OperationType.ResidencyTransaction,
      OperationType.Rental,
      OperationType.SeasonalRental,
    ].includes(operationType) ? operationType : DefaultPropertiesFilter.operationType,
    priceMax: clampPriceFilterValue(operationType, priceMax),
    bedroomsMin: clampBedroomsMinFilterValue(bedroomsMin),
    geoAreaId,
    areaId,
    subAreaId,
  };
}

function formatFilter(filter: PropertiesFilter): PropertiesFilter
{
  const { maxAmount } = getOperationTypePriceFilterValues(filter.operationType);
  return {
    ...filter,
    priceMax: (filter.priceMax === maxAmount) ? Infinity : filter.priceMax,
  };
}

function areFiltersEqual(lhs: PropertiesFilter, rhs: PropertiesFilter): boolean
{
  const fields: Array<keyof PropertiesFilter> = [
    'operationType',
    'priceMax',
    'bedroomsMin',
    'areaId',
  ];
  for (const field of fields)
    if (lhs[field] !== rhs[field])
      return false;
  if (lhs.subAreaId)
  {
    if (!rhs.subAreaId)
      return false;
    return arraysContainSameValues(lhs.subAreaId, rhs.subAreaId);
  }
  if (rhs.subAreaId)
    return false;
  return true;
}

export class PropertiesSearchStore
{
  @observable.ref private _filter: PropertiesFilter = DefaultPropertiesFilter;
  private _scrollLevel: number = 0;

  public get scrollLevel()
  {
    return this._scrollLevel;
  }

  constructor(
    public stores: {
      areas: AreasStore,
      query: QueryStore,
      locale: LocaleStore,
    })
  {
    window.addEventListener('scroll', () => {
      if (this.isCurrentPage)
        this._scrollLevel = window.scrollY;
    });
  }

  public get isCurrentPage()
  {
    const { currentPage } = router;
    if (currentPage.state === 'invalid')
      return false;
    return (currentPage.page === RootPages.PropertiesSearch);
  }

  public get filter()
  {
    return this._filter;
  }

  @action setFilter(filter: PropertiesFilter): boolean/*changed*/
  {
    const cleanFilter = sanitizeFilter(filter, this.stores.areas);
    const changed = !areFiltersEqual(this.filter, cleanFilter);
    this._filter = cleanFilter;
    return changed;
  }

  public propertiesCount = new Fetchable(
    (filter: PropertiesFilter) => GetPropertiesCount(this.stores.query, {
      filter: formatFilter(sanitizeFilter(filter, this.stores.areas)),
    }),
    { catchUnhandled: handleNonCriticalError },
  );

  public properties = new UncachedPagination<PropertySearchType>({
    fetch: async (baseArgs) => {
      return GetProperties(this.stores.query, {
        ...baseArgs,
        language: this.stores.locale.locale,
        filter: formatFilter(this._filter),
      });
    },
    pageSize: 10,
  });

  @computed public get currentArea(): Area | null
  {
    const { value } = this.stores.areas.areas;
    if (value.status !== 'success')
      return null;
    const { areaId } = this.filter;
    const currentAreaId = areaId ?? null;
    if (currentAreaId === null)
      return null;
    const currentArea = value.result.areasPerId.get(currentAreaId);
    return currentArea ? currentArea.area : null;
  }

  public setFilterAndReload(
    filters: PropertiesFilter,
    opts: {
      skipReloadIfNoChanges?: boolean,
    } = {},
  )
  {
    const filterChanged = this.setFilter(filters);
    if (filterChanged || !opts.skipReloadIfNoChanges || (
      !this.properties.loading && !this.properties.data
    ))
    {
      this.properties.first();
      this.propertiesCount.load(filters);
      this._scrollLevel = 0;
    }
  }
  public async CreateAlert(): Promise<Search> {
    const search = await CreateSearchFromPropertyFilters(this.stores.query, {
      bedroomsMin: this._filter.bedroomsMin,
      operationType: this._filter.operationType,
      budgetMax: this._filter.priceMax,
      geoAreasIds: this._filter.geoAreaId!,
      language: this.stores.locale.locale,
    });
    return search;
  }

  // @observable private _isAreaSelected: boolean = this._filter.areaId !== undefined && this._filter.areaId > -1 || false;
  @observable private _isAreaSelected: boolean = true;

  @action public setIsAreaSelected(isAreaSelected: boolean) {
    this._isAreaSelected = isAreaSelected;
  }

  public get isAreaSelected()
  {
    return this._isAreaSelected;
  }

}
