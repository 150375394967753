import * as React from 'react';
import { intRange, spliceString, findCharacter } from '@repo-lib/utils-core';
import { styled } from '@mui/material/styles';

const Container = styled('div')({
  display: 'flex',
  paddingTop: '5px',
  input: {
    caretColor: "transparent",
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      WebkitAppearance: 'textfield',
      MozAppearance: 'textfield',
    },
  },
});

const Input = styled('input')({
  display: 'block',
  marginInline: '.25em',
  width: '4rem',
  height: '80px',
  fontSize: '1.5rem',
  textAlign: 'center',
  boxShadow: '0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)',
  border: 'none',
  '&:focus': {
    outlineColor: 'blue',
  },
  '@media (max-width: 960px)': {
    width: '2.5rem',
    height: '50px',
    fontSize: '1rem',
  },
});

export interface CodeInputProps
{
  size: number,
  value: string,
  onChange: (newValue: string) => void,
}

const CodeInput = (props: CodeInputProps) => {
  const { size, value, onChange } = props;
  const currentFocus = React.useRef(0);
  const refs = React.useRef(intRange(0, size).map(() => React.createRef<HTMLInputElement>()));

  const focusCell = (idx: number) => {
    const element = refs.current[idx]?.current;
    if (element)
    {
      element.focus();
      currentFocus.current = idx;
    }
  };

  const handleCellChange = (idx: number) => {
    const cell = refs.current[idx]?.current;
    if (!cell)
      return;
    const oldValue = value[idx] || '';
    const newValue = cell.value;

    function replaceCellValue(replacingValue: string)
    {
      const updatedValue = spliceString(value, idx, 1, replacingValue);
      onChange(updatedValue);
      const focusedCell = (idx >= updatedValue.length) ? updatedValue.length : idx + 1;
      focusCell(focusedCell);
    }

    if (newValue.length === 0)
    {
      onChange(spliceString(value, idx, 1));
      //No focus change; we erase the cell, but stay here.
      return;
    }
    if (newValue.length === 1)
    {
      replaceCellValue(newValue);
      return;
    }
    if (newValue.length === 2)
    {
      if (oldValue.length === 1 && newValue.includes(oldValue)) //One char was typed; using .includes because the new char could be typed either before or after the existing char
      {
        const differentValue = findCharacter(newValue, (c) => (c !== oldValue));
        if (differentValue !== undefined)
          replaceCellValue(differentValue);
        return;
      }
    }

    if (newValue.length === size) //Paste or autocomplete
    {
      onChange(newValue);
      focusCell(size - 1);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    if (event.key === 'Backspace' || event.key === 'Delete')
    {
      if (value.length <= idx) //Cell is empty
      {
        //Deleting previous cell
        onChange(value.slice(0, -1));
        focusCell(idx - 1);
      }
      else //Cell is not empty; overriding onChange behaviour to empty the cell even if the cursor is before its content
      {
        event.preventDefault();
        onChange(spliceString(value, idx, 1));
      }
    }
    else if (event.key === "ArrowLeft")
      focusCell(currentFocus.current - 1);
    else if (event.key === "ArrowRight")
      focusCell(currentFocus.current + 1);
  };

  React.useEffect(() => {
    focusCell(0);
  }, []);

  React.useEffect(() => {
    if (!('OTPCredential' in window))
      return;

    const ac = new AbortController();
    navigator.credentials.get({
      otp: { transport: [ 'sms' ] },
      signal: ac.signal
    } as any/*TODO: typescript*/).then(otp => {
      const { code } = otp as any; //TODO: typescript
      onChange(code);
    }).catch(error => {
      //Ignored
    });

    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Container>
      {refs.current.map((ref, idx) => (
        <Input
          key={idx}
          ref={ref}
          type="number"
          name="one-time-code"
          autoComplete="one-time-code"
          maxLength={length/*For autocomplete; in fact, maxLength is 1*/}
          size={1}
          value={value[idx] || ''}
          onChange={() => { handleCellChange(idx) }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => { handleKeyDown(event, idx) }}
          onFocus={() => { currentFocus.current = idx }}
        />
      ))}
    </Container>
  );
};

export default CodeInput;
