// TODO Refactor
import type { Search } from '@breteuil-website/store/ui/pages/client-area/searches/api/shared';
import type {
  Area,
} from '@breteuil-website/store/ui/common/areas';
import type { PropertiesRentalFilter, PropertySearchType } from './api';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';

import { observable, action, computed } from 'mobx';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { ensureFetchableResource } from '@repo-breteuil/front-error';
import { AreasStore } from '@breteuil-website/store/ui/common/areas';
import router, { RootPages } from '@breteuil-website/store/routing';
import {
  GetRentalProperties,
  CreateSearchFromPropertyFilters,
} from './api';
import { OperationType } from '@repo-breteuil/common-definitions';
import { QueryStore } from '@repo-breteuil/front-store-query';

export * from './api';

function areFiltersEqual(lhs: PropertiesRentalFilter, rhs: PropertiesRentalFilter): boolean
{
  const fields: Array<keyof PropertiesRentalFilter> = [
    'operationType',
    'price',
    'priceMax',
    'bedroomsMin',
    'guests',
    'areaId',
  ];
  for (const field of fields)
    if (lhs[field] !== rhs[field])
      return false;
  return true;
}

function sanitizeFilter(filter: PropertiesRentalFilter, areas: AreasStore)
{
  const { areaId, ...rest } = filter;
  const { geoAreaIdsPerAreaId } = ensureFetchableResource(areas.areas);
  const geoAreaId = areaId ? [ geoAreaIdsPerAreaId.get(areaId)! ] : undefined; // Safe assertion
  return {
    ...rest,
    areaId,
    geoAreaId,
  };
}

export class PropertiesSearchRentStore
{
  @observable.ref private _filter: PropertiesRentalFilter = {
    operationType: OperationType.Rental,
  };
  private _scrollLevel: number = 0;

  public get scrollLevel()
  {
    return this._scrollLevel;
  }

  constructor(
    public stores: {
      areas: AreasStore,
      query: QueryStore,
      locale: LocaleStore,
    })
  {
    window.addEventListener('scroll', () => {
      if (this.isCurrentPage)
        this._scrollLevel = window.scrollY;
    });
  }

  public get isCurrentPage()
  {
    const { currentPage } = router;
    if (currentPage.state === 'invalid')
      return false;
    return (currentPage.page === RootPages.PropertiesSearchRent);
  }


  public get filter()
  {
    return this._filter;
  }

  @action setFilter(filter: PropertiesRentalFilter): boolean/*changed*/
  {
    const cleanFilter = sanitizeFilter(filter, this.stores.areas);
    const changed = !areFiltersEqual(this._filter, cleanFilter);
    this._filter = cleanFilter;
    return changed;
  }

  public properties = new UncachedPagination<PropertySearchType>({
    fetch: async (baseArgs) => {
      return GetRentalProperties(this.stores.query, {
        ...baseArgs,
        language: this.stores.locale.locale,
        filter: this._filter,
      });
    },
    pageSize: 10,
  });

  @computed public get currentArea(): Area | null
  {
    const { value } = this.stores.areas.areas;
    if (value.status !== 'success')
      return null;
    const { areaId } = this.filter;
    const currentAreaId = areaId ?? null;
    if (currentAreaId === null)
      return null;
    const currentArea = value.result.areasPerId.get(currentAreaId);
    return currentArea ? currentArea.area : null;
  }

  public setFilterAndReload(
    filters: PropertiesRentalFilter,
    opts: {
      skipReloadIfNoChanges?: boolean,
    } = {},
  )
  {
    const filterChanged = this.setFilter(filters);
    if (filterChanged || !opts.skipReloadIfNoChanges || (
      !this.properties.loading && !this.properties.data
    ))
    {
      this.properties.first();
      this._scrollLevel = 0;
    }
  }

  // @observable private _isAreaSelected: boolean = this._filter.areaId !== undefined && this._filter.areaId > -1 || false;
  @observable private _isAreaSelected: boolean = true;

  @action public setIsAreaSelected(isAreaSelected: boolean) {
    this._isAreaSelected = isAreaSelected;
  }

  public get isAreaSelected()
  {
    return this._isAreaSelected;
  }

  public async CreateAlert(): Promise<Search> {
    const search = await CreateSearchFromPropertyFilters(this.stores.query, {
      bedroomsMin: this._filter.bedroomsMin,
      operationType: this._filter.operationType,
      budgetMax: this._filter.priceMax,
      geoAreasIds: this._filter.geoAreaId!,
      language: this.stores.locale.locale,
    });
    return search;
  }

}
