import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
} from '@lib/routing';
import type { LanguageRouteHandler } from '@core/store/routing';
import type { Areas } from '@breteuil-website/store/ui/common/areas';
import type { PropertiesRentalFilter } from '@breteuil-website/store/ui/pages/properties-search';

import { optionalPromiseThen } from '@repo-lib/utils-core';
import { OperationType } from '@repo-breteuil/common-definitions';
import { Locale } from '@repo-breteuil/common-texts';
import {
  setTranslatedStaticPage,
  setTranslatedPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { getPropertiesSearchRoute } from '@breteuil-website/store/routing';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

function parseIntSearchParam(
  param: string | null,
  opts: {
    validation?: (value) => boolean,
  },
): number | null
{
  if (!param)
    return null;
  const value = Number.parseInt(param);
  if (isNaN(value))
    return null;
  if (opts.validation && !opts.validation(value))
    return null;
  return value;
}
function parsePropertiesFilterSearchParams(searchParams: URLSearchParams)
{
  const priceMin =  parseIntSearchParam(searchParams.get('priceMax'), {});
  const priceMax =  parseIntSearchParam(searchParams.get('priceMax'), {});
  const bedroomsMin = parseIntSearchParam(searchParams.get('bedroomsMin'), {});
  const guests = parseIntSearchParam(searchParams.get('guests'), {});
  return {
    priceMin: priceMin || undefined,
    priceMax: priceMax || undefined,
    bedroomsMin: bedroomsMin || undefined,
    guests: guests || undefined,
  };
}

function genPropertiesSearchHandler(
  store: any,
  routeFilters: {
    operationType: OperationType,
  },
): LanguageRouteHandler<RootPages, Locale>
{
  return (location, info, lang) => {
    return optionalPromiseThen(websiteStores.areas.areas.ensureSuccess(), (areas: Areas) => {
      return () => {
        const { routeParams, searchParams } = location;
        const operationType = routeFilters.operationType;
        const { ...searchParamsFilters } = parsePropertiesFilterSearchParams(searchParams);
        const filters: PropertiesRentalFilter = {
          ...searchParamsFilters,
          operationType,
        };

        const areaWithSubAreas = routeParams.areaSlug ? areas.areasPerSlug.get(routeParams.areaSlug) : undefined;
        if (areaWithSubAreas)
          filters.areaId = areaWithSubAreas.area.id;
        store.setFilterAndReload(filters, {
          skipReloadIfNoChanges: true,
        });
        const route = getPropertiesSearchRoute({
          ...filters,
          lang,
          areaSlug: areaWithSubAreas?.area.slug,
        });
        return {
          route,
          page: null,
          scrollTop: store.scrollLevel,
        };
      };
    });
 };
}

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  setTranslatedStaticPage(
    Public,
    RootPages.PropertiesSearchRent,
    Routes.rent.routesByLanguage,
    genPropertiesSearchHandler(websiteStores.propertiesSearchRent, { operationType: OperationType.Rental }),
  );
  setTranslatedPage(
    Public,
    RootPages.PropertiesSearchRent,
    Routes.rentArea.routesByLanguage,
    genPropertiesSearchHandler(websiteStores.propertiesSearchRent, { operationType: OperationType.Rental }),
  );
}
