import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';

import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface LoginWithCodeArgs {
  email: string,
  code: string,
}

export async function LoginWithCode(qs: QueryStore, args: LoginWithCodeArgs): Promise<ClientAreaSession>
{
  return qs.mutation({
    operationName: 'LogInWithCode',
    variables: {
      email: 'String!',
      code: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            logInWithCode: [{
              args: {
                email: true,
                code: true,
              },
            }, (
              clientAreaSessionResult()
            )],
          },
        },
      },
    },
  }, args);
}
