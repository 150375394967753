import { Typography, styled } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '28px',
  fontWeight: 700,
  color: '#050251',
  marginBottom: theme.spacing(1),
  "@media (max-width: 960px)": {
    fontSize: '22px',
  },
}));

export const Info = styled(Typography)(({ theme }) => ({
  fontFamily: 'Mulish',
  fontSize: '18px',
  color: "#757575",
  "@media (max-width: 960px)": {
    fontSize: '15px',
  },
}));
