import type { IdType } from '@repo-breteuil/common-definitions';
import type { OperationType } from '@repo-breteuil/common-definitions';
import type {
  Search,
} from './api';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';

import { observable, action, computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetSearches,
  GetCriteria,
  CreateSearch,
  UpdateSearch,
  DeleteSearch,
} from './api';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { QueryStore } from '@repo-breteuil/front-store-query';

export class SearchesStore
{
  constructor(
    public stores: {
      query: QueryStore,
      session: SessionStore,
      locale: LocaleStore,
    },
  )
  {
  }
  public searches = new Fetchable(async () => {
    const session = await GetSearches(this.stores.query, { language: this.stores.locale.locale });
    return this.stores.session.ensureContactSession(session).contact.searches;
  }, { catchUnhandled: handleNonCriticalError });

  public criteria = new Fetchable(
    (args: {
      areaId: IdType,
      operationType: OperationType,
    }) => GetCriteria(this.stores.query ,{ ...args, language: this.stores.locale.locale,
 }).then((criteriaGroups) => ({
      criteriaGroups,
      criteria: criteriaGroups.reduce((res, group) => res.concat(group)),
    })),
    { catchUnhandled: handleNonCriticalError },
  );

  /*
   * The currently selected searchId.
   * If null, no search is selected, so we should be creating a search.
   */
  @observable private _searchId: IdType | null;

  public findSearchById(searchId: IdType): Search | null
  {
    if (this.searches.lastResult === undefined)
      return null;
    if (this.searches.lastResult.length === 0)
      return null;
    const search = this.searches.lastResult.find(item => (item.id === searchId));
    if (search)
      return search;
    return null;
  }

  /*
   * Passing a valid searchId will set `searchId`.
   * Passing `null` will set `searchId` to null.
   * Passing `undefined` or an invalid searchId will set `searchId` to the first search, or null if there is none.
   */
  @action public setSearchId(searchId?: IdType | null): IdType | null
  {
    if (searchId === null || this.searches.lastResult === undefined || this.searches.lastResult.length === 0)
      this._searchId = null;
    else
    {
      if (searchId !== undefined && this.searches.lastResult.find(item => (item.id === searchId)))
        this._searchId = searchId;
      else
        this._searchId = this.searches.lastResult[0].id;
    }
    return this._searchId;
  }

  @computed private get _search(): Search | null
  {
    if (this._searchId === null)
      return null;
    return this.findSearchById(this._searchId);
  }

  @computed public get search()
  {
    const search = this._search;
    if (search !== null)
      return search;
    if (this.searches.lastResult === undefined || this.searches.lastResult.length === 0)
      return null;
    return this.searches.lastResult[0];
  }

  public async createSearch(
    args: Omit<Parameters<typeof CreateSearch>[1], 'language'>,
  ): Promise<Search>
  {
    const search = await CreateSearch(this.stores.query, {
      ...args,
      notificationEmail: true,
      language: this.stores.locale.locale,
    });
    await this.searches.ensureSuccessReload();
    return search;
  }

  public async updateSearch(
    args: Omit<Parameters<typeof UpdateSearch>[1], 'language'>,
  ): Promise<Search>
  {
    const search = await UpdateSearch(this.stores.query, {
      ...args,
      language: this.stores.locale.locale,
    });
    await this.searches.ensureSuccessReload();
    return search;
  }

  public async deleteSearch(id: IdType)
  {
    await DeleteSearch(this.stores.query ,id);
    await this.searches.ensureSuccessReload();
  }
}
