import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
} from '@mui/material';
import {
  CalendarMonth,
  AccessTime,
  LocationOn,
} from '@mui/icons-material';
import { useTexts } from '@repo-breteuil/common-texts';
import lang from '../../texts';
import SelectedAgency from '@breteuil-website/components/pages/valuation/SelectedAgency';
import { useWebsiteStores } from '@breteuil-website/components/providers';


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  height: '100%',
});

const Title = styled('h1')({
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '17px',
  fontWeight: 700,
  '@media (max-width: 1280px)': {
    textAlign: 'center',
  },
});

const ListItemIconStyled = styled(ListItemIcon)({
  color: '#050251',
});

const ListItemTextStyled = styled(ListItemText)({
  fontFamily: 'Mulish',
  color: '#050251',
  fontSize: '14px',
  fontWeight: 600,
});

const ListItemNoPadding = styled(ListItem)({
  padding: '0',
});

const FadeWithMargin = styled(Fade)({
  marginTop: 'auto !important',
});

const InfoText = styled('div')({
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '16px',
  margin: '40px 0',
  padding: '0 22px',
});

const Summary = observer(() => {
  const T = useTexts(lang).method3.details.summary;
  const { valuation } = useWebsiteStores();
  return (
    <Container>
      <Title>{T.title}</Title>
      <List disablePadding>
        <ListItemNoPadding>
          <ListItemIconStyled><CalendarMonth/></ListItemIconStyled>
          <ListItemTextStyled primary={T.appointmentDate(new Date(valuation.selectedMeetingDate!))}/>
        </ListItemNoPadding>
        <ListItemNoPadding>
          <ListItemIconStyled><AccessTime/></ListItemIconStyled>
          <ListItemTextStyled primary={T.appointmentDuration}/>
        </ListItemNoPadding>
        <ListItemNoPadding>
          <ListItemIconStyled><LocationOn/></ListItemIconStyled>
          <ListItemTextStyled primary={valuation.address}/>
        </ListItemNoPadding>
      </List>
      <InfoText>
        <span>{T.appointmentInfo}</span>
      </InfoText>
      <FadeWithMargin
          in={!!valuation.agency}
          mountOnEnter
          unmountOnExit
          exit={false}
        >
          <div>
            {valuation.agency ? <SelectedAgency agency={valuation.agency} orientationCol={true}/> : null}
          </div>
      </FadeWithMargin>

    </Container>
  );
});

export default Summary;
