import type { RootPages } from '@breteuil-website/store/routing/pages';

import { observable, action } from 'mobx';

class RoutingStore
{
  @observable public currentPage: RootPages;

  @action public setCurrentPage(page: RootPages)
  {
    this.currentPage = page;
  }
}

export default new RoutingStore();
