import type { IdType } from '@repo-breteuil/common-definitions';

import { QueryStore } from '@repo-breteuil/front-store-query';

export async function SetFavoriteProperty(
  qs: QueryStore,
  args: {
    id: IdType,
    favorite: boolean,
  },
): Promise<boolean>
{
  return qs.mutation({
    operationName: 'SetFavoriteProperty',
    variables: {
      id: 'Int!',
      favorite: 'Boolean!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            property: {
              setFavorite: [{
                args: {
                  id: true,
                  favorite: true,
                },
              }, true ],
            },
          },
        },
      },
    },
  }, args);
}
