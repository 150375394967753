import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from '../texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const ResultContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '11px 22px',
  marginTop: '13px',
  fontFamily: 'Poppins',
  fontSize: '22px',
  fontWeight: 700,
  backgroundColor: '#F0F5FF',
  color: '#050251',
  '@media (max-width: 1280px)': {
    rowGap: '5px',
    fontSize: '15px',
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});


const LoadingText = styled('span')({
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 400,
});

const ResultLabel = styled('span')({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 700,
});

const ResultComponent = observer(() => {
  const T = useTexts(texts);
  const { valuation } = useWebsiteStores();

  return (
    <ResultContainer
      style={
        valuation.detailedValuation.result && valuation.detailedValuation.result.estimatedPrice ?
          { backgroundColor: '#F0F5FF', opacity: 1} : {}
      }>
          <ResultLabel>
            {valuation.detailedValuation.result && valuation.detailedValuation.result.estimatedPrice ?
                T.method2.results.labelResult
            : T.method2.results.labelPlaceholder}
          </ResultLabel>
      {valuation.detailedValuation.pending ? (
        <LoadingText>{T.method2.results.loading}</LoadingText>
      ) : (
        <span>
          {valuation.detailedValuation.result && valuation.detailedValuation.result.estimatedPrice ?
            T.method2.results.formatResult(valuation.detailedValuation.result.estimatedPrice)
            : T.method2.results.placeholder}
        </span>
      )}
    </ResultContainer>
  );
});

export default ResultComponent;
