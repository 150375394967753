import { OperationType } from '@repo-breteuil/common-definitions';

export default {
  formatPageTitle(operationType: OperationType): string
  {
    const title = (operationType === 'Rental' || operationType === 'SeasonalRental') ?
     "Location immobilière de standing et de prestige - Breteuil Remarkable Homes" :
     "Achat immobilier de standing et de prestige - Breteuil Remarkable Homes" ;
    return title;
  },
  formatPageDescription(operationType: OperationType): string
  {
    const title = (operationType === 'Rental' || operationType === 'SeasonalRental') ?
     "Découvrez notre sélection exclusive de biens immobiliers haut de gamme disponibles à la location avec Breteuil Remarkable Homes." :
     "Breteuil Remarkable Homes vous accompagne dans votre projet d'achat immobilier haut de gamme. Découvrez notre sélection exclusive de biens d'exception à vendre." ;
    return title;
  },
  labelTransaction: 'Transaction',
  labelBedrooms: 'Nombre de Chambres',
  priceLabel: 'Prix',
  area: 'Région',
  subArea: 'Zone',
  everywhere: 'Partout',
  labelZone: 'Zone',
  labelOperationType: 'Opération',
  filterLabel: 'Filtres',
  submit: 'Rechercher',
  operationType: {
    transaction: 'Acheter',
    rental: 'Louer',
    seasonalRental: 'Vacances',
  },
  errorLoadMore: 'Un problème est survenu lors du chargement. Veuillez réessayer plus tard',
  results: 'RESULTATS',
  formatMinBedroomsText(n: number) {
    return `${n}+`;
  },
  formatPageTitleLabel(
    operationType: OperationType,
    area: {
      name: string,
      FRNamePrepositionDeLieu: string | null,
    } | null,
  ): string
  {
    const operation = new Map([
      [OperationType.Rental, "Location"],
      [OperationType.SeasonalRental, "Location Saisonnière"],
      [OperationType.ResidencyTransaction, "Achat"],
    ]);
    const title = operation.get(operationType) || "";
    if (area === null)
      return title;
    let preposition = area.FRNamePrepositionDeLieu || 'à';
    if (!preposition.endsWith("'"))
      preposition += ' ';
    return `${title} ${preposition}${area.name}`;
  },
  printResults(nbResults: number | null)
  {
    let text = '';
    if (nbResults !== null)
      text += ` (${nbResults})`;
    return text;
  },
  alertFilterArea: 'Veuillez selectionner une région pour créer une alerte',
  formatBudgetFilterPeriod(operationType: OperationType): string
  {
    const period = new Map([
      [OperationType.Rental, "/ mois"],
      [OperationType.SeasonalRental, "/ semaine"],
    ]);
    return period.get(operationType) || "";
  },
};
