export default {
  buy: 'BUY',
  rent: 'RENT',
  seasonalRental: "SEASONAL RENTAL",
  valuation: 'VALUATION',
  services: 'SERVICES',
  joinUs: "JOIN US",
  contact: 'CONTACT US',
  marketData: 'MARKET DATA',
  logoAlt: 'Breteuil logo',
  languages: {
    french: 'FR',
    english: 'EN',
  },
  logOut: 'Logout',
};
