import type { IdType, OperationType } from '@repo-breteuil/common-definitions';
import type { Area, SubArea } from './api';
import { isNonNull, indexArrayItems, indexMultipleArrayItems } from '@repo-lib/utils-core';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { QueryStore } from '@repo-breteuil/front-store-query';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';
import { GetAreas } from './api';

export * from './api';

export interface AreaWithSubAreas
{
  area: Area,
  subAreas: Array<SubArea>,
}

export interface Areas
{
  homepageAreas: Array<AreaWithSubAreas>,
  filtersAreas: Map<OperationType | null, Array<AreaWithSubAreas>>,
  areasPerId: Map<IdType, AreaWithSubAreas>,
  areasPerSlug: Map<string, AreaWithSubAreas>,
  geoAreaIdsPerAreaId: Map<IdType, IdType>,
  subAreasPerId: Map<IdType, SubArea>,
  subAreasPerSlug: Map<string, SubArea>,
  geoAreaIdsPerSubAreaId: Map<IdType, IdType>,
}

function indexItemsOnSlugs<T>(
  items: Array<T>,
  getSlugs: (item: T) => { slug: string, slugs: Array<string> },
): Map<string, T>
{
  const results = new Map<string, T>();
  for (const item of items)
  {
    const { slug, slugs } = getSlugs(item);
    results.set(slug, item);
    for (const slug of slugs)
      results.set(slug, item);
  }
  return results;
}

export class AreasStore
{
  constructor(
    public stores: {
      query: QueryStore,
      locale: LocaleStore,
    })
  {
  }

  public areas = new Fetchable(
    async (): Promise<Areas> => {
      const {
        areas,
        subAreasLinks,
        subAreas,
      } = await GetAreas(this.stores.query, { language: this.stores.locale.locale });

      const subAreasPerId = indexArrayItems(subAreas, (subArea) => subArea.id);
      const subAreasPerSlug = indexItemsOnSlugs(
        subAreas,
        (subArea) => subArea,
      );
      const geoAreaIdsPerSubAreaId = indexArrayItems(subAreas, subArea => subArea.id, subArea => subArea.geoAreaId);

      const areasLinks = indexMultipleArrayItems(subAreasLinks, (link) => link.areaId);
      const formattedAreas = areas.map((area) => ({
        area,
        subAreas: (areasLinks.get(area.id) || []).sort((a, b) => (
          a.position - b.position
        )).map(links => {
          const subArea = subAreasPerId.get(links.subAreaId);
          if (subArea)
            return subArea;
          //We could assert here that the link references an existing subArea
          return null;
        }).filter(isNonNull),
      }));

      const areasPerId = indexArrayItems(formattedAreas, (area) => area.area.id);
      const areasPerSlug = indexItemsOnSlugs(
        formattedAreas,
        (area) => area.area,
      );
      const geoAreaIdsPerAreaId = indexArrayItems(formattedAreas, area => area.area.id, item => item.area.geoAreaId);

      const homepageAreas = formattedAreas.filter(area => (
        area.area.homepagePosition !== null
      )).sort((a, b) => (
        a.area.homepagePosition! - b.area.homepagePosition!
      ));
      const operationTypes = formattedAreas.reduce((operationTypes, area) => {
        for (const operationType of area.area.operationTypes)
          operationTypes.add(operationType);
        return operationTypes;
      }, new Set<OperationType>());
      const filtersAreas = new Map([
        null,
        ...operationTypes,
      ].map((operationType) => [
        operationType,
        formattedAreas.filter(area => (
          area.area.filtersPosition !== null && (
            operationType === null || area.area.operationTypes.includes(operationType)
          )
        )).sort((a, b) => (
          (a.area.filtersPosition!) - (b.area.filtersPosition!)
        )),
      ]));

      return {
        homepageAreas,
        filtersAreas,
        areasPerId,
        areasPerSlug,
        geoAreaIdsPerAreaId,
        subAreasPerId,
        subAreasPerSlug,
        geoAreaIdsPerSubAreaId,
      };
    },
    { catchUnhandled: handleNonCriticalError },
  );
}
