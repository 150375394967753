import * as React from 'react';
import { Page as GenericPage } from '@repo-lib/utils-react';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';
import AppFooter from './footer';
import AppBar from './appBar/AppBar';

const Page = (
  props: {children: React.ReactNode, padding?: number},
) => {
  const isMobile = useMediaQueryMobile();
  const isTablet = useMediaQueryTablet();
  const initialPadding = isMobile || isTablet ? 50 : 90;
  return <GenericPage
    header={{
      element: (<AppBar/>),
      behavior: 'fixed',
      above: true,
      padding: props.padding !== undefined ? props.padding : initialPadding,
      opacity: 0.9,
    }}
    footer={{
      element: (<AppFooter/>),
    }}
  >
    {props.children}
  </GenericPage>;

};

export default Page;
