import type { Agency } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';

import { Currency } from '@repo-lib/utils-texts';
import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition {
  pageTitle: string,
  pageDescription: string,
  method1: {
    title: string,
    form: {
      labels: {
        address: string,
        transactionPrice: string,
        transactionYear: string,
        transactionMonth: string,
        renovationCost: string,
        renovationYear: string,
        renovationYearInfo: string,
        surface: string,
        phone: string,
      },
      submit: string,
      phoneAlreadyConfirmed: string,
      addressWithoutNumberError: string,
      transactionYearError: string,
      errorToast: string,
      handleFormSubmissionErrors(error) : string,
      phoneConfirmed: string,
      surfacePlaceholder: string,
    },
    confirmPhoneDialog: {
      title: JSX.Element,
      actions: {
        sendNewCode: string,
        confirm: string,
      },
      successfullCodeResent: string,
      phoneAlreadyConfirmed: string,
      handleFormSubmissionErrors(error) : string,
    },
    valuationResult: {
      valuationText: string,
      valuationPrice: (price: number | null | undefined, currency: Currency) => JSX.Element,
      valuationError: JSX.Element,
    },
  },
  method2: {
    title: string,
    form: {
      select: {
        extArea: {
          label: string,
          balcony: string,
          terrace: string,
          terraceUnoverlooked: string,
          backyard: string,
        },
        binary: {
          yes: string,
          no: string,
        },
        direction: {
          label: string,
          north: string,
          east: string,
          west: string,
          south: string,
        },
        remarkableView: {
          label: string,
          normal: string,
          good: string,
          oustanding: string,
        },
        buildingType: {
          label: string,
          haussmannian: string,
          modern: string,
          other: string,
        },
        renovation: {
          new: string,
          normal: string,
          needRefresh: string,
          workNeeded: string,
        },
        rooms: {
          one: string,
          two: string,
          three: string,
          four: string,
          fiveAndMore: string,
        },
        floors: {
          rdc: string,
          one: string,
          two: string,
          three: string,
          four: string,
          fiveAndMore: string,
        },
        popularAdress: string,
        elevator: string,
        lastFloor: string,
      },
      input: {
        exteriorSurface: string,
        address: string,
        surface: string,
        surfaceMetric: string,
        transactionPrice: string,
        transactionMonth: string,
        transactionYear: string,
        renovationYear: JSX.Element,
        renovationCost: string,
        balcony: string,
        terrace: string,
        terraceUnoverlooked: string,
        backyard: string,
        rooms: string,
        floor: string,
        renovation: string,
        contactMe: string,
      },
      submitButton: string,
      submitPhone: string,
      phoneActivationTitle: JSX.Element,
      phoneActivationTitleMobile: string,
    },
    results: {
      loading: string,
      labelPlaceholder: string,
      labelResult: string,
      placeholder: string,
      formatResult: (result: number) => string,
    },
    unsupportedCityDisclaimer: string,
  },
  method3: {
    title: string,
    prev: string,
    anchorId: string,
    anchorText: string,
    agencySelection: {
      title: string,
      subTitle: JSX.Element,
      label: string,
      selectedAgency: (agency: Agency | null) => JSX.Element,
      mobileSelectedAgency: (agency: Agency | null) => JSX.Element,
    },
    timeslotSelection: {
      selectedDate: (date: Date) => string,
      tabsLabels: Array<string>,
      confirm: string,
      errorNoAddress: string,
      errorNoAgencyNearAddress: string,
      errorNoMeetings: string,
    },
    details: {
      summary: {
        title: string,
        appointmentDate: (date: Date) => string,
        appointmentDuration: string, // hardcoded for now
        appointmentInfo: string,
      },
      confirmationForm: {
        title: string,
        labels: {
          name: string,
          surname: string,
          email: string,
          phone: string,
          comment: string,
        },
        confirm: string,
        cancel: string,
        successTitle: string,
        successSubTitle: JSX.Element,
        errorMeetingAlreadyConfirmed: string,
        errorMeetingDateAlreadyLocked: string,
        errorInvalidPhoneNumber: string,
        errorInvalidMeetingDate: string,
        errorNoMeetingDate: string,
        successMessage: string,
      },
    },
  },
  cities: {
    paris: string,
    london: string,
    newYork: string,
    deauville: string,
    dinard: string,
    laBaule: string,
    lisbon: string,
    biarritz: string,
    saintJeanDeLuz: string,
  },
  citySelectLabel: string,
  errorToast: (e: Error) => string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
