import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled('div')({
  height: '950px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  margin: "0 auto 70px auto",
  '@media (max-width: 1600px)': {
    height: '750px',
  },
  '@media (max-width: 1024px)': {
    height: '650px',
  },
  '@media (max-width: 960px)': {
    width: '100%',
    margin: '0 auto 170px',
  },
});

const AgenciesPhotoContainer = styled('div')({
  height: '26vw',
  width: "80%",
  position: 'relative',
  margin: '70px auto',
  '@media (max-width: 1600px)': {
    height: '30vw',
    width: '100%',
  },
  '@media (max-width: 960px)': {
    width: '100%',
    height: '100%',
    margin: '0 0 50px 0',
  },
});

const AgencyPhoto = styled('img')({
  position: 'absolute',
  width: "34vw",
  objectFit: 'cover',
  '@media (max-width: 1600px)': {
    width: '41vw',
  },
  '@media (max-width: 960px)': {
    position: 'static',
    maxWidth: '100%',
    width: '100%',
    height:'100%',
  },
});

const AgencyTextContainer = styled('div')({
  position: 'absolute',
  bottom: "10%",
  left: '15%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  '@media (min-width: 2000px)': {
    left: '21%', // TODO : FIX that shit
    bottom: '0',
  },
  '@media (max-width: 1600px)': {
    left: '5%',
    bottom: '0',
  },
  '@media (max-width: 960px)': {
    position: 'static',
    width: '80%',
    margin: 'auto',
  },
});

const AgencyTextTitle = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '30px',
  color: '#050251 !important',
  lineHeight: '45px',
  '@media (max-width: 960px)': {
    fontSize: '24px',
    lineHeight: '30px',
  },
});

const AgencyTextDescription = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '14px',
  color: '#050251 !important',
  margin: '25px 0',
  '@media (max-width: 960px)': {
    display: 'block',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

const AgenciesComponent = observer(() => {
  const T = useTexts(texts).agencies;
  const isMobile = useMediaQueryMobile();

  return (
    <Container>
      <AgenciesPhotoContainer>
        <AgencyPhoto src={"/assets/agencies/agence.jpeg"} style={{top: '0', left: '5%'}}/>
        {isMobile ? null : <AgencyPhoto src={"/assets/agencies/agence2.jpeg"} style={{bottom: '0', right: '5%', zIndex: 1}}/>}
      </AgenciesPhotoContainer>
      <AgencyTextContainer>
        <AgencyTextTitle>{T.HomeAgencyTitle}</AgencyTextTitle>
        <AgencyTextDescription>{T.HomeAgencyDescription()}</AgencyTextDescription>
      </AgencyTextContainer>
    </Container>
  );
});

export default AgenciesComponent;
