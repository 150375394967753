import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import TextLink, { TextLinkProps } from './TextLink';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';

const EmptySpace = styled('div')({
  height: '35px',
  width: '100%',
});

interface LinkListProps {
  elements: Array<TextLinkProps>,
  className?: string,
}

const LinkList = observer((props: LinkListProps) => {
  const isDesktop = useMediaQueryDesktop();
  return (
    <div>
      {props.elements.map((element, i) => {
        if (element.text === '' && isDesktop)
          return <EmptySpace key={i}/>;
        return (
          <TextLink
            key={i}
            text={element.text}
            to={element.to}
            {...omitUndefinedValues({ className: props.className })}
          />
        );
      })}
    </div>
  );
});

export default LinkList;
