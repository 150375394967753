import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';

import { observable, action } from 'mobx';
import { RequestPasswordReset, ResetPassword } from './api';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { QueryStore } from '@repo-breteuil/front-store-query';

export class PasswordResetStore
{
  constructor(
    public stores: {
      session: SessionStore,
      query: QueryStore,
    },
  )
  {
  }
  @observable public token: string | null = null;

  @action public setToken(token: string)
  {
    this.token = token;
  }

  public async requestPasswordReset(
    args: Parameters<typeof RequestPasswordReset>[1],
  ): Promise<void>
  {
    await RequestPasswordReset(this.stores.query, args);
  }

  public async resetPassword(
    args: Parameters<typeof ResetPassword>[1],
  ): Promise<ClientAreaSession>
  {
    const session = await ResetPassword(this.stores.query, args);
    return this.stores.session.ensureContactSession(session);
  }
}
