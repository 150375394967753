import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import {
  Button as GenericButton,
  TextField as GenericTextField,
} from '@breteuil-website/components/common';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const InvalidCredentials = styled('div')({
  color: '#FF0000',
  fontSize: '12px',
});

const StyledTextField = styled(GenericTextField)({
  '&:first-of-type': {
    marginBottom: '1rem',
  },
  '& .MuiInputBase-root': {
    color: '#989898',
    fontFamily: 'Mulish',
    fontSize: '13px',
    alignItems: 'center',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Mulish',
    fontWeight: 700,
  },
});

const StyledButton = styled(GenericButton)({
  marginTop: '1.25rem',
});

const LogInForm = observer(() => {
  const T = useTexts(texts);
  const lang = useLocale();
  const [displayInvalidCredentials, setDisplayInvalidCredentials] = React.useState(false);
  const { logIn, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    email: createValueFormField(logIn.userEmail || '', { validation: (value) => (value !== '') }),
    password: createValueFormField('', { validation: (value) => (value !== '') }),
  }), {
    onSubmit: (values) => {
      setDisplayInvalidCredentials(false);
      const promise = logIn.logIn(values);
      return promise.then(() => {
        router.changeRoute(Routes.clientAreaSearches.generateStaticPath({ lang }));
      }).catch((error) => {
        if (error.code === 'InvalidCredentials')
          setDisplayInvalidCredentials(true);
        else
          globalMessage.displayError(error);
      });
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <StyledTextField
        field={form.fields.email}
        label={T.email}
      />
      <StyledTextField
        field={form.fields.password}
        label={T.password}
        type='password'
      />
      {displayInvalidCredentials && (
        <InvalidCredentials>
          {T.invalidCredentials}
        </InvalidCredentials>
      )}
      <StyledButton
        type='submit'
        disabled={form.submitting}
      >
        {T.send}
      </StyledButton>
    </form>
  );
});

export default LogInForm;
