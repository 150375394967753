import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  pageTitle: string,
  pageDescription: string,
  title: string,
  formatAgencyTitle(isMobile : boolean, name: string): string,
  formatAgencyAddress(address: string, zipCode: string, cityName: string) : JSX.Element,
  formatAgencyAddressMobile(address: string, zipCode: string, cityName: string) : JSX.Element,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
