import { getErrorCode } from "@repo-breteuil/front-error";

export default {
  signUp: 'Create your account',
  alreadyHaveAccount: 'Have an account ?',
  logIn: 'Log in',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'Email',
  phone: 'Mobile',
  password: 'Password',
  confirmPassword: 'Confirm your password',
  confirmPasswordError: 'The password and confirmation password do not match.',
  handleRegisterError: (error: Error): string => {
    switch (getErrorCode(error)) {
      case 'EmailAlreadyUsed':
        return 'Email already in use.';
      case 'MobilePhoneAlreadyUsed':
        return "Phone number already in use.";
      default:
        return "An error as occured, please try again later.";
    }
  },
  emailAlreadyUsed: 'This e-mail is already associated to an other account.',
  activationLinkSent: 'An activation link has been sent, please check your inbox.',
  emailIsLogin: 'The email you registered will be your login.',
  receive: 'I want Breteuil to send me :',
  favoriteProperties: 'Propriétés coup de coeur',//TODO
  create: 'Create my account',
  checkPolicy: 'Consult our privacy policy.',
  security: 'Security',
  phoneNumberError: 'Number is not valid',
  registerError: "Can't register this request", // TO CHANGE
};
