import type { FormField } from '@repo-lib/mobx-forms';

import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useTexts } from '@repo-breteuil/common-texts';
import { AddressPicker } from '@repo-breteuil/react-components';
import lang from '../../texts';
import {
  TextFieldStyle as GenericTextFieldStyle,
} from '@breteuil-website/components/common';
import { TextFieldStyle } from '@breteuil-website/components/common/elements/TextField';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const ValueTextField = styled(styled(MuiTextField)(GenericTextFieldStyle))(TextFieldStyle);

const Container = styled('div')({
  display: 'flex',
  fontFamily: 'Poppins',
});

const CustomTextField = styled(ValueTextField)({
  margin: '5px 0',
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    color: '#050251',
    fontFamily: 'Mulish',
  },
});

const LocationIcon = styled(LocationOn)({
  margin: '0 10px',
  color: '#050251',
});

const AddressPickerError = styled('span')({
  margin: '5px 0',
  display: 'block',
  padding: '0 10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  color: '#D32F2F',
});

const AddressPickerContainer = styled('div')({
});

const HeadContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: 'auto',
});

interface AgencySelectionProps {
  field: FormField<string | null, {
    value: string | null,
  }, string | null>,
  addressRef: React.MutableRefObject<HTMLInputElement | null>,
  isAddressValid: boolean,
  setIsAddressValid: React.Dispatch<React.SetStateAction<boolean>>,
}

const AgencySelection = observer((props: AgencySelectionProps) => {
  const { field, addressRef, isAddressValid, setIsAddressValid } = props;
  const { valuation, query, globalMessage } = useWebsiteStores();
  const T = useTexts(lang).method3.agencySelection;
  const addressWithoutNumberError = useTexts(lang).method1.form.addressWithoutNumberError;
  const [isStreetNumberInAddress, setIsStreetNumberInAddress] = React.useState<Boolean>(true);
  const handleCorrectAddress = (e) => {
    const value = e.target.value;
    if (valuation.startsWithNumber(value) && !isStreetNumberInAddress) {
      if (!isAddressValid)
        setIsAddressValid(true);
      setIsStreetNumberInAddress(true);
    } else if (!valuation.startsWithNumber(value) && isStreetNumberInAddress) {
      setIsStreetNumberInAddress(false);
    }
    return value;
  };
  return (
    <Container>
      <HeadContainer>
        <AddressPickerContainer>
          <AddressPicker
            value={field.value}
            queryStore={query}
            onChange={(value) => {
              if (isStreetNumberInAddress) {
                field.handleChange(value);
                valuation.setAddress(value);
              }
            }}
            onLoadError={(error) => globalMessage.displayError(error)}
            renderInput={(props) => (
              <CustomTextField
                {...props}
                label={T.label}
                onChange={handleCorrectAddress}
                inputRef={addressRef}
                error={!isAddressValid}
                InputProps={{
                  // @ts-ignore
                  ...props.InputProps, // TODO props correct type
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon/>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            limitToFrance
          />
          {!isStreetNumberInAddress ? <AddressPickerError>{addressWithoutNumberError}</AddressPickerError> : null}
        </AddressPickerContainer>
      </HeadContainer>
    </Container>
  );
});

export default AgencySelection;
