import type { CTAProps } from './common';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Stack } from '@mui/material';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { DataInfoMobile, PropertyDetailsWithLogo } from './common';

const Datas = styled('div')({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "2vh 0vw 0vh 0vw",
  '@media (max-width: 1280px)': {
    width: "100%",
    margin: "10px 0",
    flexDirection: 'column',
  },
});

const Header = styled('div')({
  width: "41%",
  fontFamily: "Mulish",
  '@media (max-width: 1280px)': {
    width: '100%',
    margin: "auto",
  },
});

const Description = styled('div')({
  width: "100%",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "30px",
  fontFamily: 'Raleway',
  color: "#757575",
  margin: "40px 0",
  '@media (max-width: 1280px)': {
    width: "100%",
  },
});

const Title = styled('div')({
  fontFamily: 'Poppins',
  fontSize: "50px",
  fontWeight: 600,
  color: "#00195E",
  '@media (max-width: 1280px)': {
    width: "100%",
  },
});

const SubArea = styled('div')({
  margin: '5px 0',
  fontSize: "17px",
  fontWeight: 400,
  color: "#757575",
  fontFamily: 'Raleway',
});

const TitleContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: "baseline",
  color: "#050251",
  gap: theme.spacing(2),
}));

const SeparatorLight = styled('hr')({
  border: "1px solid rgba(117,117,117,0.25)",
  opacity: 1,
  width: "100%",
  margin: "22px 0",
});

export const PropertySeasonalData = observer((props: CTAProps) => {
  const { property } = props;
  const isDesktop = useMediaQueryDesktop();
  return (
    <div>
      <Datas>
        <Header>
          <TitleContainer>
            <Title>
              <span>{property.title}</span>
            </Title>
          </TitleContainer>
          {property.operationType === OperationType.SeasonalRental ? (
            <SubArea>
              <span>{property.subArea.name}</span>
            </SubArea>
          ) : null}
          {!isDesktop ? (
            <React.Fragment>
              <SeparatorLight/>
              <PropertyDetailsWithLogo property={property}/>
              <SeparatorLight/>
            </React.Fragment>
          ) : <PropertyDetailsWithLogo property={property}/>}
          {!isDesktop ? <DataInfoMobile property={property}/> : null}
          <Description>
            <span>{property.description}</span>
          </Description>
        </Header>
      </Datas>
    </div>
  );
});

export default PropertySeasonalData;
