import { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Box, Stack, Typography } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile, useMediaQueryDesktop } from '@breteuil-website/components/theme';
import {
  Link,
  Routes,
} from '@breteuil-website/store/routing';
import { DropdownWithTitle, Button as GenericButton } from '@breteuil-website/components/common';
import texts from './texts';
import { PropertySeasonalData } from './PropertySeasonalData';
import PropertyRows from './PropertyRows';
import {
  PropertyVideo,
  DataInfo,
  CTA,
  PropertyMap,
  PropertyCarousel,
  PropertyAdditionalInfos,
} from './common';

const StickyContainer = styled('div')({
  position:'relative',
  width: "75%",
  maxWidth: '1600px',
  margin: "35px auto 0 auto",
  paddingBottom: '100px',
  '@media (max-width: 1250px)': {
    width: "90%",
    paddingBottom: '50px',
  },
});

const AgencyRow = styled('div')({
  width: '100%',
  backgroundColor: 'rgba(216,216,216,0.25)',
  padding: '20px 0',
  margin: '75px 0 150px',
  '@media (max-width: 1280px)': {
    margin: '5vh 0',
  },
  });

const AgencyContainer = styled('div')({
  width: "75%",
  maxWidth: '1600px',
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  fontFamily: 'Mulish',
  fontWeight: 400,
  fontSize: '17px',
  color: '#050251',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    width: "90%",
    fontSize: '14px',
  },
});

const AgencyTitle = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 700,
});

const AgencyInfos = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: 'auto',
  '& > span': {
    width: '49%',
    textAlign: 'right',
    '@media (max-width: 1280px)': {
      textAlign: 'left',
    },
  },
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    marginLeft: '0',
    width: "90%",
    '& > span': {
      width: '100%',
    },
    },

});

const MapContainer = styled(Box)({
  marginTop: '60px',
});

const AdditionnalInfosContainer = styled(Box)({
  marginTop: '60px',
});


const ValuationButton = styled(GenericButton)({
  height: '33px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '12px',
  padding: '10px !important',
  maxWidth: '160px',
  marginTop: '25px',
});

const AdditionnalInfosTitle = styled(Typography)({
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 700,
    color: '#050251',
    borderBottom: '1px solid #050251',
    '@media (max-width: 1280px)': {
      fontSize: '26px',
    },
});

const InfosWrapper = styled(Box)({
  width: "75%",
  maxWidth: '1600px',
  margin: "35px auto 0 auto",
  paddingBottom: '100px',
  '@media (max-width: 1250px)': {
    width: "90%",
    paddingBottom: '50px',
  },
});

export interface PropertySeasonalRentalBodyProps {
  contactInfo: {
    phone: string,
    email: string,
  },
  property: Property,
  searchParams: URLSearchParams,
  anchorId: string,
}

const PropertyBodyAgency = (props: { property: Property, searchParams: URLSearchParams }) => {
  const { property, searchParams } = props;
  const lang = useLocale();
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  return property.agency ? <AgencyRow>
    <AgencyContainer>
    <Link to={Routes.agency.generatePath({ agencySlug: property.agency.slug }, { lang })}>
      <AgencyTitle>{T.formatAgency(property.agency.name)}</AgencyTitle>
    </Link>
      <AgencyInfos>
        {property.agency.phone ? <span>{T.formatAgencyPhone(property.agency.phone)}</span> : null}
        {property.agency.email ? <span>{T.formatAgencyEmail(property.agency.email)}</span> : null}
      </AgencyInfos>
      {isMobile ? (
        <Link to={Routes.valuation.generateStaticPath({ lang, searchParams })}>
          <ValuationButton>{T.ctaValuation}</ValuationButton>
        </Link>
      ) : null}
    </AgencyContainer>
  </AgencyRow>: null;
};

const PropertyVideoAndSimilarProperty = (props: { property: Property, anchorId: string }) => {
  const { property, anchorId } = props;
  const T = useTexts(texts);
  return (
    <React.Fragment>
      <div id={anchorId}>
        <PropertyVideo property={property}/>
      </div>
      <PropertyRows title={T.recommendations.title} properties={property.exploreProperties.nearby}/>
      <PropertyRows title={T.propertiesExplore} properties={property.exploreProperties.similar}/>
    </React.Fragment>
  );
};

const PropertySeasonalRentalBodyMobile = observer((props: PropertySeasonalRentalBodyProps) => {
  const { property, contactInfo, searchParams, anchorId } = props;
  const additionnalInfosNotEmpty = property.keyInfos?.length || property.surroundings?.length;
  const mapLocalisationNotEmpty = property.latitude && property.longitude;
  const T = useTexts(texts);
  return (
    <React.Fragment>
      <PropertyCarousel property={property}/>
      <CTA property={property} contactInfo={contactInfo}/>
      <StickyContainer>
        <PropertySeasonalData property={property} contactInfo={contactInfo}/>
      </StickyContainer>
     {additionnalInfosNotEmpty || mapLocalisationNotEmpty ? (
       <InfosWrapper>
          {additionnalInfosNotEmpty ? (
            <AdditionnalInfosContainer>
              <Stack direction='column' gap={3}>
                <AdditionnalInfosTitle>{T.keyInfos}</AdditionnalInfosTitle>
                <PropertyAdditionalInfos
                  keyInfos={property.keyInfos}
                  surroundings={property.surroundings}
                />
              </Stack>
            </AdditionnalInfosContainer>
          ) : null}
          {property.latitude && property.longitude ? (
            <MapContainer>
              <Stack direction='column' gap={3}>
                <AdditionnalInfosTitle>{T.location}</AdditionnalInfosTitle>
                <PropertyMap property={property}/>
              </Stack>
            </MapContainer>
          ) : null}
        </InfosWrapper>
     ) : null}
      <PropertyBodyAgency property={property} searchParams={searchParams}/>
      <PropertyVideoAndSimilarProperty property={property} anchorId={anchorId}/>
    </React.Fragment>
  );
});

const PropertySeasonalRentalBodyDesktop = observer((props: PropertySeasonalRentalBodyProps) => {
  const { contactInfo, property, searchParams, anchorId } = props;
  const CTAdata = {
    property,
    contactInfo: contactInfo,
  };
  const additionnalInfosNotEmpty = property.keyInfos?.length || property.surroundings?.length;
  const mapLocalisationNotEmpty = property.latitude && property.longitude;
  const T = useTexts(texts);
  return (
    <React.Fragment>
      <PropertyCarousel property={property}/>
      <StickyContainer>
        <PropertySeasonalData property={property} contactInfo={contactInfo}/>
        <DataInfo property={property} CTAdata={CTAdata}/>
      </StickyContainer>
      {additionnalInfosNotEmpty || mapLocalisationNotEmpty ?(
        <InfosWrapper>
          {additionnalInfosNotEmpty ? (
            <AdditionnalInfosContainer>
              <DropdownWithTitle title={T.keyInfos}>
                <PropertyAdditionalInfos
                  keyInfos={property.keyInfos}
                  surroundings={property.surroundings}
                />
              </DropdownWithTitle>
            </AdditionnalInfosContainer>
          ) : null}
          {mapLocalisationNotEmpty ? (
            <MapContainer>
              <DropdownWithTitle title={T.location}>
                <PropertyMap property={property}/>
              </DropdownWithTitle>
            </MapContainer>
          ) : null}
        </InfosWrapper>
      ) : null}
      <PropertyBodyAgency property={property} searchParams={searchParams}/>
      <PropertyVideoAndSimilarProperty property={property} anchorId={anchorId}/>
    </React.Fragment>
  );
});

const PropertySeasonalRentalBody = (props: PropertySeasonalRentalBodyProps) => {
  const isDesktop = useMediaQueryDesktop();
  return isDesktop ? <PropertySeasonalRentalBodyDesktop {...props}/> : <PropertySeasonalRentalBodyMobile {...props}/>;
};

export default PropertySeasonalRentalBody;
