import { Definition } from ".";

export default {
  pageTitle: "Nos honoraires - Breteuil Remarkable Homes",
  pageDescription: "Découvrez les honoraires et les coûts de services dans différents pays où nous sommes présent.",
  titles: {
    france: "France",
    uk: "UK",
  },
  links: {
    inHouseComplaints: "Procedure de réclamation (UK)",
    clientMoneyProtection: "Certificat agence location (UK)",
    fees: "Nos honoraires",
  },
} satisfies Definition;
