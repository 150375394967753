import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  buy: string,
  rent: string,
  seasonalRental: string,
  valuation: string,
  services: string,
  contact: string,
  joinUs: string,
  marketData: string,
  logoAlt: string,
  languages: {
    french: string,
    english: string,
  },
  logOut: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
