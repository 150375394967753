import type { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useTexts, number as numberTexts } from '@repo-breteuil/common-texts';
import { computePropertyPricePerUnit } from '@breteuil-website/components/common/functions';
import texts from './texts';
import { PropertyPrice } from '@breteuil-website/components/common';

const Container = styled('div')({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  lineHeight: "37px",
  fontFamily: 'Mulish',
});

const PriceContainer = styled('div')({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const Price = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '24px',
  color: "#050251",
  '@media (max-width: 500px)': {
    fontSize: '20px',
  },
});

const PricePerSurface = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '18px',
  color: '#757575',
  textAlign: "right",
  '@media (max-width: 500px)': {
    fontSize: '20px',
  },
});

const SeparatorLight = styled('hr')({
  border: "1px solid rgba(117,117,117,0.25)",
  opacity: 1,
  width: "100%",
  margin: "22px 0",
});

const PropertyDatas = styled('div')({
  display: "flex",
  flexDirection: "row",
});


const PropertyDatasLeft = styled('div')({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: 'Mulish',
  fontWeight: 400,
  fontSize: '17px',
  textAlign: "left",
});

const CollectiveOwnership = styled('div')({
  display: "flex",
  flexDirection: 'column',
  width: "100%",
});

const PlanUrlLink = styled('a')({
  width: "100%",
  display: 'flex',
  color: '#050251',
  fontWeight: 700,
});

const PdfIcon = styled('img')({
  marginLeft: '10px',
  color: '#050251',
  '&:hover': {
    filter: 'opacity(0.5) drop-shadow(0 0 0 #2c75ff)',
    cursor: 'pointer',
  },
});

const Exclu = styled('span')({
  fontWeight: 700,
});

const DpeSpan = styled('span')({
  whiteSpace: 'pre',
});

const PropertyInfo = observer((props: { property: Property }) => {
  const { property } = props;
  const T = useTexts(texts);
  const currentTextsNumber = useTexts(numberTexts.texts);
  return (
    <Container>
      <PriceContainer>
        <Price>
          <PropertyPrice
            priceOnRequest={property.priceOnRequest}
            formattedPrice={
              currentTextsNumber.formatNumber(property.price, {
                separators: true,
                currency: property.currency,
                truncate: 'integer',
                perUnit: computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
              })
            }
          />
        </Price>
        <React.Fragment>
          {!property.priceOnRequest ? (
            property.operationType === OperationType.ResidencyTransaction
              && property.surface ? <PricePerSurface>{T.formatPriceSurface(property.price, property.surface, property.currency)}</PricePerSurface> : null
          ): null}
        </React.Fragment>
      </PriceContainer>
      <SeparatorLight/>
      <PropertyDatas>
        <PropertyDatasLeft>
          {property.displayedExclusive ? <Exclu>{T.exclusive}</Exclu> : null}
          {property.surface ? <span>{T.formatSurface(property.surface)}</span> : null}
          {property.bedrooms ? <span>{T.formatBedrooms(property.bedrooms)}</span> : null}
          {property.operationType === OperationType.ResidencyTransaction ? (
            <DpeSpan>
              {T.formatDPE({ kwh: property.dpe, co2: property.dpeCarbonFootprint })}
            </DpeSpan>
          ) : null}
          {property.collectiveOwnership ? (
            <CollectiveOwnership>
              {property.collectiveOwnershipLots ? <span>{`${T.formatCopro(property.collectiveOwnershipLots.valueOf())}`}</span> : null}
              {property.collectiveOwnershipCharges ? <span>{`${T.formatCharges(property.collectiveOwnershipCharges.valueOf(), property.currency)}`}</span> : null}
            </CollectiveOwnership>
          ) : null}
          {property.planURL ? (
            <PlanUrlLink href={property.planURL} target="_blank" rel="noopener noreferrer">
              {T.pdfMap}
              <PdfIcon src="/assets/icons/pdfDarkBlue.svg" alt="pdf icon"/>
            </PlanUrlLink>
          ) : null}
        </PropertyDatasLeft>
      </PropertyDatas>
      <SeparatorLight/>
    </Container>
  );
});

export default PropertyInfo;
