import * as React from 'react';
import { EmailValidation } from '@breteuil-website/_hardcoded';
import { PhoneValidation } from '@breteuil-website/_hardcoded';
import { useForm, createValueFormField } from '@repo-lib/mobx-forms';
import { getErrorCode } from '@repo-breteuil/front-error';
import lang from '../../texts';
import MUIPhoneNumber from 'material-ui-phone-number-2';
import { styled } from '@mui/material/styles';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import {
  Button,
  TextFieldStyle as GenericTextFieldStyle,
  TextField as GenericTextField,
  ConfirmPhoneDialog,
} from '@breteuil-website/components/common';
import AgencySelection from '../agency-selection';
import { useWebsiteStores } from '@breteuil-website/components/providers';


const TextFieldStyle = {
  marginBlock: '.5em',
  fontSize: '1rem',
  backgroundColor: 'white',
  '& .MuiInputLabel-root': {
    color: 'rgba(5, 2, 81, 0.5)',
  },
};

const Container = styled('div')({
  display: 'flex',
  flexDirection: "column",
  width: "60%",
  minHeight: "350px",
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '24px',
  fontWeight: 700,
  backgroundColor: 'transparent',
  margin: '0 5px',
  padding: '20px',
  "@media (max-width: 1280px)": {
    width: '100%',
    margin: '0 auto',
    padding: '15px',
  },
});


const Title = styled('h1')({
  color: '#050251',
  fontSize: '19px',
  '@media (max-width: 1280px)': {
    fontSize: '17px',
    margin: "20px auto",
  },
});

const FormElementContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '5px',
  "@media (max-width: 1280px)": {
    display: 'block',
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  width: '50%',
  marginTop: 'auto',
  minWidth: '300px',
  "@media (max-width: 1280px)": {
    width: '100%',
    maxWidth: '275px',
    margin: 'auto',
  },
});

const ConfirmButton = styled(Button)({
  fontSize: '13px !important',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});


const TextField = styled(GenericTextField)(TextFieldStyle);
const PhonePicker = styled(styled(MUIPhoneNumber)(GenericTextFieldStyle))(TextFieldStyle);


const ConfirmationForm = (props: { handleNext: () => void }) => {
  const { valuation, globalMessage } = useWebsiteStores();
  const locale = useLocale();
  const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState<Boolean>(true);
  const [confirmPhoneDialogOpen, setConfirmPhoneDialogOpen] = React.useState(false);
  const [isAddressValid, setIsAddressValid] = React.useState<boolean>(true);
  const nameRef = React.useRef<HTMLInputElement | null>(null);
  const surnameRef = React.useRef<HTMLInputElement | null>(null);
  const emailRef = React.useRef<HTMLInputElement | null>(null);
  const phoneRef = React.useRef<HTMLInputElement | null>(null);
  const addressRef = React.useRef<HTMLInputElement | null>(null);
  const handleConfirmPhoneDialogClose = () => { setConfirmPhoneDialogOpen(false); };
  const T = useTexts(lang);
  const T_confirmationForm = useTexts(lang).method3.details.confirmationForm;

  const handlePhoneConfirmationSubmit = (submittedCode: string) => {
    valuation.setVerificationCode(submittedCode);
    const promise = valuation.verifyConfirmMeeting();
    globalMessage.handlePromise(promise, {
      onSuccess: () => {
        handleConfirmPhoneDialogClose();
        valuation.setIsMeetingConfirmed(true);
        props.handleNext();
        const elem = document.getElementById(T.method3.anchorId);
        elem?.scrollIntoView({ behavior: "smooth" });
      },
      onError: (error) => {
        if (getErrorCode(error) === 'MeetingAlreadyConfirmed') {
          valuation.setIsMeetingConfirmed(true);
        }
        if (getErrorCode(error) === 'MeetingDateAlreadyLocked') {
          valuation.lockUnavailableMeeting();
          valuation.setSelectedMeetingDate(null);
        }
      },
      successMessage: null,
      errorMessage: (error) =>  {
        return T.method1.form.handleFormSubmissionErrors(error);
      },
    });
  };

  const handleResendPromise = () => valuation.agencyMeetingResendVerifyCode.ensureSuccessReload();

  const form = useForm(() => ({
    name: createValueFormField('', { validation: (value) => value !== null && value.length > 0}),
    surname: createValueFormField('', { validation: (value) => value !== null && value.length > 0 }),
    email: createValueFormField('', { validation: (value) => EmailValidation(value) }),
    phone: createValueFormField('', { validation: (value) => (PhoneValidation(value)) }),
    comment: createValueFormField(''),
    address: createValueFormField<string | null>(valuation.selectedAddress, { validation: (value) => value ? valuation.startsWithNumber(value) : false }),
  }), {
      onSubmit: (values) => {
        const promise = valuation.createMeeting.ensureSuccessReload({
          address: valuation.address!,
          meetingDate: valuation.selectedMeetingDate!,
          phone: values.phone,
          email: values.email,
          name: `${values.surname} ${values.name}`,
          remark: values.comment,
          language: locale,
        });
        return globalMessage.handlePromise(promise, {
          onSuccess: (res) => {
            if (valuation.verifyPhone) {
              form.reset();
              valuation.setIsMeetingConfirmed(true);
            globalMessage.display(T_confirmationForm.successMessage, { severity: 'success' });
          }
          else {
              setConfirmPhoneDialogOpen(true);
            }
          },
          onError: (error) => {
          if (getErrorCode(error) === 'MeetingAlreadyConfirmed') {
              valuation.setIsMeetingConfirmed(true);
            return globalMessage.display(T_confirmationForm.errorMeetingAlreadyConfirmed, { severity: 'error' });
            }
          if (getErrorCode(error) === 'InvalidPhoneNumber') {
              setIsPhoneNumberValid(false);
            return globalMessage.display(T_confirmationForm.errorInvalidPhoneNumber, { severity: 'error' });
            }
          if (getErrorCode(error) === 'MeetingDateAlreadyLocked') {
              // this will update this._availableMeetings
              valuation.lockUnavailableMeeting();
              valuation.setSelectedMeetingDate(null);
            return globalMessage.display(T_confirmationForm.errorMeetingDateAlreadyLocked, { severity: 'error' });
            }
          if (getErrorCode(error) === 'InvalidMeetingDate') {
              // this will update this._availableMeetings
              valuation.lockUnavailableMeeting();
              valuation.setSelectedMeetingDate(null);
            return globalMessage.display(T_confirmationForm.errorInvalidMeetingDate, { severity: 'error' });
            }
            if (error && !valuation.selectedMeetingDate) {
            const anchorId = T.method3.anchorId;
              const elem = document.getElementById(anchorId);
              elem?.scrollIntoView({ behavior: "smooth" });
            return globalMessage.display(T_confirmationForm.errorNoMeetingDate, { severity: 'error' });
            }
          return ;
          },
          successMessage: null,
        errorMessage: (error) =>  T.errorToast(error),
        });
      },
      onInvalidSubmit() {
        if (!form.fields.surname.valid)
surnameRef.current?.focus();
        else if (!form.fields.name.valid)
nameRef.current?.focus();
        else if (!form.fields.email.valid)
emailRef.current?.focus();
        else if (!form.fields.phone.valid)
phoneRef.current?.focus();
        else if (!form.fields.address.valid)
addressRef.current?.focus();
        setIsPhoneNumberValid(form.fields.phone.valid);
        setIsAddressValid(form.fields.address.valid);
      },
  });
  return (
    <Container>
      <Title>{T_confirmationForm.title}</Title>
      <Form onSubmit={form.handleSubmit}>
        <FormElementContainer>
          <TextField
            field={form.fields.surname}
            label={T_confirmationForm.labels.surname}
            autoComplete="given-name"
            inputRef={surnameRef}
          />
          <TextField
            field={form.fields.name}
            label={T_confirmationForm.labels.name}
            autoComplete="family-name"
            inputRef={nameRef}
          />
        </FormElementContainer>
        <FormElementContainer>
          <TextField
            field={form.fields.email}
            label={T_confirmationForm.labels.email}
            type="email"
            autoComplete='email'
            inputRef={emailRef}
          />
          <PhonePicker
            label={T_confirmationForm.labels.phone}
            error={!isPhoneNumberValid}
            defaultCountry="fr"
            onlyCountries={['fr','gb','us','es','pt']} // TODO Remove harcoded values
            variant="outlined"
            countryCodeEditable={false}
            disableAreaCodes={true}
            onChange={(value) => {
              form.fields.phone.handleChange(value);
            }}
            inputProps={{ ref: phoneRef }}
          />
        </FormElementContainer>
        <AgencySelection
          field={form.fields.address}
          addressRef={addressRef}
          isAddressValid={isAddressValid}
          setIsAddressValid={setIsAddressValid}
        />
        <ButtonContainer>
          <ConfirmButton type="submit" disabled={form.submitting}>{T_confirmationForm.confirm}</ConfirmButton>
        </ButtonContainer>
      </Form>
      <ConfirmPhoneDialog
        open={confirmPhoneDialogOpen}
        handleClose={handleConfirmPhoneDialogClose}
        handleSubmit={handlePhoneConfirmationSubmit}
        handleResendPromise={handleResendPromise}
      />
    </Container>
  );
};

export default ConfirmationForm;
