import { QueryStore } from '@repo-breteuil/front-store-query';

export default async function AgencyMeetingResendVerifyCode(
  qs: QueryStore,
  args: {
    agencyMeetingId: number,
  },
): Promise<boolean> {
  return qs.mutation({
    operationName: 'ValuationAgencyMeetingResendVerifyCode',
    variables: {
      agencyMeetingId: 'Int!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            agencyMeeting: {
              resendPhoneVerifyCode: [{
                args: {
                  agencyMeetingId: true,
                },
              }, true],
            },
          },
        },
      },
    },
  }, args);
}
