import type { Currency } from '@repo-lib/utils-texts';
import {
  type IdType,
  type RentalPeriod,
  type OperationType,
  PictureFormat,
} from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import { paginationResultAsList, QueryStore } from '@repo-breteuil/front-store-query';
import {
  getAreaFRNamePrepositionDeLieu,
} from '@breteuil-website/_hardcoded';

export interface Area
{
  id: IdType,
  slugs: Array<string>,
  slug: string,
  name: string,
  title: string,
  description: string,
  coverPictureURL: string,
  currency: Currency,
  rentalPeriod: RentalPeriod,
  operationTypes: Array<OperationType>,
  homepagePosition: number | null,
  filtersPosition: number | null,
  geoAreaId: IdType,
  FRNamePrepositionDeLieu: string | null,
}

export interface SubArea
{
  id: IdType,
  slugs: Array<string>,
  slug: string,
  name: string,
  geoAreaId: IdType,
  FRNamePrepositionDeLieu: string | null,
}

export interface SubAreaLink
{
  areaId: IdType,
  subAreaId: IdType,
  position: number,
}

export interface GetAreasResultType
{
  areas: Array<Area>,
  subAreasLinks: Array<SubAreaLink>,
  subAreas: Array<SubArea>,
}

export async function GetAreas(
  qs: QueryStore,
  args: {
    language: Language,
  },
): Promise<GetAreasResultType>
{
  const result: GetAreasResultType = await qs.query({
    operationName: 'GetAreas',
    variables: {
      language: 'Language!',
      format: 'PictureFormat',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          areas: paginationResultAsList({
            id: true,
            slugs: true,
            slug: [{ args: { language: true }}, true ],
            name: [{ args: { language: true }}, true ],
            title: [{ args: { language: true }}, true ],
            description: [{ args: { language: true }}, true ],
            coverPictureURL: [{ args: { format: true }}, true],
            currency: true,
            rentalPeriod: true,
            operationTypes: true,
            homepagePosition: true,
            filtersPosition: true,
            geoAreaId: true,
          }),
          subAreasLinks: {
            areaId: true,
            subAreaId: true,
            position: true,
          },
          subAreas: paginationResultAsList({
            id: true,
            name: [{ args: { language: true }}, true ],
            slugs: true,
            slug: [{ args: { language: true }}, true ],
            geoAreaId: true,
          }),
        },
      },
    },
  }, { ...args, format: PictureFormat._480p });
  for (const area of result.areas)
    area.FRNamePrepositionDeLieu = getAreaFRNamePrepositionDeLieu(area.name);
  for (const subArea of result.subAreas)
    subArea.FRNamePrepositionDeLieu = getAreaFRNamePrepositionDeLieu(subArea.name);
  return result;
}
