import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import { Button, TextField } from '@breteuil-website/components/common/elements';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  fontFamily: 'Poppins',
  maxWidth: '450px',
  height: '100%',
});

const Text = styled('div')({
  fontSize: '15px',
  margin: '30px 0px',
});

const InvalidPasswordToken = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);
  const { passwordReset, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    email: createValueFormField('', { validation: (value) => (value !== '') }),
  }), {
    onSubmit: (values) => {
      const promise = passwordReset.requestPasswordReset(values);
      return globalMessage.handlePromise(promise, {
        successMessage: () => T.emailSent,
        errorMessage: () => T.errorNotif,
        onSuccess: () => {
          router.changeRoute(Routes.clientAreaLogIn.generateStaticPath({ lang }));
        },
      });
    },
  });

  return(
    <Container>
      <Text>
        {T.text}
      </Text>
      <TextField
        field={form.fields.email}
        label={T.email}
      />
      <Button
        disabled={form.submitting}
        onClick={form.handleSubmit}
      >
        {T.resendEmail}
      </Button>
    </Container>
  );
});

export default InvalidPasswordToken;
