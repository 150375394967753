import type { MatchedRouteHandler } from './RoutingInfo';
import type { PageRedirect, RouteBindingRedirect, ScrollAnchor } from './PageRouteHandlers';
import type { ParsedLocation, ResolvedRouteLocation } from '@repo-lib/routing-routes';

import { observable, action, computed } from 'mobx';
import { Router } from 'ptr-router';
import { filterRecord, omitUndefinedValues } from '@repo-lib/utils-core';
import { transformRouteHandler } from './utils';
import RoutingInfo from './RoutingInfo';
import PageRouteHandlers from './PageRouteHandlers';

export function isRouteBindingRedirect<PageType>(
  redirect: MatchedRouteHandler | RouteBindingRedirect<PageType> | PageRedirect<PageType>,
): redirect is RouteBindingRedirect<PageType>
{
  return (('page' in redirect) && ('resolvedLocation' in redirect));
}

export function isMatchedRouteHandler<PageType>(
  redirect: MatchedRouteHandler | PageRedirect<PageType>,
): redirect is MatchedRouteHandler
{
  return ('resolvedLocation' in redirect);
}

export interface PageRouterOpts<PageType>
{
  enableBrowserHistoryScrollRestoration?: boolean | undefined,
  beforeRouteChange?: ((location: ParsedLocation | null, info: RoutingInfo) => void) | undefined,
  onRouteNotFound?: ((
    location: ParsedLocation | null,
    info: RoutingInfo,
  ) => (
    MatchedRouteHandler | string | null | RouteBindingRedirect<PageType> | PageRedirect<PageType>
  )) | undefined,
  onRouteChange?: ((location: ResolvedRouteLocation, info: RoutingInfo) => void) | undefined,
  onRouteChangeError?: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => string | null | PageRedirect<PageType>) | undefined,
  onRouteChangeDroppedError?: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => void) | undefined,
}

export function makePageRedirectWithDefaults<PageType>(
  redirect: PageRedirect<PageType> | string | null,
  defaults: PageRedirect<PageType>,
): PageRedirect<PageType>
{
  return (
    (redirect === null) ? (
      defaults || {}
    ) : (typeof redirect === 'string') ? {
      ...defaults,
      route: redirect,
    } : {
      ...defaults,
      ...filterRecord(redirect, (value) => (value !== null && value !== undefined)),
    }
  );
}

export default class PageRouter<PageType>
{
  private _handlers: PageRouteHandlers<PageType>;
  private _routingInfo: RoutingInfo;

  @observable private _currentLocationValid: boolean = false;

  private convertRouteBindingRedirectToMatchedRouteHandler(
    redirect: RouteBindingRedirect<PageType>,
  ): MatchedRouteHandler
  {
    const { page, handler, resolvedLocation } = redirect;
    return {
      resolvedLocation,
      handler: transformRouteHandler(handler, (redirect) => (
        this.handlePageRedirect(makePageRedirectWithDefaults(redirect, { page }))
      )),
    };
  }

  @action.bound private handlePageRedirect(redirect: PageRedirect<PageType> | string | null): string | null
  {
    if (redirect === null || typeof redirect === 'string')
      return redirect;
    const { route, page, scrollTop } = redirect;
    const scrollTopValue: number | ScrollAnchor | null = (
      (scrollTop === undefined || scrollTop === 'reset') ? (
        0
      ) : (
        scrollTop
      )
    );
    if (page !== undefined && page !== null)
    {
      this._currentLocationValid = true;
      this.state.setCurrentPage(page);
    }
    if (scrollTopValue !== null)
    {
      setTimeout(() => { //Adding slight delay (next tick) so that the page is rendered before we scroll
        if (typeof scrollTopValue === 'number')
        {
          window.scrollTo({
            top: scrollTopValue,
          });
        }
        else
        {
          const { elementId, verticalAlign, horizontalAlign } = scrollTopValue;
          const element = document.getElementById(elementId);
          if (element)
            element.scrollIntoView(omitUndefinedValues({
              behavior: 'instant' as any/*TypeScript typings are broken here*/,
              block: verticalAlign,
              inline: horizontalAlign,
            }));
          else
            window.scrollTo({ top: 0 }); //Default behaviour: go to page top
        }
      }, 0);
    }
    if (route === undefined)
      return null;
    return route;
  }

  constructor(
    router: Router,
    private state: {
      currentPage: PageType,
      setCurrentPage(page: PageType): void,
    },
    opts: PageRouterOpts<PageType> = {},
  )
  {
    this._handlers = new PageRouteHandlers<PageType>(this.handlePageRedirect);
    const {
      onRouteNotFound,
      onRouteChange,
      onRouteChangeError,
      ...rest
    } = opts;
    this._routingInfo = new RoutingInfo(router, this._handlers.handlers, {
      ...rest,
      onRouteChange: (location, info) => {
        this._currentLocationValid = true;
        if (opts.onRouteChange)
          opts.onRouteChange(location, info);
      },
      onRouteNotFound: onRouteNotFound && ((location: ParsedLocation | null, info: RoutingInfo) => {
        const redirect = onRouteNotFound(location, info);
        if (typeof redirect !== 'string' && redirect !== null)
        {
          if (isRouteBindingRedirect(redirect))
            return this.convertRouteBindingRedirectToMatchedRouteHandler(redirect);
          if (isMatchedRouteHandler(redirect))
            return redirect;
        }
        return this.handlePageRedirect(redirect);
      }),
      onRouteChangeError: onRouteChangeError && ((error: Error, location: ParsedLocation | null, info: RoutingInfo) => {
        const redirect = onRouteChangeError(error, location, info);
        return this.handlePageRedirect(redirect);
      }),
    });
  }

  public get handlers(): PageRouteHandlers<PageType>
  {
    return this._handlers;
  }

  @action public changeRouteBypassHandler(
    route: string | null | PageRedirect<PageType>,
    opts: {
      replace?: boolean | undefined,
      force?: boolean | undefined,
    } = {},
  )
  {
    this._routingInfo.changeRouteBypassHandler(this.handlePageRedirect(route), opts);
  }

  @computed public get currentPage(): { state: 'valid', page: PageType } | { state: 'invalid' }
  {
    if (this._currentLocationValid)
      return { state: 'valid', page: this.state.currentPage };
    return { state: 'invalid' };
  }

  public get currentLocationValid(): boolean
  {
    return this._currentLocationValid;
  }

  @action public setCurrentLocationValid(valid: boolean)
  {
    this._currentLocationValid = valid;
  }

  @action public changeRoute(
    route: MatchedRouteHandler | string | null | RouteBindingRedirect<PageType>,
    opts: {
      replace?: boolean | undefined,
      force?: boolean | undefined,
      invalidateCurrentLocation?: boolean | undefined,
    } = {},
  ): void
  {
    if (opts.invalidateCurrentLocation)
      this.setCurrentLocationValid(false);
    if (route !== null && typeof route !== 'string' && isRouteBindingRedirect(route))
    {
      this._routingInfo.changeRoute(this.convertRouteBindingRedirectToMatchedRouteHandler(route), opts);
      return;
    }
    this._routingInfo.changeRoute(route, opts);
  }

  //RoutingInfo methods:

  public get loading(): boolean
  {
    return this._routingInfo.loading;
  }

  public get loadError(): Error | null
  {
    return this._routingInfo.loadError;
  }

  public get currentIdentifier(): string
  {
    return this._routingInfo.currentIdentifier;
  }

  public updateFromCurrentLocation(): void
  {
    this._routingInfo.updateFromCurrentLocation();
  }

  public get router()
  {
    return this._routingInfo.router;
  }
}
