import * as React from 'react';
import { passwordStrength as getPasswordStrength } from 'check-password-strength';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';

const Container = styled('div')({});

const StrengthBarsContainer = styled('div')({
  display: 'flex',
  columnGap: '3px',
  marginTop: '5px',
});

const StrengthBar = styled('div')<{
  isOn: boolean,
  strengthLevel: number,
}>(({ isOn, strengthLevel }) => {
  if (!isOn) {
    return {
      backgroundColor: '#C0C0C0',
      height: '10px',
      width: '100%',
    };
  }

  const colors = {
    1: '#484A73', // weak
    2: '#04055D', // medium
    3: '#1C20FF', // strong
  };

  return {
    backgroundColor: colors[strengthLevel as keyof typeof colors],
    height: '10px',
    width: '100%',
  };
});

const StrengthText = styled('div')({
  fontFamily: 'Poppins',
  fontSize: '14px',
  color: '#777777',
  marginTop: '5px',
});

const StrengthLevels = [
  {
    id: 0,
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    minDiversity: 1,
    minLength: 6,
  },
  {
    id: 2,
    minDiversity: 2,
    minLength: 7,
  },
  {
    id: 3,
    minDiversity: 3,
    minLength: 8,
  },
];

const StrengthLevelBar = (
  props: {
    passwordStrength: number,
    requiredStrength: number,
  },
) => {
  const { passwordStrength, requiredStrength } = props;
  const isOn = passwordStrength >= requiredStrength;
  const strengthLevel = isOn ? Math.min(passwordStrength, 3) : 0;

  return <StrengthBar isOn={isOn} strengthLevel={strengthLevel}/>;
};

const PasswordStrengthBar = observer((
  props: {
    password: string,
  },
) => {
  const T = useTexts(texts);
  const { id: passwordStrength } = getPasswordStrength(props.password, StrengthLevels as any);

  const text = (passwordStrength >= 3)
    ? T.strong
    : (passwordStrength >= 2)
    ? T.medium
    : (passwordStrength >= 1)
    ? T.weak
    : T.tooWeak;

  return (
    <Container>
      <StrengthBarsContainer>
        <StrengthLevelBar requiredStrength={1} passwordStrength={passwordStrength}/>
        <StrengthLevelBar requiredStrength={2} passwordStrength={passwordStrength}/>
        <StrengthLevelBar requiredStrength={3} passwordStrength={passwordStrength}/>
      </StrengthBarsContainer>
      <StrengthText>
        {text}
      </StrengthText>
    </Container>
  );
});

export default PasswordStrengthBar;
