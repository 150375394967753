import type { ComponentType, ErrorInfo } from 'react';

import * as React from 'react';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';

export type ErrorPageProps
  = { kind: 'load-error', error: Error }
  | { kind: 'render-error', error: Error }
  | { kind: 'invalid-location' }
  | { kind: 'unmapped-page' }
;

export interface RouterProps<PageType>
{
  LoadingPage: ComponentType<{}>,
  ErrorPage: ComponentType<ErrorPageProps>,

  pages: Map<PageType, ComponentType<{}>>,

  //The properties of `router` should be @observable
  router: {
    currentPage: { state: 'valid', page: PageType } | { state: 'invalid' },
    loading: boolean,
    loadError: Error | null,
  },

  onRenderError?: (error: Error, errorInfo: ErrorInfo) => void,
}

const Router = observer(<PageType extends any>(props: RouterProps<PageType>) => {
  const {
    LoadingPage,
    ErrorPage,

    pages,

    router,

    onRenderError,
  } = props;
  const { currentPage, loading, loadError } = router;

  const errorBoundaryRef = React.useRef<ErrorBoundary | null>(null);
  const renderErrorState = React.useRef<'none' | 'nextRender' | 'error'>('none');
  const ErrorBoundaryFallback = React.useCallback((props: { error: Error }) => (
    <ErrorPage kind="render-error" error={props.error}/>
  ), [ ErrorPage ]);
  const handleRenderError = React.useCallback((error: Error, errorInfo: ErrorInfo) => {
    renderErrorState.current = 'nextRender';
    if (onRenderError)
      onRenderError(error, errorInfo);
  }, [ onRenderError ]);

  //Resetting renderError to null when router state changes so we can render other pages or retry a render on the failed page
  React.useEffect(() => {
    if (renderErrorState.current === 'none')
      return;
    if (renderErrorState.current === 'nextRender')
    {
      renderErrorState.current = 'error';
      return;
    }
    const errorBoundary = errorBoundaryRef.current;
    if (errorBoundary)
    {
      errorBoundary.resetErrorBoundary();
      renderErrorState.current = 'none';
    }
  }, [ currentPage, loading, pages ]);

  if (currentPage.state === 'valid')
  {
    const Page = pages.get(currentPage.page);
    if (Page)
      return (
        <ErrorBoundary ref={errorBoundaryRef} fallbackRender={ErrorBoundaryFallback} onError={handleRenderError}>
          <Page/>
        </ErrorBoundary>
      );
  }
  if (loading)
    return (<LoadingPage/>);

  if (loadError)
    return (<ErrorPage kind="load-error" error={loadError}/>);
  if (currentPage.state !== 'valid')
    return (<ErrorPage kind="invalid-location"/>);
  return (<ErrorPage kind="unmapped-page"/>);
});

export default Router;
