import * as React from 'react';

export default {
  tabs: {
    profile: 'My profile',
    searches(): JSX.Element{
      return(
        <React.Fragment>Searches<br/> and alerts </React.Fragment>
      );
    },
    favorites: 'Favorites',
    logout: 'Log out',
    overview: 'Overview',
    walletAndMarket: 'Wallet',
  },
  returnToSite: 'Back to website',
  clientArea: 'Client Area',
  logOut: 'Logout',
};
