import { Locale } from '@repo-breteuil/common-texts';
import { makeTranslatedRoutes } from '@repo-lib/routing-routes';

const translatedRoutesLocales = {
  [Locale.French]: null,
  [Locale.English]: '/en' ,
} as const;

const {
  TranslatedRoute,
  TranslatedStaticRoute,
  TranslatedRoutes,
  getRouteInOtherLanguage,
} = makeTranslatedRoutes<Locale>(translatedRoutesLocales);

export {
  TranslatedRoute,
  TranslatedStaticRoute,
  TranslatedRoutes,
  getRouteInOtherLanguage,
};
