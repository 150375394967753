import * as React from 'react';
import texts from '../texts';
import { Page } from '@breteuil-website/components/common';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: '#050251',
  width: '100%',
  position: 'relative',
  height: isMobile ? '39px' : '91px',
}));

const Title = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  fontWeight: 700,
  ...(isMobile ? {
    fontSize: '17px',
    whiteSpace: 'nowrap',
  } : {
    fontSize: '43px',
  }),
}));

const Subtitle = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  alignItems: 'center',
  textAlign: 'center',
  color: '#050251',
  fontWeight: 700,
  ...(isMobile ? {
    margin: '57px 10% 0px 10%',
    fontSize: '21px',
  } : {
    margin: '100px auto 0px auto',
    width: '524px',
    fontSize: '26px',
  }),
}));

const TextPicture = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  alignItems: 'center',
  textAlign: 'center',
  color: '#757575',
  fontWeight: 400,
  fontSize: '15px',
  ...(isMobile ? {
    margin: '20px 15% 0px 15%',
  } : {
    margin: '15px auto 0px auto',
    width: '500px',
    whiteSpace: 'pre-line',
  }),
}));

const Picture = styled('img')<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'block',
  ...(isMobile ? {
    objectFit: 'contain',
    margin: '10px auto 55px auto',
    width: '100%',
    height: '279px',
  } : {
    margin: '10px auto 160px auto',
    width: '742px',
    height: '495px',
  }),
}));

const FatalError = observer (() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();

  return (
    <Page>
      <Container isMobile={isMobile}>
        <Title isMobile={isMobile}>
          {T.fatal.title}
        </Title>
      </Container>
      <Subtitle isMobile={isMobile}>
        {T.fatal.subtitle}
      </Subtitle>
      <TextPicture isMobile={isMobile}>
        {T.fatal.pictureText}
        <br/>
      </TextPicture>
      <Picture
        src='/assets/fatalErrorHedghog.jpg'
        isMobile={isMobile}
      />
    </Page>
  );
});

export default FatalError;
