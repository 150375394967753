import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from './texts';
import Page from '@breteuil-website/components/common/app/Page';
import RegisterForm from '@breteuil-website/components/pages/client-area/login/RegisterForm';

const Container = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  fontFamily: 'Mulish',
  color: '#757575',
  fontSize: '15px',
});

const LoginSection = styled('div')({
  maxWidth: '500px',
  width: '90%',
  margin: '0px auto 50px auto',
});

const TitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const Title = styled('p')({
  fontFamily: 'Poppins',
  color: '#050251',
  fontSize: '50px',
  fontWeight: 700,
  margin: '47px 0 0 0',
});

const LogInContainer = styled('p')({
  margin: '5px 0 30px 0',
  display: 'flex',
  justifyContent: 'center',
});

const Login = styled('span')({
  marginRight: '5px',
});

const CustomLink = styled(Link)({
  fontSize: '15px',
  color: '#0C17E1',
  fontWeight: 600,
});

const Separator = styled('div')({
  background: 'linear-gradient(to left, #FFF, #989898 20%, #989898 80%, #FFF)',
  margin: '45px auto 30px auto',
  height: '1px',
});

const SignUp = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);

  return(
    <Page>
      <Container>
        <TitleContainer>
          <Title>{T.signUp}</Title>
          <LogInContainer>
            <Login>{T.alreadyHaveAccount}</Login>
            <CustomLink to={Routes.clientAreaLogIn.generateStaticPath({ lang })}>{T.logIn}</CustomLink>
          </LogInContainer>
        </TitleContainer>
        <LoginSection>
          <RegisterForm/>
          <Separator/>
        </LoginSection>
      </Container>
    </Page>
  );
});

export default SignUp;
