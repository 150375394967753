import type { QueryStore } from '@repo-breteuil/front-store-query';

export async function LogOut(qs: QueryStore): Promise<boolean>
{
  return qs.mutation({
    operationName: 'LogOut',
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            logOut: true,
          },
        },
      },
    },
  });
}
