import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { Button } from '@breteuil-website/components/common';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from './texts';


const BannerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    margin: 'auto',
    maxWidth: '1500px',
    "@media (max-width: 960px)": {
        width: '100%',
        flexDirection: 'column',
    },
});

const ClientAreaImageWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
        boxShadow: "0px 102px 20px -100px rgba(0, 0, 0, 0.25)",
    },
});

const ClientAreaImage = styled('img')({
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px',
    maxHeight: '550px',
    "@media (max-width: 960px)": {
        maxWidth: '275px',
    },
});


const ClientAreaTextWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
    },
});

const ClientAreaText = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    margin: 'auto 0 auto auto',
    "@media (max-width: 960px)": {
        width: '80%',
        margin: '50px auto',
    },
});

const ClientAreaTextTitle = styled('span')({
    display: 'flex',
    fontSize: '30px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '45px',
    color: '#050251',
    '@media (max-width: 960px)': {
        fontSize: '24px',
        flexDirection: 'column',
        lineHeight: '30px',
    },
});

const ClientAreaTextDescription = styled('span')({
    fontSize: '14px !important',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#050251',
    margin: '30px 0',
});

const ClientAreaTextButton = styled(Button)({
    maxWidth: "200px",
    fontSize: '12px !important',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    fontWeight: 700,
    backgroundColor: '#050251 !important',
});

const ClientArea = observer(() => {
    const T = useTexts(texts).clientAreaBanner;
    const lang = useLocale();
    const isMobile = useMediaQueryMobile();
    return (
        <BannerContainer>
            <ClientAreaImageWrapper>
                <ClientAreaImage src={isMobile ? "/assets/porte_cropped.png" : "/assets/porte.png"}/>
            </ClientAreaImageWrapper>
            <ClientAreaTextWrapper>
                <ClientAreaText>
                    <ClientAreaTextTitle>{isMobile ? T.mobileTitle() : T.title}</ClientAreaTextTitle>
                    <ClientAreaTextDescription>{T.description}</ClientAreaTextDescription>
                    <Link to={Routes.clientAreaLogIn.generateStaticPath({ lang })}>
                        <ClientAreaTextButton>
                            {T.button}
                        </ClientAreaTextButton>
                    </Link>
                </ClientAreaText>
            </ClientAreaTextWrapper>
        </BannerContainer>
    );
});

export default ClientArea;
