import type { Agency as AgencyAPI } from './api';
import type { Agency as AgencyHardCoded } from '@breteuil-website/_hardcoded';

import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { type QueryStore, wrapStoreQuery } from '@repo-breteuil/front-store-query';
import { AgencyIdToAgencyDetails, hardCodedAgencyIds } from '@breteuil-website/_hardcoded';
import { GetAgencies } from './api';

export { GetAgencies };

export interface Agency extends AgencyAPI, AgencyHardCoded {}

export function extendAgenciesHardcoded(agencies: Array<AgencyAPI>): Array<Agency>
{
  return agencies
    .filter((agency) => hardCodedAgencyIds.includes(agency.id))
    .map(agency => {
      const hardCodedAgency = AgencyIdToAgencyDetails.get(agency.id);
      if (hardCodedAgency === undefined)
        throw new Error(`Missing hardcoded details for Agency${agency.id}`);
      // Temporary to prevent having agency.id as slug
      const slugs = agency.slugs.length < 2 ? [hardCodedAgency.slug, ...agency.slugs] : agency.slugs;
      // Temporary to prevent having null as address
      const address = agency.address || [hardCodedAgency.address, hardCodedAgency.zipCode, hardCodedAgency.cityName].join(', ');
      // Temporary to prevent bad slug
      const slug = agency.slug === agency.id.toString() ? hardCodedAgency.slug : agency.slug ;
      return { ...hardCodedAgency, ...agency, slugs: slugs, address: address, slug: slug };
    });
}

export async function GetAgenciesHardcoded(qs: QueryStore)
{
  const agencies = await GetAgencies(qs);
  return extendAgenciesHardcoded(agencies);
}

export class AgenciesStore
{
  constructor(public stores: { query: QueryStore })
  {
  }

  agencies = new Fetchable<any, Array<Agency>>(wrapStoreQuery(this, GetAgenciesHardcoded), { catchUnhandled: handleNonCriticalError });
}
