import { DaySchedule, TimeRange, WeekSchedule } from '@repo-lib/utils-week-schedule';

import * as React from 'react';
import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export function oxfordCommaLogic(
  array: Array<string>,
  separator: string,
  lastSeparator?: string,
): string
{
  const length = array.length;
  if (length === 1)
    return array[0];
  if (length === 2)
    return (array.join(lastSeparator));
  array = array.slice();
  array[length - 1] = `${lastSeparator || ''} ${array[length - 1]}`;
  return array.join(separator);
}

export function formatTimeRange(
  range: TimeRange,
  args: {
    formatHour: (hours: number, minutes: number) => string,
    hoursSeparator: string,
  },
): string
{
  const {
    formatHour,
    hoursSeparator,
  } = args;
  const timeRange: Array<string> = [];
  const [
    fromHour,
    fromMinute,
    toHour,
    toMinute,
  ] = [
    Math.floor(range.from / 60),
    (range.from - (Math.floor(range.from / 60) * 60)),
    Math.floor(range.to / 60),
    (range.to - (Math.floor(range.to / 60) * 60)),
  ];
  const formattedTime = [
    formatHour(fromHour, fromMinute),
    formatHour(toHour, toMinute),
  ];
  timeRange.push(formattedTime.join(hoursSeparator));
  return timeRange.join('');
}

export function formatDaySchedule(
  schedule: DaySchedule,
  args: {
    formatHour: (hours: number, minutes: number) => string,
    hoursSeparator: string,
    rangesSeparator: string,
    lastRangeSeparator?: string,
  },
): string | null
{
  if (schedule.length === 0)
    return null;
  const {
    formatHour,
    hoursSeparator,
    rangesSeparator,
    lastRangeSeparator,
  } = args;

  const formattedTimeRange: Array<string> = [];
  for (const timeRange of schedule)
    formattedTimeRange.push(formatTimeRange(timeRange, { formatHour, hoursSeparator }));
  return oxfordCommaLogic(formattedTimeRange, rangesSeparator, lastRangeSeparator);
}

export function trimWeekSchedule(
  schedule: WeekSchedule,
): {
  offset: number,
  schedule: Array<DaySchedule>,
}
{
  let offset = 0;
  for (let i = 0; i < schedule.length && (schedule[i].length === 0); ++i)
    offset += 1;
  const filteredSchedule = schedule.slice(offset);
  while (filteredSchedule.length > 0 && filteredSchedule[filteredSchedule.length - 1].length === 0)
    filteredSchedule.pop();
  return {
    offset,
    schedule: filteredSchedule,
  };
}

export interface Definition
{
  formatPageTitle(name: string): string,
  formatPageDescription(city: string): string,
  return: string,
  team: string,
  director: string,
  readReport: string,
  openingHours: string,
  phoneHours: string,
  teamTitle: string,
  formatAddress(
    adress: string,
    phone?: string | null,
    email?: string | null
  ): React.ReactNode,
  formatOpeningSchedule(
    schedule: WeekSchedule,
    components: {
      Day?: React.ComponentType<React.PropsWithChildren<{}>>,
      Hours?: React.ComponentType<React.PropsWithChildren<{}>>,
      ClosedHours?: React.ComponentType<React.PropsWithChildren<{}>>,
      Row?: React.ComponentType<React.PropsWithChildren<{}>>,
    },
  ): React.ReactNode,
  formatPhoneSchedule(
    schedule: WeekSchedule,
    components: {
      Day?: React.ComponentType<React.PropsWithChildren<{}>>,
      Hours?: React.ComponentType<React.PropsWithChildren<{}>>,
      ClosedHours?: React.ComponentType<React.PropsWithChildren<{}>>,
      Row?: React.ComponentType<React.PropsWithChildren<{}>>,
    },
  ): string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
