import type { IdType } from '@repo-breteuil/common-definitions';
import type { OrderField } from '@repo-lib/graphql-query-pagination';
import type { FavoriteProperty } from './api';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';

import { UncachedPagination, OrderType } from '@repo-lib/graphql-query-pagination';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { QueryStore } from '@repo-breteuil/front-store-query';
import { GetFavoriteProperties, SetFavoriteProperty, DeleteAllFavorites } from './api';

export class FavoritesStore
{
  constructor(
    public stores: {
      session: SessionStore,
      query: QueryStore,
      locale: LocaleStore,
    },
  )
  {
  }

  private static DefaultPropertiesOrder: Array<OrderField<'price' | 'favoriteDate'>> = [{
    fieldName: 'favoriteDate',
    ordering: OrderType.DESC,
  }];

  private _orderBy: Array<OrderField> = FavoritesStore.DefaultPropertiesOrder;

  public favoriteProperties = new UncachedPagination<FavoriteProperty>({
    fetch: async (baseArgs) => {
      const session = await GetFavoriteProperties(
      this.stores.query,
      {
        ...baseArgs,
        orderBy: this._orderBy,
        language: this.stores.locale.locale,
      });
      return this.stores.session.ensureContactSession(session).contact.favoriteProperties;
    },
    pageSize: 10,
  });

  public async setOrderByAndReloadProperties(orderBy: Array<OrderField>)
  {
    this._orderBy = orderBy;
    await this.favoriteProperties.first();
  }

  public async refresh()
  {
    this._orderBy = FavoritesStore.DefaultPropertiesOrder;
    await this.favoriteProperties.first();
  }

  public async setFavoriteProperty(
    id: IdType,
    favorite: boolean,
  ): Promise<void>
  {
    await SetFavoriteProperty(this.stores.query, { id, favorite });
    await this.refresh();
  }

  public async deleteAllFavorites(): Promise<void>
  {
    await DeleteAllFavorites(this.stores.query, {});
    await this.refresh();
  }
}
