export default {
  text: 'Ce lien semble erroné. Remplissez le champ ci-dessous pour recevoir un nouveau lien de confirmation.',
  resendEmail: 'Recevoir un nouveau lien',
  email: 'E-mail',
  emailSent: 'Un nouveau lien de confirmation a été envoyé, veuillez consulter votre boite mail.',
  errorNotif: 'Il y a eu une erreur, veuillez réessayer plus tard.',
  goToProfile: 'Accéder à votre espace',
  successText: 'Votre compte a bien été activé.',
  thankYou(name: string): string
  {
    return 'Merci, '+name;
  },
};
