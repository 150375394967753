import { QueryStore } from "@repo-breteuil/front-store-query";

export async function RequestPropertyAccessCode(
  qs: QueryStore,
  args: {
    accessToken: string,
  },
): Promise<string>
{
  return qs.mutation({
    operationName: 'RequestPropertyAccessCode',
    variables: {
      accessToken: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          requestPropertyAccessCode: [{ args: { accessToken: true } }, {
            phoneNumberHint: true,
          }],
        },
      },
    },
  }, args);
}
