import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { CookiesPreferences } from '@breteuil-website/store/ui/common/CookiesStore';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 8,
    maxWidth: '600px',
    width: '100%',
    margin: theme.spacing(2),
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%',
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      maxHeight: 'calc(100% - 96px)',
      borderRadius: 8,
      height: 'auto',
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '28px',
  fontWeight: 700,
  color: theme.palette.primary.main,
  padding: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px',
    padding: theme.spacing(2.5),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '16px',
  color: theme.palette.secondary.main,
  padding: theme.spacing(0, 3, 3),
  lineHeight: 1.6,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '15px',
    padding: theme.spacing(0, 2.5, 2.5),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    padding: theme.spacing(0, 2, 2),
    lineHeight: 1.5,
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3, 3),
  display: 'flex',
  gap: theme.spacing(2),
  '& > button': {
    flex: 1,
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2, 2.5, 2.5),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 2, 2),
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '15px',
  padding: theme.spacing(1.5, 3),
  borderRadius: 4,
  textTransform: 'none',
  minHeight: 48,
  '&.MuiButton-outlined': {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
      backgroundColor: 'rgba(5, 2, 81, 0.04)',
    },
  },
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    minHeight: 44,
    padding: theme.spacing(1.25, 2.5),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    minHeight: 40,
    padding: theme.spacing(1, 2),
  },
}));

const CookiePopup: React.FC = observer(() => {
  const T = useTexts(texts);
  const { cookies } = useWebsiteStores();

  const handleEssentials = React.useCallback(() => {
    cookies.setCookiesPreferences(CookiesPreferences.Essentials);
  }, [cookies]);

  const handleAcceptAll = React.useCallback(() => {
    cookies.setCookiesPreferences(CookiesPreferences.All);
  }, [cookies]);

  return (
    <StyledDialog
      open={cookies.isOpen}
      keepMounted
      aria-describedby="cookie-preferences-description"
      maxWidth={false}
    >
      <StyledDialogTitle>{T.title}</StyledDialogTitle>

      <StyledDialogContent>
        <div id="cookie-preferences-description">
          {T.text()}
        </div>
      </StyledDialogContent>

      <StyledDialogActions>
        <ActionButton
          variant="outlined"
          onClick={handleEssentials}
          color="primary"
          aria-label={T.onlyEssentials}
        >
          {T.onlyEssentials}
        </ActionButton>

        <ActionButton
          variant="contained"
          onClick={handleAcceptAll}
          color="primary"
          aria-label={T.acceptAll}
        >
          {T.acceptAll}
        </ActionButton>
      </StyledDialogActions>
    </StyledDialog>
  );
});

export default CookiePopup;
