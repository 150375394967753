import { styled } from '@mui/material/styles';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Page } from '@breteuil-website/components/common';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';

const PictureWrapper = styled('div')({
 display: 'flex',
 width: '100%',
 height: '100vh',
 margin: '0px auto 160px auto',
 '@media (max-width: 960px)': {
   margin: '0px auto 55px auto',
 },
});

const Picture = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'block',
  margin: 'auto',
  ...(isMobile ? {
    objectFit: 'contain',
    maxWidth: '150px',
  } : {
    objectFit: 'cover',
    objectPosition: 'bottom',
    maxWidth: '250px',
  }),
}));

const Loading = observer(() => {
  const isMobile = useMediaQueryMobile();
  return (
    <Page>
      <PictureWrapper>
        <Picture
         src='/assets/loading_shiny_transparent.gif'
         isMobile={isMobile}
       />
      </PictureWrapper>
    </Page>
  );
});

export default Loading;
