import type { Currency } from '@repo-lib/utils-texts';
import type { OperationType } from '@repo-breteuil/common-definitions';

import * as React from 'react';
import { number as numberTexts } from '@repo-breteuil/common-texts';
import { formatPrice } from './index';

const T = {
  filter: {
    price: {
      formatPrice(
        value: number,
        args: {
          maxAmount?: number,
          currency: Currency,
          operationType: OperationType,
        },
      ): string
      {
        if (args.maxAmount !== undefined && value >= args.maxAmount)
          return 'Pas de limite';
        return formatPrice(value, {
          ...args,
          formatNumber: numberTexts.FR.formatNumber,
        });
      },
      formatRange(
        value: [ number, number ],
        args: {
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element
      {
        const min = T.filter.price.formatPrice(value[0], args);
        const max = T.filter.price.formatPrice(value[1], args);
        return (
          <b>
            {`${min} - ${max}`}
          </b>
        );
      },
      description: 'Budget',
      formatMin(
        value: number,
        args: {
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element
      {
        return (
          <React.Fragment>
            <b>Min:</b>
            {` ${T.filter.price.formatPrice(value, args)}`}
          </React.Fragment>
        );
      },
      formatMax(
        value: number,
        args: {
          maxAmount?: number,
          currency: Currency,
          operationType: OperationType,
        },
      ): JSX.Element
      {
        return (
          <b>
            Max:{` ${T.filter.price.formatPrice(value, args)}`}
          </b>
        );
      },
      apply: 'Appliquer',
    },
  },
};

export default T;
