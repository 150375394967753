import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import { VimeoPlayer } from '@breteuil-website/components/common';

const Container = styled('div')({
  width:"100%",
  height: "100%",
  marginTop: '-75px',
  marginBottom: '100px',
  '@media (max-width: 960px)': {
    marginTop: 'unset',
    marginBottom: '42.2px',
  },
});

const Video = styled('video')({
  width: '100%',
  height: 'auto',
  margin: '50px 0px',
});

const PropertyVideo = observer((props: { property: Property }) => {
  const { video } = props.property;
  if (!video)
    return null;
  if (video.__typename === 'VideoSource_Raw')
    return (
      <Container>
        <Video src={video.videoURL} controls/>
      </Container>
    );
  if (video.__typename === 'VideoSource_Vimeo') {
    return (
      <Container>
        <VimeoPlayer html={video.embedIframe}/>
      </Container>
    );
  }
  return null;
});

export default PropertyVideo;
