import { SwipeableDrawer, styled } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import DrawerContent from './Content';

const Button = styled('div')({
  cursor: 'pointer',
  height: '30px',
  width: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

interface LineProps {
  isActive?: boolean,
  isBottom?: boolean,
}

const Line = styled('span')<LineProps>(({ isActive, isBottom }) => ({
  display: 'block',
  height: '2px',
  width: isBottom ? '22px' : '30px',
  backgroundColor: '#050251 !important',
  WebkitTransition: '.7s ease',
  transition: '.7s ease',
  margin: '0 0 4px 0',
  ...(isActive && {
    ...(isBottom ? {
      width: '30px',
      marginTop: 0,
      WebkitTransform: 'rotate(135deg)',
      transform: 'rotate(135deg)',
    } : {
      marginTop: '7px',
      marginLeft: 'auto',
      WebkitTransform: 'rotate(45deg)',
      transform: 'rotate(45deg)',
      marginBottom: '-2px',
    }),
  }),
}));

interface DrawerProps {
  open: boolean,
  onOpen: () => void,
  onClose: () => void,
}

const AnimatedDrawerButton = (props: {
  onClick: React.MouseEventHandler<HTMLDivElement>,
  isActive: boolean,
}) => {
  return (
    <Button onClick={props.onClick}>
      <Line isActive={props.isActive}/>
      <Line isActive={props.isActive} isBottom/>
    </Button>
  );
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)({
  '& .MuiPaper-root': {
    backgroundColor: '#FFFFFF !important',
  },
  zIndex: 1300,
});

const Drawer = observer((props: DrawerProps) => {
  const toggleDrawer = () => {
    if (props.open)
      props.onClose();
    else
      props.onOpen();
  };

  return (
    <>
      <AnimatedDrawerButton isActive={props.open} onClick={toggleDrawer}/>
      <StyledSwipeableDrawer
        anchor="left"
        open={props.open}
        onClose={props.onClose}
        onOpen={props.onOpen}
        transitionDuration={500}
      >
        <DrawerContent handleClose={props.onClose}/>
      </StyledSwipeableDrawer>
    </>
  );
});

export default Drawer;
