export default {
  title: 'My account',
  logIn: 'Log in:',
  logInWithoutColon: 'Log in!',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot your username or password?',
  resetPassword: 'Reset your password',
  noAccount: 'No account yet?',
  createAccount: 'Register now!',
  passwordResetEmailSent: 'An password reset e-mail has been sent, please check your inbox.',
  accountNotVerified: "Your account is not activated. To activate it, click on the link in the e-mail we sent you when you signed up, or enter your e-mail below to get a new link.",
  sendActivationLink: 'Get a new link',
  invalidCredentials: 'Invalid credentials, please try again.',
  send: 'Login',
  clientArea: 'One account.',
  endlessPossibilities: 'Infinite possibilities.',
  comfort: 'Always more comfort for our exclusive clients.',
  propertyAccess: 'Exclusive preview of newly listed properties',
  adjustments: 'Real-time adjustment of your searches',
  reports: 'Detailed market reports',
  monitoring: 'Monitoring your property portfolio',
  createArea: 'Create my account',
  loginWithCode: 'Login with a code',
  successfullCodeResent: 'A new code has been sent.',
  sendNewCode: 'Get a new code',
  codeSent: 'A connection code has been sent succesfully.',
  invalidCodeCredentials: "Login code is invalid or expired, please try again.",
  codeDescription(email: string): string
  {
    return `A connection code has benn sent to your mail box (${email})`;
  },
};
