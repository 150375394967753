export const RegExpValidation = (regexp: RegExp) => (field) => regexp.test(field);
export const RequiredString = (s: string) => (typeof(s) === 'string' && s.length > 0);

export function StringLengthValidation(minLength: number, trim: boolean = false)
{
  return function (s: string): boolean
  {
    return (typeof(s) === 'string' && s.trim().length >= minLength);
  };
}

export const PhoneValidation = RegExpValidation(/^[0-9- .+()]+$/); //TODO: this is an extremely basic validation
export const EmailValidationRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const EmailValidation = RegExpValidation(EmailValidationRegex);

export function NonNull<T>(value: T)
{
  return (value !== null);
}

export function NonNullish<T>(value: T)
{
  return Boolean(value);
}

export function PositiveNumber(value: number | null)
{
  if (!value)
    return false;
  return (value > 0);
}

export function OptionalPositiveNumber(value: number | null)
{
  if (value === null)
    return true;
  return (value > 0);
}

export function ValidURL(value: string)
{
  try
  {
    new URL(value);
    return true;
  }
  catch(e)
  {
    return false;
  }
}

export function Nullable<T>(validation: (value: T) => boolean)
{
  return (value: T | null) => {
    if (value === null)
      return true;
    return validation(value);
  };
}

export function AllowEmptyString(validation: (value: string) => boolean)
{
  return (value: string) => {
    if (value === '')
      return true;
    return validation(value);
  };
}
