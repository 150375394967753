import type { Currency } from '@repo-lib/utils-texts';
import type {
  IdType,
  OperationType,
  PropertyType,
  PropertyStatus,
  RentalPeriod,
} from '@repo-breteuil/common-definitions';
import type {
  PaginationExtendedResponse,
  PaginationBaseArgs,
  OrderField,
} from '@repo-lib/graphql-query-pagination';
import type { Language } from '@repo-breteuil/common-texts';
import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';

import {
  QueryStore,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@repo-breteuil/front-store-query';
import { PictureFormat } from '@repo-breteuil/common-definitions';
import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';

export interface FavoriteProperty
{
  id: IdType,
  slug: string,
  title: string,
  price: number,
  priceMax: number,
  bedrooms: number,
  currency: Currency,
  operationType: OperationType,
  propertyType: PropertyType,
  picturesURL: Array<string>,
  exclusive: boolean,
  displayedExclusive: boolean | null,
  currentUserFavorite: boolean,
  mainPictureURL: string,
  status: PropertyStatus,
  displayedStatus: PropertyStatus | null,
  area: {
    id: IdType,
    slug: string,
    rentalPeriod: RentalPeriod,
    name: string,
  },
  subArea: {
    id: IdType,
    slug: string,
    name: string,
  },
  priceOnRequest: boolean,
}

export interface Args extends PaginationBaseArgs
{
  orderBy?: Array<OrderField>,

  language: Language,
}

export async function GetFavoriteProperties(
  qs: QueryStore,
  args: Args,
): Promise<ClientAreaSession<{
  favoriteProperties: PaginationExtendedResponse<FavoriteProperty>,
}> | null>
{
  return qs.query({
    operationName: 'GetFavoriteProperties',
    variables: {
      language: 'Language!',
      ...ExtendedPaginationVariables('MyBreteuilProperty'),
      orderBy: '[CustomOrder__ContactFavoriteProperties!]',
      format: 'PictureFormat',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          session: clientAreaSessionResult({
            favoriteProperties: [{
              args: ExtendedPaginationArgs,
            }, (
              paginationResult({
                id: true,
                slug: [{ args: { language: true }}, true ],
                title: [{ args: { language: true }}, true ],
                picturesURL: [{ args: { format: true }}, true ],
                price: true,
                priceMax: true,
                bedrooms: true,
                currency: true,
                operationType: true,
                propertyType: true,
                exclusive: true,
                displayedExclusive: true,
                currentUserFavorite: true,
                mainPictureURL: [{ args: { format: true }}, true ],
                status: true,
                displayedStatus: true,
                area: {
                  id: true,
                  slug: [{ args: { language: true } }, true],
                  rentalPeriod: true,
                },
                subArea: {
                  id: true,
                  slug: [{ args: { language: true }}, true ],
                  name: [{ args: { language: true }}, true ],
                },
                priceOnRequest: true,
              })
            )],
          }),
        },
      },
    },
  }, {
    ...args,
    format: PictureFormat._720p,
  });
}
