import type { IdType } from '@repo-breteuil/common-definitions';

import { setsIntersect, addToSet } from '@repo-lib/utils-core';

const groups = [
  new Set([ 46, 158, 159, 160 ]), //Paris: 1er, 2eme, 3eme, 4eme
  new Set([ 50, 51 ]), //Paris: 5eme, 6eme
  new Set([ 164, 165, 166, 173, 174, 343, 342 ]), //Paris: 10eme, 11eme, 12eme, 19eme, 20eme, Vincenne, Saint-Mandé
  new Set([ 167, 168 ]), //Paris: 13eme, 14eme
  new Set([ 163, 171, 172 ]), //Paris: 9eme, 17eme, 18eme
  new Set([ 74, 228, 354 ]), //Neuilly, Levallois, Puteau
  new Set([ 75, 317 ]), //Boulogne, St Cloud
] as const;

export function computeGroupedAreasFilter(geoAreaIds: Array<IdType> | null | undefined)
{
  if (!geoAreaIds)
    return geoAreaIds;
  const res = new Set(geoAreaIds);
  for (const group of groups)
  {
    if (setsIntersect(res, group))
      addToSet(res, group);
  }
  return Array.from(res);
}
