import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { OperationType } from '@repo-breteuil/common-definitions';
import type { Search } from '@breteuil-website/store/ui/pages/client-area/searches/api/shared';

import { searchFields } from '@breteuil-website/store/ui/pages/client-area/searches/api/shared';
import { QueryStore } from '@repo-breteuil/front-store-query';

async function CreateSearchFromPropertyFilters(
  qs: QueryStore,
  args: {
    language: Language,
    operationType: OperationType,
    geoAreasIds: Array<IdType>,
    budgetMax?: number | null | undefined,
    bedroomsMin?: number | null | undefined,
  },
): Promise<Search>
{
  return qs.mutation({
    operationName: 'CreateSearchFromPropertyFilter',
    variables: {
      language: 'Language!',
      operationType: 'MyBreteuilOperationType!',
      geoAreasIds: '[Int!]!',
      budgetMax: 'Int',
      bedroomsMin: 'Int',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            search: {
              create: [{
                args: {
                  operationType: true,
                  geoAreasIds: true,
                  budgetMax: true,
                  bedroomsMin: true,
                },
              }, searchFields ],
            },
          },
        },
      },
    },
  }, args);
}

export default CreateSearchFromPropertyFilters;
