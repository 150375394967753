import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  about: string,
  aboutBreteuil: string,
  intro: string,
  arthurTitle: string,
  arseneTitle: string,
  arthurBackground: string,
  arseneBackground: string,
  companyIntro: string,
  freeValuation: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
