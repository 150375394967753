import * as React from 'react';

export default {
  pageTitle: "Contact and find the nearest agency - Breteuil Remarkable Homes",
  pageDescription: "Contact Breteuil Remarkable Homes and our passionate team for personalized expertise and tailor-made service.",
  title: 'Contact us',
  formatAgencyTitle(isMobile : boolean, name: string) {
    return (
      isMobile ? name : `${name}`
    );
  },
  formatAgencyAddress(address: string, zipCode: string, cityName: string) {
    return <React.Fragment>
      <span>{`${address} ${zipCode} ${cityName}`}</span>
    </React.Fragment>;
  },
  formatAgencyAddressMobile(address: string, zipCode: string, cityName: string) {
    return <React.Fragment>
      <span>{address}</span>
      <span>{`${zipCode} ${cityName}`}</span>
    </React.Fragment>;
  },
};

