import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { Button } from '@breteuil-website/components/common';
import { Link, Routes } from '@breteuil-website/store/routing';
import texts from './texts';


const BannerContainer = styled('div')({
    width: '90%',
    minHeight: '750px',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    maxWidth: '1600px',
    "@media (max-width: 960px)": {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

const ValuationImageWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
    },
});

const ValuationImage = styled('img')({
    width: '100%',
    maxWidth: '650px',
    margin: 'auto 0 auto auto',
    "@media (max-width: 960px)": {
        maxHeight: '275px',
        objectFit: 'cover',
    },
});


const ValuationTextWrapper = styled('div')({
    width: '50%',
    display: 'flex',
    "@media (max-width: 960px)": {
        width: '100%',
    },
});

const ValuationText = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
    "@media (max-width: 960px)": {
        width: '80%',
        margin: '25px auto',
    },
});

const ValuationTextTitle = styled('span')({
    fontSize: '30px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    lineHeight: '45px',
    color: '#050251',
    '@media (max-width: 960px)': {
        fontSize: '24px',
        lineHeight: '30px',
    },
});

const ValuationTextDescription = styled('span')({
    fontSize: '14px !important',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#050251',
    margin: '30px 0',
});

const ValuationTextButton = styled(Button)({
    maxWidth: "200px",
    fontSize: '12px !important',
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    fontWeight: 700,
    backgroundColor: '#050251 !important',
});

const Valuations = observer(() => {
    const T = useTexts(texts).valuations;
    const lang = useLocale();
    const isMobile = useMediaQueryMobile();

    return (
        <BannerContainer>
            {isMobile ? <ValuationImageWrapper>
                <ValuationImage src={"/assets/asset_estim_cropped.png"}/>
            </ValuationImageWrapper> : null}
            <ValuationTextWrapper>
                <ValuationText>
                    <ValuationTextTitle>{T.title}</ValuationTextTitle>
                    <ValuationTextDescription>{T.description}</ValuationTextDescription>
                    <Link to={Routes.valuation.generateStaticPath({ lang })}>
                        <ValuationTextButton>
                            {T.button}
                        </ValuationTextButton>
                    </Link>
                </ValuationText>
            </ValuationTextWrapper>
            {isMobile ? null : <ValuationImageWrapper>
                <ValuationImage src={"/assets/asset_estim_crop.png"}/>
            </ValuationImageWrapper>}
        </BannerContainer>
    );
});

export default Valuations;
