import * as React from 'react';

export default {
  pageTitle: "Immobilier de prestige en France et à l'internationnal - Breteuil Remarkable Homes",
  metaDescription: "Trouvez votre prochaine résidence d'exception avec Breteuil Remarkable Homes, l'agence immobilière de référence pour l'immobilier de prestige.",
  titles: {
    excellence: "Excellence",
    transparency: "Transparence",
    ethics: "Déontologie",
  },
  formatTitle(): JSX.Element
  {
    return (
      <React.Fragment>
        <span>{"17 agences à Paris"}</span>
        <span>{"5 agences sur le littoral"}</span>
        <span>{"4 à l'international"}</span>
      </React.Fragment>
    );
  },
  title: 'Remarkable homes.',
  operationType: {
    transaction: 'Acheter',
    rental: 'Louer',
    seasonalRental: 'Location Saisonniere',
  },
  budget: 'Budget',
  search: 'Rechercher',
  everywhere: 'Partout',
  cities: {
    paris: 'Paris',
    london: 'Londres',
    newYork: 'New York',
    deauville: 'Deauville',
    dinard: 'Dinard',
    laBaule: 'La Baule',
    lisbon: 'Lisbonne',
    biarritz: 'Biarritz',
    saintJeanDeLuz: 'Saint-Jean-De-Luz',
  },
  citiesSelect: [
    'Paris',
    'Londres',
    'New York',
    'Deauville',
    'Dinard',
    'La Baule',
    'Lisbonne',
    'Biarritz',
    'Saint-Jean-De-Luz',
  ],
  transactionTypes: [
    'Acheter',
    'Louer',
  ],
  submitBannerForm: 'Rechercher',
  selectLabelTransactionType: "Action",
  implantations: 'Nos implantations',
  implantationsText: `Implantées sans franchise dans 4 pays, nos agences travaillent
  ensemble autour d'un fichier global dans un seul intérêt: le vôtre.`,
  implantationsTitleMobile: 'Nos implantations',
  implantationsMobile: `Implantées sans franchise dans 4 pays, nos agences travaillent ensemble autour d'un fichier global dans un seul intérêt:
  le vôtre`,
  stJean: 'St Jean',
  wantToSell: 'Vous souhaitez vendre ou simplement connaître la valeur de votre bien :',
  freeValuation: 'ESTIMATION GRATUITE',
  properties: {
    title: 'Nos mandats coup de coeur',
    subtitle: 'Laissez-nous vous inspirer.',
    seeMore: 'VOIR PLUS',
    exclusive: 'Exclusivité',
  },
  selectLabelCity: 'Ville',
  agencies : {
    HomeAgencyTitle: "Des agences signatures conçues pour le futur",
    HomeAgencyDescription(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Signés Studio MHNA, ces écrins de prestige aux matériaux durables "}</span>
        <span>{"jouent la transparence totale et offrent un confort unique. Nos "}</span>
        <span>{"emplacements d'exception assurent une visibilité optimale."}</span>
        </React.Fragment>
      );
    },
    HomeAgencyButton : 'Toutes nos agences',
  },
  keyNumbers: {
    agencies(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbAgencies: Number,
      },
    ): JSX.Element {
      const { Highlight, nbAgencies } = component;
      return (
        <React.Fragment>
          <Highlight>
            {`${nbAgencies} `}agences
          </Highlight>
        </React.Fragment>
      );
    },
    employees(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbEmployees: Number,
      },
    ): JSX.Element {
      const { Highlight, nbEmployees } = component;
      return (
        <React.Fragment>
          <Highlight>
            {`${nbEmployees} `}salariés
          </Highlight>
        </React.Fragment>
      );
    },
    cities(
      component: {
        Highlight: React.ComponentType<{ children: React.ReactNode }>,
        nbCities: Number,
      },
    ): JSX.Element {
      const { Highlight, nbCities } = component;
      return (
        <React.Fragment>
          <Highlight>
            dans{` ${nbCities} `}villes
          </Highlight>
        </React.Fragment>
      );
    },
    keyNumbersQuote: "pour servir tous vos projets immobiliers.",
    keyNumbersLogoAltText: "Breteuil logo",
    keyNumbersMapAnimText: "Carte animée",
  },

  clientAreaBanner: {
    mobileTitle(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Un espace client"}</span>
        <span>{"sur-mesure"}</span>
        <span>{"pour votre patrimoine"}</span>
        </React.Fragment>
      );
    },
    title: "Un espace client sur-mesure pour votre patrimoine",
    description: `Suivi de la valeur de votre patrimoine en temps réel, personnalisation de vos recherches et accès à certaines propriétés exclusives : découvrez les avantages de notre espace client.`,
    mobileText(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Certaines propriétés ne sont visibles"}</span>
        <span>{"que par nos utilisateurs inscrits."}</span>
        <span>{"Retrouvez-les sur votre espace client !"}</span>
        </React.Fragment>
      );
    },
    button: `CRÉER MON ESPACE`,
    ipadAlt: `L'espace client sur un téléphone`,
  },

  intro: {
    title: "L'immobilier international de prestige",
    text1: "Breteuil est implanté dans 4 pays pour vous offrir  un service d’exception à Paris, Londres, Lisbonne, New York et sur le littoral français. Nous proposons, à la vente, à la location ou à la location saisonnière, un portefeuille de biens de prestige à une clientèle exigente et fidèle.",
    text2: "Toutes nos implantations, sans franchise, travaillent ensemble autour d’un seul fichier d’acquéreurs global, pour exposer votre propriété de façon optimale à l’international et vous garantir des opérations d’une efficacité sans précédent.",
    text3: "Nos agences vous accueillent du lundi au samedi et nos agents sont disponibles au téléphone 7 jours sur 7 pour vous accompagner dans votre projet : contactez-nous !",
  },

  market: {
    title: 'Les points sur le marché',
    button: 'TOUS LES POINTS MARCHÉ',
  },
  services: {
    title: "Breteuil vous accompagne avant et après votre projet",
    description: "Les meilleurs partenaires à votre service, dans chacune de nos villes d’implantation.",
    button: "Nos Services",
  },
  people: {
    title: "Des conseillers experts, des managers impliqués",
    description: "Des conseillers salariés, un management participatif et bienveillant, une grande agilité opérationnelle : des composantes clés pour des performances ambitieuses.",
    button: "Rejoignez nous",
  },
  valuations: {
    title: "Des estimations intelligentes et rapides",
    description: "Une estimation fiable en ligne avant votre rendez-vous avec un conseiller.",
    button: "Estimer mon bien",
  },
  availableCoffee: {
    agentsAvailable: 'Disponibles 7 jours sur 7,',
    availability: 'de 7h à 22h',
    agentsAvailableMobile(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Disponibles 7 jours sur 7,"}</span>
        </React.Fragment>
      );
    },
    availabilityMobile(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"de 7h à 22h"}</span>
        </React.Fragment>
      );
    },
    agentsAvailableDescription: "Nos agents sont joignables quand vous l'êtes. Parce que c'est souvent tôt le matin, ou plus tard le soir que vous êtes disposé à échanger sur votre projet, vous pouvez nous appeler à votre convenance et trouver un conseiller à votre écoute.",
    contactUsLabel: "CONTACTEZ-NOUS",
  },
  myMarket: {
    title(): JSX.Element {
      return (
        <React.Fragment>
        <span>{"Les données de marché"}</span>
        <span>{"les plus claires"}</span>
        </React.Fragment>
      );
    },
    titleMobile: 'Toute la data de l’immobilier.',
    text: "La base de données immobilière la plus fiable et complète qui recense les biens vendus à Paris pour suivre le marché immobilier.",
    buttonLabel: 'Découvrir',
    imageAltTag: 'DONNEES DE MARCHE',
  },
  ethics: {
    impactTitle: "Impact réduit.",
    impactDesc: "Moins de papier, une flotte de véhicules tout électrique, des agences rénovées avec des matériaux durables, une pollution lumineuse réduite. Objectif empreinte positive.",
    integrityTitle: "Intégrité.",
    integrityDesc: "Nos méthodes d'estimation, de conseil et de vente sont strictes et ne tolèrent aucun écart. Nous ne sacrifions jamais la probité sur l'autel de la complaisance.",
    securityTitle: "Discrétion et sécurité.",
    securityDesc: "Nous avons très à coeur la préservation de la vie privée de nos clients, de la signature du mandat jusqu'au pilotage de leur portefeuille foncier. Nos process sont ultra-sécurisés et notre politique interne est particulièrement stricte.",
    managementTitle: "Bienveillance managériale.",
    managementDesc: "Nos directeurs d'agence sont de véritables capitaines de navire, dont l'implication va jusqu'à la participation au capital du Groupe. Ils sont rompus à un management bienveillant et participatif, et mènent leurs équipes par l'exemple, dans l'écoute et l'échange.",
    parityTitle: "Parité.",
    parityDesc: "Les effectifs de notre société comptent plus de 50% de femmes, et nous sommes fier de favoriser la diversité culturelle et l'égalité salariale.",
  },
};
