import type { FormFieldSelectNullable } from '@repo-lib/mobx-forms';

import * as React from 'react';
import { observer } from 'mobx-react';
import SelectTiles from './SelectTiles';
import { SxProps, Theme } from '@mui/material';

export const SelectWithTilesNullable = observer(<T extends any>(
  props: {
    field: FormFieldSelectNullable<T>,
    overrideTileWidth?: boolean | undefined,
    overrideSelectedColor?: boolean | undefined,
    customStyle?: SxProps<Theme> | undefined,
  },
) => {
  const {
    field,
    overrideTileWidth = false,
    overrideSelectedColor = false,
    customStyle = {},
  } = props;
  const handleSelect = (value: T) => {
    if (field.value === value)
      field.setValue(null);
    else
      field.setValue(value);
  };
  const selected = React.useMemo(() => (
    field.value === null ? []  : [ field.value ]
  ), [ field.value ]);
  return (
    <SelectTiles
      options={field.options}
      overrideTileWidth={overrideTileWidth}
      overrideSelectedColor={overrideSelectedColor}
      selected={selected}
      onSelect={handleSelect}
      customStyle={customStyle}
    />
  );
});

export default SelectWithTilesNullable;
