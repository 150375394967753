import * as React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({

  width: '100%',
  top: '90px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '44px',
  textAlign: 'center',
  backgroundColor: 'rgba(5, 2, 81, 0.90)',
  color: 'white',
  padding: '15px 0',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 'auto',
    fontSize: '18px',
    top: '50px',
  },
  [theme.breakpoints.down('lg')]: {
    top: '50px',
  },
}));

const BannerTextComponent = styled('div')(({ theme }) => ({
  margin: 'auto',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '44px',
  color: 'white',
  [theme.breakpoints.down('md')]: {
      fontSize: '18px',
  },
}));

export interface TitleBannerProps
{
  title: string,
  nonSticky?: boolean,
}

const PageTitle = (props: TitleBannerProps) => {
  return (
    <Container style={{  position: props.nonSticky ? 'unset' : 'sticky'  }}>
      <BannerTextComponent>{props.title}</BannerTextComponent>
    </Container>
  );
};

export default PageTitle;
