import type { Language } from '@repo-breteuil/common-texts';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import { PictureFormat } from '@repo-breteuil/common-definitions';
import { QueryStore } from '@repo-breteuil/front-store-query';

export async function GetfavoritePropertiesShowcase(
  qs: QueryStore,
  args: {
    language: Language,
  },
): Promise<Array<Property>> {
  // TODO Remove dangerous typing
  return qs.query({
    operationName: 'favoritePropertiesShowcase',
    variables: {
      language: 'Language!',
      format: 'PictureFormat',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          favoritePropertiesShowcase: {
            id: true,
            slug: [{ args: { language: true } }, true],
            title: [{ args: { language: true } }, true],
            area: {
              id: true,
              slug: [{ args: { language: true } }, true],
              name: [{ args: { language: true } }, true],
              rentalPeriod: true,
            },
            subArea: {
              id: true,
              slug: [{ args: { language: true } }, true],
              name: [{ args: { language: true } }, true],
            },
            currency: true,
            propertyType: true,
            mainPictureURL: [{ args: { format: true } }, true],
            picturesURL: [{ args: { format: true } }, true],
            price: true,
            priceMax: true,
            priceOnRequest: true,
            bedrooms: true,
            operationType: true,
            exclusive: true,
            displayedExclusive: true,
            currentUserFavorite: true,
            status: true,
            displayedStatus: true,
          },
        },
      },
    },
  }, {
    ...args,
    format: PictureFormat._720p,
  });
}
