import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  FacebookRounded,
  LinkedIn,
  Instagram,
} from '@mui/icons-material';

const SocialMediaInsideContainer = styled('div')({
  width: '100%',
  display: "flex",
});

const SocialMediaItem = styled('a')({
  marginRight: '10px',
  "&:last-child": {
    marginRight: 0,
  },
});

const StyledIcon = styled('div')({
  '& .MuiSvgIcon-root': {
    width: '1.5em !important',
    height: '1.5em !important',
    objectFit: 'contain',
  },
});

interface SocialMediasItemProps {
  link: string,
  Icon: JSX.Element,
  color: string,
}

const SocialMediaItemComponent = (props: SocialMediasItemProps) => {
  return (
    <SocialMediaItem
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      style={{color: props.color}}>
      <StyledIcon>{props.Icon}</StyledIcon>
    </SocialMediaItem>
  );
};

export interface SocialMediasProps {
  iconColor: string,
  alignStart?: boolean,
}

export const SocialMedias = (props: SocialMediasProps) => {
  return (
    <SocialMediaInsideContainer style={{justifyContent: props.alignStart ? "start" : "end"}}>
      <SocialMediaItemComponent
        link='https://www.facebook.com/maisonBreteuil'
        Icon={<FacebookRounded/>}
        color={props.iconColor}
      />
      <SocialMediaItemComponent
        link='https://www.linkedin.com/company/breteuil-immobilier/'
        Icon={<LinkedIn/>}
        color={props.iconColor}
      />
      <SocialMediaItemComponent
        link='https://www.instagram.com/maisonbreteuil/'
        Icon={<Instagram/>}
        color={props.iconColor}
      />
    </SocialMediaInsideContainer>
  );
};
