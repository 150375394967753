import { Definition } from ".";

export default {
  pageTitle: "High-end and prestigious property rentals - Breteuil Remarkable Homes",
  pageDescription: "High-end and prestigious real estate purchase - Breteuil Remarkable Homes",
  form: {
    budget: "Budget",
    priceMin: "Minimum",
    priceMax: "Maximum",
    formatBedrooms(value: number): string
    {
      return value > 1 ? "Bedrooms" : "Bedroom";
    },
    area: "Area",
    guests: "Guests",
  },
  everywhere: 'Everywhere',
  resetButton: "Reset",
  submitButton: "Validate",
  errorLoadMore: 'Something unexpected happened during the loading. Try again later.',
} satisfies Definition;
