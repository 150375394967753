import type { IdType } from '@repo-breteuil/common-definitions';
import type { PropertiesFilter } from '@breteuil-website/store/ui/pages/properties-search';

import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Dialog, Popover, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import {
  LocationOn,
  KingBed,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Close,
  Search,
} from '@mui/icons-material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import { omitUndefinedValues, isNonNull, intRange } from '@repo-lib/utils-core';
import { SuspendableReaction } from '@repo-lib/utils-mobx-store';
import { useReaction } from '@repo-lib/utils-mobx-react';
import { useEffectAfterMount } from '@repo-lib/utils-react';
import { useDebounced } from '@repo-lib/utils-react';
import {
  useFormFieldSelect,
  useFormFieldSelectNullable,
  useFormFieldSelectMultiple,
  createFormFieldSlider,
} from '@repo-lib/mobx-forms';
import {
  handleNonCriticalError,
  ensureFetchableResource,
} from '@repo-breteuil/front-error';
import { Currency } from '@repo-lib/utils-texts';
import { OperationType } from '@repo-breteuil/common-definitions';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router, { getPropertiesSearchRoute } from '@breteuil-website/store/routing';
import {
  Page,
  BudgetSlider,
  SelectNullable,
  Button,
  makeBudgetSliderFormatInputValueFn,
  CreateAlertButton,
} from '@breteuil-website/components/common';
import {
  SelectWithTilesMultiple,
  SelectWithTilesNullable,
} from '@breteuil-website/components/common';
import { scale, scaleDown, PageMeta, PropertiesResults } from '@breteuil-website/components/common';
import {
  BedroomsMinFilterValues,
} from '@breteuil-website/store/ui/pages/properties-search';
import texts from './texts';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  justifyContent: 'center',
  maxWidth: 'fit-content',
  position: 'sticky',
  top: '90px',
  left: '0',
  zIndex: 50,
  margin: '0 auto 15px auto',
  '@media (max-width: 1280px)': {
    top: '50px',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 'unset',
  },
});

const PopoverContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  position: 'relative',
  alignItems: 'center',
});

const RowOperationType = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  '@media (max-width:1400px)': {
    flexDirection: 'column',
  },
});

const RowDistricts = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '15px auto 0 auto',
  boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)",
});

const RowSlider = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '15px auto 0 auto',
  padding: '5px',
  backgroundColor: '#FAFAFA',
  boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)",
});

const RowBedRooms = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  margin: '15px auto 0 auto',
  boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)",
  '@media (max-width:1200px)': {
    flexDirection: 'column',
  },
});

const PropertySearchLabel = styled('div')({
  display: 'flex',
  height: '50px',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'italic',
  lineHeight: '44px',
  color: '#424242',
  alignItems: "center",
});

const BedroomsLabel = styled(PropertySearchLabel)({
  width: '40%',
  '@media (max-width:1200px)': {
    width: '100%',
  },

});

const LocationIcon = styled(LocationOn)({
  margin: '0 10px',
  color: '#050251',
});


const KingBedIcon = styled(KingBed)({
  margin: '0 10px',
  color: '#050251',
});

const SelectCity = styled(SelectNullable)({
  '& .MuiSelect-select': {
    fontWeight: 700,
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    color: '#050251 !important',
  },
});

const PriceSlider = styled(BudgetSlider)({
  width: '80% !important',
  margin: 'auto',
  color: '#050251 !important',
  '& .MuiSlider-thumb': {
    backgroundColor: '#050251',
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#FAFAFA',
    color: '#050251',
  },
});


const FilterButton = styled(Button)({
  width: '100%',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '12px',
  transition: 'opacity 343ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  marginBottom: '12px',
  border: 'none',
  boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)",
  '@media (max-width:1280px)': {
    flex: 1,
    marginBottom: 0,
  },
});

const FilterPopOverButton = styled(Button)({
  width: '100%',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '12px',
  display: 'flex',
  backgroundColor: 'white',
  color: '#050251',
  border: "1px solid #050251",
  marginBottom: '0',
  '&:hover': {
    color: '#FFFFFF',
  },
  '@media (max-width: 1200px)': {
    maxWidth: 'unset',
  },
});

const FilterButtonLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

const BudgetPickerIcon = styled('span')({
  display: 'flex',
  color: '#050251',
  padding: '12px',
  fontSize: '14px !important',
  alignItems: 'center',
});

const MobileFilterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '80%',
  margin: '16px auto',
  fontSize: '16px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  color: '#050251',
});

const MobileFilterIcon = styled('div')({
  color: '#979797',
});

const DialogButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '12px',
  padding: '42px 25px',
});

const SelectWithTilesWrapper = styled('div')({
  width: '100%',
  padding: '2px',
});

const AreaErrorText = styled('span')({
  fontFamily: 'Poppins',
  fontSize: '14px',
  color: 'red',
  textAlign: 'center',
  margin: '5px 0',
});

const EverywhereValue = -1;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>,
) {
  const easing = {
    enter: "cubic-bezier(0.4, 0, 0.2, 1)",
    exit: "cubic-bezier(0.4, 0, 0.2, 1)",
  };
  const timeout = {
    enter: 500,
    exit: 500,
  };
  return <Slide
    direction="right"
    ref={ref}
    {...omitUndefinedValues(props)}
    easing={easing}
    timeout={timeout}
  />;
});

interface ModalProp {
  children: React.ReactChild,
  targetRef: HTMLDivElement | null,
  open: boolean,
  handleClose: () => void,
}

const Modal = observer((props: ModalProp) => {
  const { children, targetRef, open, handleClose } = props;
  const id = open ? 'filters-popover' : undefined;
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  const isTablet = useMediaQueryTablet();
  return isMobile || isTablet ? (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{top: '50px', zIndex: 1200}} // TopBar Size
    >
      <MobileFilterContainer>
        <span>{T.filterLabel}</span>
        <MobileFilterIcon>
          <Close
            fontSize={"large"}
            onClick={handleClose}
          />
        </MobileFilterIcon>
      </MobileFilterContainer>
      {children}
    </Dialog>
    ) : (
      <Popover
        {...omitUndefinedValues({ id })}
        open={open}
        anchorEl={targetRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ '&.MuiPopover-root > .MuiPaper-root': {
            marginTop: '30px',
            width: '80%',
            maxWidth: '900px',
            maxHeight: 'calc(100vh - 90px - 70px)',
            padding: '30px',
          },
        }}
        onClose={handleClose}
      >
        {children}
      </Popover>
    );
});

const PropertiesSearch = observer(() => {
  const { areas, propertiesSearch, globalMessage } = useWebsiteStores();
  const { filtersAreas, areasPerId, subAreasPerId } = ensureFetchableResource(areas.areas);

  const [open, setOpen] = React.useState(false);
  const lang = useLocale();
  const T = useTexts(texts);

  const area = useFormFieldSelect({
    options: () => new SelectOptions<IdType>(new Map([
      [ EverywhereValue, T.everywhere ],
      ...(filtersAreas.get(propertiesSearch.filter.operationType) || []).map(({ area }) => [ area.id, area.name ]),
    ] as Array<[ IdType, string ]>)),
    defaultValue: () => propertiesSearch.filter.areaId ?? EverywhereValue,
  }, [ filtersAreas, propertiesSearch.filter.operationType, T ]);

  function getCurrentArea()
  {
    const areaId = area.value;
    if (areaId === EverywhereValue)
      return null;
    const areaWithSubAreas = areasPerId.get(areaId);
    if (!areaWithSubAreas)
      throw new Error(`Assertion failed: couldn't find Area#${areaId}`);
    return areaWithSubAreas;
  }

  const currentArea = React.useMemo(
    getCurrentArea,
    [ area.value, filtersAreas ],
  );

  const subAreas = useFormFieldSelectMultiple({
    options: () => new SelectOptions<IdType>(
      new Map((currentArea?.subAreas || []).map(subArea => (
        [ subArea.id, subArea.name ]
      ))),
    ),
    defaultValue: () => propertiesSearch.filter.subAreaId ?? [],
  }, [ currentArea ]);

  const bedroomsMin = useFormFieldSelectNullable({
    options: () => new SelectOptions<number>(new Map(
      intRange(
        BedroomsMinFilterValues.min,
        BedroomsMinFilterValues.max + 1 - BedroomsMinFilterValues.min,
      ).map(n => [n, T.formatMinBedroomsText(n)]),
    )),
    defaultValue: () => propertiesSearch.filter.bedroomsMin ?? null,
  }, [T]);

  const [price] = React.useState(() => createFormFieldSlider<number>(propertiesSearch.filter.operationType === OperationType.ResidencyTransaction ?
     scaleDown(propertiesSearch.filter.priceMax) : propertiesSearch.filter.priceMax, {
    formatInputValue: makeBudgetSliderFormatInputValueFn({
      getOperationType: () => propertiesSearch.filter.operationType,
    }),
  }));

  useReaction(() => new SuspendableReaction(
    () => propertiesSearch.filter,
    action((filter) => {
      price.setValue(filter.operationType === OperationType.ResidencyTransaction ? scaleDown(filter.priceMax) : filter.priceMax);
      bedroomsMin.setValue(filter.bedroomsMin ?? null);
      area.setValue(filter.areaId ?? EverywhereValue);
      subAreas.setValue(filter.subAreaId ?? []);
    }),
  ));

  function getFilters(): PropertiesFilter {
    return {
      operationType: propertiesSearch.filter.operationType,
      priceMax: propertiesSearch.filter.operationType === OperationType.ResidencyTransaction ? scale(price.value)  : price.value,
      bedroomsMin: bedroomsMin.value ?? undefined,
      areaId: area.value === EverywhereValue ? undefined : area.value,
      subAreaId: subAreas.value.length > 0 ? subAreas.value : undefined,
    };
  }

  function handleFiltersUpdate() {
    area.value ? propertiesSearch.setIsAreaSelected(true) : propertiesSearch.setIsAreaSelected(false);
    const filter = getFilters();
    propertiesSearch.propertiesCount.reload(filter);
  }

  function applyFilters() {
    const filter = getFilters();
    propertiesSearch.setFilter(filter);
    propertiesSearch.properties.first().catch(() => {
      globalMessage.display(T.errorLoadMore, { severity: 'error' });
    });
    propertiesSearch.propertiesCount.reload(filter);

    const subAreasSlugs = filter.subAreaId?.map(id => {
      const subArea = subAreasPerId.get(id);
      if (!subArea)
      {
        handleNonCriticalError(new Error(`Assertion failed: couldn't fiend SubArea#${id}`));
        return null;
      }
      return subArea.slug;
    }).filter(isNonNull);

    //Refresh the URL when any of the filters change,
    //to update all the parts of the URL (both the route and the searchParams)
    const route = getPropertiesSearchRoute({
      ...filter,
      lang,
      areaSlug: currentArea?.area.slug,
      subAreasSlugs,
    });
    router.changeRouteBypassHandler(route, { replace: true });
    setOpen(false);
  }

  const handleCreateAlert = () => {
    applyFilters();
    if (!propertiesSearch.filter.subAreaId) {
      const filter = getFilters();
      filter.subAreaId = currentArea?.subAreas.map((subArea) => subArea.id);
      propertiesSearch.setFilter(filter);
    }
  };

  useDebounced(
    handleFiltersUpdate,
    { delay: 1000 },
    [subAreas.value.length === 0 ? null : subAreas.value],
  );
  useEffectAfterMount(
    handleFiltersUpdate,
    [price.value, bedroomsMin.value, area.value],
  );

  // Used as anchorEl to set the position of the Popover
  const filterRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Page>
      <PageMeta
        title={T.formatPageTitle(propertiesSearch.filter.operationType)}
        description={T.formatPageDescription(propertiesSearch.filter.operationType)}
      />
        <Modal
          targetRef={filterRef.current}
          open={open}
          handleClose={() => {
            applyFilters();
            setOpen(false);
          }}
        >
          <PopoverContainer>
            <RowOperationType>
              <SelectCity label={T.area} field={area}/>
              {!propertiesSearch.isAreaSelected ? <AreaErrorText>{T.alertFilterArea}</AreaErrorText> : null}
            </RowOperationType>
            <RowSlider>
              <Stack direction='row' justifyContent='space-between'>
                <PropertySearchLabel style={{ paddingBottom: '10px' }}>
                  <BudgetPickerIcon>
                    <img src={"/assets/coin-stack.svg"}/>
                  </BudgetPickerIcon>
                  {T.priceLabel}
                </PropertySearchLabel>
                <Typography
                  fontFamily='Mulish'
                  fontSize='12px'
                  color='#424242'
                  display='flex'
                  alignItems='center'
                  marginRight='12px'
                  paddingBottom='10px'
                  fontStyle='italic'
                >
                  {T.formatBudgetFilterPeriod(propertiesSearch.filter.operationType)}
                </Typography>
              </Stack>
              <PriceSlider
                field={price}
                currency={currentArea ? currentArea.area.currency : Currency.EUR}
                operationType={propertiesSearch.filter.operationType}
                showLabels
              />
            </RowSlider>
            {currentArea && (subAreas.options.options.length > 0) ? (
              <RowDistricts>
                <PropertySearchLabel>
                  <LocationIcon/>{T.subArea}
                </PropertySearchLabel>
                <SelectWithTilesWrapper>
                  <SelectWithTilesMultiple field={subAreas}/>
                </SelectWithTilesWrapper>
              </RowDistricts>
            ) : null}
            {propertiesSearch.filter.operationType !== OperationType.SeasonalRental ? (
              <RowBedRooms>
                <BedroomsLabel>
                  <KingBedIcon/>{T.labelBedrooms}
                </BedroomsLabel>
                <SelectWithTilesWrapper>
                  <SelectWithTilesNullable field={bedroomsMin}/>
                </SelectWithTilesWrapper>
              </RowBedRooms>
            ) : null}
            <DialogButtonContainer>
              <FilterPopOverButton onClick={() => applyFilters()}>
                  <Search/>
                  {T.submit}
              </FilterPopOverButton>
              <CreateAlertButton
                CreateAlertMutation={() => propertiesSearch.CreateAlert()}
                handleClick={handleCreateAlert}
                showText
                hasAreaSelected={area.value !== EverywhereValue}
              />
            </DialogButtonContainer>
          </PopoverContainer>
        </Modal>
      <Container ref={filterRef}>
        <FilterButton
          onClick={() => setOpen(!open)}
        >
          {T.filterLabel}
          <FilterButtonLabel>{open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</FilterButtonLabel>
        </FilterButton>
      </Container>
      <PropertiesResults properties={propertiesSearch.properties} errorLoadMore={T.errorLoadMore}/>
    </Page>
  );
});

export default PropertiesSearch;
