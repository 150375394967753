import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetfavoritePropertiesShowcase,
  GetRemarkablePeople,
} from './api';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import router, { RootPages } from '@breteuil-website/store/routing';
import { type QueryStore, wrapStoreQuery } from '@repo-breteuil/front-store-query';
import type { LocaleStore } from '@breteuil-website/store/ui/common/Locale';

export class HomeStore
{
  private _scrollLevel: number = 0;

  public get scrollLevel()
  {
    return this._scrollLevel;
  }

  constructor(
    public stores: {
      query: QueryStore,
      locale: LocaleStore,
    },
  )
  {
    window.addEventListener('scroll', () => {
      if (this.isCurrentPage)
        this._scrollLevel = window.scrollY;
    });
  }
  public recentProperties = new Fetchable(
    () => GetfavoritePropertiesShowcase(this.stores.query, {
      language: this.stores.locale.locale,
    }),
    { catchUnhandled: handleNonCriticalError },
  );

  public remarkablePeople = new Fetchable(wrapStoreQuery(this, GetRemarkablePeople), { catchUnhandled: handleNonCriticalError });

  public get isCurrentPage()
  {
    const { currentPage } = router;
    if (currentPage.state === 'invalid')
      return false;
    return (currentPage.page === RootPages.Home);
  }

  //TODO: unhardcode these
  public get nbCities(): number
  {
    return 9;
  }

  public get nbEmployees(): number
  {
    return 123;
  }

  public get nbWarrants(): number
  {
    return 1423;
  }

  public get nbAgencies(): number
  {
    return 25;
  }
}
