import { styled } from '@mui/material/styles';
import {
  Slider as GenericSlider,
} from '@repo-lib/mobx-forms-mui-components';

// Fix type not portable ts compile issue
type GenericSliderT = typeof GenericSlider;

export const Slider: GenericSliderT = styled(GenericSlider)({
  color: '#050251',
  height: '3px',
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: '20px',
    width: '20px',
    backgroundColor: '#0C17E1',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    height: '3px',
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 1,
    height: '3px',
  },
}) as GenericSliderT;
