import type { SimplePropertyValuation } from './types';

import { QueryStore } from '@repo-breteuil/front-store-query';

export interface VerifyPhoneArgs {
  valuationId: number,
  phoneVerifyCode: string,
}

export default async function VerifyPhone(
  qs: QueryStore,
  args: VerifyPhoneArgs,
): Promise<SimplePropertyValuation | null> {
  return qs.mutation({
    operationName: 'ValuationVerifyPhone',
    variables: {
      valuationId: 'Int!',
      phoneVerifyCode: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            verifyPhone: [{
              args: {
                valuationId: true,
                phoneVerifyCode: true,
              },
            }, {
              id: true,
              address: true,
              phone: true,
              transactionPrice: true,
              transactionDate: true,
              surface: true,
              renovationCost: true,
              renovationYear: true,
              resolvedAddress: true,
              phoneVerified: true,
              estimatedPrice: true,
            }],
          },
        },
      },
    },
  }, args);
}
