import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedRoute, TranslatedStaticRoute } from '@breteuil-website/store/routing/translatedRoutes';

export const contact = new TranslatedStaticRoute({
  [Locale.French]: '/contact',
  [Locale.English]: '/contact',
});

export const agencies = new TranslatedStaticRoute({
  [Locale.French]: '/agences',
  [Locale.English]: '/agencies',
});

export const agency = new TranslatedRoute({
  [Locale.French]: '/agences/[agencySlug]',
  [Locale.English]: '/agencies/[agencySlug]',
});
