import type { WeekSchedule } from '@repo-lib/utils-week-schedule';
import { wrapWithOptionalComponent } from '@repo-lib/utils-react';
import * as React from 'react';

import {
  formatDaySchedule,
  trimWeekSchedule,
} from '.';

function formatHour(hour: number, minute: number): string
{
  const hoursAndMinutes: Array<string> = [
    (hour.toString()).padStart(2, '0'),
    minute.toString().padStart(2, '0'),
  ];
  return (hoursAndMinutes.join(':'));
}

const days = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
] as const;

const T = {
  formatPageTitle(name: string)
  {
    return `Agence immobilière ${name} - Breteuil Remarkable Homes`;
  },
  formatPageDescription(name: string)
  {
    return `Votre agence immobilière à ${name}. Découvrez notre sélection de biens immobiliers et profitez de notre expertise de l'immobilier de prestige.`;
  },
  return: 'Retour à la liste des agences',
  team: 'Votre équipe',
  director: 'Responsable agence',
  readReport: 'Lire le rapport',
  openingHours: "Horaires d'ouverture",
  phoneHours: 'Horaire de permanence téléphonique',
  teamTitle: 'Votre équipe',
  formatAddress(address, phone = null, email = null): React.ReactNode
  {
    return (
      <React.Fragment>
        <span>{address}</span>
        {phone && email ? (
          <>
            <br/><span>{phone}</span><br/>
            <span>{email}</span>
          </>
        ) : null}
      </React.Fragment>
    );
  },
  formatOpeningSchedule(
    schedule: WeekSchedule,
    components: {
      Day?: React.ComponentType<React.PropsWithChildren<{}>>,
      Hours?: React.ComponentType<React.PropsWithChildren<{}>>,
      ClosedHours?: React.ComponentType<React.PropsWithChildren<{}>>,
      Row?: React.ComponentType<React.PropsWithChildren<{}>>,
    },
  ): React.ReactNode {
    const { Day, Hours, ClosedHours, Row } = components;
    const {
      schedule: filteredSchedule,
      offset: skippedDaysCount,
    } = trimWeekSchedule(schedule);
    if (filteredSchedule.length === 0)
      return wrapWithOptionalComponent(ClosedHours, 'Temporairement fermé');
    return (
      <React.Fragment>
        {schedule.map((day, subIndex) => {
          const index = subIndex + skippedDaysCount;
          const daySchedule = formatDaySchedule(day, {
            formatHour: ((hours, minutes) => formatHour(hours, minutes)),
            hoursSeparator: ' - ',
            rangesSeparator: ', ',
          });
          const RowComponent = Row || React.Fragment;
          return (
            <RowComponent key={index}>
              {wrapWithOptionalComponent(Day, days[index])}
              {(daySchedule === null) ? null : (
                wrapWithOptionalComponent(Hours, daySchedule)
              )}
            </RowComponent>
          );
        })}
      </React.Fragment>
    );
  },

  formatPhoneSchedule(
    schedule: WeekSchedule,
  ): string
  {
    //TOOD: when needed, handle different ranges per day of week
    const daySchedule = schedule[0];

    //TODO: when needed, handle multiple ranges per day
    const timeRange = daySchedule.slice(0, 1);

    const scheduleString = formatDaySchedule(timeRange, {
      //TODO: when needed, handle minutes
      formatHour: (hour, minute) => `${hour}h`,
      hoursSeparator: ' à ',
      rangesSeparator: ', ',
      lastRangeSeparator: ' et de ',
    });
    if (scheduleString === null)
      return 'Temporairement fermé';

    return `Joignable 7 jours sur 7 de ${scheduleString}`;
  },
};

export default T;
