import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import type { FavoriteProperty } from '@breteuil-website/store/ui/pages/client-area/favorites/api';

import * as React from 'react';
import { observer } from 'mobx-react';
import {
  styled,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import { useLocale, useTexts, number as numberTexts } from '@repo-breteuil/common-texts';
import { OperationType } from '@repo-breteuil/common-definitions';
import { Link, Routes } from '@breteuil-website/store/routing';
import { PropertyPrice } from '@breteuil-website/components/common';
import { computePropertyPricePerUnit } from '@breteuil-website/components/common/functions';
import StatusBanner from './StatusBanner';
import FavoriteOverlay from './FavoriteOverlay';
import Pictures from './Pictures';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import PropertyCardHeader from './PropertyCardHeader';
import texts from './texts';

const Container = styled('div')({
  position: 'relative',
});

const StyledCard = styled(Card)({
  margin: '5px 0px',
  borderRadius: '0 !important',
  boxShadow: 'none !important',
});

const StyledCardContent = styled(CardContent)({
  padding: '10px !important',
});

const IconWrapper = styled('div')({
  display: 'flex',
  alignSelf: 'center',
});

const FooterContainer = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '16px',
  alignItems: "baseline",
  color: "#050251",
  "@media (max-width: 960px)": {
    columnGap: '8px',
    justifyContent: 'space-between',
  },
});

const Info = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "17px",
  fontWeight: 400,
  "@media (max-width: 960px)": {
    fontSize: "",
  },
});

const Price = styled(Typography)({
  fontFamily: "Raleway",
  fontSize: "24px",
  fontWeight: "bold",
  "@media (max-width: 960px)": {
    fontSize: "15px",
  },
});

const Separator = styled(Box)({
  flex: 1,
  height: '1px',
  backgroundColor: '#979797',
  margin: '0 16px',
  "@media (max-width: 960px)": {
    fontSize: "",
  },
});

export interface PropertyCardCommonProps extends PropertyCardProps {
  children: React.ReactNode,
}

const PropertyCardCommon = observer((props: PropertyCardCommonProps) => {
  const { children, ...rest } = props;
  const lang = useLocale();

  return (
    <Container>
      <StyledCard>
        <Pictures {...rest}/>
        <Link to={Routes.propertyShort.generatePath({ propertySlug: rest.property.slug }, { lang })}>
          <StyledCardContent>
            <Box>
              <PropertyCardHeader property={rest.property} showSubArea={rest.showSubArea}/>
              {children}
            </Box>
          </StyledCardContent>
          <StatusBanner {...rest}/>
        </Link>
      </StyledCard>
      <FavoriteOverlay {...rest}/>
    </Container>
  );
});

const PropertyCardTransaction = observer((props: Pick<PropertyCardCommonProps, "property">) => {
  const { property } = props;
  const T = useTexts(texts);
  const currentTextsNumber = useTexts(numberTexts.texts);
  const isDesktop = useMediaQueryDesktop();

  return (
    <FooterContainer>
      {property.bedrooms ? (
        <Stack direction="row" spacing={1} alignItems="baseline">
          <IconWrapper>
            <BedIcon/>
          </IconWrapper>
          <Info>{isDesktop ? T.formatBedrooms(property.bedrooms) : property.bedrooms}</Info>
        </Stack>
      ) : null}
      <Separator/>
      <Price>
        <PropertyPrice
          priceOnRequest={property.priceOnRequest}
          formattedPrice={
            currentTextsNumber.formatNumber(property.price, {
              separators: true,
              currency: property.currency,
              truncate: 'integer',
              perUnit: computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
            })
          }
        />
      </Price>
    </FooterContainer>
  );
});

const PropertyCardRental = observer((props: Pick<PropertyCardCommonProps, "property">) => {
  const { property } = props;
  const T = useTexts(texts);
  const currentTextsNumber = useTexts(numberTexts.texts);
  const isDesktop = useMediaQueryDesktop();

  return (
    <FooterContainer>
      {property.bedrooms ? (
        <Stack direction="row" spacing={1} alignItems="baseline">
          <IconWrapper>
            <BedIcon/>
          </IconWrapper>
          <Info>{isDesktop ? T.formatBedrooms(property.bedrooms) : property.bedrooms}</Info>
        </Stack>
      ) : null}
      <Separator/>
      <Price>
        <PropertyPrice
          priceOnRequest={property.priceOnRequest}
          formattedPrice={
            currentTextsNumber.formatNumber(property.price, {
              separators: true,
              currency: property.currency,
              truncate: 'integer',
              perUnit: computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
            })
          }
        />
      </Price>
    </FooterContainer>
  );
});

const PropertyCardSeasonalRental = observer((props: Pick<PropertyCardCommonProps, "property">) => {
  const { property } = props;
  const T = useTexts(texts);
  const isDesktop = useMediaQueryDesktop();

  return (
    <FooterContainer>
      {property.bedrooms ? (
        <Stack direction="row" spacing={1} alignItems="baseline">
          <IconWrapper>
            <BedIcon/>
          </IconWrapper>
          <Info>{isDesktop ? T.formatBedrooms(property.bedrooms) : property.bedrooms}</Info>
        </Stack>
      ) : null}
      <Separator/>
      <Price>
        <PropertyPrice
          priceOnRequest={property.priceOnRequest}
          formattedPrice={
            T.formatTextRange(
              property.price,
              null,
              property.currency,
              computePropertyPricePerUnit(property.operationType, property.area.rentalPeriod),
            )
          }
        />
      </Price>
    </FooterContainer>
  );
});

function renderPropertyCardComponent(property: Property | FavoriteProperty) {
  switch (property.operationType) {
    case OperationType.ResidencyTransaction:
      return <PropertyCardTransaction property={property}/>;
    case OperationType.Rental:
      return <PropertyCardRental property={property}/>;
    case OperationType.SeasonalRental:
      return <PropertyCardSeasonalRental property={property}/>;
  }
}

export interface PropertyCardProps {
  property: Property | FavoriteProperty,
  variant?: 'clientAreaFavorites',
  large?: boolean,
  showSubArea?: boolean,
}

const PropertyCard = observer((props: PropertyCardProps) => {
  const { property } = props;

  return (
    <PropertyCardCommon {...props}>
      {renderPropertyCardComponent(property)}
    </PropertyCardCommon>
  );
});

export default PropertyCard;
