import * as React from 'react';

export default {
    title: 'Welcome on BRETEUIL website',
    cookieNotice: 'Cookie Notice.',
    text(): JSX.Element
    {
      return (
        <React.Fragment>
          We use cookies and similar technologies to give you a sleek browsing experience and analyse our traffic.
          Some cookies are necessary to use our services properly.
        </React.Fragment>
      );
    },
    onlyEssentials: 'REFUSE NON-ESSENTIAL COOKIES',
    acceptAll: 'ACCEPT ALL COOKIES',
};
