import type { IdType } from '@repo-breteuil/common-definitions';

import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import { PictureFormat } from '@repo-breteuil/common-definitions';
import type { QueryStore } from '@repo-breteuil/front-store-query';

export interface User {
  id: IdType,
  photoURL: string,
}

export interface Agency {
  id: IdType,
  slug: string | null,
  slugs: Array<string>,
  name: string | null,
  phone: string | null,
  email: string | null,
  latitude: number | null,
  longitude: number | null,
  timezone: string | null,
  address: string | null,
  team: Array<User>,
}

const UserFields: ReadonlyNodeFields = {
  id: true,
  photoURL: [{ args: { format: true }}, true],
};

const AgencyFields: ReadonlyNodeFields = {
  id: true,
  name: true,
  phone: true,
  email: true,
  latitude: true,
  longitude: true,
  timezone: true,
  address: true,
  team: UserFields,
};

export default async function GetAgency(qs: QueryStore, args: { id: IdType }) {
  return qs.query<Agency>({
    operationName: 'GetAgency',
    variables: {
      id: 'Int!',
      format: 'PictureFormat',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          agency: [{ args: { id: true }}, AgencyFields],
        },
      },
    },
  }, {
    ...args,
    format: PictureFormat._720p, // TODO Check size
  });
}
