import * as React from "react";

export default {
  pageTitle: "Our tailor-made services - Breteuil Remarkable Homes",
  pageDescription: "Discover the exclusive services of Breteuil Remarkable Homes, the prestigious real estate agency specializing in high-end properties.",
  title()  {
    return(
      <React.Fragment>
        <span>{"The best partners in each city"}</span>
      </React.Fragment>
    );
  },
  beforeProject: "Before project",
  afterProject: "After project",
  brokerTitle: "Brokers",
  brokerDescription: "Breteuil offers you a credit access service through seasoned brokers with whom our agents have been working for many years.",
  advisorsTitle: "Tax specialists",
  advisorsDescription: "Our partner lawyers provide advice and litigation services to help their clients optimize and control the weight of taxation in the context of their real estate assets.",
  architectTitle: "Architects",
  architectDescription: "The architecture firms carefully selected by Breteuil ensure exceptional projects that meet your requirements and budget.",
  entrepreneurTitle: "Entrepreneurs",
  entrepreneurDescription: "Master contractors and all-trade companies: a spectrum of reliable and qualified professionals to help you control your costs and deadlines.",
  craftTitle: "Craftsmen and home automation",
  craftDescription: "Custom furniture, exceptional joinery, as well as connected homes and energy control: the best last-mile providers are in our network.",
  contactService: 'Contact us',
};
