import type { Currency } from '@repo-lib/utils-texts';
import { MyBreteuilPropertySurroundingDistance } from '@repo-breteuil/common-definitions';
import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  exclusive: string,
  formatBedrooms(amount: number): string,
  formatBathrooms(amount: number): string,
  formatSurface(amount: number): string,
  formatPrice(amount: number, currency: Currency): string,
  formatPriceSurface(amount: number, surface: number, currency: Currency),
  formatAgency(agencyName: string): string,
  formatAgencyPhone(phone: string): string,
  formatAgencyEmail(email: string): string,
  formatCopro(lots: number): string,
  formatCharges(charges: number, currency: Currency): string,
  formatExclu(isExclusive: Boolean): string,
  formatDPE(
    args: {
      kwh: number | null,
      co2: number | null,
    },
  ): string,
  perMonth: string,
  perWeek: string,
  pdfMap: string,
  recommendations: {
    title: string,
    around: string,
    farAway: string,
  },
  market: {
    title: string,
  },
  linkMarketData: string,
  textMarketData: string,
  textGeoRisk: string,
  propertiesNearby: string,
  propertiesExplore: string,
  ctaShare: string,
  ctaMail: string,
  ctaPhone: string,
  ctaValuation: string,
  similarProperties: string,
  formatMailSubject(ref: string): string,
  formatMailText(url: string): string,
  fullscreen: string,
  exitFullscreen: string,
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string,
  location: string,
  keyInfos: string,
  keyInfosTitle: string,
  surroundingsTitle: string,
  formatDistance(distance: MyBreteuilPropertySurroundingDistance): string,
  formatGuests(guestsCount: number): string,
  seasonalRentalDuration: string,
  defaultQuote: JSX.Element,
  propertyAccessRequest: {
    title: string,
    description: string,
    button: string,
  },
  propertyAccessCodeInput: {
    title: string,
    description: string,
    formatPhoneNumber: (phoneNumber: string) => string,
    invalidAccessCode: string,
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
