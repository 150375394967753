import { Locale } from '@repo-breteuil/common-texts';
import {
  makeTranslatedPageSetters,
} from '@core/store/routing';
import router from '@core/store/routing/router';
import { websiteStores } from '../ui/BreteuilWebsiteStores';
import { getRouteInOtherLanguage } from './translatedRoutes';

const {
  setTranslatedPage,
  setTranslatedStaticPage,
} = makeTranslatedPageSetters<Locale>({
  setLanguage: (locale: Locale) => websiteStores.locale.setLocale(locale),
});

export {
  setTranslatedPage,
  setTranslatedStaticPage,
};

export function getCurrentLocationInOtherLanguage(
  lang: Locale,
): string | null
{
  return getRouteInOtherLanguage(router.currentRoute, lang);
}
