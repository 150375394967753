import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Slide } from '@mui/material';
import { Calendar, TimeslotsTabs } from './timeslot-selection';
import AgenciesMap from './agency-selection/agencies-map';
import ConfirmationForm from './details/ConfirmationForm';
import Summary from './details/Summary';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: "100%",
  maxWidth: '1200px',
  margin: 'auto',
});

const AnimationContainer = styled('div')({
  display: 'flex',
  width: "100%",
  height:'100%',
  overflow: 'hidden',
  "@media (max-width: 1280px)": {
    minWidth: 'unset',
  },
});

const AgencyInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
  minWidth: '485px',
  borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
  padding: '20px',
  width: '55%',
  "@media (max-width: 1280px)": {
    minWidth: 'unset',
  },
});

const Method3LayoutWrapper = styled('div')({
  display: 'flex',
  height: '100%',
  width: "100%",
  "@media (max-width: 1280px)": {
    display: 'flex',
    flexDirection: 'column',
  },
});


const MapWrapper = styled('div')({
  width: '100%',
  maxHeight: '600px',
  aspectRatio: '16 / 12',
});

const Wrapper = styled('div')({
  height: '100%',
  width: "100%",
});

const CalendarAndTimeslotsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '350px',
  "@media (max-width: 1280px)": {
    display: 'flex',
    flexDirection: 'column',
  },
});

const SummaryAndMapWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  minHeight: '400px',
});

const DetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FormElementContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  columnGap: '5px',
  border: '2px solid rgba(0, 0, 0, 0.1)',
  "@media (max-width: 1260px)": {
    display: 'block',
  },
});

const StyledParisMap = styled('img')({
    width: '51%',
    paddingLeft: '20px',
    borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
});

const CalendarAndTimeslots = observer(() => {
  return (
    <CalendarAndTimeslotsWrapper>
      <Calendar/>
      <TimeslotsTabs/>
    </CalendarAndTimeslotsWrapper>
  );
});

const Details = observer((props: { handleNext: () => void }) => {
  const { valuation } = useWebsiteStores();
  const imgSrc = valuation.isParisSelected ? '/assets/map_illu.png' : '/assets/map_france.png';
  const isMobile = useMediaQueryMobile();
  return (
    <DetailsWrapper>
      <CalendarAndTimeslots/>
      <FormElementContainer>
        <ConfirmationForm handleNext={props.handleNext}/>
        {isMobile ? null : <StyledParisMap src={imgSrc}/>}
      </FormElementContainer>
    </DetailsWrapper>
  );
});

const SummaryAndMap = observer(() => {
  return (
    <SummaryAndMapWrapper>
      <FormElementContainer>
        <Summary/>
        <Map/>
      </FormElementContainer>
    </SummaryAndMapWrapper>
  );
});

const Map = observer(() => {
  const { valuation } = useWebsiteStores();
  const { agencyMeeting } = valuation;
  const isTablet = useMediaQueryTablet();
  const isMobile = useMediaQueryMobile();
  const resolvedAddressMarker = (agencyMeeting !== null ?
    new google.maps.Marker({
      position: {
        lat: agencyMeeting.latitude,
        lng: agencyMeeting.longitude,
      },
      icon: {
        url: "/assets/agencies/map/pin_light_blue.svg",
        scaledSize: new google.maps.Size(25, 35),
      },
    }) : null);
  return (
    <AgencyInfo>
      {!isTablet && !isMobile ? (
        <MapWrapper>
          <AgenciesMap
            opts={{
              focusedAgency: valuation.agency,
              markers: resolvedAddressMarker ? [resolvedAddressMarker] : [],
            }}
          />
        </MapWrapper>
      ) : null}
    </AgencyInfo>
  );
});

const Method3 = observer(() => {
  const { valuation } = useWebsiteStores();
  const [direction, setDirection] = React.useState<"left" | "right">("right");
  const [panel, setPanel] = React.useState<number>(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const handleNext = () => {
    if (panel === 0 && valuation.address && valuation.selectedDate && valuation.selectedMeetingDate){
      setDirection('left');
      setPanel(1);
    }
    else if (panel === 1) {
      setDirection('left');
      setPanel(2);
    }
    else if (panel === 2){
      setDirection('left');
      setPanel(3);
    }
  };

  return (
    <Container>
      <Method3LayoutWrapper>
        <AnimationContainer ref={containerRef}>
          <Slide
            direction={direction}
            in={panel === 0}
            container={containerRef.current}
            exit={false}
            mountOnEnter
            unmountOnExit
          >
            <Wrapper>
              {panel === 0 ? <Details handleNext={handleNext}/> : null}
            </Wrapper>
          </Slide>
          <Slide
            direction={direction}
            in={panel === 1}
            appear
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
          >
            <Wrapper>
              {panel === 1 ? <SummaryAndMap/> : null}
            </Wrapper>
          </Slide>
        </AnimationContainer>
      </Method3LayoutWrapper>
    </Container>
  );
});

export default Method3;
