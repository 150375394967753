import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { Link } from '@breteuil-website/store/routing';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const ActiveTabIndicator = styled('div')({
  backgroundColor: '#050251',
  height: '100%',
  width: '10px',
  boxShadow: '3px 0px 8px #AAAAAA',
  position: 'absolute',
  left: '0px',
});

const Tab = styled(Button)({
  borderRadius: 0,
  width: '100%',
  color: '#989898',
  fontFamily: 'Poppins',
  fontSize: '12px',
  height: '100px',
  fontWeight: 500,
  flexDirection: 'column',
  textTransform: 'none',
  rowGap: '8px',
  borderBottom: '1px solid #E5E5E5 !important',
  lineHeight: '15px',
});

const MobileTab =  styled(Button)({
  borderRadius: 0,
  color: '#050251',
  backgroundColor: '#FFFFFF',
  height: '100%',
  width: '100%',
});

const MobileActiveTab =  styled(Button)({
  borderRadius: 0,
  color: '#050251',
  backgroundColor: '#FFFFFF',
  height: '100%',
  width: '100%',
  borderBottom: '6px solid #050251',
});

interface ClientAreaTabProps
{
  text: string | JSX.Element,
  to: string,
  icon?: JSX.Element,
  name?: string,
  onClick?: () => void,
}

const ClientAreaTab = observer((props: ClientAreaTabProps) => {
  const isMobile = useMediaQueryMobile();
  const isTablet = useMediaQueryTablet();
  const { clientAreaTabs } = useWebsiteStores();
  return (
    <Link to={props.to}>
      {isMobile || isTablet ?
      ( props.name === clientAreaTabs.activeTab ?
        ( <MobileActiveTab {...omitUndefinedValues({ onClick: props.onClick })}>
            {props.icon}
          </MobileActiveTab>
        ) :
        (
          <MobileTab {...omitUndefinedValues({ onClick: props.onClick })}>
            {props.icon}
          </MobileTab>
        )
      ) :
      ( props.name === clientAreaTabs.activeTab ?
        (
          <Tab
            sx={{color: '#050251'}}
            {...omitUndefinedValues({ onClick: props.onClick })}
          >
            <ActiveTabIndicator/>
            {props.icon}
            {props.text}
          </Tab>
        ) :
        (
          <Tab {...omitUndefinedValues({ onClick: props.onClick })}>
            {props.icon}
            {props.text}
          </Tab>
        )
      )
      }
    </Link>
  );
});

export default ClientAreaTab;
