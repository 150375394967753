import * as React from "react";
import MUIPhoneNumber from 'material-ui-phone-number-2';
import { styled } from "@mui/material/styles";
import {
  Slide,
  Dialog,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  ensureFetchableResource,
  assertComponentDependency,
} from '@repo-breteuil/front-error';
import { useTexts } from '@repo-breteuil/common-texts';
import {
  Button as GenericButton,
  TextFieldStyle as GenericTextFieldStyle,
} from '@breteuil-website/components/common';
import texts from '../texts';
import RadioOptions from './RadioOptions';
import CodeConfirmation from './CodeConfirmation';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useWebsiteStores } from "@breteuil-website/components/providers";

const TextFieldStyle = {
  marginBlock: '.5em',
  fontSize: '1rem',
  backgroundColor: 'white',
  '& .MuiInputLabel-root': {
    color: 'rgba(5, 2, 81, 0.5)',
  },
};


const PhonePicker = styled(styled(MUIPhoneNumber)(GenericTextFieldStyle))(TextFieldStyle);

const AnimationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'center',
  alignItems: 'center',
  height:'100%',
  overflow: 'hidden',
});

const Wrapper = styled('div')({
  height: '100%',
});

const PhonePickerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F0F5FF',
  width: '50vw',
  maxWidth: '750px',
  padding: '50px',
  alignItems: 'center',
  '@media (max-width: 1280px)': {
    padding: '25px',
  },
  '@media (max-width: 960px)': {
    width: '80vw',
  },
  '@media (min-width: 1600px)': {
    maxWidth: '900px',
  },
});

const PhonePickerStyled = styled(PhonePicker)({
  width: '100%',
});

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '750px',
    overflow: 'unset',
    backgroundColor: '#F0F5FF',
    '@media (min-width: 1600px)': {
      maxWidth: '900px',
      marginTop: '5px',
    },
  },
});

const PhoneActivationTitle = styled('h2')({
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Poppins',
  width:'100%',
  color: '#050251',
  '@media (max-width: 1280px)': {
    fontSize: '18px',
    textAlign: 'center',
  },
});

const PhoneNumberWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  maxWidth: '650px',
  width: '100%',
  columnGap: '10px',
  margin: '40px 0',
  '@media (max-width: 1280px)': {
    margin: '40px 0',
    gridTemplateColumns: '1fr',
  },
  '@media (min-width: 1600px)': {
    maxWidth: '800px',
  },
});

const RadioFieldWrapper = styled('div')({
  display: 'flex',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 500,
  color: '#050251',
  columnGap: '15px',
  '@media (max-width: 1280px)': {
    marginTop: '15px',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '24px',
  height: '24px',
  padding: "24px",
});

const RadioFieldLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  minWidth: '185px',
  flexShrink: 0,
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  color: '#050251',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const SubmitButton = styled(GenericButton)({
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '14px',
  padding: '10px !important',
});

interface PhoneActivationModalProps {
  form: any, // TODO use correct type if possible
  handleCloseModal: () => void,
  handleConfirmPhoneDialogClose: () => void,
  confirmPhoneDialogOpen: boolean,
  isPhoneNumberValid: boolean,
  openModal: boolean,
  phoneRef: React.MutableRefObject<HTMLInputElement | null>,
}

const PhoneValidationModal = (props: PhoneActivationModalProps) => {
  const {
    form,
    handleCloseModal,
    handleConfirmPhoneDialogClose,
    confirmPhoneDialogOpen,
    isPhoneNumberValid,
    openModal,
    phoneRef,
  } = props;
  const { valuation, globalMessage } = useWebsiteStores();
  const [panel, setPanel] = React.useState<number>(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (panel === 0 && confirmPhoneDialogOpen){
      setPanel(1);
    }
    else if (panel === 1 && !confirmPhoneDialogOpen) {
      setTimeout(() => setPanel(0), 500);
    }
  }, [confirmPhoneDialogOpen, isPhoneNumberValid]);

  const handleCloseCodeConfirmationModal = () => {
    form.fields.phone.handleChange(null);
    handleConfirmPhoneDialogClose();
    handleCloseModal();
  };

  const handleResendPromise = () => {
    const v = ensureFetchableResource(valuation.detailedValuation);
    assertComponentDependency(v);
    return valuation.resendVerifyCode.ensureSuccessReload({ id: v.id });
  };

  const handlePhoneConfirmationSubmit = (submittedCode: string) => {
    valuation.setVerificationCode(submittedCode);
    const promise = valuation.verifyDetailedValuation({
      phoneVerifyCode: submittedCode,
    });
    globalMessage.handlePromise(promise, {
      onSuccess: () => {
        handleCloseModal();
        handleConfirmPhoneDialogClose();
        form.fields.phone.handleChange(null);
      },
      successMessage: null,
      errorMessage: (error) => {
        return T.method1.form.handleFormSubmissionErrors(error);
      },
    });
  };

  const T = useTexts(texts);
  const isDesktop = useMediaQueryDesktop();

  return (
    <DialogStyled
      open={openModal}
      onClose={handleCloseModal}
      transitionDuration={500}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: '#FFFFFF', opacity: '0.8 !important', padding: '35px' },
        },
      }}
    >
      <AnimationContainer ref={containerRef}>
      <Slide
        direction={"right"}
        in={panel === 0}
        container={containerRef.current}
        appear={false}
        exit={false}
      >
        <Wrapper>
          {panel === 0 ? (
            <PhonePickerContainer>
              <CloseButton onClick={handleCloseModal}>
                <Close/>
              </CloseButton>
              <PhoneActivationTitle>
                {isDesktop ? T.method2.form.phoneActivationTitle : T.method2.form.phoneActivationTitleMobile}
              </PhoneActivationTitle>
              <PhoneNumberWrapper>
                <PhonePickerStyled
                  label={T.method1.form.labels.phone}
                  inputProps={{ ref: phoneRef }}
                  error={!isPhoneNumberValid}
                  defaultCountry="fr"
                  onlyCountries={['fr', 'gb', 'us', 'es', 'pt']}
                  variant="outlined"
                  countryCodeEditable={false}
                  disableAreaCodes={true}
                  onChange={(value) => {
                    form.fields.phone.handleChange(value);
                  }}
                />
                <SubmitButton
                  type="submit"
                  onClick={form.handleSubmit}
                  disabled={form.submitting}
                >
                  {T.method2.form.submitPhone}
                </SubmitButton>
              </PhoneNumberWrapper>
              <RadioFieldWrapper>
                <RadioFieldLabel>{T.method2.form.input.contactMe}</RadioFieldLabel>
                <RadioOptions field={form.fields.contactMe}/>
              </RadioFieldWrapper>
            </PhonePickerContainer>
          ) : null}
        </Wrapper>
      </Slide>
      <Slide
        direction={"left"}
        in={panel === 1}
        container={containerRef.current}
        timeout={300}
      >
        <Wrapper>
          {panel === 1 ? (
            <PhonePickerContainer>
              <CodeConfirmation
                handleClose={handleCloseCodeConfirmationModal}
                handleSubmit={handlePhoneConfirmationSubmit}
                handleResendPromise={handleResendPromise}
              />
            </PhonePickerContainer>
          ) : null}
        </Wrapper>
      </Slide>
    </AnimationContainer>
    </DialogStyled>
  );
};

export default PhoneValidationModal;
