import type { WeekSchedule } from '@repo-lib/utils-week-schedule';
import { wrapWithOptionalComponent } from '@repo-lib/utils-react';

import * as React from 'react';
import {
  formatDaySchedule,
  trimWeekSchedule,
} from '.';

//TODO: all EN texts

function formatHour(hour: number, minute: number): string
{
  const ampm = (hour < 12) ? 'a.m' : 'p.m';
  const hoursAndMinutes: Array<string> = [
    (((hour > 12) ? (hour - 12) : ((hour === 0) ? 12 : hour)).toString()).padStart(2, '0'),
    (minute.toString()).padStart(2, '0'),
  ];
  return (hoursAndMinutes.join(':') + ampm);
}

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

const T = {
  formatPageTitle(name: string)
  {
    return `Real estate agency ${name} - Breteuil Remarkable Homes`;
  },
  formatPageDescription(name: string)
  {
    return `Your real estate agency in ${name}. Discover our selection of properties and benefit from our expertise in prestigious real estate.`;
  },
  return: 'Return to the agency list',
  team: 'Your team',
  director: 'Agency Manager',
  readReport: 'Read report',
  openingHours: "Business hours", //TODO
  phoneHours: 'Out-of-hours telephone services', //TODO
  teamTitle: 'Your Team',

  formatAddress(address, phone = null, email = null): React.ReactNode
  {
    return (
      <React.Fragment>
        <span>{address}</span>
        {phone && email ? (
          <>
            <br/><span>{phone}</span><br/>
            <span>{email}</span>
          </>
        ) : null}
      </React.Fragment>
    );
  },
  formatOpeningSchedule(
    schedule: WeekSchedule,
    components: {
      Day?: React.ComponentType<React.PropsWithChildren<{}>>,
      Hours?: React.ComponentType<React.PropsWithChildren<{}>>,
      ClosedHours?: React.ComponentType<React.PropsWithChildren<{}>>,
      Row?: React.ComponentType<React.PropsWithChildren<{}>>,
    },
  ): React.ReactNode
  {
    const { Day, Hours, ClosedHours, Row } = components;
    const {
      schedule: filteredSchedule,
      offset: skippedDaysCount,
    } = trimWeekSchedule(schedule);
    if (filteredSchedule.length === 0)
      return wrapWithOptionalComponent(ClosedHours, 'Temporarily Closed');
    return (
      <React.Fragment>
        {schedule.map((day, subIndex) => {
          const index = subIndex + skippedDaysCount;
          const daySchedule = formatDaySchedule(day, {
            formatHour: ((hours, minutes) => formatHour(hours, minutes)),
            hoursSeparator: ' - ',
            rangesSeparator: ', ',
          });
          const RowComponent = Row || React.Fragment;
          return (
            <RowComponent key={index}>
              {wrapWithOptionalComponent(Day, days[index])}
              {(daySchedule === null) ? (
                wrapWithOptionalComponent(ClosedHours, 'Closed')
              ) : (
                wrapWithOptionalComponent(Hours, daySchedule)
              )}
            </RowComponent>
          );
        })}
      </React.Fragment>
    );
  },

  formatPhoneSchedule(
    schedule: WeekSchedule,
  ): string
  {
    //TOOD: when needed, handle different ranges per day of week
    const daySchedule = schedule[0];

    //TODO: when needed, handle multiple ranges per day
    const timeRange = daySchedule.slice(0, 1);

    const scheduleString = formatDaySchedule(timeRange, {
      //TODO: when needed, handle minutes
      formatHour: (hour, minute) => `${hour}h`,
      hoursSeparator: ' to ',
      rangesSeparator: ', ',
      lastRangeSeparator: ' and in ',
    });
    if (scheduleString === null)
      return 'Temporarily Closed';

    return `Available 7 days a week from ${scheduleString}`;
  },
};

export default T;
