import type { IdType } from '@repo-breteuil/common-definitions';

import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import { PictureFormat } from '@repo-breteuil/common-definitions';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface User {
  id: IdType,
  photoURL: string,
}

const AgentFields: ReadonlyNodeFields = {
  id: true,
  photoURL: [{ args: { format: true }}, true],
};

export default async function GetRemarkablePeople(qs: QueryStore) {
  return qs.query<Array<User>>({
    operationName: 'GetRemarkablePeople',
    variables: {
      format: 'PictureFormat',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          remarkablePeople: AgentFields,
        },
      },
    },
  }, {
    format: PictureFormat._720p, // TODO Check size
  });
}
