import * as React from 'react';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { LocaleProvider } from '@repo-breteuil/common-texts';
import {
  BreteuilWebsiteStoresProps,
  BreteuilWebsiteStores,
  websiteStores,
} from '@breteuil-website/store/ui/BreteuilWebsiteStores';

const WebsiteStoresContext = createContext<BreteuilWebsiteStores | null>(null);

export function useWebsiteStores(): BreteuilWebsiteStores
{
  const stores = useContext(WebsiteStoresContext);
  if (stores === null)
    throw new Error('useWebsiteStores was called outside of its WebsiteStoresProvider');
  return stores;
}

export const StoresProvider = observer(function StoresProvider(props: PropsWithChildren<BreteuilWebsiteStoresProps>)
{
  const { children, ...args } = props;
  const [ stores ] = useState(() => websiteStores/*TODO: remove this once stores migration is complete*/ || new BreteuilWebsiteStores(args));

  return (
    <WebsiteStoresContext.Provider value={stores}>
      <LocaleProvider value={stores.locale.locale}>
        {children}
      </LocaleProvider>
    </WebsiteStoresContext.Provider>
  );
});
