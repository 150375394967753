import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@repo-breteuil/common-texts';
import type { OperationType } from '@repo-breteuil/common-definitions';
import type { Search } from './shared';

import { QueryStore } from '@repo-breteuil/front-store-query';
import { searchFields } from './shared';

export async function UpdateSearch(
  qs: QueryStore,
  args: {
    language: Language,

    id: IdType,
    operationType?: OperationType,
    geoAreasIds?: Array<IdType>,
    budgetMin?: number | null,
    budgetMax?: number | null,
    surfaceMin?: number | null,
    surfaceMax?: number | null,
    bedroomsMin?: number | null,
    bedroomsMax?: number | null,
    criteriaIds?: Array<IdType> | null,
    notificationEmail?: boolean | null,
    notificationSMS?: boolean | null,
    notificationWhatsApp?: boolean | null,
  },
): Promise<Search>
{
  return qs.mutation({
    operationName: 'UpdateSearch',
    variables: {
      language: 'Language!',

      id: 'Int!',
      operationType: 'MyBreteuilOperationType',
      geoAreasIds: '[Int!]',
      budgetMin: 'Int',
      budgetMax: 'Int',
      surfaceMin: 'Int',
      surfaceMax: 'Int',
      bedroomsMin: 'Int',
      bedroomsMax: 'Int',
      criteriaIds: '[Int!]',
      notificationEmail: 'Boolean',
      notificationSMS: 'Boolean',
      notificationWhatsApp: 'Boolean',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            search: {
              update: [{
                args: {
                  id: true,
                  operationType: true,
                  geoAreasIds: true,
                  budgetMin: true,
                  budgetMax: true,
                  surfaceMin: true,
                  surfaceMax: true,
                  bedroomsMin: true,
                  bedroomsMax: true,
                  criteriaIds: true,
                  notificationEmail: true,
                  notificationSMS: true,
                  notificationWhatsApp: true,
                },
              }, searchFields ],
            },
          },
        },
      },
    },
  }, args);
}
