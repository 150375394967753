import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  pageTitle: string,
  pageDescription: string,
  title(): JSX.Element,
  beforeProject: string,
  afterProject: string,
  brokerTitle: string,
  brokerDescription: string,
  advisorsTitle: string,
  advisorsDescription: string,
  architectTitle: string,
  architectDescription: string,
  entrepreneurTitle: string,
  entrepreneurDescription: string,
  craftTitle: string,
  craftDescription: string,
  contactService: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
