import * as React from 'react';
import { observer } from 'mobx-react';
import DesktopAppBar from '@breteuil-website/components/common/app/appBar/desktop/AppBar';
import MobileAppBar from '@breteuil-website/components/common/app/appBar/mobile/AppBar';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';

const AppBar = observer(() => {
  const isTablet = useMediaQueryTablet();
  const isMobile = useMediaQueryMobile();

  return isTablet || isMobile ? <MobileAppBar/> : <DesktopAppBar/>;
});

export default AppBar;
