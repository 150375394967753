import type { IdType } from '@repo-breteuil/common-definitions';
import type { OperationType } from '@repo-breteuil/common-definitions';
import type { PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';

import { paginationResult } from '@repo-breteuil/front-store-query';

const namedEntityFields = {
  id: true,
  name: [{
    args: { language: true },
  }, true ],
} as const;

export interface Area
{
  id: IdType,
  name: string,
}

export const areaFields = namedEntityFields;

export interface SubArea
{
  id: IdType,
  name: string,
}

export const subAreaFields = namedEntityFields;

export interface Criteria
{
  id: IdType,
  name: string,
}

export const criteriaFields = namedEntityFields;

export interface Search
{
  id: IdType,
  label: string,
  budgetMin: number,
  budgetMax: number,
  surfaceMin: number,
  surfaceMax: number,
  bedroomsMin: number,
  bedroomsMax: number,
  operationType: OperationType,
  notificationEmail: boolean,
  notificationSMS: boolean,
  notificationWhatsApp: boolean,
  area: Area,
  subAreas: PaginationExtendedResponse<SubArea>,
  criteria: Array<Criteria>,
}

export const searchFields = {
  id: true,
  label: [{
    args: { language: true },
  }, true ],
  budgetMin: true,
  budgetMax: true,
  surfaceMin: true,
  surfaceMax: true,
  bedroomsMin: true,
  bedroomsMax: true,
  operationType: true,
  notificationEmail: true,
  notificationSMS: true,
  notificationWhatsApp: true,
  area: areaFields,
  subAreas: paginationResult(subAreaFields),
  criteria: criteriaFields,
} as const;

