import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
} from '@mui/material';
import { Link } from '@breteuil-website/store/routing';
import {
  pdfRatesURL,
  clientMoneyProtection,
  inHouseComplaints,
  ukFees,
} from '@breteuil-website/_hardcoded';
import {
  Page,
  PageMeta,
} from '@breteuil-website/components/common';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const OurFeeContainers = styled(Box)({
  marginBottom: "40px",
});

const StickyTitle = styled(Box)({
  width: '100%',
  position: 'sticky',
  top: '90px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '44px',
  textAlign: 'center',
  backgroundColor: 'rgba(5, 2, 81, 0.90)',
  color: 'white',
  padding: '15px 0',
  zIndex: 3,
  "@media (max-width: 960px)": {
    width: '100%',
    margin: 'auto',
    fontSize: '18px',
    top: '50px',
  },
});

const StyledLink = styled(Link)({
  fontSize: "22px",
  textDecoration: "underline",
  "@media (max-width: 960px)": {
    fontSize: "18px",
  },
});

const StyledImg = styled('img')({
  display: "block",
  width: "70%",
  margin: "auto",
  "@media (min-width: 1800px)": {
    width: '50%',
  },
  "@media (max-width: 960px)": {
    width: '100%',
  },
});

const StyledBox = styled(Box)({
  width: "85%",
  margin: "30px auto",
  "@media (max-width: 960px)": {
    margin: "20px auto",
  },
});


const Uk = () => {
  const T = useTexts(texts);
  return (
    <React.Fragment>
      <StyledImg src="/assets/tpo-tsi.jpg"/>
      <StyledBox>
        <StyledLink
          to={ukFees}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {T.links.fees}
        </StyledLink>
      </StyledBox>
      <StyledBox>
        <StyledLink
          to={clientMoneyProtection}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {T.links.clientMoneyProtection}
        </StyledLink>
      </StyledBox>
      <StyledBox>
        <StyledLink
          to={inHouseComplaints}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {T.links.inHouseComplaints}
        </StyledLink>
      </StyledBox>
    </React.Fragment>
  );
};

const France = () => {
  const T = useTexts(texts);
  return (
    <StyledBox>
      <StyledLink
        to={pdfRatesURL}
        target={"_blank"}
        rel={"noreferrer"}
      >
        {T.links.fees}
      </StyledLink>
    </StyledBox>
  );
};

const OurFees = () => {
  const T = useTexts(texts);
  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <Stack
        direction={"column"}
      >
        <OurFeeContainers>
          <StickyTitle>{T.titles.france}</StickyTitle>
          <France/>
        </OurFeeContainers>
        <OurFeeContainers>
          <StickyTitle>{T.titles.uk}</StickyTitle>
          <Uk/>
        </OurFeeContainers>
      </Stack>
    </Page>
  );
};

export default OurFees;
