import type {
  YearTransactionsStats,
} from '@breteuil-website/store/ui/pages/market-data/api';

export async function GetVariationFromResult(TransactionsStatsData: Array<Array<YearTransactionsStats>> | undefined) {
  if (TransactionsStatsData === undefined)
    return null;
  const [
    TransactionsStats_70m2,
    TransactionsStats_70m2plus,
  ] = TransactionsStatsData;
  if (TransactionsStats_70m2 && TransactionsStats_70m2plus) {

    const [lastYear_70m2, currentYear_70m2] = TransactionsStats_70m2.slice(-2).map((tStats70 => {
      if (tStats70.stats.adjustedPricePerSurface)
        return { avg: tStats70.stats.adjustedPricePerSurface.avg, volume: tStats70.stats.volume, count: tStats70.stats.count };
      return { avg: 0, volume: 1, count: 1 }; // Cancel the value
    }));
    const [lastYear_70m2plus, currentYear_70m2plus] = TransactionsStats_70m2plus.slice(-2).map((tStats70 => {
      if (tStats70.stats.adjustedPricePerSurface)
        return { avg: tStats70.stats.adjustedPricePerSurface.avg, volume: tStats70.stats.volume, count: tStats70.stats.count };
      return { avg: 0, volume: 1, count: 1 }; // Cancel the value
    }));


    let currentYearAvg = 0;
    if (currentYear_70m2 && currentYear_70m2plus)
      currentYearAvg = (currentYear_70m2.avg * currentYear_70m2.count + currentYear_70m2plus.avg * currentYear_70m2plus.count) /
                       (currentYear_70m2.count + currentYear_70m2plus.count);

    let lastYearAvg = 0;
    if (lastYear_70m2 && lastYear_70m2plus)
      lastYearAvg = (lastYear_70m2.avg * lastYear_70m2.count + lastYear_70m2plus.avg * lastYear_70m2plus.count) /
                    (lastYear_70m2.count + lastYear_70m2plus.count);

    if (lastYearAvg && currentYearAvg)
      return currentYearAvg * 100 / lastYearAvg - 100;
  }
  return null;
}
