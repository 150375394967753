import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  signUp: string,
  alreadyHaveAccount: string,
  logIn: string,
  firstname: string,
  lastname: string,
  email: string,
  phone: string,
  password: string,
  confirmPassword: string,
  confirmPasswordError: string,
  handleRegisterError: (error: Error) => string,
  emailAlreadyUsed: string,
  activationLinkSent: string,
  emailIsLogin: string,
  receive: string,
  favoriteProperties: string,
  create: string,
  checkPolicy: string,
  security: string,
  phoneNumberError: string,
  registerError: string,
};

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
