import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import {
  Button,
  TextField,
} from '@breteuil-website/components/common/elements';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  fontFamily: 'Poppins',
  maxWidth: '650px',
});

const Text = styled('div')({
  fontSize: '20px',
  color: '#050251',
  margin: '50px auto',
  maxWidth: '600px',
});

const Form = styled('form')({
  display: 'grid',
  rowGap: '15px',
  maxWidth: '400px',
  margin: '50px auto 50px auto',
});

const InvalidToken = observer(() => {
  const T = useTexts(texts);
  const { emailVerify, globalMessage } = useWebsiteStores();

  const form = useForm(() => ({
    email: createValueFormField('', { validation: (value) => (value !== '') }),
  }), {
    onSubmit: (values) => {
      const promise = emailVerify.sendEmailVerify(values.email);
      return globalMessage.handlePromise(promise, {
        successMessage: () => T.emailSent,
        errorMessage: () => T.errorNotif,
      });
    },
  });

  return (
    <Container>
      <Text>
        {T.text}
      </Text>
      <Form onSubmit={form.handleSubmit}>
        <TextField
          field={form.fields.email}
          label={T.email}
          variant='outlined'
        />
        <Button
          disabled={form.submitting}
          type='submit'
        >
          {T.resendEmail}
        </Button>
      </Form>
    </Container>
  );
});

export default InvalidToken;
