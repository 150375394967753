import { QueryStore } from '@repo-breteuil/front-store-query';

export default async function resendPhoneVerifyCode(
  qs: QueryStore,
  args: {
    valuationId: number,
  },
): Promise<boolean> {
  return qs.mutation({
    operationName: 'resendPhoneVerifyCode',
    variables: {
      valuationId: 'Int!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            resendPhoneVerifyCode: [{
              args: {
                valuationId: true,
              },
            }, true],
          },
        },
      },
    },
  }, args);
}
