export default {
  about: 'About',
  aboutBreteuil: 'About Breteuil',
  intro: "Acteur majeur de l’immobilier international de prestige, Breteuil sert vos projets depuis près de 25 ans.",
  arthurTitle: 'Arthur Colarossi, CEO',
  arseneTitle: 'Arsène Colarossi, COO',
  arthurBackground: 'Commodity trader à Londres et New York de 2006 à 2019 au service de banques et de hedge funds majeurs, Arthur a mis au service de Breteuil sa vision du marché à la fois précise et profonde.',
  arseneBackground: 'Arsène  rentre de Londres en 2017 où il a développé la branche outre-Manche de la société, et y a affuté des méthodes de management innovantes et une extrême exigeance opérationnelle.',
  companyIntro: `Breteuil est implanté à Paris, Londres, Lisbonne et New York, ainsi que sur le littoral français (Biarritz, Saint-Jean-de-Luz, Deauville, La Baule et Dinard).

  La société a trouvé son élan international dans l’experience anglo-saxonne du top management, adossée à un sens de l’excellence et du service et à une parfaite connaissance des enjeux économiques et du marché.`,
  freeValuation: 'Free valuation',
};
