import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { UpdateAccount, GetProfileData } from './api';
import { SessionStore } from '@breteuil-website/store/ui/common/Session';
import { QueryStore } from '@repo-breteuil/front-store-query';

export class ProfileStore
{
  constructor(
    public stores: {
      query: QueryStore,
      session: SessionStore,
    },
  )
  {
  }

  public profileData = new Fetchable(
    () => GetProfileData(this.stores.query).then((session) => {
      const { contact } = this.stores.session.ensureContactSession(session);
      return contact;
    }),
    { catchUnhandled: handleNonCriticalError },
  );

  public async updateAccount(
    args: Parameters<typeof UpdateAccount>[1],
  ): Promise<void>
  {
    const session = await UpdateAccount(this.stores.query, args);
    const { contact } = this.stores.session.ensureContactSession(session);
    this.profileData.setResult(contact);
  }
}
