import type { IdType } from '@repo-breteuil/common-definitions';

export const inseeToParisDistrictId = {
  '75101': 1,
  '75102': 2,
  '75103': 3,
  '75104': 4,
  '75105': 5,
  '75106': 6,
  '75107': 7,
  '75108': 8,
  '75109': 9,
  '75110': 10,
  '75111': 11,
  '75112': 12,
  '75113': 13,
  '75114': 14,
  '75115': 15,
  '75116': 16,
  '75117': 17,
  '75118': 18,
  '75119': 19,
  '75120': 20,
  '92051': 22,
  '92012': 21,
};

export const inseeToLaBauleDistrictId = {
  '44132': 29,
  '44055': 27,
  '44135': 30,
  '44010': 31,
  '44049': 32,
  '44069': 33,
  '44151': 34,
};

export const ParisMapValues = {
  zoomLevel: 13,
  mobileZoomLevel: 11.4,
  latitude: 48.861002,
  longitude: 2.323336,
};

export const LaBauleMapValues = {
  zoomLevel: 12,
  mobileZoomLevel: 10.6,
  latitude: 47.3048352,
  longitude: -2.4016423,
};

export interface SubAreas {
  id: number,
  name: string,
  fullName?: string,
};

export interface City {
  id: number,
  title: string,
  chartTitleKey: string,
  subAreas: Array<SubAreas>,
}

export const city: City = {
  id: 1,
  title: 'Paris',
  chartTitleKey:'innerParis',
  subAreas: [
    {id: 1, name: "1er"},
    {id: 2, name: "2e"},
    {id: 3, name: "3e"},
    {id: 4, name: "4e"},
    {id: 5, name: "5e"},
    {id: 6, name: "6e"},
    {id: 7, name: "7e"},
    {id: 8, name: "8e"},
    {id: 9, name: "9e"},
    {id: 10, name: "10e"},
    {id: 11, name: "11e"},
    {id: 12, name: "12e"},
    {id: 13, name: "13e"},
    {id: 14, name: "14e"},
    {id: 15, name: "15e"},
    {id: 16, name: "16e"},
    {id: 17, name: "17e"},
    {id: 18, name: "18e"},
    {id: 19, name: "19e"},
    {id: 20, name: "20e"},
    {id: 21, name: "Boul.", fullName: 'Boulogne'},
    {id: 22, name: "Neui.", fullName: 'Neuilly'},
  ],
};

export const laBaule: City = {
  id: 2,
  title: 'La Baule',
  chartTitleKey: 'innerLaBaule',
  subAreas: [
    {id: 29, name: "Pornichet"},
    {id: 27, name: "La Baule-Escoublac"},
    {id: 30, name: "Le Pouliguen"},
    {id: 31, name: "Batz-sur-Mer"},
    {id: 32, name: "Le Croisic"},
    {id: 33, name: "Guérande"},
    {id: 34, name: "Saint-André-des-Eaux"},
  ],
};

// 10: Paris, 17: la Baule
export const marketDataAreas = [10, 17] as const;

// 1: Paris, 2: la Baule
export const marketDataCities = new Map([[10, city], [17, laBaule]]);

export const AreaIdToCityId = new Map([[10, 1], [17, 2]]);

export function getIdFromMapData(selectedAreaId: IdType, idFromMap: string | IdType): IdType {
  let id: IdType;
  if (selectedAreaId === 10) //TODO get it from query. (Paris)
    id = inseeToParisDistrictId[idFromMap];
  else if (selectedAreaId === 17) //TODO get it from query. (La Baule)
    id = inseeToLaBauleDistrictId[idFromMap];
  else
    id = 0; // will never happen
  return id;
};
