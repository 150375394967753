import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Box,
  Typography,
  Stack,
  Checkbox,
  styled,
} from '@mui/material';
import { Link } from '@breteuil-website/store/routing';

const StyledButton = styled(Button)({
  width: '100%',
  textAlign: 'left',
  padding: "10px",
  marginBottom: "20px !important",
});

const StyledLink = styled(Link)({
  padding: 0,
  width: '100%',
});

const Title = styled(Typography)({
  fontFamily: 'Muli, sans-serif',
  color: "#050251",
  fontSize: '16px',
  textTransform: 'none',
  "@media (max-width: 600px)" : {
    fontSize: '14px !important',
  },
});

const Description = styled(Typography)({
  fontFamily: 'Muli, sans-serif',
  fontStyle: 'italic',
  color: "#616161",
  textTransform: 'none',
  "@media (max-width: 600px)" : {
    fontSize: '11px !important',
  },
});

export interface OptOutButtonProps
{
  title: string,
  description: string,
  checked?: boolean,
  disabled?: boolean,
  onClick?: (checked: boolean) => void,
  href?: string,
}

const OptOutButton = observer((props: OptOutButtonProps) => {
  const { checked = false } = props;

  const button = (
    <StyledButton
      variant="text"
      color="primary"
      onClick={() => typeof props.onClick === 'function' ? props.onClick(!checked) : undefined}
      disabled={props.disabled || false}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Box>
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
        </Box>
        <Box>
          <Checkbox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={checked || false}
          />
        </Box>
      </Stack>
    </StyledButton>
  );
  if (!props.href)
    return button;
  return (
    <StyledLink to={props.href}>
      {button}
    </StyledLink>
  );
});

export default OptOutButton;
