import type {
  ExteriorSurfaceType,
  Orientation,
  BreteuilWebsiteViewType,
  LuminosityType,
  BuildingType,
  BreteuilWebsiteRenovationType,
} from '@repo-breteuil/common-definitions';
import type { SimplePropertyValuation } from './types';
import { QueryStore } from '@repo-breteuil/front-store-query';

export interface ValuationExteriorSurfaceArgs {
  surface: number,
  surfaceType: ExteriorSurfaceType,
}

export interface CreateDetailedSimplePropertyValuationArgs {
  address: string,
  phone: string,
  transactionPrice?: number | null,
  surface: number,
  renovationCost?: number | null,
  renovationYear?: number | null,
  exteriorSurfaces?: Array<ValuationExteriorSurfaceArgs> | null,
  floor?: number | null,
  lastFloor?: boolean | null,
  elevator?: boolean | null,
  orientation?: Orientation | null,
  view?: BreteuilWebsiteViewType | null,
  luminosity?: LuminosityType | null,
  noisy?: boolean | null,
  popularAdress?: boolean | null,
  rooms?: number | null,
  buildingType?: BuildingType | null,
  renovation?: BreteuilWebsiteRenovationType | null,
  contactMe: boolean,
  communicationIdentifier?: string,
}

export default async function CreateDetailedSimplePropertyValuation(
  qs: QueryStore,
  args: CreateDetailedSimplePropertyValuationArgs,
): Promise<SimplePropertyValuation> {
  return qs.mutation({
    operationName: 'SimplePropertyValuation',
    variables: {
      address: 'String!',
      phone: 'String!',
      transactionPrice: 'Int',
      surface: 'Int!',
      renovationCost: 'Int',
      renovationYear: 'Int',
      exteriorSurfaces: '[ValuationExteriorSurfaceArgs!]',
      floor: 'Int',
      lastFloor: 'Boolean',
      elevator: 'Boolean',
      orientation: 'Orientation',
      view: 'BreteuilWebsiteViewType',
      luminosity: 'LuminosityType',
      noisy: 'Boolean',
      popularAdress: 'Boolean',
      rooms: 'Int',
      buildingType: 'BreteuilWebsiteBuildingType',
      renovation: 'BreteuilWebsiteRenovationType',
      contactMe: 'Boolean',
      communicationIdentifier: 'String',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            simplePropertyValuation: [{
              args: {
                address: true,
                phone: true,
                transactionPrice: true,
                surface: true,
                renovationCost: true,
                renovationYear: true,
                exteriorSurfaces: true,
                floor: true,
                lastFloor: true,
                elevator: true,
                orientation: true,
                view: true,
                luminosity: true,
                noisy: true,
                popularAdress: true,
                rooms: true,
                buildingType: true,
                renovation: true,
                contactMe: true,
                communicationIdentifier: true,
              },
            }, {
              id: true,
              phoneVerified: true,
              estimatedPrice: true,
            }],
          },
        },
      },
    },
  }, args);
}
