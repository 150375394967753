import { Definition } from ".";

export default {
  pageTitle: "Our Fees - Breteuil Remarkable Homes",
  pageDescription: "Discover the fees and service costs in different countries where we are present.",
  titles: {
    france: "France",
    uk: "UK",
  },
  links: {
    inHouseComplaints: "Complaints Procedure (UK)",
    clientMoneyProtection: "Lettings agent certification (UK)",
    fees: "Our Fees",
  },
} satisfies Definition;
