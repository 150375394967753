import type { OrderField } from '@repo-lib/graphql-query-pagination';
import * as React from 'react';
import { observer } from "mobx-react";
import { CircularProgress, Popover, styled } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { useFormFieldSelect } from '@repo-lib/mobx-forms';
import { SelectOptions } from '@repo-lib/mobx-forms';
import { ClientAreaTabs } from '@breteuil-website/store/ui/pages/client-area/common/tabs';
import {
  Select as GenericSelect,
  Button as GenericButton,
} from '@breteuil-website/components/common';
import PropertyCard from '@breteuil-website/components/common/fragments/property-card/PropertyCard';
import ClientArea from '@breteuil-website/components/pages/client-area/ClientArea';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  margin: '0px 30px 30px 30px',
  fontFamily: 'Poppins',
});

const Title = styled('div')({
  color: '#050251',
  fontSize: '40px',
  fontWeight: 700,
  marginTop: '20px',
  '@media (max-width: 1280px)': {
    marginTop: '14px',
    fontSize: '22px',
  },
});

const Properties = styled('div')({
  display: 'grid',
  rowGap: '10px',
});

const NoProperties = styled('div')({
  color: '#050251',
  fontSize: '25px',
  textAlign: 'center',
  marginBottom: '500px',
});

const Error = styled('div')({
  color: '#050251',
  fontSize: '25px',
  textAlign: 'center',
  marginBottom: '500px',
});

const FiltersContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '30px auto',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    margin: '9px auto 43px auto',
    rowGap: '12px',
  },
});

const DeleteFavorites = styled(GenericButton)({
  width: '15% !important',
  minWidth: '220px !important',
  height: '56px !important',
  '@media (max-width: 1280px)': {
    width: '100% !important',
    height: '44px !important',
  },
});

const PopOverContainer = styled('div')({
  padding: '15px',
});

const PopOverButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const PopOverButton = styled(GenericButton)({
  width: '44% !important',
});

const SelectOrder = styled(GenericSelect)({
  width: '85% !important',
  marginRight: '13px !important',
  '& .MuiSelect-outlined': {
    fontSize: '14px',
    fontWeight: "700 !important",
    fontFamily: 'Mulish !important',
    color: '#050251 !important',
    paddingRight: '0 !important',
  },
  '@media (max-width: 1280px)': {
    width: '100% !important',
    marginRight: '0',
  },
});

enum OrderOptions {
  'PRICE_DESC',
  'PRICE_ASC',
  'DATE_DESC',
  'DATE_ASC',
}

const OrderCorrelation = new Map<OrderOptions, OrderField<string>>([
  [OrderOptions.PRICE_DESC, { fieldName: 'price', ordering: 'DESC' }],
  [OrderOptions.PRICE_ASC, { fieldName: 'price', ordering: 'ASC' }],
  [OrderOptions.DATE_DESC, { fieldName: 'favoriteDate', ordering: 'DESC' }],
  [OrderOptions.DATE_ASC, { fieldName: 'favoriteDate', ordering: 'ASC' }],
] as Array<[OrderOptions, OrderField<string>]>);

const Favorites = observer(() => {
  const { clientAreaTabs, favorites } = useWebsiteStores();
  clientAreaTabs.setActiveTab(ClientAreaTabs.Favorites);
  const T = useTexts(texts);
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const id = open ? 'simple-popover' : undefined;

  const orderField = useFormFieldSelect({
    options: () => new SelectOptions<OrderOptions>(new Map([
      [OrderOptions.PRICE_DESC, T.filters.price_desc],
      [OrderOptions.PRICE_ASC, T.filters.price_asc],
      [OrderOptions.DATE_DESC, T.filters.date_desc],
      [OrderOptions.DATE_ASC, T.filters.date_asc],
    ] as Array<[OrderOptions, string]>)),
    defaultValue: () => OrderOptions.DATE_DESC,
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const DeleteAllFavorites = () => {
    setOpen(false);
    favorites.deleteAllFavorites();
  };

  React.useEffect(() => {
    const filter: OrderField<string> = OrderCorrelation.get(orderField.value)!;
    favorites.setOrderByAndReloadProperties([filter]);
  }, [orderField.value]);

  if (!favorites.favoriteProperties.data) {
    if (!favorites.favoriteProperties.loading) {
      return (
        <ClientArea>
          <Error>{T.error}</Error>
        </ClientArea>
      );
    }
    return (
      <ClientArea>
        <CircularProgress/>
      </ClientArea>
    );
  }

  const properties = favorites.favoriteProperties.data.items.filter(property => property.currentUserFavorite);

  if (properties.length === 0) {
    return (
      <ClientArea>
        <NoProperties>{T.noProperties}</NoProperties>
      </ClientArea>
    );
  }

  return (
    <ClientArea>
      <Container>
        <Title>{T.title}</Title>
        <FiltersContainer>
          <SelectOrder
            field={orderField}
            placeholder={"placeholder"}
          />
          <DeleteFavorites
            ref={buttonRef}
            onClick={handleOpen}
            endIcon={<Delete/>}
          >
            {T.deleteAll}
          </DeleteFavorites>
          <Popover
            {...omitUndefinedValues({ id })}
            open={open}
            anchorEl={buttonRef.current}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <PopOverContainer>
              <p>{T.confirmations.text}</p>
              <PopOverButtonContainer>
                <PopOverButton onClick={DeleteAllFavorites}>
                  {T.confirmations.yes}
                </PopOverButton>
                <PopOverButton onClick={handleClose}>
                  {T.confirmations.no}
                </PopOverButton>
              </PopOverButtonContainer>
            </PopOverContainer>
          </Popover>
        </FiltersContainer>
        <Properties>
          {properties.map((property, index) => (
            <PropertyCard
              key={index}
              property={property}
              variant='clientAreaFavorites'
              large
            />
          ))}
        </Properties>
      </Container>
    </ClientArea>
  );
});

export default Favorites;
