import { QueryStore } from '@repo-breteuil/front-store-query';

export async function SendEmailVerify(
  qs: QueryStore,
  args: {
    email: string,
  },
): Promise<boolean>
{
  return qs.mutation({
    operationName: 'SendEmailVerify',
    variables: {
      email: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            sendEmailVerify: [{
              args: { email: true },
            }, true ],
          },
        },
      },
    },
  }, args);
}
