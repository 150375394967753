import type { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { OperationType } from '@repo-breteuil/common-definitions';
import PropertyInfo from '../PropertyInfo';
import PropertySeasonalRentalInfo from '../PropertySeasonalRentalInfo';
import { CTA, CTAProps } from './CTA';

const BodyDesktop = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  position: 'sticky',
  top: '120px',
  marginLeft: 'auto',
  width: '100%',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 2px 4px 0px rgba(117,117,117,0.75)',
  padding: '22px 33px',
  maxWidth: '500px',
});

const BodyMobile = styled('div')({
  backgroundColor: '#FFFFFF',
  margin: "20px auto 0",
});

const CardContainer = styled('div')({
  right: '0%',
  position: 'absolute',
  top: '-90px',
  width: '50%',
  minHeight: 'calc(100% + 130px)',
  padding: '20px 0',
});

const CtaWrapper = styled('div')({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  margin: 'auto',
});

export const DataInfo = observer((props: { property: Property, CTAdata: CTAProps }) => {
  const { property, CTAdata } = props;
  return (
    <CardContainer>
      <BodyDesktop
        sx={
          property.operationType === OperationType.SeasonalRental ? {
            maxWidth: '600px',
          } : {}
        }
      >
        {property.operationType === OperationType.SeasonalRental
          ? <PropertySeasonalRentalInfo property={property}/>
          : <PropertyInfo property={property}/>
        }
        <CtaWrapper>
          <CTA property={property} contactInfo={CTAdata.contactInfo}/>
        </CtaWrapper>
      </BodyDesktop>
    </CardContainer>
  );
});

export const DataInfoMobile = observer((props: { property: Property }) => {
  const { property } = props;
  return (
    <div>
      <BodyMobile>
        {property.operationType === OperationType.SeasonalRental
          ? <PropertySeasonalRentalInfo property={property}/>
          : <PropertyInfo property={property}/>
        }
      </BodyMobile>
    </div>
  );
});
