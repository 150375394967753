import type { Currency } from '@repo-lib/utils-texts';
import type { OperationType, PropertyType } from '@repo-breteuil/common-definitions';
import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  exclusive: string,
  sold(operationType: OperationType): string,
  offer: string,
  favoriteDeletionConfirmation: string,
  yes: string,
  no: string,
  favoriteDeletionToast: string,
  favoriteAddToast: string,
  favoriteError: string,
  clientAreaPropertyModalTitle: string,
  loginText: string,
  connect: string,
  formatBedrooms(bedrooms: number): string,
  formatGuests(guests: number): string,
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string,
  formatTransactionInfos: (operationType?: OperationType, propertyType?: PropertyType, district?: string,) => string | null,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
