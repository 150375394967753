import { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { wrapElement } from '@repo-lib/utils-react';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import {
  Link,
  Routes,
} from '@breteuil-website/store/routing';
import { Button as GenericButton } from '@breteuil-website/components/common';
import texts from './texts';
import { PropertyData } from './PropertyData';
import PropertyCarousel from './common/PropertyCarousel';
import PropertyRows from './PropertyRows';
import {
  PropertyVideo,
  DataInfo,
  CTA,
} from './common';

const StickyContainer = styled('div')({
  position:'relative',
  width: "75%",
  maxWidth: '1600px',
  margin: "35px auto 0 auto",
  paddingBottom: '100px',
  '@media (max-width: 1280px)': {
    width: "90%",
    paddingBottom: '50px',
  },
});

const AgencyRow = styled('div')({
  width: '100%',
  backgroundColor: 'rgba(216,216,216,0.25)',
  padding: '20px 0',
  margin: '75px 0 150px',
  '@media (max-width: 1280px)': {
    margin: '5vh 0',
  },
  });

const AgencyContainer = styled('div')({
  width: "75%",
  maxWidth: '1600px',
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto',
  fontFamily: 'Mulish',
  fontWeight: 400,
  fontSize: '17px',
  color: '#050251',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    width: "90%",
    fontSize: '14px',
  },
});

const AgencyTitle = styled('span')({
  fontFamily: 'Poppins',
  fontWeight: 700,
});

const AgencyInfos = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: 'auto',
  '& > span': {
    width: '49%',
    textAlign: 'right',
    '@media (max-width: 1280px)': {
      textAlign: 'left',
    },
  },
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
    marginLeft: '0',
    width: "90%",
    '& > span': {
      width: '100%',
    },
    },

});

const ValuationButton = styled(GenericButton)({
  height: '33px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '12px',
  padding: '10px !important',
  maxWidth: '160px',
  marginTop: '25px',
});

export interface PropertyBodyProps {
  contactInfo: {
    phone: string,
    email: string,
  },
  property: Property,
  searchParams: URLSearchParams,
  anchorId: string,
}

const PropertyBody = observer((props: PropertyBodyProps) => {
  const { contactInfo, property, searchParams, anchorId } = props;
  const CTAdata = {
    property,
    contactInfo: contactInfo,
  };
  const lang = useLocale();
  const T = useTexts(texts);
  const isDesktop = useMediaQueryDesktop();
  const agencyInfo = (property.confidential && property.contact) ? {
    agencyLink: false,
    title: property.contact.name,
    phone: property.contact.phone,
    email: property.contact.email,
  } : property.agency ? {
    agencyLink: true,
    title: T.formatAgency(property.agency.name),
    phone: property.agency.phone,
    email: property.agency.email,
  } : null;
  return (
    <React.Fragment>
      <PropertyCarousel property={property}/>
      {!isDesktop ? <CTA property={property} contactInfo={contactInfo}/> : null}
      <StickyContainer>
        <PropertyData property={property} contactInfo={contactInfo}/>
        {isDesktop ? <DataInfo property={property} CTAdata={CTAdata}/> : null}
      </StickyContainer>
      {agencyInfo ? (
        <AgencyRow>
          <AgencyContainer>
            {wrapElement((
              <AgencyTitle>
                {agencyInfo.title}
              </AgencyTitle>
            ), agencyInfo.agencyLink && ((children) => (
              <Link to={Routes.agency.generatePath({ agencySlug: property.agency.slug }, { lang })}>
                {children}
              </Link>
            )))}
            <AgencyInfos>
              <span>{T.formatAgencyPhone(agencyInfo.phone)}</span>
              {agencyInfo.email ? (<span>{T.formatAgencyEmail(agencyInfo.email)}</span>) : null}
            </AgencyInfos>
            {!isDesktop ? (
              <Link to={Routes.valuation.generateStaticPath({ lang, searchParams })}>
                <ValuationButton>{T.ctaValuation}</ValuationButton>
              </Link>
            ) : null}
          </AgencyContainer>
        </AgencyRow>
      ) : null}
      <div id={anchorId}>
        <PropertyVideo property={property}/>
      </div>
      <PropertyRows title={T.recommendations.title} properties={property.exploreProperties.nearby}/>
      <PropertyRows title={T.propertiesExplore} properties={property.exploreProperties.similar}/>
    </React.Fragment>
  );
});

export default PropertyBody;
