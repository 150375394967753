import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { OperationType } from '@repo-breteuil/common-definitions';
import BreteuilWebsitePage from '@breteuil-website/components/common/app/Page';
import { PageMeta } from '@breteuil-website/components/common';
import PropertyBody from './PropertyBody';
import PropertySeasonalRentalBody from './PropertySeasonalRentalBody';
import PropertyAccessRequest from './PropertyAccessRequest';
import PropertyAccessCodeInput from './PropertyAccessCodeInput';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  width: "100%",
  position:'relative',
});

const Property = observer(() => {
  const { state } = useWebsiteStores().property;
  const contactInfo = state.property.contact;

  if (state.state === 'PropertyAccessRequest')
    return <PropertyAccessRequest contact={contactInfo}/>;
  if (state.state === 'PropertyAccessCodeInput')
    return <PropertyAccessCodeInput state={state}/>;

  const { property } = state;
  const selectedCity = property.area.name ?? "Paris";
  const searchParams = new URLSearchParams();
  searchParams.set('city', selectedCity);
  const anchorId = "property-video";
  return (
    <BreteuilWebsitePage>
      <PageMeta
        title={property.title}
        description={property.description || ""}
      />
      <Container>
        {property.operationType === OperationType.SeasonalRental ? (
          <PropertySeasonalRentalBody
            property={property}
            contactInfo={contactInfo}
            anchorId={anchorId}
            searchParams={searchParams}
          />
        ) : (
          <PropertyBody
            property={property}
            contactInfo={contactInfo}
            anchorId={anchorId}
            searchParams={searchParams}
          />
        )}
      </Container>
    </BreteuilWebsitePage>
  );
});

export default Property;
