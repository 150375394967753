import type { PartialUndefined } from '@repo-lib/utils-core';
import { OperationType } from '@repo-breteuil/common-definitions';
import type { Locale } from '@repo-breteuil/common-texts';
import {
  type PropertiesFilter,
  clampPriceFilterValue,
  clampBedroomsMinFilterValue,
} from '@breteuil-website/store/ui/pages/properties-search';
import * as Routes from './routes';

export function getPropertiesSearchRoute(
  args: PartialUndefined<Omit<PropertiesFilter, 'areaId' | 'subAreaId' | 'geoAreaId'> & {
    areaSlug: string,
    subAreasSlugs: Array<string>,
  }> & {
    lang: Locale,
  },
): string
{
  const { lang } = args;
  const searchParams = new URLSearchParams();
  if (args.priceMax !== undefined)
  {
    const priceMax = args.operationType === OperationType.ResidencyTransaction
      ? clampPriceFilterValue(args.operationType, args.priceMax)
      : args.priceMax;
    searchParams.set('priceMax', priceMax.toString());
  }
  const bedroomsMin = args.operationType === OperationType.ResidencyTransaction
    ? clampBedroomsMinFilterValue(args.bedroomsMin)
    : args.bedroomsMin;
  if (bedroomsMin !== undefined)
    searchParams.set('bedroomsMin', bedroomsMin.toString());
  if (args.subAreasSlugs && args.subAreasSlugs.length > 0)
    searchParams.set('subAreas', args.subAreasSlugs.join(','));

  if (args.operationType === OperationType.ResidencyTransaction)
  {
    if (args.areaSlug)
      return Routes.buyArea.generatePath({ areaSlug: args.areaSlug }, { lang, searchParams });
    return Routes.buy.generateStaticPath({ lang, searchParams });
  }
  if (args.operationType === OperationType.Rental)
  {
    if (args.areaSlug)
      return Routes.rentArea.generatePath({ areaSlug: args.areaSlug }, { lang, searchParams });
    return Routes.rent.generateStaticPath({ lang, searchParams });
  }
  if (args.operationType === OperationType.SeasonalRental)
  {
    if (args.areaSlug)
      return Routes.seasonalRentArea.generatePath({ areaSlug: args.areaSlug }, { lang, searchParams });
    return Routes.seasonalRent.generateStaticPath({ lang, searchParams });
  }
  return Routes.properties.generateStaticPath({ lang, searchParams });
}
