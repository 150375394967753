import * as React from 'react';
import { observer } from 'mobx-react';
import { LocationOn, Mail, Phone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import { Button } from '@breteuil-website/components/common';
import { Agency } from '@breteuil-website/store/ui/pages/contact';
import texts from './texts';
import { Link, Routes } from '@breteuil-website/store/routing';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { useTexts } from '@repo-breteuil/common-texts';

const AgencyContainer = styled('div')({
  width: '100%',
  '@media (max-width: 959px)': {
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    width: '60%',
  },
  '@media (max-width: 700px)': {
    width: '90%',
  },
});

const DataWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (max-width: 959px)': {
    width: '30%',
    lineHeight: '20px',
  },
});

const AgencyName = styled('div')({
  color: '#050251',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer',
    color: '#0C17E1',
  },
  fontFamily: 'Poppins',
  fontSize: '23px',
  fontWeight: 700,
  textTransform: 'uppercase',
  '@media (max-width: 959px)': {
    fontSize: '17px',
  },
});

const Text = styled('div')({
  fontFamily: 'Mulish',
  fontSize: '14px',
  color: '#050251',
  '@media (max-width: 959px)': {
    fontSize: '12px',
  },
});

const DataContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const CTAContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: 'auto 0 auto auto',
  justifyContent: 'end',
});

const AddressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const CardMobile = styled('div')({
  display: 'flex',
  width: '70%',
  alignItems: 'center',
});

const AddressIcon = styled(LocationOn)({
  color: '#050251',
  marginLeft: '-8px',
});

const AgencyAddressMobile = styled('div')({
  fontSize: '12px',
  fontFamily: 'Mulish',
  color: '#050251',
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'column',
});

const CTAButton = styled(Button)({
  margin: '0 2px',
  backgroundColor: '#050251',
  '& .MuiButton-endIcon': {
    margin: '0 !important',
  },
  '@media (max-width: 500px)': {
    maxWidth: '150px',
    padding: '0',
  },
  '@media (max-width: 400px)': {
    maxWidth: '140px',
    padding: '0',
  },
});

const Agency_mobile = (props: { agency: Agency }) => {
  const T = useTexts(texts);
  const { locale } = useWebsiteStores();
  const name = props.agency.shortName(locale.locale);
  const { shortAddress, zipCode, cityName, phone, email } = props.agency;
  const isMobile = useMediaQueryMobile();

  return (
    <AgencyContainer>
      <DataWrapper>
        <Link to={Routes.agency.generatePath({ agencySlug: props.agency.slug }, { lang: locale.locale })}>
          <AgencyName>
            {T.formatAgencyTitle(isMobile, name)}
          </AgencyName>
        </Link>
        <AgencyAddressMobile>
          {T.formatAgencyAddressMobile(shortAddress, zipCode, cityName)}
        </AgencyAddressMobile>
      </DataWrapper>
      <CardMobile>
        <CTAContainer>
          <CTAButton variant='contained' endIcon={<Phone/>} href={`tel:${phone}`}/>
          <CTAButton variant='contained' endIcon={<Mail/>} href={`mailto:${email}`}/>
        </CTAContainer>
      </CardMobile>
    </AgencyContainer>
  );
};

const Agency_desktop = (props: { agency: Agency }) => {
  const T = useTexts(texts);
  const { locale } = useWebsiteStores();
  const name = props.agency.nameTranslated(locale.locale);
  const { address, zipCode, cityName, phone, email } = props.agency;
  const isMobile = useMediaQueryMobile();

  return (
    <AgencyContainer>
      <DataWrapper>
        <Link to={Routes.agency.generatePath({ agencySlug: props.agency.slug }, { lang: locale.locale })}>
          <AgencyName>{T.formatAgencyTitle(isMobile, name)}</AgencyName>
        </Link>
        <AgencyAddressMobile>
          {T.formatAgencyAddress(address, zipCode, cityName)}
        </AgencyAddressMobile>
      </DataWrapper>
      <Text>
        <DataContainer>
          <AddressContainer>
            <AddressIcon/>
            <span>{address}</span>
          </AddressContainer>
          <span>{phone}</span>
          <span>{email}</span>
        </DataContainer>
      </Text>
    </AgencyContainer>
  );
};

export const AgencyComponent = observer((props: { agency: Agency }) => {
  const isMobile = useMediaQueryMobile();
  const Component = isMobile ? Agency_mobile : Agency_desktop;
  return <Component {...props}/>;
});

export default AgencyComponent;
