import * as React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { Box } from '@mui/material';

const spinnerEffect = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerRing = styled(Box)({
  display: 'inline-block',
  position: 'relative',
  width: '80px',
  height: '80px',

  '& div': {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: '64px',
    height: '64px',
    margin: '8px',
    border: '8px solid #000A4F',
    borderRadius: '50%',
    animation: `${spinnerEffect} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: '#000A4F transparent transparent transparent',
  },

  '& div:nth-of-type(1)': {
    animationDelay: '-0.45s',
  },
  '& div:nth-of-type(2)': {
    animationDelay: '-0.3s',
  },
  '& div:nth-of-type(3)': {
    animationDelay: '-0.15s',
  },
});

const SpinnerSegment = styled(Box)({});

const PropertyLoadingSpinner = () => {
  return (
    <SpinnerRing>
      <SpinnerSegment/>
      <SpinnerSegment/>
      <SpinnerSegment/>
      <SpinnerSegment/>
    </SpinnerRing>
  );
};

export default PropertyLoadingSpinner;
