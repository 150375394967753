import type { Agency } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';

import * as React from 'react';
import * as datefns from 'date-fns';
import { fr as DateFnsFrLocale } from 'date-fns/locale';
import { Currency } from '@repo-lib/utils-texts';
import {
  number as numberTexts,
  error as errorTexts,
} from '@repo-breteuil/common-texts';
import { formatNumberWithSeparators } from '@repo-lib/utils-texts';

export default {
  pageTitle: "Estimation immobilière : faites estimer votre bien gratuitement - Breteuil Remarkable Homes",
  pageDescription: "Besoin d'estimer votre bien immobilier ? Faites confiance à Breteuil Remarkable Homes, l'agence immobilière de prestige spécialisée dans l'immobilier haut de gamme.",
  method1: {
    title: 'Estimation ~ 30 secondes',
    form: {
      labels: {
        address: 'Adresse du bien',
        transactionPrice: "Prix d'achat de votre bien",
        transactionMonth: "Mois d'achat",
        transactionYear: "Année d'achat",
        renovationCost: 'Montant des travaux',
        renovationYear: "Année de début des travaux",
        renovationYearInfo: "(si différent de l'année d'achat)",
        surface: 'Surface',
        phone: 'Mon numéro de mobile*',
      },
      submit: "Recevoir un code d'activation",
      phoneAlreadyConfirmed: 'Numéro de téléphone déjà confirmé.',
      addressWithoutNumberError: "L'adresse doit commencer par le numéro de rue.",
      transactionYearError: "L'année d'achat doit être composée de 4 chiffres.",
      errorToast: 'Une erreur est survenue',
      handleFormSubmissionErrors(error): string {
        switch (error.code)  {
          case 'InvalidPhoneNumber' :
            return "Numéro de téléphone incorrect. Veuillez le saisir à nouveau.";
          case 'UnsupportedAddress':
            return "Nous ne disposons pas de données suffisantes à cette adresse.";
          case 'InvalidAddress':
            return "Adresse non localisable.";
          case 'InvalidTransactionYear':
            return "Année d'achat invalide.";
          case 'InvalidRenovationYear':
            return "Année de début des travaux invalide.";
          case 'InvalidToken':
            return "Le code fourni est invalide.";
          case 'MeetingAlreadyConfirmed':
            return "Ce rendez-vous est déjà confirmé.";
          case 'MeetingIncomplete':
            return "Un erreur est survenue lors de la confirmation de votre rendez-vous.";
          case 'MeetingDateAlreadyLocked':
            return "Le créneau sélectionné n'est plus disponible.";
          default :
            return "Une erreur est survenue.";
        }
      },
      phoneConfirmed: "Nouvelle estimation",
      surfacePlaceholder: 'm2',
    },
    confirmPhoneDialog: {
      title: (
        <React.Fragment>
          Saississez le code d&apos;activation
          <br/>
          reçu par SMS
        </React.Fragment>
      ),
      actions: {
        sendNewCode: 'nouveau code',
        confirm: 'Continuer',
      },
      successfullCodeResent: 'Un nouveau code a été envoyé.',
      phoneAlreadyConfirmed: 'Numéro de téléphone déjà confirmé.',
      handleFormSubmissionErrors(error): string {
        switch (error.code)  {
          case 'InvalidToken' :
            return("Le numéro entré est invalide.");
          default :
            return("Une erreur est survenue.");
        }
    },
    },
    valuationResult: {
      valuationText: "Estimation de votre bien",
      valuationPrice: (price: number | null | undefined, currency: Currency) => (
        <span style={{margin: 'auto'}}>
          {price != null
            ? numberTexts.FR.formatNumber(price, { currency, truncate: 'integer' })
            : numberTexts.FR.decorateNumberValue('-', { currency })
          }
        </span>
      ),
      valuationError: (
        <React.Fragment>
          Nous n&apos;avons pas la donnée suffisante pour évaluer votre bien de manière précise.<br/>
        </React.Fragment>
      ),
    },
  },
  method2: {
    title: 'Estimation Intelligente',
    form: {
      select: {
        extArea: {
          label: 'Espace extérieur',
          balcony: 'Balcon',
          terrace: 'Terrasse',
          terraceUnoverlooked: 'Toit terrasse',
          backyard: 'Jardin',
        },
        binary: {
          yes: 'Oui',
          no: 'Non',
        },
        direction: {
          label: 'Orientation Salon',
          north: 'Nord',
          east: 'Est',
          west: 'Ouest',
          south: 'Sud',
        },
        remarkableView: {
          label: 'Vue',
          normal: 'Normale',
          good: 'Belle',
          oustanding: 'Exceptionnelle',
        },
        buildingType: {
          label: 'Immeuble',
          haussmannian: 'Haussmannien',
          modern: 'Récent',
          other: '1950 / Pierre de Paris / etc...',
        },
        renovation: {
          new: 'Refait parfaitement',
          normal: 'Bon état',
          needRefresh: 'À rafraichir',
          workNeeded: 'À rénover',
        },
        rooms: {
          one: '1',
          two: '2',
          three: '3',
          four: '4',
          fiveAndMore: '5+',
        },
        floors: {
          rdc: 'RDC',
          one: '1',
          two: '2',
          three: '3',
          four: '4',
          fiveAndMore: '5+',
        },
        popularAdress: 'Adresse recherchée',
        elevator: 'Ascenseur',
        lastFloor: 'Dernier étage',
      },
      input: {
        exteriorSurface: 'Surface extérieure',
        address: 'Adresse du bien',
        surface: 'Surface du bien',
        surfaceMetric: 'm²',
        transactionMonth: "Mois d'achat du bien",
        transactionPrice: "Prix d'achat du bien",
        transactionYear: "Année d'achat",
        renovationCost: 'Montant des travaux',
        renovationYear:  (
          <React.Fragment>
            Année de début des travaux
          </React.Fragment>
        ),
        balcony: 'Surface du balcon',
        terrace: 'Surface de la terrasse',
        terraceUnoverlooked: 'Surface du toit terrasse',
        backyard: 'Surface du jardin',
        rooms: 'Nombre de chambres',
        floor: 'Étage',
        renovation: 'Finitions / Travaux',
        contactMe: 'Je souhaite être contacté par téléphone : ',
      },
      submitButton: 'Calculer',
      submitPhone: 'Recevoir mon code',
      phoneActivationTitle: (
        <React.Fragment>
          Saisissez votre numéro de téléphone
          <br/>
          pour recevoir votre code d&apos;activation
        </React.Fragment>
      ),
      phoneActivationTitleMobile: "Saisissez votre numéro de téléphone pour recevoir votre code d'activation",
    },
    results: {
      loading: 'Calcul en cours',
      labelPlaceholder: 'Prix de votre bien',
      labelResult: 'Prix compris entre',
      placeholder: '- €',
      formatResult: (result: number) => {
        const roundedResult = Math.round(result / 10000) * 10000;
        return `${formatNumberWithSeparators(roundedResult - Math.round((result * 0.05) / 10000) * 10000, ' ')} € et ${formatNumberWithSeparators(Math.round((result * 1.15) / 10000) * 10000, ' ')} €`;
      },
    },
    unsupportedCityDisclaimer: "Nous n'avons pas suffisament de data pour estimer votre bien dans cette ville pour le moment",
  },
  method3: {
    title: 'Estimation sur rendez-vous',
    prev: 'Retour',
    anchorId: 'estimation-rendez-vous',
    anchorText: 'Prendre rdv en ligne',
    agencySelection: {
      title: "Votre estimation ne saurait être complète sans l'expertise d'un conseiller !",
      subTitle: (
        <React.Fragment>
          Notre conseiller vous confirmera sa venue sous 24 heures pour finaliser votre estimation avec précision.
          <span> Service gratuit et sans engagement.</span>
        </React.Fragment>
      ),
      selectedAgency: (agency: Agency | null) => (
        <React.Fragment>
          <span>{agency?.address ? `Votre agence Breteuil au ${agency?.address}` : null}</span>
          <span>{agency?.phone} &#8226; {agency?.email}</span>
        </React.Fragment>
      ),
      mobileSelectedAgency: (agency: Agency | null) => (
        <React.Fragment>
          <span>{agency?.address ? `Votre agence Breteuil au` : null}</span>
          <span>{agency?.address}</span>
        </React.Fragment>
      ),
      label: 'Adresse du bien',
    },
    timeslotSelection: {
      selectedDate: (date: Date) => datefns.format(date, 'eeee dd MMMM', { locale: DateFnsFrLocale }),
      tabsLabels: ['MATIN', 'APRES-MIDI', 'SOIR'],
      confirm: 'Confirmer',
      errorNoAddress: "Veuillez saisir l'adresse de votre bien dans le champs",
      errorNoAgencyNearAddress: "Aucune agence près de votre adresse, nous avons quand même enregistré votre demande.",
      errorNoMeetings: 'Aucun meeting disponible à cette date',
    },
    details: {
      summary: {
        title: 'Récapitulatif de votre demande',
        appointmentDate: (date: Date) =>  datefns.format(date, 'eeee dd MMMM HH:mm', { locale: DateFnsFrLocale }),
        appointmentDuration: '30 mn',
        appointmentInfo: "Un conseiller vous contactera pour préparer ce rendez-vous.",
      },
      confirmationForm: {
        title: 'Détails pour confirmer votre RDV',
        labels: {
          name: 'Votre nom',
          surname: 'Votre prénom',
          email: 'Votre email',
          phone: 'Votre mobile',
          comment: 'Remarques liées à votre rendez-vous',
        },
        cancel: 'Changer la date',
        confirm: 'Confirmer votre demande',
        successTitle: "Merci de votre confiance ! ",
        successSubTitle: (
          <React.Fragment>
            Votre conseiller vous contactera
            <br/>
            sous 24h pour confirmer sa venue.
          </React.Fragment>
        ),
        errorMeetingAlreadyConfirmed: 'Votre rendez-vous a déjà été confirmé.',
        errorMeetingDateAlreadyLocked: "Le créneau sélectionné n'est plus disponible.",
        errorInvalidPhoneNumber: "Le format du numéro de téléphone n'est pas valide.",
        errorInvalidMeetingDate: "Le créneau sélectionné n'est pas valide.",
        errorNoMeetingDate: "Veuillez selectionner un créneau.",
        successMessage: "Votre rendez-vous est confirmé avec succès.",
      },
    },
  },
  cities: {
    paris: 'Paris / Boulogne / Neuilly',
    london: 'Londres',
    newYork: 'New-York',
    deauville: 'Deauville',
    dinard: 'Dinard',
    laBaule: 'La Baule',
    lisbon: 'Lisbonne',
    biarritz: 'Biarritz',
    saintJeanDeLuz: 'Saint-Jean-De-Luz',
  },
  citySelectLabel: "Sélectionnez la localisation de votre bien :",
  errorToast: (e: Error) => errorTexts.FR.formatError(e),
};
