import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  PaginationExtendedResponse,
  PaginationBaseArgs,
} from '@repo-lib/graphql-query-pagination';
import type { Language } from '@repo-breteuil/common-texts';
import type { PropertySearchType } from './types';
import { PictureFormat, type OperationType } from '@repo-breteuil/common-definitions';
import {
  QueryStore,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@repo-breteuil/front-store-query';
import { computeGroupedAreasFilter } from '@breteuil-website/_hardcoded/groupedSubAreas';
import { propertyField } from './propertyFields';

export interface PropertiesRentalFilter
{
  operationType: OperationType,
  price?: number | undefined,
  priceMax?: number | undefined,
  bedroomsMin?: number | undefined,
  guests?: number | undefined,
  areaId?: IdType | undefined,
  geoAreaId?: Array<IdType> | undefined,
}

export interface PropertiesRentalArgs extends PaginationBaseArgs
{
  language: Language,
  filter: PropertiesRentalFilter,
}

function getFilterArgs(filter: PropertiesRentalFilter)
{
  return {
    operationType: { equals: filter.operationType },
    bedrooms: filter.bedroomsMin ? { gte: filter.bedroomsMin } : undefined,
    guests: filter.guests ? { gte: filter.guests } : undefined,
    geoAreaId: computeGroupedAreasFilter(filter.geoAreaId),
  };
}

export async function GetSeasonalRentalPropertiesCount(
  qs: QueryStore,
  args: {
    filter: PropertiesRentalFilter,
  },
): Promise<number>
{
  const { filter } = args;

  return qs.query({
    operationName: 'GetPropertiesCount',
    variables: {
      filter: 'Filters__Public_Website_Properties_MainPagination',
      priceMin: 'Int',
      priceMax: 'Int',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          properties: [{
            args: {
              filter: true,
              priceMin: true,
              priceMax: true,
            },
          }, {
            count: true,
          }],
        },
      },
    },
  }, {
    filter: getFilterArgs(filter),
    priceMin: filter.price,
    priceMax: filter.priceMax,
  });
}

export async function GetSeasonalRentalProperties(
  qs: QueryStore,
  args: PropertiesRentalArgs,
): Promise<PaginationExtendedResponse<PropertySearchType>>
{
  const { filter, ...rest } = args;

  return qs.query({
    operationName: 'GetSeasonnalRentalProperties',
    variables: {
      language: 'Language!',
      ...ExtendedPaginationVariables('Public_Website_Properties_MainPagination'),
      orderBy: '[Order__MyBreteuilProperty!]',
      format: 'PictureFormat',
      priceMin: 'Int',
      priceMax: 'Int',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          properties: [{
            args: {
              ...ExtendedPaginationArgs,
              priceMin: true,
              priceMax: true,
            },
          }, (
            paginationResult(propertyField)
          )],
        },
      },
    },
  }, {
    ...rest,
    filter: getFilterArgs(filter),
    priceMin: filter.priceMax || undefined,
    priceMax: filter.priceMax || undefined,
    orderBy: undefined,
    format: PictureFormat._720p,
  });
}
