import type { Language } from '@repo-breteuil/common-texts';
import type { ClientAreaSession } from '@breteuil-website/store/ui/common/Session/api';
import type { Search } from './shared';

import { clientAreaSessionResult } from '@breteuil-website/store/ui/common/Session/api';
import { searchFields } from './shared';
import { QueryStore } from '@repo-breteuil/front-store-query';

export async function GetSearches(
  qs: QueryStore,
  args: {
    language: Language,
  },
): Promise<ClientAreaSession<{
  searches: Array<Search>,
}> | null>
{
  return qs.query({
    operationName: 'GetSearches',
    variables: {
      language: 'Language!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          session: clientAreaSessionResult({
            searches: searchFields,
          }),
        },
      },
    },
  }, args);
}
