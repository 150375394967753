import type { Surrounding } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Stack, Typography } from '@mui/material';
import texts from '../texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Image = styled('img')({
  width: '32px',
  height: '32px',
});

const SurroundingText = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '19px',
  color: '#050251',
  '@media (max-width: 1280px)': {
    fontSize: '17px',
  },
});

export interface PropertySurrondingProps {
  surrounding: Surrounding,
}

const PropertySurronding = observer((props: PropertySurrondingProps) => {
  const { distance, surrounding } = props.surrounding;
  const T = useTexts(texts);
  return (
    <Stack direction="row" gap={1}>
      <Image src={surrounding.iconFileURL}/>
      <SurroundingText>{`${surrounding.content} ${T.formatDistance(distance)}`}</SurroundingText>
    </Stack>
  );
});

export default PropertySurronding;
