import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useTexts } from '@repo-breteuil/common-texts';
import lang from './texts';
import { Page } from '@breteuil-website/components/common';
import {
  Button as GenericButton,
  PageMeta,
} from '@breteuil-website/components/common';
import MethodContainer from './MethodContainer';
import Method2 from './method2';
import Method3 from './method3';
import SelectCity from './method2/SelectCity';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  position: 'relative',
});


const SubmitButton = styled(GenericButton)({
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '14px',
  padding: '10px !important',
  minWidth: '245px',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const ValuationAnchorContainer = styled('div')({
  display: 'flex',
  margin: '30px auto',
  maxWidth: '250px',
  '@media (max-width: 1280px)': {
    width: '100%',
  },
});


const ValuationAnchor = styled(SubmitButton)({
  minWidth: '245px',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const Valuation = observer(() => {
  const T = useTexts(lang);
  const { valuation } = useWebsiteStores();

  const scrollIntoView = () => {
    const elem = document.getElementById(T.method3.anchorId);
    elem?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <Container>
        {!valuation.detailedValuation.result ? (
          <ValuationAnchorContainer>
            <ValuationAnchor onClick={scrollIntoView}>{T.method3.anchorText}</ValuationAnchor>
          </ValuationAnchorContainer>
        ) : null}
        <SelectCity/>
        {valuation.isParisSelected ? (
          <MethodContainer title={T.method2.title}>
            <Method2/>
          </MethodContainer>
        ) : null}
        <MethodContainer title={T.method3.title} anchorId={T.method3.anchorId}>
          <Method3/>
        </MethodContainer>
      </Container>
    </Page>
  );
});

export default Valuation;
