import { observable, action } from 'mobx';
import type { Locale } from '@repo-breteuil/common-texts';

export class LocaleStore
{
  @observable private _locale: Locale;

  constructor(locale: Locale)
  {
    this._locale = locale;
  }

  public get locale()
  {
    return this._locale;
  }

  @action public setLocale(locale: Locale)
  {
    this._locale = locale;
  }
}
