export enum ClientAreaTabs {
  Profile = 'profile',
  Favorites = 'favorites',
  Searches = 'searches',
  Overview = 'overview',
  WalletAndMarket = 'walletAndMarket',
}

export class ClientAreaTabsStore
{
  private tab: ClientAreaTabs;

  public get activeTab()
  {
    return this.tab;
  }

  public setActiveTab = (tab: ClientAreaTabs) => {
    this.tab = tab;
  };
}
