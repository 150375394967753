import * as React from 'react';
import type { Currency } from '@repo-lib/utils-texts';
import {
  OperationType,
  PropertyType,
  MyBreteuilPropertySurroundingDistance,
  getDPERating,
} from '@repo-breteuil/common-definitions';
import { number as numberTexts } from '@repo-breteuil/common-texts';

export default {
  exclusive: 'Exclusivité',
  buy: 'Achat',
  apartment: 'appartement',

  formatBedrooms(amount: number): string
  {
    return `${amount} chambre${amount > 1 ? 's' : ''}`;
  },
  formatBathrooms(amount: number): string
  {
    return `${amount} salle${amount > 1 ? 's' : ''} de bain`;
  },
  formatSurface(amount: number): string
  {
    return `${amount} m2`;
  },
  formatDPE(
    args: {
      kwh: number | null,
      co2: number | null,
    },
  ): string
  {
    const text = "Perf. énergétique: ";
    const { kwh, co2 } = args;
    if (!kwh || !co2)
      return text + "en cours";
    const rating = getDPERating({ kwh, co2 });
    const details = [
      `${args.kwh}kWh`,
      `${args.co2}kg CO2`,
    ].join(' & ');
    return text + `${rating} (${details})`;
  },
  formatPrice(amount: number, currency: Currency): string
  {
    return `${numberTexts.FR.formatNumber(amount, { truncate: 'integer', currency })}`;
  },
  formatPriceSurface(amount: number, surface: number, currency: Currency): string
  {
    return `${numberTexts.FR.formatNumber(amount / surface, { truncate: 'integer', currency })} / m2`;
  },
  formatAgency(agencyName: string): string
  {
    return `Agence ${agencyName}`.toUpperCase();
  },
  formatAgencyPhone(phone: string): string
  {
    return `Tel. : ${phone}`;
  },
  formatAgencyEmail(email: string): string
  {
    return `E-mail : ${email}`;
  },
  formatCopro(lots: number): string
  {
    return `Copropriété : ${lots} lots`;
  },
  formatCharges(charges: number, currency: Currency): string
  {
    return `Charges/an : ${this.formatPrice(charges, currency)}`;
  },
  formatPropertyType(propertyType: PropertyType) {
    switch (propertyType) {
      case 'Apartment':
        return 'Appartement';
      case 'House':
        return 'Maison';
      case 'Land':
        return 'Terrain';
      case 'Loft':
        return 'Loft';
      default:
        return null;
    }
  },
  formatOperationType(operationType: OperationType) {
    switch (operationType) {
      case 'ResidencyTransaction':
        return 'à vendre';
      case 'Rental':
      case 'SeasonalRental':
        return 'à louer';
      default:
        return null;
    }
  },

  formatExclu(isExclusive: Boolean): string
  {
    return isExclusive ? 'EXCLUSIVITÉ' : '';
  },
  linkMarketData: 'Notre outil',
  textMarketData: 'pour suivre le marché dans ce quartier',
  textGeoRisk: 'Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : www.georisques.gouv.fr',
  perMonth: ' / mois',
  perWeek: ' / semaine',
  pdfMap: 'plan',
  recommendations: {
    title: 'Vous aimerez aussi...',
    around: 'Dans le même quartier',
    farAway: 'Pour vous évader',
  },
  market: {
    title: 'Le point sur le marché',
  },
  propertiesNearby: 'Dans le même quartier',
  propertiesExplore: 'Pour vous évader',
  ctaShare: 'Partager',
  ctaMail: 'Email',
  ctaPhone: 'Téléphone',
  similarProperties: 'Propriétés similaires',
  ctaValuation : 'Estimer votre bien',
  formatMailSubject(ref: string): string
  {
    return `Demande d'informations sur la propriété : ${ref}`;
  },
  formatMailText(url: string): string
  {
    return `Bonjour,%0A%0AJe voudrais visiter : ${url}%0A%0AMerci de me rappeler au : `;
  },
  fullscreen: "Plein Ecran",
  exitFullscreen: "Sortir du Plein Ecran",
  formatTextRange(min: number | null, max: number | null, currency: Currency, perUnit: "week" | "month" | null): string
  {
    const startOnly = "À partir";
    const middle = "à";
    const middleEndOnly = "Jusqu'à";
    const fromWithUnit = min ? numberTexts.FR.formatNumber(min, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    const from = min ? numberTexts.FR.formatNumber(min, { separators: true, currency: currency, truncate: 'integer' }) : null;
    const toWithUnit = max ? numberTexts.FR.formatNumber(max, { separators: true, currency: currency, truncate: 'integer', perUnit: perUnit }) : null;
    if (!min && !max)
      return '';
    else if (!max && min) {
      return `${startOnly} ${fromWithUnit}`;
    }
    else if (!min && max) {
      return `${middleEndOnly} ${toWithUnit}`;
    }
    else {
      return `${from} ${middle} ${toWithUnit}`;
    }
  },
  location: "Situation :",
  keyInfos: "Informations-clé et environs :",
  keyInfosTitle: "Informations-clé :",
  surroundingsTitle: "Environs :",
  formatDistance(distance: MyBreteuilPropertySurroundingDistance): string
  {
    const parsedDistance = distance.split('_')[1] || '';
    return `${parsedDistance}`;
  },
  formatGuests(guestsCount: number): string
  {
    return `${guestsCount} invité${guestsCount > 0 ? 's' : ''}`;
  },
  seasonalRentalDuration: "Tarifs pour 7 nuits*",
  defaultQuote: (
    <React.Fragment>
      Ce devis comprend l&apos;occupation de la villa et le service de ménage 6 fois par semaine, nous proposons également l&apos;option de notre service de Chef, offrant le petit-déjeuner aux convives.
      <br/>
      <br/>
      Pour affiner ce devis, notre équipe de conseillers est à votre disposition.
    </React.Fragment>
  ),
  propertyAccessRequest: {
    title: "Demander un accès",
    description: "Cette propriété est confidentielle et nécessite une autorisation spéciale pour y accéder, veuillez contacter votre référent pour obtenir les informations d'accès.",
    button: "Accéder"  },
  propertyAccessCodeInput: {
    title: "Propriété exclusive - lien confidentiel",
    description: "Cette propriété est confidentielle et requiert un code d'accès SMS.",
    formatPhoneNumber: (phoneNumber: string) => `Un code d'accès vous a été envoyé au ${phoneNumber}`,
    invalidAccessCode: "Code incorrect. Veuillez réessayer.",
  },
};
