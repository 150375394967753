import { QueryStore, errorWrapper } from '@repo-breteuil/front-store-query';

export interface ChangeCommunicationPreferencesProps {
  contactCommunicationJWT: string,
  newsletters: boolean,
}

export default function ChangeCommunicationPreferences(qs: QueryStore, args: ChangeCommunicationPreferencesProps)
{
  return qs.mutation<boolean>({
    operationName: 'ChangeCommunicationPreferences',
    variables: {
      contactCommunicationJWT: 'String!',
      newsletters: 'Boolean',
    },
    fieldsSelection: {
      public: {
        mybContact: {
          changeCommunicationPreferences: errorWrapper([{
            args: {
              contactCommunicationJWT: true,
              newsletters: true,
            },
          }, true]),
        },
      },
    },
  }, args);
}
