import { paginationResultAsList } from '@repo-breteuil/front-store-query';
import type { QueryStore } from '@repo-breteuil/front-store-query';

export interface Agency
{
  id: number,
  slug: string,
  slugs: Array<string>,
  name: string,
  phone: string,
  email: string,
  latitude: number,
  longitude: number,
  timezone: string,
  address: string,
}

function GetAgencies(qs: QueryStore): Promise<Array<Agency>>
{
  return qs.query({
    operationName: 'GetAgencies',
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          agencies: paginationResultAsList({
            id: true,
            slug: true,
            slugs: true,
            name: true,
            phone: true,
            email: true,
            latitude: true,
            longitude: true,
            timezone: true,
            address: true,
          }),
        },
      },
    },
  });
}

export default GetAgencies;
