import type { PropertyCardProps } from './PropertyCard';

import * as React from 'react';
import { styled, Box } from '@mui/material';
import { PropertyStatus } from '@repo-breteuil/common-definitions';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Poppins',
  backgroundColor: '#050251',
  color: '#FFFFFF',
  fontSize: '20px',
  fontWeight: 700,
  width: '168px',
  height: '38px',
  position: 'absolute',
  left: 0,
  top: '46px',
});

function isSold(status: PropertyStatus): boolean
{
  return status === PropertyStatus.Sold;
}

function isOffer(status: PropertyStatus): boolean
{
  return status === PropertyStatus.Agreement
    || status === PropertyStatus.Offer;
}

const StatusBanner = (
  props: PropertyCardProps,
) => {
  const { property } = props;
  const { displayedExclusive, displayedStatus, operationType } = property;
  const T = useTexts(texts);
  const text = (displayedExclusive) ? (
    T.exclusive
  ) : (displayedStatus && isSold(displayedStatus)) ? (
    T.sold(operationType)
  ) : (displayedStatus && isOffer(displayedStatus)) ? (
    T.offer
  ) : null;
  return (text === null) ? null : (
    <Container>
      {text}
    </Container>
  );
};

export default StatusBanner;
