import * as React from 'react';
import { Box, IconButton, Stack, SwipeableDrawer, styled } from '@mui/material';
import type { ShareDetails } from './Share';
import {
  EmailIcon, EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ContentCopy } from '@mui/icons-material';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

// react-share don't accept prop component
const ShareButtonContainer = styled('div')({
  '& .share-button': {
    display: 'flex',
  },
});

const CopyText = styled('div')({
  fontFamily: 'Raleway',
  fontSize: '12px',
  textAlign: 'center',
});

const CopyButton = styled(IconButton)({
  width: '62px',
  height: '62px',
  borderRadius: '50% !important',
});

interface ShareModalProps {
  open: boolean,
  onClose: () => void,
  onOpen: () => void,
  shareDetails: ShareDetails,
}

function ShareModal(props: ShareModalProps) {
  const T = useTexts(texts);
  const [isCopied, setIsCopied] = React.useState(false);

  const handleClose = () => {
    setIsCopied(false);
    props.onClose();
  };

  return (
    <SwipeableDrawer
      open={props.open}
      onClose={handleClose}
      onOpen={props.onOpen}
      anchor={'bottom'}
    >
      <Stack
        height="94px"
        pl={1}
        pr={1}
        mt={4}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        spacing={1}
        sx={{ minWidth: 'max-content', width: '100%' }}
      >
        <ShareButtonContainer>
          {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <FacebookShareButton
            className="share-button"
            url={props.shareDetails.url}
            quote={props.shareDetails.text}
          >
            {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
            <FacebookIcon fontSize={15} round/>
          </FacebookShareButton>
          {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <WhatsappShareButton
            className="share-button"
            url={props.shareDetails.url}
            title={props.shareDetails.text}
          >
            {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
            <WhatsappIcon fontSize={15} round/>
          </WhatsappShareButton>
          {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <LinkedinShareButton
            className="share-button"
            url={props.shareDetails.url}
            title={props.shareDetails.title}
            summary={props.shareDetails.text}
          >
            {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
            <LinkedinIcon fontSize={15} round/>
          </LinkedinShareButton>
          {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <EmailShareButton
            className="share-button"
            url={props.shareDetails.url}
            subject={props.shareDetails.title}
            body={props.shareDetails.text}
          >
            {/*// @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
            <EmailIcon fontSize={15} round/>
          </EmailShareButton>
        </ShareButtonContainer>
        <Box>
          <CopyToClipboard
            text={props.shareDetails.url}
            onCopy={() => setIsCopied(true)}
          >
            <CopyButton size="large">
              <ContentCopy fontSize="inherit"/>
            </CopyButton>
          </CopyToClipboard>
          {isCopied && (
            <CopyText>
              {T.pasteLink}
            </CopyText>
          )}
        </Box>
      </Stack>
    </SwipeableDrawer>
  );
}

export default ShareModal;
