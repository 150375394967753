import type {
  Thunk,
} from '@repo-lib/utils-core';
import type {
  RouteHandler,
  RouteHandlerReturnType,
  RouteHandlersMap,
  RoutingInfo,
} from '@lib/routing';

import {
  unthunk,
} from '@repo-lib/utils-core';
import {
  type StaticRoute,
  type RouteInterface,
  type ResolvedRouteLocation,
  type LenientRouteParams,
  generateRoutePathInfo,
} from '@repo-lib/routing-routes';
import {
  transformAndExecuteRouteHandler,
  transformRouteHandler,
  combineRouteHandlers,
  handleRouteHandlerErrors,
  makePageRedirectWithDefaults,
} from '@lib/routing';

export type ScrollAlign = 'start' | 'center' | 'end' | 'nearest';

export interface ScrollAnchor
{
  elementId: string,
  verticalAlign?: ScrollAlign | undefined, //Default: 'start' (from Element.scrollIntoView)
  horizontalAlign?: ScrollAlign | undefined, //Default: 'nearest' (from Element.scrollIntoView)
}

export interface PageRedirect<PageType>
{
  route?: string | null | undefined,
  page?: PageType | null | undefined,
  scrollTop?: 'reset' | number | ScrollAnchor | undefined, //Default: 'reset'
}

export interface RouteBindingRedirect<PageType>
{
  page: PageType,
  resolvedLocation: ResolvedRouteLocation,
  handler?: RouteHandler<PageRedirect<PageType>> | undefined,
}

export interface StaticRouteBindingArgs
{
  searchParams?: URLSearchParams | undefined,
  hash?: string | undefined,
}

export interface DynamicRouteBindingArgs extends StaticRouteBindingArgs
{
  routeParams: LenientRouteParams,
}

//Note: because of a typescript bug (4.7), DynamicRouteBinding is assignable to StaticRouteBinding
export type StaticRouteBinding<PageType> = (args?: StaticRouteBindingArgs) => RouteBindingRedirect<PageType>;
export type DynamicRouteBinding<PageType> = (args: DynamicRouteBindingArgs) => RouteBindingRedirect<PageType>;

export class PageRouteHandlersScope<PageType>
{
  constructor(
    private _setPage: (
      page: PageType,
      route: RouteInterface,
      handler?: RouteHandler<PageRedirect<PageType>> | undefined,
    ) => RouteHandler<PageRedirect<PageType>> | undefined,
  )
  {
  }

  public setPage(
    page: PageType,
    route: RouteInterface,
    handler?: RouteHandler<PageRedirect<PageType>> | undefined,
  ): DynamicRouteBinding<PageType>
  {
    const fullHandler = this._setPage(page, route, handler);
    return (args) => ({
      page,
      resolvedLocation: generateRoutePathInfo(route, args.routeParams, args),
      handler: fullHandler,
    });
  }

  public setStaticPage(
    page: PageType,
    route: StaticRoute,
    handler?: RouteHandler<PageRedirect<PageType>> | undefined,
  ): StaticRouteBinding<PageType>
  {
    const fullHandler = this._setPage(page, route, handler);
    return (args = {}) => ({
      page,
      resolvedLocation: generateRoutePathInfo(route, {}, args),
      handler: fullHandler,
    });
  }

  public extend(
    preHandler: RouteHandler<PageRedirect<PageType>>,
    errorHandler?: ((
      error: Error,
      location: ResolvedRouteLocation,
      info: RoutingInfo,
    ) => RouteHandlerReturnType<PageRedirect<PageType>>) | undefined,
  )
  {
    return new PageRouteHandlersScope<PageType>((page, route, handler) => (
      this._setPage(
        page,
        route,
        handleRouteHandlerErrors(
          combineRouteHandlers(preHandler, handler),
          errorHandler,
        ),
      )
    ));
  }
}

export default class PageRouteHandlers<PageType>
{
  private _handlers: RouteHandlersMap = new Map<RouteInterface, RouteHandler<string | null>>();

  constructor(private _handleRedirect: (redirect: PageRedirect<PageType> | string | null) => string | null)
  {
  }

  public get handlers(): Readonly<RouteHandlersMap>
  {
    return this._handlers;
  }

  public createHandlersScope()
  {
    return new PageRouteHandlersScope<PageType>((page, route, handler) => {
      this._handlers.set(route, transformRouteHandler(handler, (redirect) => (
        this._handleRedirect(makePageRedirectWithDefaults(redirect, { page }))
      )));
      return handler;
    });
  }

  public redirect(
    redirectLocation: Thunk<RouteBindingRedirect<PageType>>,
    location: ResolvedRouteLocation,
    info: RoutingInfo,
  ): ReturnType<RouteHandler<PageRedirect<PageType>>>
  {
    const {
      page,
      resolvedLocation: { locationString: route },
      handler,
    } = unthunk(redirectLocation);
    return transformAndExecuteRouteHandler(handler, (redirect) => (
      makePageRedirectWithDefaults(redirect, { page, route })
    ), location, info);
  }
}
