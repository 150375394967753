import { QueryStore } from '@repo-breteuil/front-store-query';

export interface IsEmailUsedForClientAreaArgs {
  email: string,
}

export async function IsEmailUsedForClientArea(
  qs: QueryStore,
  args: IsEmailUsedForClientAreaArgs,
): Promise<boolean>
{
  return qs.query({
    operationName: 'IsEmailUsedForClientArea',
    variables: {
      email: 'String!',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            isEmailUsedForClientArea: [{
              args: { email: true },
            }, true],
          },
        },
      },
    },
  }, args);
}
