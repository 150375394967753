import type { KeyInfo } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled, Stack, Typography } from '@mui/material';

const Image = styled('img')({
  width: '32px',
  height: '32px',
});

const KeyInfoText = styled(Typography)({
  fontFamily: 'Raleway',
  fontSize: '19px',
  color: '#050251',
  '@media (max-width: 1280px)': {
    fontSize: '17px',
  },
});

export interface PropertyKeyInfoProps {
  keyInfo: KeyInfo,
}

const PropertyKeyInfo = observer((props: PropertyKeyInfoProps) => {
  const { keyInfo } = props;
  return (
    <Stack direction="row" gap={1}>
      <Image src={keyInfo.iconFileURL}/>
      <KeyInfoText>{keyInfo.content}</KeyInfoText>
    </Stack>
  );
});

export default PropertyKeyInfo;
