import type { IdType } from '@repo-breteuil/common-definitions';

import { QueryStore } from '@repo-breteuil/front-store-query';

export interface ConfirmMeetingArgs {
  agencyMeetingId: IdType,
  meetingDate: number,
  phone: string,
  email: string,
  name: string,
  remark?: string,
}

export default async function ConfirmMeeting(
  qs: QueryStore,
  args: ConfirmMeetingArgs,
): Promise<boolean> {
  return qs.mutation({
    operationName: 'ConfirmMeeting',
    variables: {
      agencyMeetingId: 'Int!',
      meetingDate: 'Date!',
      phone: 'String!',
      email: 'String!',
      name: 'String!',
      remark: 'String',
    },
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          valuation: {
            agencyMeeting: {
              confirmMeeting: [{
                args: {
                  agencyMeetingId: true,
                  meetingDate: true,
                  phone: true,
                  email: true,
                  name: true,
                  remark: true,
                } }, true],
            },
          },
        },
      },
    },
  }, args);
}
