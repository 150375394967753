import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  title: string,
  newSearch: string,
  operation: string,
  operations: {
    buy: string,
    rent: string,
  },
  sqMeter: string,
  label: string,
  city: string,
  districts: string,
  budget: string,
  min: string,
  max: string,
  surface: string,
  bedrooms: string,
  frequency: string,
  frequencies: {
    instant: string,
    daily: string,
  },
  stopNotif: string,
  addAlert: string,
  updateAlert: string,
  receiveAlertsBy: string,
  email: string,
  sms: string,
  whatsApp: string,
  error: string,
  myAlerts: string,
  searchCreationSuccessMessage: string,
  searchCreationErrorMessage: string,
  searchUpdateSuccessMessage: string,
  searchUpdateErrorMessage: string,
  confirmDeletion: string,
  yes: string,
  no: string,
  deletionSuccessToast: string,
  deletionErrorToast: string,
  cannotLoad: string,
  searchLimit: string,
  searchLimitReached: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
