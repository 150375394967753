import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedRoute, TranslatedStaticRoute } from '@breteuil-website/store/routing/translatedRoutes';

export const clientAreaLogIn = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client/connexion',
  [Locale.English]: '/client-area/log-in',
});

export const clientAreaSignUp = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client/inscription',
  [Locale.English]: '/client-area/sign-up',
});

export const clientAreaEmailVerify = new TranslatedRoute({
  [Locale.French]: '/espace-client/verification-email/[emailVerifyToken]',
  [Locale.English]: '/client-area/email-verify/[emailVerifyToken]',
});

export const clientAreaPasswordReset = new TranslatedRoute({
  [Locale.French]: '/espace-client/reset-password/[passwordResetToken]',
  [Locale.English]: '/client-area/password-reset/[passwordResetToken]',
});

export const clientAreaProfile = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client',
  [Locale.English]: '/client-area',
});

export const clientAreaSearch = new TranslatedRoute({
  [Locale.French]: '/espace-client/recherches/[searchId]',
  [Locale.English]: '/client-area/searches/[searchId]',
});

export const clientAreaSearchCreate = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client/recherches/creation',
  [Locale.English]: '/client-area/searches/create',
});

export const clientAreaSearches = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client/recherches',
  [Locale.English]: '/client-area/searches',
});

export const clientAreaFavorites = new TranslatedStaticRoute({
  [Locale.French]: '/espace-client/favoris',
  [Locale.English]: '/client-area/favorites',
});
