import { QueryStore } from '@repo-breteuil/front-store-query';

export async function DeleteAllFavorites(
  qs: QueryStore,
  args: {},
): Promise<boolean>
{
  return qs.mutation({
    operationName: 'DeleteAllFavorites',
    variables: {},
    fieldsSelection: {
      public: {
        breteuilWebsite: {
          clientArea: {
            property: {
              deleteAllFavorites: [{}, true],
            },
          },
        },
      },
    },
  }, args);
}
