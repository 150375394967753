export default {
  title: 'Email Alerts',
  newSearch: '+ New alert',
  operation: 'Operation',
  operations: {
    buy: 'Purchase',
    rent: 'Rent',
  },
  sqMeter: 'm²',
  label: 'Label',
  city: 'City',
  districts: 'Districts',
  budget: 'Budget',
  min: 'Min',
  max: 'Max',
  surface: 'Surface',
  bedrooms: 'Bedrooms',
  frequency: 'Alert frequency',
  frequencies: {
    instant: 'Instantly',
    daily: 'Daily',
  },
  stopNotif: 'Pause notifications',
  addAlert: 'Save',
  updateAlert: 'Update',
  receiveAlertsBy: 'Receive my alerts by',
  email: 'Enable email alerts',
  sms: 'SMS',
  whatsApp: 'WhatsApp',
  error: "There has been an error, please reload the page.",
  myAlerts: 'My alerts',
  searchCreationSuccessMessage: 'Your alert has been created',
  searchCreationErrorMessage: 'We could not create your alert, please try again later',
  searchUpdateSuccessMessage: 'Your alert has been updated',
  searchUpdateErrorMessage: 'We could not update your alert, please try again later',
  confirmDeletion: 'Are you sure you want to delete this alert ?',
  yes: 'Yes',
  no: 'No',
  deletionSuccessToast: 'Your alert has been successfully deleted',
  deletionErrorToast: "We couldn't delete your alert, please try again later",
  cannotLoad: 'We could not load your alerts, please refresh the page.',
  searchLimit: 'You can only create 4 alerts.',
  searchLimitReached: 'You have reached the limit of 4 alerts per account.',
};
