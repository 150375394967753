import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import nl2br from 'react-nl2br';
import { useTexts } from '@repo-breteuil/common-texts';
import Bios from '@breteuil-website/components/pages/about/Bios';
import { Page } from '@breteuil-website/components/common';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import texts from './texts';

const Container = styled('div')({
  textAlign: 'center',
  fontFamily: 'Poppins',
  maxWidth: '750px',
  margin: 'auto',
});

const MobileBannerImage = styled('img')({
  height: '110px',
  width: '100%',
  verticalAlign: 'middle',
});

const DesktopBannerImage = styled('img')({
  height: '300px',
  width: '100%',
  verticalAlign: 'middle',
});

const BannerBase = styled('div')({
  display: 'flex',
  fontFamily: 'Poppins',
  color: '#FFFFFF',
  textAlign: 'center',
  backgroundColor: '#050251',
  justifyContent: 'center',
  alignItems: 'center',
});

const DesktopBanner = styled(BannerBase)({
  fontSize: '43px',
  fontWeight: 700,
  height: '90px',
});

const MobileBanner = styled(BannerBase)({
  fontSize: '17px',
  fontWeight: 700,
  height: '40px',
});

const IntroBase = styled('div')({
  fontWeight: 700,
  color: '#050251',
});

const Intro = styled(IntroBase)({
  fontSize: '25px',
  margin: '100px auto',
});

const IntroMobile = styled(IntroBase)({
  fontSize: '18px',
  margin: '32px 25px 56px',
});

const CompanyIntroBase = styled('div')({
  fontFamily: 'Mulish',
  color: '#757575',
  margin: '0px 25px 80px',
});

const CompanyIntro = styled(CompanyIntroBase)({
  fontSize: '15px',
});

const CompanyIntroMobile = styled(CompanyIntroBase)({
  fontSize: '13px',
});

const About = observer(() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  return (
    <Page>
      {isMobile ? (
        <>
          <MobileBanner>{T.about}</MobileBanner>
          <MobileBannerImage src="/assets/randomcity.jpg" alt=""/> {/*TODO asset manquant*/}
        </>
      ) : (
        <>
          <DesktopBannerImage src="/assets/randomcity.jpg" alt=""/> {/*TODO asset manquant*/}
          <DesktopBanner>{T.aboutBreteuil}</DesktopBanner>
        </>
      )}
      <Container>
        {isMobile ? (
          <IntroMobile>{T.intro}</IntroMobile>
        ) : (
          <Intro>{T.intro}</Intro>
        )}
        <Bios/>
        {isMobile ? (
          <CompanyIntroMobile>{nl2br(T.companyIntro)}</CompanyIntroMobile>
        ) : (
          <CompanyIntro>{nl2br(T.companyIntro)}</CompanyIntro>
        )}
      </Container>
    </Page>
  );
});

export default About;
