import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  title: string,
  noProperties: string,
  error: string,
  price: string,
  addDate: string,
  deleteAll: string,
  filters: {
    price_desc: string,
    price_asc: string,
    date_desc: string,
    date_asc: string,
  },
  confirmations: {
    text: string,
    yes: string,
    no: string,
  },
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
