import type { IdType } from '@repo-breteuil/common-definitions';


import { QueryStore } from '@repo-breteuil/front-store-query';

export interface TransactionFilter
{
  cityId?: { equals: IdType },
  districtId?: { in: Array<number> },
  surface?: { gte?: number, lte?: number, gt?: number },
}

interface NumberStats
{
  min: number,
  max: number,
  avg: number,
}

interface TransactionsStats
{
  count: number,
  volume: number,
  adjustedPricePerSurface: NumberStats | null,
}

export interface YearTransactionsStats
{
  year: number,
  availability: number,
  stats: TransactionsStats,
}

export default async function GetTransactionsStatsPerYearGov(
  qs: QueryStore,
  args: {
    filter: TransactionFilter,
  },
): Promise<Array<YearTransactionsStats>>
{
  return qs.query({
    operationName: 'GetTransactionsStatsPerYearGov',
    variables: {
      filter: 'Filters__Transaction',
    },
    fieldsSelection: {
      public: {
        transactionsStatsPerYearGov: [{ args: { filter: true } }, {
          year: true,
          availability: true,
          stats: {
            count: true,
            volume: true,
            adjustedPricePerSurface: {
              min: true,
              avg: true,
              max: true,
            },
          },
        }],
      },
    },
  }, args);
}
