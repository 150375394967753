import * as MuliLightTrueType from './Muli-Light.ttf';
import * as MuliBoldTrueType from './Muli-Bold.ttf';
import * as PoppinsMediumOpenType from './Poppins-Medium.otf';
import * as PoppinsBoldOpenType from './Poppins-Bold.otf';
import * as PoppinsExtraBoldOpenType from './Poppins-ExtraBold.otf';
import * as CSS from 'csstype';

// from '@mui/material/styles/createMixins'
type JSSFontface = CSS.AtRule.FontFace & { fallbacks?: Array<CSS.AtRule.FontFace> };

export const MuliLight: JSSFontface = {
  fontFamily: 'Muli',
  src: `url(${MuliLightTrueType}) format('ttf')`,
  fontWeight: 200,
  fontStyle: 'normal',
};

export const MuliBold: JSSFontface = {
  fontFamily: 'Muli',
  src: `url(${MuliBoldTrueType}) format('ttf')`,
  fontWeight: 700,
  fontStyle: 'normal',
};

export const PoppinsMedium: JSSFontface = {
  fontFamily: 'Poppins',
  src: `url(${PoppinsMediumOpenType}) format('otf')`,
  fontWeight: 500,
  fontStyle: 'normal',
};

export const PoppinsBold: JSSFontface = {
  fontFamily: 'Poppins',
  src: `url(${PoppinsBoldOpenType}) format('otf')`,
  fontWeight: 700,
  fontStyle: 'normal',
};

export const PoppinsExtraBold: JSSFontface = {
  fontFamily: 'Poppins',
  src: `url(${PoppinsExtraBoldOpenType}) format('otf')`,
  fontWeight: 900,
  fontStyle: 'normal',
};

export const FontFaces = [MuliLight, MuliBold, PoppinsMedium, PoppinsBold, PoppinsExtraBold];
