export default {
  title: 'Mon profil',
  firstname: 'Prénom',
  lastname: 'Nom',
  mail: 'E-mail',
  password: 'Mot de passe',
  phone: 'Mobile',
  address: 'Adresse',
  zipCode: 'Code postal',
  city: 'Ville',
  languages: {
    french: 'Français',
    english: 'English',
    frenchEn: 'French',
  },
  language: 'Langue',
  alerts: 'Recevoir alertes par',
  coms: 'Communications Breteuil',
  alertFrequency: "Fréquence des emails liés aux recherches",
  newsletterOptOut: 'Newsletter',
  saveChanges: 'Enregistrer',
  sms: 'SMS',
  whatsapp: 'WhatsApp',
  yes: 'Oui',
  no: 'Non',
  successToast: 'Votre profil a bien été modifié',
  errorToast: 'Il y a eu une erreur, veuillez réessayer plus tard.',
  frequencies: {
    instant: 'Instantané',
    daily: 'Quotidienne',
  },
  comsDisabled: 'Toutes communications de Breteuil sont désactivées.',
};
