import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTexts } from "@repo-breteuil/common-texts";
import lang from '../texts';
import {
  Button as GenericButton,
  CodeInput,
} from '@breteuil-website/components/common';
import { useWebsiteStores } from "@breteuil-website/components/providers";

const CodeInputSize = 4;

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '24px',
  height: '24px',
  padding: "24px",
});

const DialogTitle = styled(MuiDialogTitle)({
  color: '#050251',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  fontFamily: 'Poppins',
  textAlign: 'center',
  '@media (max-width: 960px)': {
    fontSize: '18px',
    position: "relative",
    margin: "15px auto 0",
  },
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '15px',
  '@media (max-width: 960px)': {
    padding: '20px 0',
  },
});

const Button = styled(GenericButton)({
  position: 'relative',
  width: '100% !important',
  margin: '70px auto 25px auto !important',
});

export interface CodeConfirmationProps {
  handleClose: () => void,
  handleSubmit: (submittedCode: string) => void,
  handleResendPromise: () => Promise<boolean>,
}

const CodeConfirmation = (props: CodeConfirmationProps) => {
  const { handleClose, handleSubmit, handleResendPromise } = props;
  const { globalMessage } = useWebsiteStores();
  const T = useTexts(lang).method1.confirmPhoneDialog;
  const [code, setCode] = React.useState('');
  const resetCodeWhenClosing = () => {
    setCode('');
    handleClose();
  };

  return (
    <div>
      <CloseButton onClick={resetCodeWhenClosing}>
        <CloseIcon/>
      </CloseButton>
      <DialogTitle>
        {T.title}
      </DialogTitle>
      <DialogContent>
        <CodeInput
          size={CodeInputSize}
          value={code}
          onChange={(newValue) => {
            setCode(newValue);
            if (newValue.length === CodeInputSize) {
              handleSubmit(newValue);
              setCode('');
            }
          }}
        />
        <Button
          onClick={() => {
            setCode('');
            const promise = handleResendPromise();
            globalMessage.handlePromise(promise, {
              onSuccess: (res) => {
                if (res)
                  globalMessage.display(T.successfullCodeResent, { severity: 'success' });
                else
                  globalMessage.display(T.phoneAlreadyConfirmed, { severity: 'error' });
              },
              successMessage: null,
              errorMessage: (error) =>  {
                return T.handleFormSubmissionErrors(error);
              },
            });
          }}
        >
          {T.actions.sendNewCode}
        </Button>
      </DialogContent>
    </div>
  );
};

export default CodeConfirmation;
