import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { AppBar as MuiAppBar } from '@mui/material';
import { useLocale, useTexts, type Locale } from '@repo-breteuil/common-texts';
import { Link, Routes } from '@breteuil-website/store/routing';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Logout } from '@mui/icons-material';
import router from '@breteuil-website/store/routing';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from '../texts';
import TextLink from '@breteuil-website/components/common/app/TextLink';
import LanguagePicker from '../LanguagePicker';

const Bar = styled('div')({
  display: 'grid',
  gridTemplateColumns: "125px 1fr 115px 1fr 125px",
  width: '100%',
  padding: '17px 20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 0 4px #a2a2a2',
  height: '90px',
});

const StyledTextLink = styled(TextLink)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Poppins',
  fontSize: '13px',
  fontWeight: 500,
  color: '#050251',
  whiteSpace: 'nowrap',
  textAlign: 'center',
});

const NavBarSides = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
});

const LanguagePickerWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '70px',
  paddingLeft: '10px',
  justifyContent: 'space-between',
});

const ProfileLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  overflow: 'hidden',
  color: '#0C17E1',
  justifyContent: 'flex-end',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '13px',

});

const LogoLink = styled(Link)({
  display: "flex",
  alignItems: "center",
});

const AccountIcon = styled(AccountCircleIcon)({
  width: '30px',
  height: '30px',
  color: '#050251',
});

const LogOutButton = styled(Link)({
  display: 'flex',
  justifySelf: 'end',
  alignSelf: 'center',
  color: '#050251',
  columnGap: '5px',
});

// TODO find a better way
export function isRouteFromClientArea(route: string | null, args: { lang: Locale }) {
  if (!route)
    return false;
  const parsedRoute = route.split('/');
  const routeWithLang = Routes.clientAreaProfile.routesByLanguage[args.lang];
  const parsedRouteWithLang = routeWithLang?.generateStaticPath() ? routeWithLang.generateStaticPath().split('/') : null;
  const index = parsedRoute.length > 0 && parsedRouteWithLang?.length && parsedRouteWithLang?.length > 0 ? parsedRouteWithLang.length - 1 : -1;
  if (index >= 0 && parsedRoute && parsedRouteWithLang && parsedRoute[index] === parsedRouteWithLang[index])
    return true;
  return false;
}

const AppBar = observer(() => {
  const stores = useWebsiteStores();
  const lang = useLocale();
  const T = useTexts(texts);

  return (
    <MuiAppBar sx={{ bgcolor: "white" }} elevation={0}>
    <Bar>
      <LanguagePickerWrapper>
        <LanguagePicker/>
      </LanguagePickerWrapper>
      <NavBarSides>
        <StyledTextLink text={T.buy} to={Routes.buy.generateStaticPath({ lang })}/>
        <StyledTextLink text={T.rent} to={Routes.rent.generateStaticPath({ lang })}/>
        <StyledTextLink text={T.seasonalRental} to={Routes.seasonalRent.generateStaticPath({ lang })}/>
        <StyledTextLink text={T.valuation} to={Routes.valuation.generateStaticPath({ lang })}/>
      </NavBarSides>
      <LogoLink to={Routes.home.generateStaticPath({ lang })}>
        <img
          src="/assets/LOGO_BRETEUIL.svg"
          alt={T.logoAlt} //TODO: lang
        />
      </LogoLink>
      <NavBarSides>
        <StyledTextLink text={T.services} to={Routes.services.generateStaticPath({ lang })}/>
        <StyledTextLink text={T.contact} to={Routes.contact.generateStaticPath({ lang })}/>
        <StyledTextLink text={T.joinUs} to={Routes.joinUs.generateStaticPath({ lang })}/>
      </NavBarSides>
        {stores.session.authenticated && isRouteFromClientArea(router.router.currentRoute, { lang }) ? (
          <LogOutButton
            to={Routes.home.generateStaticPath({ lang })}
            onClick={() => stores.session.logOut()}
            title={T.logOut}
          >
            <Logout/>
          </LogOutButton>
        ) : (
          <ProfileLink to={Routes.clientAreaSearches.generateStaticPath({ lang })}>
            <AccountIcon/>{/* TODO: shrink div at a certain point when shrinking the page*/}
          </ProfileLink>
        )}
    </Bar>
    </MuiAppBar>
  );
});

export default AppBar;
