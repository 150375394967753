import * as React from 'react';
import { omitUndefinedValues } from '@repo-lib/utils-core';
import { Link } from '@breteuil-website/store/routing';

export interface TextLinkProps
{
  to: string,
  text: string,
  className?: string,
  style?: React.CSSProperties,
  target?: string,
}

const TextLink = (props: TextLinkProps) => {
  const { to, target, className, style }= props;
  return (
    <Link { ...omitUndefinedValues({ to, target })}>
      <div { ...omitUndefinedValues({ className, style })}>
        {props.text}
      </div>
    </Link>
  );
};

export default TextLink;
