import * as React from 'react';
import { observer } from 'mobx-react';
import { Page, PageMeta } from '@breteuil-website/components/common';
import { useTexts } from '@repo-breteuil/common-texts';
import texts from './texts';
import Region from './Region';

const Contact = observer(() => {
  const T = useTexts(texts);
  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.pageDescription}
      />
      <Region/>
    </Page>
  );
});

export default Contact;
