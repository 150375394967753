import * as React from 'react';
import { observer } from "mobx-react";
import type { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import { Notifications, Favorite, Settings } from '@mui/icons-material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import {
  clientAreaProfile,
  clientAreaSearches,
  clientAreaFavorites,
} from '@breteuil-website/store/routing/routes';
import { ClientAreaTabs } from '@breteuil-website/store/ui/pages/client-area/common/tabs';
import texts from './texts';
import { Page } from '@breteuil-website/components/common';
import ClientAreaTab from '@breteuil-website/components/pages/client-area/ClientAreaTab';
import { useMediaQueryMobile, useMediaQueryTablet } from '@breteuil-website/components/theme';

const HeaderDesktopHeight = 90;

const Container = styled('div')({
  position: 'relative',
  minHeight: `calc(100vh - ${HeaderDesktopHeight}px)`,
});

const Wrapper = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  ...(isMobile ? {
    paddingTop: '50px',
    minHeight: `calc(100vh - ${HeaderDesktopHeight}px - 68px)`, // 68 px : side bar height
  } : {
    padding: '30px 0px',
    marginLeft: '153px',
    backgroundColor: '#F6F6F6',
    minHeight: `calc(100vh - ${HeaderDesktopHeight}px)`,
  }),
}));

const Menu = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '153px',
  height: `calc(100vh - ${HeaderDesktopHeight}px)`,
  boxShadow: '3px 0px 8px #AAAAAA',
  '@media (max-width: 1280px)': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'unset',
    width: '100%',
    height: '68px',
    minHeight: 'unset',
    '& > a': {
      flexGrow: 1,
    },
  },
});

const ClientArea = observer((props: PropsWithChildren<{}>) => {
  const lang = useLocale();
  const T = useTexts(texts);
  const isMobileView = useMediaQueryMobile();
  const isTabletView = useMediaQueryTablet();
  const isMobile = isMobileView || isTabletView;

  return (
    <Page>
      <Container>
        <Menu>
          <ClientAreaTab
            name={ClientAreaTabs.Searches}
            text={T.tabs.searches()}
            to={clientAreaSearches.generateStaticPath({ lang })}
            icon={<Notifications sx={{ fontSize: 40 }}/>}
          />
          <ClientAreaTab
            name={ClientAreaTabs.Profile}
            text={T.tabs.profile}
            to={clientAreaProfile.generateStaticPath({ lang })}
            icon={<Settings sx={{ fontSize: 40 }}/>}
          />
          <ClientAreaTab
            name={ClientAreaTabs.Favorites}
            text={T.tabs.favorites}
            to={clientAreaFavorites.generateStaticPath({ lang })}
            icon={<Favorite sx={{ fontSize: 40 }}/>}
          />
        </Menu>
        <Wrapper isMobile={isMobile}>
          {props.children}
        </Wrapper>
      </Container>
    </Page>
  );
});

export default ClientArea;
