import type { Agency } from '@breteuil-website/store/ui/pages/valuation/api/PrepareMeeting';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Mail, Phone } from '@mui/icons-material';
import { useTexts } from '@repo-breteuil/common-texts';
import { Button } from '@breteuil-website/components/common';
import lang from './texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '11px 22px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 700,
  color: '#757575',
  opacity: 1,
  '@media (max-width: 1280px)': {
    fontSize: '15px',
  },
});

const SelectedAgencyTitle = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: "100%",
  '& > span': {
    margin: 'auto 0',
  },
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
  },
});

const Icons = styled('div')({
  display: 'flex',
  columnGap: '5px',
  margin:'30px',
  justifyContent: 'center',
});

const MobileSelectedAgencyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5px',
  margin: 'auto',
});


const CTAButton = styled(Button)({
  width: 'auto',
  backgroundColor: '#050251',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
  "& .MuiButton-endIcon": {
    margin:'0',
    fontSize: '32px',
  },
});

const SelectedAgency = (props: { agency: Agency | undefined | null, orientationCol?: boolean }) => {
  const T = useTexts(lang).method3.agencySelection;
  const agency = props.agency ? props.agency : null;
  const isDesktop = useMediaQueryDesktop();
  return (
    <Container>
        {isDesktop ? (
          <SelectedAgencyTitle style={{flexDirection: props.orientationCol ? 'column' : 'row'}}>{T.selectedAgency(agency)}</SelectedAgencyTitle>
        ) : (
          <MobileSelectedAgencyContainer>
            <SelectedAgencyTitle>{T.mobileSelectedAgency(agency)}</SelectedAgencyTitle>
            {agency ?
              <Icons>
                <CTAButton variant='contained' href={`mailto:${agency.email}`} endIcon={<Mail/>}/>
                <CTAButton variant='contained' href={`tel:${agency.phone}`} endIcon={<Phone/>}/>
              </Icons>
            : null}
          </MobileSelectedAgencyContainer>
        )}
    </Container>
  );
};

export default SelectedAgency;
