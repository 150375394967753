import * as React from 'react';
import { observer } from 'mobx-react';
import {
  styled,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import {
  ArrowDownward as ArrowDownwardcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';

const ShowMoreText = styled(Typography)({
  fontFamily: 'Cormorant',
  fontSize: '22px',
  color: '#050251',
});

export interface ShowMoreActionProps {
  expanded: boolean,
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
}

const ShowMoreAction = (props: ShowMoreActionProps) => {
  const { expanded, setExpanded } = props;
  const T = useTexts(texts);

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={1}
      marginTop={2}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded
        ? <ArrowUpwardIcon sx={{ color: '#050251' }}/>
        : <ArrowDownwardcon sx={{ color: '#050251' }}/>
      }
      <ShowMoreText>
        {expanded ? T.showLess : T.showMore}
      </ShowMoreText>
    </Stack>
  );
};

export interface ShowMoreProps {
  firstElements: React.ReactNode,
  children: React.ReactNode,
}

const ShowMore = observer((props: ShowMoreProps) => {
  const { firstElements, children } = props;
  const [ expanded, setExpanded ] = React.useState(false);

  return (
    <Stack direction='column'>
      {firstElements}
      <Collapse orientation='vertical' in={expanded}>
        {children}
      </Collapse>
      <ShowMoreAction expanded={expanded} setExpanded={setExpanded}/>
    </Stack>
  );
});

export default ShowMore;
