import * as React from 'react';
import { observer } from 'mobx-react';
import { Close as CloseIcon, CallMade as CallMadeIcon } from '@mui/icons-material';
import {
  Box,
  Modal,
  IconButton,
} from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Link, Routes } from '@breteuil-website/store/routing';
import { styled } from '@mui/material/styles';
import RegisterForm from '@breteuil-website/components/pages/client-area/login/RegisterForm';
import texts from './texts';

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '5px',
  right: '5px',
  width: '24px',
  height: '24px',
  padding: "24px",
});

const ModalTitle = styled('h1')({
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '30px',
  color: '#050251',
  maxWidth: '600px',
  textAlign: 'center',
  margin: '20px auto',
  '@media (max-width: 960px)': {
    fontSize: '22px',
  },
});

const LoginLinkContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Mulish',
  fontSize: '15px',
  marginTop: '100px',
  justifyContent: 'center',
  '@media (max-width: 960px)': {
    marginTop: '15px',
    fontSize: '12px',
    textAlign: 'center',
  },
});

const LoginText = styled('span')({
  color: '#757575',
  display: 'flex',
  alignItems: 'center',
});

const LoginLink = styled(Link)({
  color: '#050251',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '5px',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  maxWidth: '1000px',
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 1440px)': {
  top: '55%',
  },
};

const ClientAreaModal = observer((props : {show: boolean, handleClose: () => void}) => {
  const {show, handleClose} = props;
  const lang = useLocale();
  const T = useTexts(texts);

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
      >
        <Box sx={style}>
          <CloseButton onClick={handleClose}>
            <CloseIcon/>
          </CloseButton>
          <ModalTitle>{T.clientAreaPropertyModalTitle}</ModalTitle>
          <RegisterForm closeModal={handleClose}/>
          <LoginLinkContainer>
            <LoginText>
              {T.loginText}
            </LoginText>
            <LoginLink to={Routes.clientAreaLogIn.generateStaticPath({ lang })}>
              {T.connect}
              <CallMadeIcon/>
            </LoginLink>
          </LoginLinkContainer>
        </Box>
      </Modal>
    </div>
  );
});

export default ClientAreaModal;
