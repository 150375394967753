import * as React from 'react';
import { styled, Popper, ClickAwayListener, CircularProgress } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router from '@breteuil-website/store/routing';
import { Routes } from '@breteuil-website/store/routing';
import texts from './texts';
import { Button } from '@breteuil-website/components/common';
import type { IdType } from '@repo-breteuil/common-definitions';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const CustomButton = styled(Button)({
  width: '56px',
  height: '56px',
  backgroundColor: '#D32F2F !important',
});

const PopperButton = styled(Button)({
  width: '80px',
});

const ConfirmationPopper = styled('div')({
  fontFamily: 'Poppins',
  fontSize: '14px',
  color: '#050251',
  backgroundColor: '#FFFFFF',
  height: '120px',
  width: '300px',
  padding: '10px 20px',
  zIndex: 100,
  border: '2px solid #0C17E1',
});

const PopperButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '10px',
});

const DeleteButton = (props: { searchId: IdType }) => {
  const lang = useLocale();
  const T = useTexts(texts);
  const { searches, globalMessage } = useWebsiteStores();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

  const setPopperAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const [ loading, deleteSearch ] = globalMessage.useAsyncHandler(() => searches.deleteSearch(props.searchId),
    {
      onSuccess: () => {
        const { search } = searches;
        const route = search ? (
          Routes.clientAreaSearch.generatePath({ searchId: search.id }, { lang })
        ): (
          Routes.clientAreaSearches.generateStaticPath({ lang })
        );
        router.changeRoute(route);
      },
      successMessage: () => T.deletionSuccessToast,
      errorMessage: () => T.deletionErrorToast,
    },
  );

  const handleClick = () => {
    setAnchorEl(null);
    deleteSearch();
  };

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress sx={{color: '#0C17E1'}}/>
      ) : (
        <CustomButton onClick={setPopperAnchor}>
          <Delete sx={{fontSize: '34px', color: '#FFFFFF'}}/>
        </CustomButton>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        {...(null as unknown as {
          nonce: any,
          onResize: any,
          onResizeCapture: any,
          placeholder: any,
          onPointerEnterCapture: any,
          onPointerLeaveCapture: any,
        })}
        modifiers={[{
          name: 'preventOverflow',
          enabled: false,
        }, {
          name: 'flip',
          options: {
            altBoundary: true,
            rootBoundary: 'document',
          },
          enabled: true,
        }]}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <ConfirmationPopper>
            {T.confirmDeletion}
            <PopperButtons>
              <PopperButton onClick={handleClick}>
                {T.yes}
              </PopperButton>
              <PopperButton onClick={() => setAnchorEl(null)}>
                {T.no}
              </PopperButton>
            </PopperButtons>
          </ConfirmationPopper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default DeleteButton;
