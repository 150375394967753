import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import { Button } from '@breteuil-website/components/common/elements';
import router from '@breteuil-website/store/routing';
import { getErrorCode } from '@repo-breteuil/front-error';
import { Routes } from '@breteuil-website/store/routing';
import Page from '@breteuil-website/components/common/app/Page';
import InvalidToken from '@breteuil-website/components/pages/client-area/email-verification/InvalidToken';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import texts from './texts';

const Container = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  fontFamily: 'Poppins',
  maxWidth: '400px',
  paddingBottom: '200px',
});

const ThankYou = styled('div')({
  color: '#050251',
  fontSize: '50px',
  fontWeight: 700,
  marginTop: '100px',
});

const Text = styled('div')({
  fontFamily: 'Mulish',
  fontSize: '15px',
  color: '#757575',
  margin: '20px auto 50px auto',
});

const EmailVerification = observer(() => {
  const lang = useLocale();
  const T = useTexts(texts);
  const { emailVerify } = useWebsiteStores();
  const { value } = emailVerify.verify;

  if (value.status === 'success') {
    const { result } = value;
    return (
      <Page>
        <Container>
          <ThankYou>
            {T.thankYou(result.contact.firstname)}
          </ThankYou>
          <Text>
            {T.successText}
          </Text>
          <Button
            onClick={() => router.changeRoute(Routes.clientAreaSearches.generateStaticPath({ lang }))}
          >
            {T.goToProfile}
          </Button>
        </Container>
      </Page>
    );
  }

  if (value.status === 'failure') {
    const { error } = value;
    if (getErrorCode(error) === 'InvalidToken') {
      return (
        <Page>
          <InvalidToken/>
        </Page>
      );
    }
  }

  return <div>o</div>;
});

export default EmailVerification;
