import type { Property } from '@breteuil-website/store/ui/pages/property/api';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { getLocalURL } from '@repo-lib/browser-utils-dom';
import { StaticMap } from '@repo-breteuil/react-components';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';

// const Container = styled(Box)({
//   width: '100%',
//   '@media (max-width: 1280px)': {
//     padding: '20px 0px',
//   },
// });

const MapContainer = styled(Box)({
  height: '530px',
  width: '100%',
  marginBottom: '74px',
  '@media (max-width: 1280px)': {
    height: '214px',
    width: '100%',
    marginBottom: '20px',
  },
});

export interface PropertyMapProps {
  property: Property,
}

const PropertyMap = observer((props: PropertyMapProps) => {
  const { property } = props;
  const { env } = useWebsiteStores();
  const isDesktop = useMediaQueryDesktop();

  return (
    <MapContainer>
      <StaticMap
        googleMapsAPIKey={env.googleMapsAPIKey}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        location={{ lat: property.latitude, lng: property.longitude }}
        zoomLevel={isDesktop ? 10 : 12}
        size={{ width: 640, height: 640 }}
        scale={2}
        markers={[
          {
            icon: { icon: getLocalURL('/assets/mapMarkerOutlined.png').href, anchor: 'center' },
            scale: 2,
            locations: [{ lat: property.latitude, lng: property.longitude }],
          },
        ]}
      />
    </MapContainer>
  );
});

export default PropertyMap;
