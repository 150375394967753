import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
} from '@lib/routing';
import type { LanguageRouteHandler } from '@core/store/routing';
import type { Areas } from '@breteuil-website/store/ui/common/areas';
import type { PropertiesFilter } from '@breteuil-website/store/ui/pages/properties-search';

import { isNonNull, optionalPromiseThen } from '@repo-lib/utils-core';
import { OperationType } from '@repo-breteuil/common-definitions';
import { Locale } from '@repo-breteuil/common-texts';
import {
  setTranslatedStaticPage,
  setTranslatedPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { getPropertiesSearchRoute } from '@breteuil-website/store/routing';
import {
  DefaultPropertiesFilter,
  getOperationTypePriceFilterValues,
  BedroomsMinFilterValues,
} from '@breteuil-website/store/ui/pages/properties-search';
import { scale } from '@breteuil-website/components/common';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

function parseIntSearchParam(
  param: string | null,
  opts: {
    validation?: (value) => boolean,
  },
): number | null
{
  if (!param)
    return null;
  const value = Number.parseInt(param);
  if (isNaN(value))
    return null;
  if (opts.validation && !opts.validation(value))
    return null;
  return value;
}

function parseResourceSlugsSearchParam<T>(
  param: string | null,
  resources: Map<string, T>,
): Array<T>
{
  if (!param)
    return [];
  const slugs = param.split(',');
  return slugs.map(slug => (
    resources.get(slug) || null
  )).filter(isNonNull);
}

function parsePropertiesFilterSearchParams(
  searchParams: URLSearchParams,
  args: {
    areas: Areas,
    operationType: OperationType | undefined,
  },
)
{
  const { areas, operationType } = args;
  const priceFilterValues = getOperationTypePriceFilterValues(operationType);
  const priceValidation = (n) => (n >= priceFilterValues.min && n <= priceFilterValues.maxAmount);
  const priceMax =  parseIntSearchParam(searchParams.get('priceMax'), { validation: priceValidation });
  const bedroomsMin = parseIntSearchParam(searchParams.get('bedroomsMin'), {
    validation: (n) => (n >= BedroomsMinFilterValues.min && n <= BedroomsMinFilterValues.max),
  });
  const subAreas = parseResourceSlugsSearchParam(searchParams.get('subAreas'), areas.subAreasPerSlug);
  const defaultValue = operationType === OperationType.ResidencyTransaction ? scale(priceFilterValues.default) : priceFilterValues.default;
  return {
    priceMax: priceMax === null ? defaultValue : priceMax,
    bedroomsMin: bedroomsMin === null ? undefined : bedroomsMin,
    subAreas,
  };
}

function genPropertiesSearchHandler(
  routeFilters: {
    operationType?: OperationType,
  } = {},
): LanguageRouteHandler<RootPages, Locale>
{
  return (location, info, lang) => {
    return optionalPromiseThen(websiteStores.areas.areas.ensureSuccess(), (areas: Areas) => {
      return () => {
        const { routeParams, searchParams } = location;
        const operationType = routeFilters.operationType !== undefined ? (
          routeFilters.operationType
        ) : DefaultPropertiesFilter.operationType;
        const { subAreas: subAreas_, ...searchParamsFilters } = parsePropertiesFilterSearchParams(searchParams, {
          areas,
          operationType,
        });
        let subAreas = subAreas_;
        const filters: PropertiesFilter = {
          ...searchParamsFilters,
          operationType,
        };

        const areaWithSubAreas = routeParams.areaSlug ? areas.areasPerSlug.get(routeParams.areaSlug) : undefined;
        if (areaWithSubAreas)
        {
          filters.areaId = areaWithSubAreas.area.id;
          subAreas = subAreas.filter(a => (
            areaWithSubAreas.subAreas.find(b => (a.id === b.id))
          ));
        }
        else
          subAreas = [];
        if (subAreas.length > 0)
          filters.subAreaId = subAreas.map(subArea => subArea.id);
        websiteStores.propertiesSearch.setFilterAndReload(filters, {
          skipReloadIfNoChanges: true,
        });
        const route = getPropertiesSearchRoute({
          ...filters,
          lang,
          areaSlug: areaWithSubAreas?.area.slug,
          subAreasSlugs: subAreas.map(subArea => subArea.slug),
        });
        return {
          route,
          page: null,
          scrollTop: websiteStores.propertiesSearch.scrollLevel,
        };
      };
    });
 };
}

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  setTranslatedStaticPage(
    Public,
    RootPages.PropertiesSearch,
    Routes.buy.routesByLanguage,
    genPropertiesSearchHandler({ operationType: OperationType.ResidencyTransaction }),
  );
  setTranslatedPage(
    Public,
    RootPages.PropertiesSearch,
    Routes.buyArea.routesByLanguage,
    genPropertiesSearchHandler({ operationType: OperationType.ResidencyTransaction }),
  );
  setTranslatedPage(
    Public,
    RootPages.PropertiesSearch,
    Routes.compatBuyArea.routesByLanguage,
    genPropertiesSearchHandler({ operationType: OperationType.ResidencyTransaction }),
  );

  setTranslatedStaticPage(
    Public,
    RootPages.PropertiesSearch,
    Routes.properties.routesByLanguage,
    genPropertiesSearchHandler(),
  );
}
