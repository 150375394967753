import * as React from 'react';
import { observer } from 'mobx-react';
import type { EmptyInterface } from '@repo-lib/utils-core';
import { Router as LibRouter } from '@lib/components';
import router, { RootPages } from '@breteuil-website/store/routing';
import {
  Loading,
  Error404,
  FatalError,
  OptOutPage,
  Property,
  Home,
  PropertiesSearch,
  PropertiesSearchRent,
  PropertiesSearchSeasonalRent,
  Services,
  Valuation,
  About,
  Contact,
  Agency,
  ClientAreaProfile,
  ClientAreaFavorites,
  ClientAreaSearches,
  ClientAreaLogIn,
  ClientAreaSignUp,
  ClientAreaPasswordReset,
  ClientAreaEmailVerify,
  OurFees,
  JoinUs,
  LegalNotice,
} from './pages';

const pages = new Map<RootPages, React.ComponentType<EmptyInterface>>([
  [ RootPages.OptOut, OptOutPage ],
  [ RootPages.NotFound, Error404 ],
  [ RootPages.Property, Property],
  [ RootPages.Home, Home ],
  [ RootPages.PropertiesSearch, PropertiesSearch ],
  [ RootPages.PropertiesSearchRent, PropertiesSearchRent ],
  [ RootPages.PropertiesSearchSeasonalRent, PropertiesSearchSeasonalRent ],
  [ RootPages.Services, Services],
  [ RootPages.Valuation, Valuation],
  [ RootPages.About, About],
  [ RootPages.Contact, Contact],
  [ RootPages.Agency, Agency],
  [ RootPages.ClientAreaProfile, ClientAreaProfile ],
  [ RootPages.ClientAreaFavorites, ClientAreaFavorites ],
  [ RootPages.ClientAreaSearches, ClientAreaSearches ],
  [ RootPages.ClientAreaLogIn, ClientAreaLogIn ],
  [ RootPages.ClientAreaSignUp, ClientAreaSignUp ],
  [ RootPages.ClientAreaPasswordReset, ClientAreaPasswordReset ],
  [ RootPages.ClientAreaEmailVerify, ClientAreaEmailVerify ],
  [ RootPages.OurFees, OurFees ],
  [ RootPages.JoinUs, JoinUs ],
  [ RootPages.LegalNotice, LegalNotice ],
]);

export const Router = observer(() => (
  <LibRouter
    LoadingPage={Loading}
    ErrorPage={FatalError}
    pages={pages}
    router={router}
  />
));
