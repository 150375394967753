export default {
  error404: {
    title: 'Page introuvable',
    subtitle: `Nous sommes désolés,
    ce lien ne fonctionne plus.`,
    pictureText: `Nous n’avons pas trouvé la page que vous recherchiez.
    Parfois, un petit chien mignon peut consoler.`,
    pictureAlt: 'Chien',
  },
  fatal: {
    title: 'Une erreur est survenue',
    subtitle: 'Ouch.',
    pictureText: `Nous avons rencontré un problème.
    Nous vous recommandons de recharger la page.`,
    pictureAlt: 'Hérisson',
  },
};
