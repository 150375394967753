import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
  ResolvedRouteLocation,
  RoutingInfo,
} from '@lib/routing';
import type { Locale } from '@repo-breteuil/common-texts';

import { optionalPromiseThen } from '@repo-lib/utils-core';
import {
  setTranslatedStaticPage,
  setTranslatedPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';
import { redirectToTranslatedPage } from '@core/store/routing';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  function logInHandler(
    location: ResolvedRouteLocation,
    info: RoutingInfo,
    language: Locale,
  )
  {
    return optionalPromiseThen(websiteStores.session.initialSession, () => {
      //Redirecting to LogIn page if not connected
      if (websiteStores.session.authenticated)
        return redirectToTranslatedPage(handlers, {
          to: Profile,
          language,
          location,
          info,
        });
      return () => ({
        page: null,
        route: Routes.clientAreaLogIn.generateStaticPath({
          lang: language,
          searchParams: location.searchParams,
          hash: location.hash,
        }),
      });
    });
  }

  const LogIn = setTranslatedStaticPage(
    Public,
    RootPages.ClientAreaLogIn,
    Routes.clientAreaLogIn.routesByLanguage,
    logInHandler,
  );

  function emailVerifyHandler(
    location: ResolvedRouteLocation,
    info: RoutingInfo,
    language: Locale,
  )
  {
    const { emailVerifyToken } = location.routeParams;
    if (!emailVerifyToken)
      return redirectToTranslatedPage(handlers, {
        to: LogIn,
        language,
        location,
        info,
      });
    return () => {
      websiteStores.emailVerify.verify.reload(emailVerifyToken);
      return {
        page: null,
        route: Routes.clientAreaEmailVerify.generatePath(location.routeParams, {
          lang: language,
          searchParams: location.searchParams,
          hash: location.hash,
        }),
      };
    };
  }

  setTranslatedPage(
    Public,
    RootPages.ClientAreaEmailVerify,
    Routes.clientAreaEmailVerify.routesByLanguage,
    emailVerifyHandler,
  );

  setTranslatedPage(
    Public,
    RootPages.ClientAreaPasswordReset,
    Routes.clientAreaPasswordReset.routesByLanguage,
    (location, info, language) => {
      const { passwordResetToken } = location.routeParams;
      if (!passwordResetToken)
        return redirectToTranslatedPage(handlers, {
          to: LogIn,
          language,
          location,
          info,
        });
      return () => {
        websiteStores.passwordReset.setToken(passwordResetToken);
        return null;
      };
    },
  );

  const ClientArea = Public.extend((location, info) => {
    //Waiting for the initial session to be loaded if needed
    return optionalPromiseThen(websiteStores.session.initialSession, () => {
      //Redirecting to LogIn page if not connected
      if (!websiteStores.session.authenticated)
        return redirectToTranslatedPage(handlers, {
          to: LogIn,
          language: websiteStores.locale.locale,
          location,
          info,
        });
      return null;
    });
  });

  const Profile = setTranslatedStaticPage(
    ClientArea,
    RootPages.ClientAreaProfile,
    Routes.clientAreaProfile.routesByLanguage,
    async (location, info) => {
      await websiteStores.agencies.agencies.ensureSuccess();
      await websiteStores.profile.profileData.ensureSuccessReload();
      return null;
    },
  );

  const SearchCreate = setTranslatedStaticPage(
    ClientArea,
    RootPages.ClientAreaSearches,
    Routes.clientAreaSearchCreate.routesByLanguage,
    async (location, info, language) => {
      await Promise.all([
        websiteStores.areas.areas.ensureSuccess(),
        websiteStores.agencies.agencies.ensureSuccess(),
        websiteStores.searches.searches.ensureSuccess(),
      ]);
      websiteStores.searches.setSearchId(null);
      return null;
    },
  );
  async function SearchesHandler(
    location: ResolvedRouteLocation,
    info: RoutingInfo,
    language: Locale,
  )
  {
    await Promise.all([
      websiteStores.areas.areas.ensureSuccess(),
      websiteStores.agencies.agencies.ensureSuccess(),
      websiteStores.searches.searches.ensureSuccess(),
    ]);
    const { searchId: rawSearchId } = location.routeParams;
    const searchId = Number.parseInt(rawSearchId);
    const effectiveSearchId = websiteStores.searches.setSearchId(searchId || undefined);
    if (effectiveSearchId === null)
      return redirectToTranslatedPage(handlers, {
        to: SearchCreate,
        language,
        location,
        info,
      });
    return () => {
      return {
        page: null,
        route: Routes.clientAreaSearch.generatePath({
          searchId: effectiveSearchId,
        }, {
          lang: language,
        }),
      };
    };
  };
  setTranslatedPage(
    ClientArea,
    RootPages.ClientAreaSearches,
    Routes.clientAreaSearch.routesByLanguage,
    SearchesHandler,
  );
  setTranslatedStaticPage(
    ClientArea,
    RootPages.ClientAreaSearches,
    Routes.clientAreaSearches.routesByLanguage,
    SearchesHandler,
  );

  setTranslatedStaticPage(
    ClientArea,
    RootPages.ClientAreaFavorites,
    Routes.clientAreaFavorites.routesByLanguage,
    async (location, info) => {
      await websiteStores.agencies.agencies.ensureSuccess();
      await websiteStores.favorites.refresh();
      return null;
    },
  );

  setTranslatedStaticPage(Public, RootPages.ClientAreaSignUp, Routes.clientAreaSignUp.routesByLanguage);
}
