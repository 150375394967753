import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {fr} from 'date-fns/locale';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const Container = styled('div')({
  display: 'flex',
  width: '40%',
  columnGap: '60px',
  "@media (max-width: 1280px)": {
    padding: '0',
    paddingTop: '25px',
    flexDirection: 'column',
    columnGap: '0',
    rowGap: '15px',
    width: '100%',
  },
  '& .MuiButtonBase-root': {
    color: '#050251 !important',
  },
});

const StyledDatePicker = styled(StaticDatePicker)({
  "& .Mui-disabled": {
    opacity: 0.10,
  },
  "& .Mui-selected": {
    color: 'white !important',
  },
  "& .MuiPickersDay-today": {
    border: 'none !important',
    color: 'red !important',
    opacity: "1 !important",
  },
});


const Calendar = observer(() => {
  const { valuation } = useWebsiteStores();
  const [value, setValue] = React.useState<Date | null>(valuation.selectedDate);
  if (!value) {
    setValue(valuation.getMinDate());
  }
  return (
    <Container>
      <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
        <StyledDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          views={["day"]}
          minDate={valuation.getMinDate()}
          value={value}
          disablePast={true}
          showDaysOutsideCurrentMonth={true}
          onChange={(newValue : Date) => {
            setValue(newValue);
            valuation.setSelectedDate(newValue);
          }}
        />
      </LocalizationProvider>
    </Container>
  );
});

export default Calendar;
