import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, styled } from '@mui/material';
import type { Language } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { Agency } from '@breteuil-website/store/ui/pages/contact';
import { PageTitle } from '@breteuil-website/components/common';
import { AgencyComponent } from './Agency';

const Container = styled('div')({
  position: 'relative',
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(9.375)} 0`,
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(2.5)} 0`,
  },
}));

const AgenciesGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '30px',
  columnGap: '16px',
  padding: '0px 130px 50px 130px',
  '@media (max-width: 1250px)': {
    padding: '0px 50px 50px 50px',
    gridTemplateColumns: '1fr',
  },
  '@media (max-width: 959px)': {
    padding: '0',
    margin: '10% 0',
  },
});

interface CityProps {
  city: (language: Language) => string,
  agencies: Array<Agency>,
}

const AgenciesContainerDesktop = observer((props: { city: CityProps }) => {
  const { agencies } = props.city;

  return (
    <AgenciesGrid>
      {agencies.map((agency, index) => (
        <AgencyComponent
          key={index}
          agency={agency}
        />
      ))}
    </AgenciesGrid>
  );
});

const Region = observer(() => {
  const citiesWithAgencies = useWebsiteStores().contact.agenciesPerCity;
  const { locale } = useWebsiteStores();

  return (
    <>
      {Array.from(citiesWithAgencies.entries()).map(([city, agencies], i) => (
        <Container key={i}>
          <PageTitle title={city(locale.locale)}/>
          <ContentWrapper>
            <AgenciesContainerDesktop city={{city, agencies}}/>
          </ContentWrapper>
        </Container>
      ))}
    </>
  );
});

export default Region;
