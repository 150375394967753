import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

//TODO: deduplicate this with common/elements/Button
//If this is specific to Homepage, move it to Homepage's directory
export default styled(Button)({
    width:"100%",
    height:"100%",
    padding: '10px 28px !important',
    fontFamily: 'Mulish !important',
    fontWeight: '700 !important' as any,
    fontSize: '14px !important',
    borderRadius: '0px !important',
    backgroundColor: '#050251 !important',
    color:'white !important',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: '#0C17E1 !important',
      boxShadow: 'none !important',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(211,40,124,0.6) !important',
    },
  });
