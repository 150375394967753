import type { Agency as AgencyAPI } from '@breteuil-website/store/ui/common/agencies/api';

import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { ensureFetchableResource } from '@repo-breteuil/front-error';
import { useLocale, type Locale } from '@repo-breteuil/common-texts';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import type { AgenciesStore } from '@breteuil-website/store/ui/common/agencies';
import mapStyle from './mapStyle';

export type Agency = Pick<AgencyAPI, 'slug' | 'latitude' | 'longitude'>;

export interface MapOpts {
  focusedAgency?: Agency | null,
  markers?: Array<google.maps.Marker>,
  disabled?: boolean,
}

export interface AgenciesMapProps {
  opts?: MapOpts,
}

const DefaultCenter: google.maps.LatLngLiteral = { lat: 48.864716, lng: 2.349014 }; // Paris
const DefaultZoom = 12;
const FocusedAgencyZoom = 14;
const DefaultMarkerIcon: google.maps.Icon = {
  url: '/assets/transactions-map/map-marker-breteuil.svg',
  scaledSize: { width: 35, height: 35 } as google.maps.Size,
};
const GreyedAgencyMarkerIcon: google.maps.Icon = {
  url: '/assets/transactions-map/map-marker-breteuil-grey.svg',
  scaledSize: { width: 35, height: 35 } as google.maps.Size,
};
const SelectedMarkerIcon: google.maps.Icon = {
  url: '/assets/transactions-map/map-marker-breteuil-dark.svg',
  scaledSize: { width: 60, height: 60 } as google.maps.Size,
};

function initMapWithMarkers(
  mapElement: HTMLElement,
  opts: {
    agenciesStore: AgenciesStore,
    lang: Locale,
  } & MapOpts,
)
{
  const { agenciesStore, lang, focusedAgency, markers, disabled } = opts;
  const zoom = Boolean(focusedAgency) ? FocusedAgencyZoom : DefaultZoom;
  const center: google.maps.LatLngLiteral = focusedAgency != null
    ? { lat: focusedAgency.latitude, lng: focusedAgency.longitude }
    : DefaultCenter;
  const map = new google.maps.Map(mapElement, {
    zoom,
    minZoom: zoom - 3,
    maxZoom: zoom + 3,
    center: center,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    ...(disabled ? { gestureHandling: 'none' } : null),
    styles: mapStyle,
  });
  const defaultAgencyIcon = focusedAgency ? GreyedAgencyMarkerIcon : DefaultMarkerIcon;
  const agencies = ensureFetchableResource(agenciesStore.agencies);
  for (const agency of agencies) {
    new google.maps.Marker({
      map,
      position: { lat: agency.latitude, lng: agency.longitude },
      title: agency.nameTranslated(lang),
      icon: (agency.slug === focusedAgency?.slug ? SelectedMarkerIcon : defaultAgencyIcon),
    });
  }
  markers?.forEach((marker) => { marker.setMap(map); });
}

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const AgenciesMap = observer((props: AgenciesMapProps) => {
  const lang = useLocale();
  const stores = useWebsiteStores();
  const { opts } = props;
  const [mapElement, setMapElement] = React.useState<HTMLElement | null>(null);
  React.useEffect(() => {
    if (!googleMapsAPI.loaded || mapElement == null)
      return;
    initMapWithMarkers(mapElement, {
      ...opts,
      agenciesStore: stores.agencies,
      lang,
    });
  }, [googleMapsAPI.loaded, mapElement, opts?.focusedAgency, opts?.markers]);

  return (
    <Container>
      <div style={{ width: '100%', height: '100%' }} ref={setMapElement}></div>
    </Container>
  );
});

export default AgenciesMap;
