
import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { Button } from '@breteuil-website/components/common';
import ShareComponent from '@breteuil-website/components/common/app/share/Share';
import type { Property } from '@breteuil-website/store/ui/pages/property/api';
import texts from '../texts';
import { useTexts } from '@repo-breteuil/common-texts';

const Container = styled('div')(({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  '@media (max-width: 960px)': {
    padding: '10px',
    position: 'sticky',
    margin: "5px auto",
    top: "50px",
    zIndex: 2,
    backgroundColor: '#FFFFFF',
  },
}));


export const CTAButton = styled(Button)(({
  width: '31% !important',
  backgroundColor: '#050251 !important',
  '&:hover': {
    backgroundColor: '#072BB8',
    boxShadow: 'none',
  },
  '& .MuiButton-endIcon': {
    margin: "0 !important",
  },
  '& img': {
    width: '28px',
    height: '28px',
  },
}));

export interface CTAProps {
  property: Property,
  contactInfo: {
    phone?: string,
    email?: string,
  },
};

export const CTA = observer((props: CTAProps) => {
  const { property } = props;
  const {phone, email} = props.contactInfo;
  const T = useTexts(texts);
  return (
    <Container>
      {/* share */}
      <ShareComponent url={window.location.href}/>
      {/* show agency phone */}
      <CTAButton disabled={phone == null} href={`tel:${phone}`} variant="contained" endIcon={<img src="/assets/icons/phone.svg" alt="phone icon"/>}/>
      {/* mailto agency */}
      <CTAButton disabled={email == null} href={`mailto:${email}?subject=${T.formatMailSubject(property.ref)}&body=${T.formatMailText(window.location.href)}`} variant="contained" endIcon={<img src="/assets/icons/mail.svg" alt="mail icon"/>}/>
    </Container>
  );
});
