import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Page, PageTitle as BannerText, PageMeta } from '@breteuil-website/components/common';
import texts from './texts';
import Banner from './banner/Banner';
import Agencies from './Agencies';
import Properties from './properties/Properties';
import AvailableCoffeeComponent from './AvailableCoffeeComponent';
import Services from './Services';
import Valuation from './Valuations';
import ClientArea from './ClientArea';
import Ethics from './Ethics';
import RemarkablePeople from './RemarkablePeople';
import { useTexts } from '@repo-breteuil/common-texts';
import { Box } from '@mui/material';

const Container = styled(Box)({
  position: 'relative',
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(5)} 0`,
}));

const Home = observer(() => {
  const T = useTexts(texts);
  return (
    <Page>
      <PageMeta
        title={T.pageTitle}
        description={T.metaDescription}
      />
      <Banner/>
      <Properties/>
      <Container>
        <BannerText title={T.titles.excellence}/>
        <ContentWrapper>
          <Agencies/>
          <RemarkablePeople/> {/* TO ADJUST */}
          <AvailableCoffeeComponent/>
          <Services/>
        </ContentWrapper>
      </Container>
      <Container>
        <BannerText title={T.titles.transparency}/>
        <ContentWrapper>
          <Valuation/>
          <ClientArea/>
        </ContentWrapper>
      </Container>
      <Container>
        <BannerText title={T.titles.ethics}/>
        <ContentWrapper>
          <Ethics/>
        </ContentWrapper>
      </Container>
    </Page>
  );
});

export default Home;
