import type {
  PageRouteHandlers,
  PageRouteHandlersScope,
} from '@lib/routing';
import type { LanguageRouteHandler } from '@core/store/routing';

import { action } from 'mobx';
import { fromBase64_URLSafe } from '@repo-lib/utils-core';
import { Locale } from '@repo-breteuil/common-texts';
import device from '@core/store/Device';
import {
  setTranslatedPage,
} from '@breteuil-website/store/routing/translatedRoutesLang';
import * as Routes from '@breteuil-website/store/routing/routes';
import { RootPages } from '@breteuil-website/store/routing/pages';

import {
  RequestPropertyAccessCode,
} from '@breteuil-website/store/ui/pages/property/api';
import { websiteStores } from '@breteuil-website/store/ui/BreteuilWebsiteStores';

//TODO: centralize this code: rgrep PROPERTY_SHORT_TOKEN
export function parsePropertyShortToken(token: string)
{
  const contactUserIdPart = token.substring(0, 3);
  const accessToken = token.substring(3);
  try
  {
    const contactUserId = fromBase64_URLSafe(contactUserIdPart);
    if (contactUserId === 0)
      return { accessToken, contactUserId: null };
    return { accessToken, contactUserId };
  }
  catch (error)
  {
  }
  return { accessToken, contactUserId: null };
}

function getPropertyParams(searchParams: URLSearchParams)
{
  const shortToken = searchParams.get('t');
  if (shortToken)
    return parsePropertyShortToken(shortToken);

  const agentIdString = searchParams.get('agentId') || searchParams.get('utm_agent');
  const contactUserId = agentIdString ? parseInt(agentIdString) : null;
  const accessToken = searchParams.get('accessToken');
  return { accessToken, contactUserId };
}

const propertyRouteHandler: LanguageRouteHandler<RootPages, Locale> = async (location, info, language) => {
  const { routeParams, searchParams, hash } = location;
  const propertySlug = routeParams.propertySlug;

  if (propertySlug)
  {
    const { contactUserId, accessToken } = getPropertyParams(searchParams);
    const state = await websiteStores.property.queryState({ slug: propertySlug, language, contactUserId, accessToken });

    websiteStores.areas.areas.load();
    if (state)
    {
      if ([
        'PropertyAccessRequest',
        'PropertyAccessCodeInput',
      ].includes(state.state))
        websiteStores.cookies.setOpen(false);
      if (state.state === 'PropertyAccessCodeInput')
      {
        /*
         * If GetProperty returned AccessCodeRequired,
         * RequestPropertyAccessCode should work without errors.
         * So we can safely ignore errors, which will be caught as "An error occured".
         * Note: the only exception would be a race condition between the 2 API calls, with a property whose "confidential" flag changed.
         */
        const phoneNumberHint = await RequestPropertyAccessCode(websiteStores.query, { accessToken: state.accessToken });
        Object.assign(state, { phoneNumberHint });
      }
      return action(() => {
        websiteStores.property.setState(state);
        websiteStores.property.setContactUserId(contactUserId);

        const scrollTop = (hash === '#property-video') ? {
          elementId: hash.substring(1),
          verticalAlign: device.desktop ? 'end' as const : 'center' as const,
        } : undefined;
        const route = ('property' in state) ? (
          Routes.propertyShort.generatePath({ propertySlug: state.property.slug }, { lang: language })
        ) : null;
        return {
          page: null,
          route,
          scrollTop,
        };
      });
    }
  }
  return () => ({ page: RootPages.NotFound, route: null });
};

export default function setHandlers(
  handlers: PageRouteHandlers<RootPages>,
  deps: {
    Public: PageRouteHandlersScope<RootPages>,
  },
)
{
  const { Public } = deps;

  setTranslatedPage(
    Public,
    RootPages.Property,
    Routes.propertyByRefId.routesByLanguage,
    propertyRouteHandler,
  );
  setTranslatedPage(
    Public,
    RootPages.Property,
    Routes.propertyShort.routesByLanguage,
    propertyRouteHandler,
  );
}
