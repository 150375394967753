import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { EmailValidation } from '@breteuil-website/_hardcoded';
import { getErrorCode } from '@repo-breteuil/front-error';
import {
  createValueFormField,
  useForm,
} from '@repo-lib/mobx-forms';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router, { Routes } from '@breteuil-website/store/routing';
import texts from '@breteuil-website/components/pages/client-area/sign-up/texts';
import {
  Button,
  TextField,
  PasswordStrengthBar,
} from '@breteuil-website/components/common';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const SignupContainer = styled('div')({
  maxWidth: '600px',
  margin: '0px auto',
  width: '90%',
});

const FormGrid = styled('form')({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(5, 0.4fr)',
  gap: '20px 10px',
  '@media (max-width: 960px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridTemplateRows: 'unset',
    gap: '10px',
  },
});

const FirstNameField = styled('div')({
  gridArea: '1 / 1 / 2 / 3',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const LastNameField = styled('div')({
  gridArea: '1 / 3 / 2 / 5',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const MobileField = styled('div')({
  gridArea: '2 / 1 / 3 / 3',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const EmailField = styled('div')({
  gridArea: '2 / 3 / 3 / 5',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const PasswordField = styled('div')({
  gridArea: '3 / 1 / 4 / 5',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const ConfirmPasswordField = styled('div')({
  gridArea: '4 / 1 / 5 / 5',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const SubmitButton = styled(Button)({
  gridArea: '5 / 1 / 6 / 5',
  fontSize: '12px !important',
  '@media (max-width: 960px)': {
    gridArea: 'unset',
  },
});

const EmailInfo = styled('p')({
  margin: '15px auto 0 0',
  opacity: '0.5',
  fontSize: '12px',
});

const EmailAlreadyUsed = styled('div')({
  color: '#FF0000',
  fontSize: '12px',
});

const ConfirmPasswordError = styled('div')({
  color: '#FF0000',
  fontSize: '12px',
});

export interface TitleBannerProps {
  closeModal?: () => void,
}

export const RegisterForm = observer((props: TitleBannerProps) => {
  const lang = useLocale();
  const T = useTexts(texts);
  const { logIn, globalMessage } = useWebsiteStores();
  const PhoneNumberErrorStyle = { marginBottom: '15px' };
  const [loginAlreadyUsed, setLoginAlreadyUsed] = React.useState(false);

  const form = useForm(() => ({
    firstname: createValueFormField('', { validation: (value) => (value !== '') }),
    lastname: createValueFormField('', { validation: (value) => (value !== '') }),
    mobilePhone: createValueFormField(''),
    email: createValueFormField(logIn.userEmail || ''),
    password: createValueFormField('', { validation: (value) => (value !== '' && value.length >= 6) }),
    confirmPassword: createValueFormField(''),
  }), {
    onSubmit: (values) => {
      const promise = logIn.register(values);
      globalMessage.handlePromise(promise, {
        onSuccess: () => {
          form.reset();
          if (props.closeModal)
            props.closeModal();
          router.changeRoute(Routes.clientAreaSearches.generateStaticPath({ lang }));
        },
        successMessage: null,
        onError: (error) => {
          if (getErrorCode(error) === 'EmailAlreadyUsed') {
            setLoginAlreadyUsed(true);
          }
        },
        errorMessage: (error) => T.handleRegisterError(error),
      });
      return promise;
    },
  });
  form.fields.email.setValidation((value) => (EmailValidation(value) && !loginAlreadyUsed));
  form.fields.confirmPassword.setValidation((value) => (value !== '' && value === form.fields.password.value));
  React.useEffect(() => {
    setLoginAlreadyUsed(false);
  }, [form.fields.email.value]);

  return (
    <SignupContainer>
      <FormGrid onSubmit={form.handleSubmit}>
        <FirstNameField>
          <TextField
            field={form.fields.firstname}
            label={T.firstname}
          />
        </FirstNameField>
        <LastNameField>
          <TextField
            field={form.fields.lastname}
            label={T.lastname}
          />
        </LastNameField>
        <MobileField>
          <TextField
            style={form.fields.mobilePhone.error ? PhoneNumberErrorStyle : {}}
            errorText={T.phoneNumberError}
            field={form.fields.mobilePhone}
            label={T.phone}
          />
        </MobileField>
        <EmailField>
          <TextField
            field={form.fields.email}
            label={T.email}
          />
          {loginAlreadyUsed && (
            <EmailAlreadyUsed>
              {T.emailAlreadyUsed}
            </EmailAlreadyUsed>
          )}
        </EmailField>
        <PasswordField>
          <TextField
            field={form.fields.password}
            label={T.password}
            type='password'
          />
          <PasswordStrengthBar password={form.fields.password.value}/>
        </PasswordField>
        <ConfirmPasswordField>
          <TextField
            field={form.fields.confirmPassword}
            label={T.confirmPassword}
            type='password'
          />
          {form.fields.password.value !== form.fields.confirmPassword.value
            && form.fields.confirmPassword.value.length > 0
            && form.fields.password.value.length > 0 && (
            <ConfirmPasswordError>
              {T.confirmPasswordError}
            </ConfirmPasswordError>
          )}
        </ConfirmPasswordField>
        <SubmitButton
          type='submit'
          disabled={form.submitting}
        >
          {T.signUp}
        </SubmitButton>
      </FormGrid>
      <EmailInfo>{T.emailIsLogin}</EmailInfo>
    </SignupContainer>
  );
});

export default RegisterForm;
