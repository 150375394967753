import { Locale } from '@repo-breteuil/common-texts';
import { TranslatedStaticRoute } from '@breteuil-website/store/routing/translatedRoutes';

export * from './optOut';
export * from './clientArea';
export * from './properties';
export * from './agencies';
export * from './news';
export * from './news';

export * from './compat';

export const home = new TranslatedStaticRoute({
  [Locale.French]: '/',
  [Locale.English]: '/',
});

export const services = new TranslatedStaticRoute({
  [Locale.French]: '/services',
  [Locale.English]: '/services',
});

export const valuation = new TranslatedStaticRoute({
  [Locale.French]: '/estimation',
  [Locale.English]: '/valuation',
});

export const about = new TranslatedStaticRoute({
  [Locale.French]: '/a-propos',
  [Locale.English]: '/about',
});

export const ourFees = new TranslatedStaticRoute({
  [Locale.French]: '/nos-honoraires',
  [Locale.English]: '/our-fees',
});

export const joinUs = new TranslatedStaticRoute({
  [Locale.French]: '/rejoignez-nous',
  [Locale.English]: '/join-us',
});

export const legalNotices = new TranslatedStaticRoute({
  [Locale.French]: '/mentions-legales',
  [Locale.English]: '/legal-notices',
});
