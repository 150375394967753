import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardMedia,
} from '@mui/material';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import type { User } from '@breteuil-website/store/ui/pages/home/api';
import { Carousel } from '@repo-breteuil/react-components';
import texts from './texts';
import { useTexts } from '@repo-breteuil/common-texts';
import { useWebsiteStores } from '@breteuil-website/components/providers';

const BannerContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  margin: '250px 0',
  "@media (max-width: 960px)": {
    margin: '250px 0 150px 0',
    flexDirection: 'column',
  },
  "@media (max-width: 425px)": {
    margin: '150px 0',
    flexDirection: 'column',
  },
});

const CarouselWrapper = styled('div')({
  display: 'flex',
  width: '50%',
  "@media (max-width: 960px)": {
    width: '100%',
  },
});

const PeopleTextWrapper = styled('div')({
  display: 'flex',
  width: '40%',
  '@media (max-width: 1440px)': {
    width: '50%',
    },
  "@media (max-width: 960px)": {
    width: '100%',
  },
});

const PeopleText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
  "@media (max-width: 960px)": {
    width: '80%',
    margin: '25px auto',
  },
});

const PeopleTextTitle = styled('span')({
  fontSize: '30px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  lineHeight: '45px',
  color: '#050251',
  '@media (max-width: 960px)': {
    fontSize: '24px',
    lineHeight: '30px',
  },
});

const PeopleTextDescription = styled('span')({
  fontSize: '14px !important',
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#050251',
  margin: '30px 0',
});

const RemarkablePeopleOverlay = styled('img')({
  position: 'absolute',
  width: '45vw',
  maxWidth: '400px',
  objectFit: 'cover',
  top: '-120px',
  left: '250px',
  "@media (max-width: 2000px)": {
    left: '100px',
  },
  "@media (max-width: 960px)": {
    position: 'absolute',
    width: '100%',
    top: '-100px',
    left: '10%',
  },
  "@media (max-width: 425px)": {
    width: '80%',
  },
});

const StyledAgentCard = styled(Card)({
  width: "100%",
  maxWidth: '300px',
  margin: 'auto',
  boxShadow: 'none',
  "@media (max-width: 960px)": {
    maxWidth: '225px',
  },
});

interface AgentProps {
  agent: User,
}

const AgentCard = observer((props: AgentProps) => {
  return (
    <StyledAgentCard>
      <CardMedia
        component={"img"}
        sx={{ width: "100%" }}
        image={props.agent.photoURL || '/assets/agencies/collabs/PHOTO_PLACEHOLDER.jpg'}/>
    </StyledAgentCard>
  );
});

interface ConditionalWrapperProps {
  children?: React.ReactNode,
}

const ConditionalWrapper = observer((props: ConditionalWrapperProps) => {
  const isMobile = useMediaQueryMobile();
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const breakPoints: Array<[number/*minWidth*/, number/*slidesPerView*/]> = [
    [960, 1],
    [1032, 1.5],
    [1376, 2.5],
    [1548, 3.5],
    [1892, 4.5],
  ];
  const point = breakPoints.find(([w]) => (width <= w)) || breakPoints[breakPoints.length - 1];
  const slidesPerView = point[1];
  return (
    <Carousel
      slidesPerView={slidesPerView}
      loop={true}
      draggable={false}
      mobile={isMobile}
      spaceBetweenSlides={5 * slidesPerView}
      align={"end"}
      slidesToScroll={isMobile ? 1 : 2}
    >
      {props.children}
    </Carousel>
  );
});

const RemarkablePeople = observer(() => {
  const T = useTexts(texts).people;
  const { home } = useWebsiteStores();
  return (
    <BannerContainer>
      <CarouselWrapper>
        <ConditionalWrapper>
          {home.remarkablePeople.result?.map((item, index) => (
            <AgentCard key={index} agent={item}/>
          ))}
        </ConditionalWrapper>
      </CarouselWrapper>
      <PeopleTextWrapper>
        <PeopleText>
          <PeopleTextTitle>{T.title}</PeopleTextTitle>
          <PeopleTextDescription>{T.description}</PeopleTextDescription>
        </PeopleText>
      </PeopleTextWrapper>
      <RemarkablePeopleOverlay src={"/assets/remarkable_people.png"} alt={"Remarkable people"}/>
    </BannerContainer>
  );
});

export default RemarkablePeople;
