import * as React from 'react';

import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { FormFieldSelect } from '@repo-lib/mobx-forms';

interface RadioOptionsProps
{
  field: FormFieldSelect<boolean>,
}

const RadioOptions = (props: RadioOptionsProps) => {
  const { field } = props;
  const [value, setValue] = React.useState(field?.defaultValue);

  const handleChange = (value: boolean) => {
    setValue(value);
    field.handleChange(value);
  };

  return(
    <RadioGroup row>
      {field.options?.options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
          <Radio
            onChange={() => handleChange(option.value)}
            value={option.value}
            checked={option.value == value}
          />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioOptions;
