import type { ResolvedRouteLocation } from '@repo-lib/routing-routes';
import type {
  RouteHandler,
  RouteHandlerReturnType,
  RoutingInfo,
} from './';

import { isPromise, optionalPromiseThen } from '@repo-lib/utils-core';

export function transformAndExecuteRouteHandler<SourceResultT, TargetResultT>(
  handler: RouteHandler<SourceResultT> | null | undefined,
  transformResult: (result: SourceResultT | null) => TargetResultT,
  location: ResolvedRouteLocation,
  info: RoutingInfo,
): ReturnType<RouteHandler<TargetResultT>>
{
  const res = handler ? handler(location, info) : null;
  return optionalPromiseThen(res, (effect) => {
    if (effect === null)
      return () => transformResult(null);
    return () => transformResult(effect());
  });
}

export function transformRouteHandler<SourceResultT, TargetResultT>(
  handler: RouteHandler<SourceResultT> | null | undefined,
  transformResult: (result: SourceResultT | null) => TargetResultT,
): RouteHandler<TargetResultT>
{
  return (location: ResolvedRouteLocation, info: RoutingInfo) => (
    transformAndExecuteRouteHandler<SourceResultT, TargetResultT>(handler, transformResult, location, info)
  );
}

export function combineRouteHandlers<T>(
  handler1: RouteHandler<T> | null | undefined,
  handler2: RouteHandler<T> | null | undefined,
): RouteHandler<T>
{
  if (!handler1)
  {
    if (!handler2)
      return () => null;
    return handler2;
  }
  if (!handler2)
    return handler1;
  return (location: ResolvedRouteLocation, info: RoutingInfo) => {
    const result1 = handler1(location, info);
    return optionalPromiseThen(result1, (effect1) => {
      if (effect1 !== null)
        return effect1;
      return handler2(location, info);
    });
  };
}

export function handleRouteHandlerErrors<T>(
  handler: RouteHandler<T>,
  errorHandler?: ((
    error: Error,
    location: ResolvedRouteLocation,
    info: RoutingInfo,
  ) => RouteHandlerReturnType<T>) | null,
): RouteHandler<T>
{
  if (!errorHandler)
    return handler;
  return (location: ResolvedRouteLocation, info: RoutingInfo) => {
    try
    {
      const result = handler(location, info);
      if (isPromise(result))
        return result.catch((error) => (
          errorHandler(error, location, info)
        ));
      return result;
    }
    catch (error)
    {
      return errorHandler(error, location, info);
    }
  };
}
