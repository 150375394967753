import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { useTexts } from '@repo-breteuil/common-texts';
import { useMediaQueryMobile } from '@breteuil-website/components/theme';
import texts from './texts';

const OwnersBiosContainer = styled('div')({
  marginBottom: '60px',
});

const OwnersBiosDesktop = styled('div')({
  marginBottom: '60px',
  display: 'flex',
  fontSize: '16px',
});

const Bio = styled('div')({
  marginBottom: '30px',
  fontWeight: 700,
});

const OwnerName = styled('div')({
  color: '#050251',
  fontWeight: 700,
  fontSize: '24px',
});

const OwnerPicture = styled('img')({
  width: '286px',
  height: '286px',
  marginBottom: '30px',
  borderRadius: '50%',
});

const StyledLinkedIn = styled(LinkedIn)({
  fontSize: '35px !important',
  color: '#0C17E1',
  margin: '20px 0px',
});

const BioText = styled('div')({
  fontFamily: 'Mulish',
  fontSize: '15px',
  color: '#050251',
  margin: '0px 25px',
});

const Bios = observer(() => {
  const T = useTexts(texts);
  const isMobile = useMediaQueryMobile();
  const Container = isMobile ? OwnersBiosContainer : OwnersBiosDesktop;

  return (
    <div>
      <Container>
        <Bio>
          <OwnerPicture src='/assets/randomcity.jpg'/> {/*TODO photo arthur*/}
          <OwnerName>
            {T.arthurTitle}
          </OwnerName>
          <a href='https://www.linkedin.com/in/arthur-colarossi-b27a5096'>
            <StyledLinkedIn/> {/*TODO icone mui différent du sketch*/}
          </a>
          <BioText>
            {T.arthurBackground}
          </BioText>
        </Bio>
        <Bio>
          <OwnerPicture src='/assets/randomcity.jpg'/> {/*TODO photo arsene*/}
          <OwnerName>
            {T.arseneTitle}
          </OwnerName>
          <a href='https://www.linkedin.com/in/arsene-colarossi-2215ba104/?originalSubdomain=uk'>
            <StyledLinkedIn/> {/*TODO icone mui différent du sketch*/}
          </a>
          <BioText>
            {T.arseneBackground}
          </BioText>
        </Bio>
      </Container>
    </div>
  );
});

export default Bios;
