import { Locale } from '@repo-breteuil/common-texts';
import { makeTextResources } from '@repo-lib/utils-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  tabs: {
    profile: string,
    searches(): JSX.Element,
    favorites: string,
    logout: string,
    overview: string,
    walletAndMarket: string,
  },
  returnToSite: string,
  clientArea: string,
  logOut: string,
}

export default makeTextResources<Locale, Definition>(new Map([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));
