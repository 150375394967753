import { makeTextResources } from '@repo-lib/utils-texts';
import { Locale } from '@repo-breteuil/common-texts';
import FR from './fr';
import EN from './en';

export interface Definition
{
  tooWeak: string,
  weak: string,
  medium: string,
  strong: string,
}

const texts = makeTextResources<Locale, Definition>(new Map<Locale, Definition>([
  [ Locale.French, FR ],
  [ Locale.English, EN ],
]));

export default texts;
