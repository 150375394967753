/*
 * The field `FRPrepositionDeLieu` should exist on areas, cities and districts.
 * It should be optional (default 'à'), and settable on the backOffice.
 * It would be better to do it once we own the MyBreteuil database, as it would require modifying the cities/districts tables.
 * Maybe it would be even better to do it after we completely rework the areas system (with no more cities/districts, but something more generic).
 */
const AreasFRNamePrepositionDeLieu = new Map<string/*area name*/, string/*préposition*/>([
  [ 'bretagne', 'en' ],
  [ 'normandie', 'en' ],
  [ 'ouest', "dans l'" ],
  [ 'sud ouest', 'dans le' ],
]);
export function getAreaFRNamePrepositionDeLieu(name: string): string | null
{
  return AreasFRNamePrepositionDeLieu.get(name.toLowerCase()) || null;
}
