import { loadScript } from '@repo-lib/browser-utils-dom';
import router from '@core/store/routing/router';

//Initialize ga command queue
//See https://developers.google.com/analytics/devguides/collection/analyticsjs/how-analyticsjs-works
window['ga'] = window['ga'] || function () { (ga.q = ga.q || []).push(arguments); }; // eslint-disable-line
ga.l = +new Date;

if (__GA_TRACKING_ID__)
{
  loadScript('https://www.google-analytics.com/analytics.js', { defer: true });
  ga('create', __GA_TRACKING_ID__, 'auto');
  router.addRouteChangeListener((route) => {
    ga('set', 'page', route);
    ga('send', 'pageview');
  });
}
