import type { CTAProps } from './common';

import * as React from 'react';
import { observer } from "mobx-react";
import { styled } from '@mui/material/styles';
import { OperationType } from '@repo-breteuil/common-definitions';
import texts from './texts';
import { DataInfoMobile, PropertyDetailsWithLogo } from './common';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useTexts } from '@repo-breteuil/common-texts';

const Datas = styled('div')({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "2vh 0vw 0vh 0vw",
  '@media (max-width: 1280px)': {
    width: "100%",
    margin: "10px 0",
    flexDirection: 'column',
  },
});

const Header = styled('div')({
  width: "41%",
  fontFamily: "Mulish",
  '@media (max-width: 1280px)': {
    width: '100%',
    margin: "auto",
  },
});

const Description = styled('div')({
  width: "100%",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "30px",
  color: "#757575",
  margin: "40px 0",
  '@media (max-width: 1280px)': {
    width: "100%",
  },
});

const Title = styled('div')({
  fontSize: "31px",
  fontWeight: 600,
  color: "#00195E",
  '@media (max-width: 1280px)': {
    width: "100%",
  },
});

const GeoriskContainer = styled('div')({
  fontWeight: 400,
  fontSize: '12px',
  fontFamily: 'Mulish',
  color: '#757575',
  marginTop: '50px',
});

export const PropertyData = observer((props: CTAProps) => {
  const { property } = props;
  const isDesktop = useMediaQueryDesktop();
  const T = useTexts(texts);
  return (
    <div>
      <Datas>
        <Header>
          <Title>
            <span>{property.title}</span>
          </Title>
          {!isDesktop ? <DataInfoMobile property={property}/> : null}
          {property.operationType === OperationType.SeasonalRental ? (
            <PropertyDetailsWithLogo property={property}/>
          ) : null}
          <Description>
            <span>{property.description}</span>
          </Description>
          {property.operationType === OperationType.ResidencyTransaction ? (
            <GeoriskContainer>
              <span>{T.textGeoRisk}</span>
            </GeoriskContainer>
          ) : null}
        </Header>
      </Datas>
    </div>
  );
});

export default PropertyData;
