import type { OptionalPromise, RequiredNonNull } from '@repo-lib/utils-core';
import type { ClientAreaSession, ClientAreaContact } from './api';

import { observable, action } from 'mobx';
import { TokenStorage, type TokenInfo } from '@repo-lib/browser-utils-dom';
import { GlobalErrorCode } from '@repo-breteuil/common-definitions';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import type { QueryStore } from '@repo-breteuil/front-store-query';
import { GetSession, LogOut } from './api';

class AuthenticationRequired extends Error
{
  constructor()
  {
    super('Authentication required');
  }

  get __typename()
  {
    return GlobalErrorCode.AuthenticationRequired;
  }
}

export class SessionStore
{
  private static storage = new TokenStorage('authenticationToken');

  constructor(
    public stores: {
      query: QueryStore,
    },
  )
  {
  }

  @observable private _session: ClientAreaSession | null = null;

  private _initializeSessionFromStoragePromise: Promise<void> | null = null;
  private _sessionInitialized = false;

  private async _initializeSessionFromStorage(): Promise<void>
  {
    const storedToken = SessionStore.storage.get();
    if (storedToken === null)
      return;
    const session = await GetSession(this.stores.query);
    this.setSession(session);
  }

  public initializeSessionFromStorage(): Promise<void>
  {
    if (this._initializeSessionFromStoragePromise)
      return this._initializeSessionFromStoragePromise;
    this._initializeSessionFromStoragePromise = this._initializeSessionFromStorage().catch((error) => {
      handleNonCriticalError(error);
      this.setSession(null);
    }).then(() => {
      this._sessionInitialized = true;
    });
    return this._initializeSessionFromStoragePromise!;
  }

  public get sessionInitialized()
  {
    return this._sessionInitialized;
  }

  public get initialSession(): OptionalPromise<ClientAreaSession | null>
  {
    if (this.sessionInitialized)
      return this._session;
    return this.initializeSessionFromStorage().then(() => this._session);
  }

  @action.bound public logOut(): void
  {
    LogOut(this.stores.query).catch(handleNonCriticalError);
    this.setSession(null);
  }

  @action public setSession(session: ClientAreaSession | null)
  {
    if (session === null)
    {
      this._session = null;
      SessionStore.storage.clear();
    }
    else
    {
      const { token, expire } = session;
      this._session = session;
      SessionStore.storage.store(token, expire);
    }
  }

  public ensureContactSession<T extends ClientAreaSession>(
    session: T | null,
  ): RequiredNonNull<T, 'contact'>
  {
    this.setSession(session);
    if (session === null)
      throw new AuthenticationRequired();
    const { contact, ...rest } = session;
    if (contact === null)
      throw new AuthenticationRequired();
    return {
      ...rest,
      contact,
    } as any/*TODO: typescript strangely currently doesn't accept this*/;
  }

  public ensureVerifiedSession<T extends ClientAreaSession>(
    session: T | null,
  ): RequiredNonNull<T, 'contact'>
  {
    const res = this.ensureContactSession(session);
    if (!res.contact.verified)
      throw new AuthenticationRequired();
    return res;
  }

  public get authenticated(): boolean
  {
    return this._session?.contact ? true : false;
  }

  public get verified(): boolean
  {
    return this._session?.contact?.verified || false;
  }

  public get session(): ClientAreaSession | null
  {
    return this._session;
  }

  public get sessionContact(): ClientAreaContact | null
  {
    if (this._session === null)
      return null;
    return this._session.contact;
  }

  public get verifiedSessionContact(): ClientAreaContact | null
  {
    if (!this.verified)
      return null;
    return this._session!.contact!;
  }

  public get authToken(): TokenInfo | null
  {
    if (this._session !== null)
    {
      const { token, expire } = this._session;
      return { token, expire };
    }
    const storedToken = SessionStore.storage.get();
    if (storedToken !== null)
      return storedToken;
    return null;
  }

  public get authorizationHeader(): string | null
  {
    const { authToken } = this;
    if (authToken)
      return `Bearer ${authToken.token}`;
    return null;
  }
}
