import type { Language } from '@repo-breteuil/common-texts';

import { computed } from 'mobx';
import { insertToArrayMap } from '@repo-lib/utils-core';
import type { AgencyStore } from '@breteuil-website/store/ui/pages/agency';

export interface Agency {
  slug: string,
  nameTranslated: (language: Language) => string,
  shortName: (language: Language) => string,
  shortNameMobile: (language: Language) => string | null,
  city: (language: Language) => string, //MyBreteuil "city", which can be a region
  district: (language: Language) => string, //MyBreteuil "district", which can be a city
  address: string,
  shortAddress: string,
  zipCode: string,
  cityName: string,
  phone: string,
  email: string,

  description: ((language: Language) => string | null) | null,
  longitude: number,
  latitude: number,
  pictureURL: string,
}

export class ContactStore
{
  constructor(
    public stores: {
      agency: AgencyStore,
    },
  )
  {
  }

  @computed public get agenciesPerCity()
  {
    const agencies = new Map<(language: Language) => string/*city*/, Array<Agency>>();
    for (const agency of this.stores.agency.agencies)
      insertToArrayMap(agencies, agency.city, agency);
    return agencies;
  }
}
