export default {
  adjustBtn: {
    title: "Alertes de recherches",
    description: 'Je souhaite recevoir des biens correspondant à ma/mes recherches.',
  },

  newsletter: {
    title: "Remarkable Homes",
    description: "Je souhaite recevoir 3 fois par mois les propriétés coup de coeur.",
  },

  unsubscribeBtn: {
    title: 'Communications Breteuil',
    description: 'Je souhaite recevoir les communications de Breteuil (décocher cette case pour ne plus rien recevoir).',
  },

  update: "Ajuster mes préférences",

  unsubscribe: "Se désabonner",

  error: 'Une erreur est survenue. Veuillez réessayer plus tard.',

  confirm: {
    title: 'Votre désinscription a bien été enregistrée !',
    subtitle: 'Nous espérons vous revoir bientôt !',
    caption: '',
  },

  confirmUpdate: {
    title: 'Vos préférences ont bien été enregistées !',
    subtitle: 'Merci pour votre confiance !',
    caption: '',
  },
};
