import type { ChangeCommunicationPreferencesProps } from './api';

import {
  action,
  observable,
} from 'mobx';
import {
  OptOut,
  OptIn,
  ChangeCommunicationPreferences,
  ContactJWTInfo,
} from './api';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { type QueryStore, wrapStoreQuery } from '@repo-breteuil/front-store-query';

export class OptOutStore
{
  constructor(
    public stores: {
      query: QueryStore,
    },
  )
  {
  }

  @observable private _jwt: string | null = null;

  @action private setJWT(jwt: string): void
  {
    this._jwt = jwt;
  }

  public optOut = async (): Promise<void> =>
  {
    await OptOut(this.stores.query, this._jwt!);
  };

  public optIn = async (): Promise<void> =>
  {
    await OptIn(this.stores.query, this._jwt!);
  };

  public contactJWTInfo = new Fetchable(wrapStoreQuery(this, ContactJWTInfo), { catchUnhandled: handleNonCriticalError });

  public async changeCommunicationPreferences(
    args: Omit<ChangeCommunicationPreferencesProps, 'contactCommunicationJWT'>,
  )
  {
    await ChangeCommunicationPreferences(this.stores.query, {
      newsletters: args.newsletters,
      contactCommunicationJWT: this._jwt!,
    });
  };

  public async init(jwt: string)
  {
    this.setJWT(jwt);
    await this.contactJWTInfo.ensureSuccessReload(jwt);
  }
}
