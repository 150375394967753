import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { hasErrorCode } from '@repo-breteuil/front-error';
import { GlobalErrorCode } from '@repo-breteuil/common-definitions';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import router, { Routes } from '@breteuil-website/store/routing';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { Button } from '../Button';
import texts from './texts';

const AlertButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#050251',
  fontSize: '11px !important',
  fontWeight: 500,
  backgroundColor: '#FFFFFF',
  border: '1px solid #050251',
  textTransform: 'none',
  cursor: 'pointer',
  padding: '10px 12px',
  '&:hover': {
    color: '#FFFFFF',
    borderColor: "#050251",
  },
  '@media (max-width:1280px)': {
    marginBottom: 0,
    flex: 1,
    flexGrow: 2,
    border: 'none',
    boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 12%)",
    maxWidth: 'unset',
  },
});

const AlertButtonContainer = styled(AlertButton)({
  '@media (max-width:1280px)': {
    border: '1px solid #050251',
  },
});

const CreateAlertButtonText = styled('span')({
  marginLeft: '5px',
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: '12px',
});

export interface CreateAlertButtonProps {
  CreateAlertMutation: () => Promise<unknown>,
  hasAreaSelected: boolean,
  handleClick?: () => void,
  showText?: boolean,
}

const CreateAlertButton = observer((props: CreateAlertButtonProps) => {
  const { session, globalMessage } = useWebsiteStores();
  const { CreateAlertMutation, hasAreaSelected, handleClick, showText = false } = props;
  const lang = useLocale();
  const T = useTexts(texts);

  const [ loading, CreateAlert ] = globalMessage.useAsyncHandler(CreateAlertMutation,
  {
    onError: (error) => {
      if (hasErrorCode(error) && error.code === GlobalErrorCode.AuthenticationRequired) {
        const route = Routes.clientAreaLogIn.generateStaticPath({ lang });
        router.changeRoute(route);
      }
    },
    successMessage: () => T.alertSuccess,
    errorMessage: (error) => {
      if (hasErrorCode(error) && error.code === "LimitReached")
        return T.alertLimitError;
      return T.alertError;
    },
  });

  if (!hasAreaSelected)
    return null;

  const handleCreateAlert = () => {
    if (!session.sessionContact)
    {
      const route = Routes.clientAreaLogIn.generateStaticPath({ lang });
      router.changeRoute(route);
      return;
    }
    if (handleClick)
      handleClick();
    CreateAlert();
  };

  return (
    <AlertButtonContainer
      onClick={handleCreateAlert}
      disabled={loading}
    >
      <Notifications/>
      {showText ? <CreateAlertButtonText>{T.createAlert.toUpperCase()}</CreateAlertButtonText> : null}
    </AlertButtonContainer>
  );
});

export default CreateAlertButton;
