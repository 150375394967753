import * as React from 'react';

export default {
  pageTitle: "Contactez et trouvez l'agence la plus proche - Breteuil Remarkable Homes",
  pageDescription: "Contactez Breteuil Remarkable Homes et notre équipe passionnée pour une expertise personnalisée et un service sur mesure.",
  title: 'Nous contacter',
  formatAgencyTitle(isMobile : boolean, name: string) {
    return (
      isMobile ? name : `${name}`
    );
  },
  formatAgencyAddress(address: string, zipCode: string, cityName: string) {
    return <React.Fragment>
      <span>{`${address} ${zipCode} ${cityName}`}</span>
    </React.Fragment>;
  },
  formatAgencyAddressMobile(address: string, zipCode: string, cityName: string) {
    return <React.Fragment>
      <span>{address}</span>
      <span>{`${zipCode} ${cityName}`}</span>
    </React.Fragment>;
  },

};
