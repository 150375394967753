import * as React from 'react';
import { observer } from 'mobx-react';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  AddCircleOutline,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { useFormFieldTextInputIntNullable } from '@repo-breteuil/react-components';

export type NumberNullableFieldType = ReturnType<typeof useFormFieldTextInputIntNullable>

export interface NumberPickerProps {
  field: NumberNullableFieldType,
  formatLabel(number: number | null): string,
  color?: string,
}

function handleAdd(value: number | null) {
  const newValue = value !== null ? value + 1 : 1;
  return newValue.toString();
};

function handleRemove(value: number | null) {
  const newValue = value !== null && value > 1 ? value - 1 : 0;
  return newValue.toString();
};

const NumberPicker = observer((props: NumberPickerProps) => {
  const { field, formatLabel, color } = props;

  const value = Number(field.value) || 0;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" maxWidth="190px">
      <IconButton onClick={() => field.handleChange(handleRemove(value))}>
        <RemoveCircleOutline sx={{ color: color || "#050251" }}/>
      </IconButton>
      <Typography component="span">
        {`${value || ''} ${formatLabel(value)}`}
        </Typography>
      <IconButton onClick={() => field.handleChange(handleAdd(value))}>
        <AddCircleOutline sx={{ color: color || "#050251" }}/>
      </IconButton>
    </Stack>
  );
});

export default NumberPicker;
