import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField } from '@mui/material';
import { SelectOptions } from '@repo-lib/mobx-forms';
import {
  useForm,
  createValueFormField,
  useFormFieldSelect,
  useFormFieldSelectNullable,
  useFormFieldSelectMultiple,
} from '@repo-lib/mobx-forms';
import { PhoneValidation } from '@breteuil-website/_hardcoded';
import { useFormFieldTextInputIntNullable } from '@repo-breteuil/react-components';
import { AddressPicker } from '@repo-breteuil/react-components';
import { getErrorCode } from '@repo-breteuil/front-error';
import {
  ExteriorSurfaceType,
  Orientation,
  BreteuilWebsiteViewType,
  BuildingType,
  BreteuilWebsiteRenovationType,
} from '@repo-breteuil/common-definitions';
import { useLocale, useTexts } from '@repo-breteuil/common-texts';
import {
  Button as GenericButton,
  TextFieldStyle as GenericTextFieldStyle,
  TextField as GenericTextField,
} from '@breteuil-website/components/common';
import { ValuationExteriorSurfaceArgs } from '@breteuil-website/store/ui/pages/valuation/api/CreateDetailedSimplePropertyValuation';
import SelectedAgency from '@breteuil-website/components/pages/valuation/SelectedAgency';
import texts from '../texts';
import { useMediaQueryDesktop } from '@breteuil-website/components/theme';
import { useWebsiteStores } from '@breteuil-website/components/providers';
import { SelectWithTilesNullable, SelectTiles } from '@breteuil-website/components/common/elements/select-with-tiles';
import PhoneValidationModal from './PhoneValidationModal';
import ResultComponent from './ResultComponent';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  margin: 'auto',
});

const FormContainer = styled('form')({
  width: '100%',
  maxWidth: '1200px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  '@media (min-width: 2000px)': {
    gap: '20px',
  },
});

const Button = styled(GenericButton)({
});

const FieldLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  minWidth: '185px',
  flexShrink: 0,
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 700,
  color: '#050251',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const FieldInline = styled(FieldLabel)({
  minWidth: 'unset',
  marginRight: '35px',
  '@media (max-width: 1280px)': {
    marginRight: 0,
    marginBottom: '6px',
  },
});

const FieldLabelMarginMobile = styled(FieldLabel)({
  '@media (max-width: 1280px)': {
    marginBottom: '6px',
  },
});

const TextFieldStyle = {
  marginBlock: '.5em',
  fontSize: '1rem',
  backgroundColor: 'white',
  '& .MuiInputLabel-root': {
    color: 'rgba(5, 2, 81, 0.5)',
  },
};


const AddressPickerContainer = styled('div')({
  width: '100%',
  marginBottom: '-13px', // To prevent double space under the picker
});

const ErrorFieldText = styled('span')({
  display: 'block',
  padding: '0 10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  color: '#D32F2F',
});

const FormInlineContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  '@media (max-width: 1280px)': {
    rowGap: '6px',
    flexDirection: 'column',
  },
});

const FormItemContainer = styled('div')({
  display: 'flex',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
  },
  input: {
    caretColor: "transparent",
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      WebkitAppearance: 'textfield',
      MozAppearance: 'textfield',
    },
  },
});

const FormAddressPickerContainer = styled('div')({
  display: 'flex',
  width: '100%',
  marginBottom: '14px',
  '@media (max-width: 1280px)': {
    flexDirection: 'column',
  },
});

const FormExteriorTypeContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

const SubmitButton = styled(Button)({
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '14px',
  padding: '10px !important',
  minWidth: '245px',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const FormInlineTilesContainer = styled(FormInlineContainer)({
  width: '100%',
  margin: '4px 0',
});

const FormTilesContainer = styled(FormItemContainer)({
  margin: '4px 0',
});

const FormTilesFirstInlineContainer = styled(FormTilesContainer)({
  width: '60%',
  '@media (max-width: 1280px)': {
    width: '100%',
  },
});

const FormTilesFullContainer = styled(FormTilesContainer)({
  width: '100%',
});

const SubmitButtonContainer = styled('div')({
  display: 'flex',
  margin: '10px auto',
  '@media (max-width: 1280px)': {
    width: '100%',
    margin: '0',
  },
});

const ValuationAnchorAfterSubmitContainer = styled(SubmitButtonContainer)({
});

const ValuationAnchor = styled(SubmitButton)({
  minWidth: '245px',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
  },
});

const TextField = styled(GenericTextField)(TextFieldStyle);
const ValueTextField = styled(styled(MuiTextField)(GenericTextFieldStyle))(TextFieldStyle);

const SurfaceTextField = styled(TextField)({
  minWidth: '150px',
  maxWidth: '150px',
  '@media (max-width: 1280px)': {
    minWidth: 'unset',
    maxWidth: 'unset',
  },
});

const SelectedAgencyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width:'100%',
  '@media (max-width: 1280px)': {
    marginTop: '5px',
  },
});

const StickyResultPrice = styled('div')({
  position: 'sticky',
  bottom: '0px',
  // Dependant of MethodBody padding + margin
  margin: '0 calc(-1 * (5vw + 1em))',
});

const ResultPriceWrapper = styled('div')({
  backgroundColor: '#FFFFFF',
  padding: '25px calc(5vw + 1em)',
});

function getExteriorSurfaces(values) {
  const fields: Array<ValuationExteriorSurfaceArgs> = [];
  if (values.balcony)
    fields.push({
      surface: values.balcony,
      surfaceType: ExteriorSurfaceType.Balcony,
    });
  if (values.terrace)
    fields.push({
      surface: values.terrace,
      surfaceType: ExteriorSurfaceType.Terrace,
    });
  if (values.closedTerrace)
    fields.push({
      surface: values.closedTerrace,
      surfaceType: ExteriorSurfaceType.ClosedTerrace,
    });
  if (values.backyard)
    fields.push({
      surface: values.backyard,
      surfaceType: ExteriorSurfaceType.Backyard,
    });
  return fields;
}

const Method2 = observer(() => {
  const [exteriorSurfacesTypeButtons, setExteriorSurfacesTypeButtons] = React.useState(new Set<ExteriorSurfaceType>());
  const [confirmPhoneDialogOpen, setConfirmPhoneDialogOpen] = React.useState(false);
  const [isStreetNumberInAddress, setIsStreetNumberInAddress] = React.useState<boolean>(true);
  const [isAddressValid, setIsAddressValid] = React.useState<boolean>(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState<boolean>(true);
  const [openModal, setOpenModal] = React.useState(false);
  const addressRef = React.useRef<HTMLInputElement | null>(null);
  const surfaceRef = React.useRef<HTMLInputElement | null>(null);
  const phoneRef = React.useRef<HTMLInputElement | null>(null);
  const balconyRef = React.useRef<HTMLInputElement | null>(null);
  const terraceRef = React.useRef<HTMLInputElement | null>(null);
  const closedTerraceRef = React.useRef<HTMLInputElement | null>(null);
  const backyardRef = React.useRef<HTMLInputElement | null>(null);
  const { valuation, query, globalMessage } = useWebsiteStores();

  // this is needed because in form onInvalidSubmit the state doesnt change
  const openModalRef = React.useRef<boolean>(openModal);
  openModalRef.current = openModal;

  const isDesktop = useMediaQueryDesktop();

  const handleCloseModal = () => setOpenModal(false);

  const handleConfirmPhoneDialogClose = () => { setConfirmPhoneDialogOpen(false); };
  const T = useTexts(texts);
  const locale = useLocale();
  const lang = () => locale;

  const handleCorrectAddress = (e) => {
    const value = e.target.value;
    if (valuation.startsWithNumber(value) && !isStreetNumberInAddress) {
      if (!isAddressValid) {
        setIsAddressValid(true);
      }
      setIsStreetNumberInAddress(true);
    }
    else if (!valuation.startsWithNumber(value))
      setIsStreetNumberInAddress(false);
    return value;
  };

  const surface = useFormFieldTextInputIntNullable(() => null, {
    lang,
    validation: (value) => (value != null && value > 0),
    useSeparators: false,
  });

  const transactionPrice = useFormFieldTextInputIntNullable(() => null, { lang });

  const renovationCost = useFormFieldTextInputIntNullable(() => null, { lang });

  const renovationYear = useFormFieldTextInputIntNullable(() => null, { lang, useSeparators: false });

  const exteriorSurfaces = useFormFieldSelectMultiple({
    options: () => new SelectOptions<ExteriorSurfaceType>(
      new Map([
        [ExteriorSurfaceType.Balcony, T.method2.form.select.extArea.balcony],
        [ExteriorSurfaceType.Terrace, T.method2.form.select.extArea.terrace],
        [ExteriorSurfaceType.ClosedTerrace, T.method2.form.select.extArea.terraceUnoverlooked],
        [ExteriorSurfaceType.Backyard, T.method2.form.select.extArea.backyard],
      ]),
    ),
    defaultValue: () => [],
  }, [T]);

  const lastFloor = useFormFieldSelectNullable({
    options: () => new SelectOptions<boolean>(
      new Map([
        [true, T.method2.form.select.binary.yes],
        [false, T.method2.form.select.binary.no],
      ]),
    ),
    defaultValue: () => false,
  }, [T]);

  const elevator = useFormFieldSelectNullable({
    options: () => new SelectOptions<boolean>(
      new Map([
        [true, T.method2.form.select.binary.yes],
        [false, T.method2.form.select.binary.no],
      ]),
    ),
    defaultValue: () => false,
  }, [T]);

  const orientation = useFormFieldSelectNullable({
    options: () => new SelectOptions<Orientation>(
      new Map([
        [Orientation.North, T.method2.form.select.direction.north],
        [Orientation.East, T.method2.form.select.direction.east],
        [Orientation.West, T.method2.form.select.direction.west],
        [Orientation.South, T.method2.form.select.direction.south],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);

  const remarkableView = useFormFieldSelectNullable({
    options: () => new SelectOptions<BreteuilWebsiteViewType>(
      new Map([
        [BreteuilWebsiteViewType.Normal, T.method2.form.select.remarkableView.normal],
        [BreteuilWebsiteViewType.Good, T.method2.form.select.remarkableView.good],
        [BreteuilWebsiteViewType.Outstanding, T.method2.form.select.remarkableView.oustanding],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);

  // const popularAdress = useFormFieldSelectNullable({
  //   options: () => new SelectOptions<boolean>(
  //     new Map([
  //       [true, T.method2.form.select.binary.yes],
  //       [false, T.method2.form.select.binary.no],
  //     ]),
  //   ),
  //   defaultValue: () => false,
  // }, [T]);

  const renovation = useFormFieldSelectNullable({
    options: () => new SelectOptions<BreteuilWebsiteRenovationType>(
      new Map([
        [BreteuilWebsiteRenovationType.WorkNeeded, T.method2.form.select.renovation.workNeeded],
        [BreteuilWebsiteRenovationType.Normal, T.method2.form.select.renovation.normal],
        [BreteuilWebsiteRenovationType.New, T.method2.form.select.renovation.new],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);


  // TODO use enum instead of number
  const rooms = useFormFieldSelectNullable({
    options: () => new SelectOptions<number>(
      new Map([
        [1, T.method2.form.select.rooms.one],
        [2, T.method2.form.select.rooms.two],
        [3, T.method2.form.select.rooms.three],
        [4, T.method2.form.select.rooms.four],
        [5, T.method2.form.select.rooms.fiveAndMore],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);

  const floor = useFormFieldSelectNullable({
    options: () => new SelectOptions<number>(
      new Map([
        [0, T.method2.form.select.floors.rdc],
        [1, T.method2.form.select.floors.one],
        [2, T.method2.form.select.floors.two],
        [3, T.method2.form.select.floors.three],
        [4, T.method2.form.select.floors.four],
        [5, T.method2.form.select.floors.fiveAndMore],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);


  const buildingType = useFormFieldSelectNullable({
    options: () => new SelectOptions<BuildingType>(
      new Map([
        [BuildingType.Modern, T.method2.form.select.buildingType.modern],
        [BuildingType.Haussmann, T.method2.form.select.buildingType.haussmannian],
        [BuildingType.Other, T.method2.form.select.buildingType.other],
      ]),
    ),
    defaultValue: () => null,
  }, [T]);

  const contactMe = useFormFieldSelect({
    options: () => new SelectOptions<boolean>(
      new Map([
        [true, T.method2.form.select.binary.yes],
        [false, T.method2.form.select.binary.no],
      ]),
    ),
    defaultValue: () => true,
  }, [T]);

  // TODO related to ExteriorSurfaceType
  const balcony = useFormFieldTextInputIntNullable(() => null, { lang, useSeparators: false });
  const terrace = useFormFieldTextInputIntNullable(() => null, { lang, useSeparators: false });
  const closedTerrace = useFormFieldTextInputIntNullable(() => null, { lang, useSeparators: false });
  const backyard = useFormFieldTextInputIntNullable(() => null, { lang, useSeparators: false });

  const setExteriorSurfacesValidation = (value: ExteriorSurfaceType, visible: boolean) => {
    if (value === ExteriorSurfaceType.Balcony) {
      visible ?
        form.fields.balcony.setValidation((value) => (value != null && value > 0)) :
        form.fields.balcony.setValidation(null);
    } else if (value === ExteriorSurfaceType.Terrace) {
      visible ?
        form.fields.terrace.setValidation((value) => (value != null && value > 0)) :
        form.fields.terrace.setValidation(null);
    } else if (value === ExteriorSurfaceType.ClosedTerrace) {
      visible ?
        form.fields.closedTerrace.setValidation((value) => (value != null && value > 0)) :
        form.fields.closedTerrace.setValidation(null);
    } else if (value === ExteriorSurfaceType.Backyard) {
      visible ?
        form.fields.backyard.setValidation((value) => (value != null && value > 0)) :
        form.fields.backyard.setValidation(null);
    }
  };

  const form = useForm(() => ({
    address: createValueFormField<string | null>(valuation.selectedAddress, { validation: (value) => value ? valuation.startsWithNumber(value) : false }),
    surface,
    transactionPrice,
    exteriorSurfaces,
    floor,
    lastFloor,
    elevator,
    orientation,
    remarkableView,
    rooms,
    buildingType,
    renovationCost,
    renovationYear,
    phone: createValueFormField('', { validation: (value) => (PhoneValidation(value) && value.length > 2) }),
    balcony,
    terrace,
    closedTerrace,
    backyard,
    renovation,
    contactMe,
  }), {
    onSubmit: (values) => {
      setIsAddressValid(true);
      setIsPhoneNumberValid(true);
      const promise = valuation.detailedValuation.ensureSuccessReload({
        address: values.address!,
        surface: values.surface!,
        transactionPrice: values.transactionPrice,
        renovationCost: values.renovationCost,
        renovationYear: values.renovationYear,
        exteriorSurfaces: getExteriorSurfaces(values),
        floor: values.floor,
        lastFloor: values.lastFloor,
        elevator: values.elevator,
        orientation: values.orientation,
        view: values.remarkableView,
        rooms: values.rooms,
        buildingType: values.buildingType,
        phone: values.phone,
        renovation: values.renovation,
        contactMe: values.contactMe,
      });
      return globalMessage.handlePromise(promise, {
        onSuccess: (res) => {
          if (res.estimatedPrice)
            globalMessage.display(T.method1.form.phoneAlreadyConfirmed, { severity: 'success' });
          else {
            if (!openModal)
              setOpenModal(true);
            setConfirmPhoneDialogOpen(true);
          }
          valuation.closestAgency.ensureSuccessReload({
            address: values.address!,
            language: locale,
          });
        },
        onError: (error) => {
          if (getErrorCode(error) === 'InvalidPhoneNumber') {
            setIsPhoneNumberValid(false);
          }
          if (getErrorCode(error) === 'InvalidAddress')
            setIsAddressValid(false);
          return;
        },
        successMessage: null,
        errorMessage: (error) => {
          return T.method1.form.handleFormSubmissionErrors(error);
        },
      });
    },
    onInvalidSubmit() {
      // Focus first invalid field
      if (!form.fields.address.valid)
        addressRef.current?.focus();
      else if (!form.fields.surface.valid)
        surfaceRef.current?.focus();
      else if (!form.fields.balcony.valid)
        balconyRef.current?.focus();
      else if (!form.fields.terrace.valid)
        terraceRef.current?.focus();
      else if (!form.fields.closedTerrace.valid)
        closedTerraceRef.current?.focus();
      else if (!form.fields.backyard.valid)
        backyardRef.current?.focus();
      else if (!form.fields.phone.valid && !openModalRef.current)
        setOpenModal(true);
      else if (!form.fields.phone.valid && openModalRef.current) {
        setIsPhoneNumberValid(form.fields.phone.valid);
        phoneRef.current?.focus();
      }
      setIsAddressValid(form.fields.address.valid);
      if (form.fields.address.value)
        setIsStreetNumberInAddress(valuation.startsWithNumber(form.fields.address.value));
    },
  });

  const handleExteriorSurfaceTypeSelect = (value: ExteriorSurfaceType) => {
    const res = new Set(exteriorSurfacesTypeButtons);
    if (exteriorSurfacesTypeButtons.has(value))
      res.delete(value);
    else
      res.add(value);
    setExteriorSurfacesValidation(value, !exteriorSurfacesTypeButtons.has(value)); // set/unset validation on form field dynamically
    setExteriorSurfacesTypeButtons(res);
  };

  const scrollIntoView = () => {
    const elem = document.getElementById(T.method3.anchorId);
    elem?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    if (form.fields.address.pristine && !form.fields.address.value && valuation.selectedAddress)
      form.fields.address.handleChange(valuation.selectedAddress);
  }, [valuation.selectedAddress]);

  return (
    <Container>
      <FormContainer onSubmit={form.handleSubmit}>
        <FormAddressPickerContainer>
          <FieldLabel>{T.method1.form.labels.address}</FieldLabel>
          <AddressPickerContainer>
            <AddressPicker
              value={form.fields.address.value}
              queryStore={query}
              onChange={(value) => {
                form.fields.address.handleChange(value);
                if (isStreetNumberInAddress) {
                  valuation.setAddress(value);
                }
              }}
              onLoadError={(error) => globalMessage.displayError(error)}
              renderInput={(props) => (
                <ValueTextField
                  {...props}
                  onChange={handleCorrectAddress}
                  inputRef={addressRef}
                  error={!isAddressValid}
               />
              )}
              limitToFrance
              limitToParis
           />
            {!isStreetNumberInAddress ? <ErrorFieldText>{T.method1.form.addressWithoutNumberError}</ErrorFieldText> : null}
          </AddressPickerContainer>
        </FormAddressPickerContainer>
        <FormInlineContainer>
          <FormItemContainer>
            <FieldLabel>{T.method2.form.input.surface}</FieldLabel>
            <SurfaceTextField
              label={T.method2.form.input.surfaceMetric}
              inputRef={surfaceRef}
              field={form.fields.surface}
              type={"number"}
           />
          </FormItemContainer>
          <FormTilesContainer>
            <FieldInline>{T.method2.form.input.rooms}</FieldInline>
            <SelectWithTilesNullable
              field={form.fields.rooms}
              overrideTileWidth
              overrideSelectedColor
              customStyle={isDesktop ? {} : {
                minWidth: 'unset',
                padding: '10px 15px',
              }}
           />
          </FormTilesContainer>
        </FormInlineContainer>

        <FormExteriorTypeContainer>
          <FormInlineTilesContainer>
            <FieldLabel>{T.method2.form.select.extArea.label}</FieldLabel>
            <SelectTiles
              options={form.fields.exteriorSurfaces.options}
              selected={Array.from(exteriorSurfacesTypeButtons)}
              onSelect={handleExteriorSurfaceTypeSelect}
              overrideTileWidth
              overrideSelectedColor
              customStyle={{ width: !isDesktop ? 'calc(50% - 4px)' : 'unset' }}
           />
          </FormInlineTilesContainer>
          {exteriorSurfacesTypeButtons.has(ExteriorSurfaceType.Balcony) ? (
            <FormItemContainer>
              <FieldLabel>{T.method2.form.input.balcony}</FieldLabel>
              <TextField type={"number"} inputRef={balconyRef} field={form.fields.balcony} label={T.method2.form.input.surfaceMetric}/>
            </FormItemContainer>
          ) : null}
          {exteriorSurfacesTypeButtons.has(ExteriorSurfaceType.Terrace) ? (
            <FormItemContainer>
              <FieldLabel>{T.method2.form.input.terrace}</FieldLabel>
              <TextField type={"number"} inputRef={terraceRef} field={form.fields.terrace} label={T.method2.form.input.surfaceMetric}/>
            </FormItemContainer>
          ) : null}
          {exteriorSurfacesTypeButtons.has(ExteriorSurfaceType.ClosedTerrace) ? (
            <FormItemContainer>
              <FieldLabel>{T.method2.form.input.terraceUnoverlooked}</FieldLabel>
              <TextField type={"number"} inputRef={closedTerraceRef} field={form.fields.closedTerrace} label={T.method2.form.input.surfaceMetric}/>
            </FormItemContainer>
          ) : null}
          {exteriorSurfacesTypeButtons.has(ExteriorSurfaceType.Backyard) ? (
            <FormItemContainer>
              <FieldLabel>{T.method2.form.input.backyard}</FieldLabel>
              <TextField type={"number"} inputRef={backyardRef} field={form.fields.backyard} label={T.method2.form.input.surfaceMetric}/>
            </FormItemContainer>
          ) : null}
        </FormExteriorTypeContainer>

        <FormInlineContainer>
          <FormTilesFirstInlineContainer>
            <FieldLabelMarginMobile>{T.method2.form.input.floor}</FieldLabelMarginMobile>
            <SelectWithTilesNullable
              field={form.fields.floor}
              overrideTileWidth
              overrideSelectedColor
              customStyle={{ padding: '10px 15px' }}
           />
          </FormTilesFirstInlineContainer>
          <FormTilesContainer>
            <FieldInline>{T.method2.form.select.lastFloor}</FieldInline>
            <SelectWithTilesNullable
              field={form.fields.lastFloor}
              overrideTileWidth
              overrideSelectedColor
           />
          </FormTilesContainer>
        </FormInlineContainer>
        <FormInlineContainer>
          <FormTilesFirstInlineContainer>
            <FieldLabelMarginMobile>{T.method2.form.select.direction.label}</FieldLabelMarginMobile>
            <SelectWithTilesNullable
              field={form.fields.orientation}
              overrideTileWidth
              overrideSelectedColor
            />
            </FormTilesFirstInlineContainer>
            <FormTilesContainer>
              <FieldInline>{T.method2.form.select.elevator}</FieldInline>
              <SelectWithTilesNullable
                field={form.fields.elevator}
                overrideTileWidth
                overrideSelectedColor
             />
            </FormTilesContainer>
        </FormInlineContainer>
        <FormInlineContainer>
          <FormTilesFirstInlineContainer>
            <FieldLabelMarginMobile>{T.method2.form.select.remarkableView.label}</FieldLabelMarginMobile>
            <SelectWithTilesNullable
              field={form.fields.remarkableView}
              overrideTileWidth
              overrideSelectedColor
           />
          </FormTilesFirstInlineContainer>
        </FormInlineContainer>
        <FormTilesFullContainer>
          <FieldLabelMarginMobile>{T.method2.form.select.buildingType.label}</FieldLabelMarginMobile>
          <SelectWithTilesNullable
            field={form.fields.buildingType}
            overrideTileWidth
            overrideSelectedColor
         />
        </FormTilesFullContainer>
        <FormTilesFullContainer>
          <FieldLabelMarginMobile>{T.method2.form.input.renovation}</FieldLabelMarginMobile>
          <SelectWithTilesNullable
              field={form.fields.renovation}
              overrideTileWidth
              overrideSelectedColor
           />
        </FormTilesFullContainer>
        <SubmitButtonContainer>
          <SubmitButton type="submit" disabled={!(form.fields.address.value !== null && form.fields.surface.value !== null)}>{T.method2.form.submitButton}</SubmitButton>
        </SubmitButtonContainer>
        <PhoneValidationModal
          form={form}
          handleCloseModal={handleCloseModal}
          handleConfirmPhoneDialogClose={handleConfirmPhoneDialogClose}
          confirmPhoneDialogOpen={confirmPhoneDialogOpen}
          isPhoneNumberValid={isPhoneNumberValid}
          openModal={openModal}
          phoneRef={phoneRef}
        />
        {isDesktop ? (
          <React.Fragment>
            <ResultComponent/>
            <SelectedAgencyContainer>
              <SelectedAgency agency={valuation.closestAgency.result}/>
            </SelectedAgencyContainer>
          </React.Fragment>
        ) : null}
      </FormContainer>
      {!isDesktop && valuation.isParisSelected ? (
        <React.Fragment>
          <StickyResultPrice>
            <ResultPriceWrapper>
              <ResultComponent/>
            </ResultPriceWrapper>
          </StickyResultPrice>
          <SelectedAgencyContainer>
            <SelectedAgency agency={valuation.closestAgency.result}/>
          </SelectedAgencyContainer>
        </React.Fragment>
      ) : null}
        {valuation.detailedValuation.result ? (
          <ValuationAnchorAfterSubmitContainer>
            <ValuationAnchor onClick={scrollIntoView}>{T.method3.anchorText}</ValuationAnchor>
          </ValuationAnchorAfterSubmitContainer>
        ) : null}
    </Container>
  );
});

export default Method2;
