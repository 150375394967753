import * as React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import {
  Language,
  isValidLocale,
} from '@repo-breteuil/common-texts';
import { getCurrentLocationInOtherLanguage } from '@breteuil-website/store/routing';

//Keep this up to date when adding new languages
const languages = [
  [ Language.French, 'FR' ],
  [ Language.English, 'EN' ],
  [ Language.Portuguese, 'PT' ],
  [ Language.Spanish, 'ES' ],
] as const;

// Convert our lang format to ISO 639-1 language code
const langToIsoCode = new Map([
  [ 'FR', 'fr' ],
  [ 'EN', 'en' ],
  [ 'PT', 'pt' ],
  [ 'ES', 'es' ],
]);

export const HrefLangTags = observer(() => {
  const links = languages.map(([ lang, hreflang ]) => {
    if (!isValidLocale(lang))
      return null;
    const path = getCurrentLocationInOtherLanguage(lang);
    if (!path)
      return null;
    const href = window.location.origin + path;
    return (
      <link key={hreflang} rel="alternate" hrefLang={langToIsoCode.get(hreflang)!} href={href}/>
    );
  }).filter(s => (s !== null));
  return (
    <Helmet>
      {links}
    </Helmet>
  );
});
